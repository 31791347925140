import { Title } from '@mantine/core';
import React from 'react';

interface StepTitleProps {
    title: string;
    customClassName?: string;
}

const StepTitle: React.FC<StepTitleProps> = ({ title, customClassName }) => {
    return (
        <Title
            className={`text-lg font-medium text-gray-800 ${customClassName}`}
        >
            {title}
        </Title>
    );
};

export default React.memo(StepTitle);
