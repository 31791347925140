import { subject } from '@casl/ability';
import { DEFAULT_PROVIDER } from '@components/Channels/ChannelTabs';
import ChannelTabs from '@components/ChannelTabs';
import { useAbilityContext } from '@components/common/Authorization';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useLocale } from '@hooks/useLocale';
import {
    useProject,
    useUpdateReachabilityConfigMutation,
} from '@hooks/useProject';
import {
    ProjectSettings,
    type CommunicationChannel,
    type ReachabilityConfig,
    type TableConfig,
} from '@lightdash/common';
import { Button, Group, Stack, Tabs, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { removeEmptyParams } from '@utils/helpers';
import _ from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';
import ReachabilityVariable from './ReachabilityVariable';
import VariablePropertySelect from './VariablePropertySelect';

const ReachabilityVariables = () => {
    const { t } = useLocale();
    const [activePill, setActivePill] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);
    const [opened, { open, close }] = useDisclosure();
    const [
        primaryVariableOpened,
        { open: openPrimaryVariable, close: closePrimaryVariable },
    ] = useDisclosure();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: project, isInitialLoading } = useProject(projectUuid);
    const history = useHistory();
    const ability = useAbilityContext();
    const { user } = useApp();
    const canEditReachabilityVariables = ability.can(
        'edit',
        subject(ProjectSettings.reachabilityVariables, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const cannotViewReachabilityVariables = ability.cannot(
        'view',
        subject(ProjectSettings.reachabilityVariables, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const {
        mutate: updateReachabilityConfig,
        isLoading: isUpdatingReachabilityConfig,
    } = useUpdateReachabilityConfigMutation(projectUuid);

    const [reachabilityConfig, setReachabilityConfig] = useState<
        ReachabilityConfig | undefined
    >(project?.attributes?.reachability);

    const handlePillChange = useCallback((pill: CommunicationChannel) => {
        setActivePill(pill);
    }, []);
    const hasFormChanged = _.isEqual(
        removeEmptyParams(project?.attributes?.reachability ?? []),
        removeEmptyParams(reachabilityConfig ?? []),
    );
    const handlePrimaryVariableChange = useCallback(
        (newConfig: TableConfig) => {
            setReachabilityConfig((prev) => {
                if (!prev)
                    return {
                        [activePill]: { primary: newConfig },
                    } as ReachabilityConfig;
                return {
                    ...prev,
                    [activePill]: {
                        ...prev[activePill],
                        primary: newConfig,
                    },
                };
            });
        },
        [activePill],
    );

    const handleSecondaryVariableChange = useCallback(
        (newConfigs: TableConfig[]) => {
            setReachabilityConfig((prev) => {
                if (!prev)
                    return {
                        [activePill]: {
                            primary: project?.attributes?.reachability?.[
                                activePill
                            ]?.primary ?? {
                                tableName: '',
                                dimensionName: '',
                            },
                            secondary: newConfigs,
                        },
                    } as ReachabilityConfig;
                return {
                    ...prev,
                    [activePill]: {
                        ...prev[activePill],
                        secondary: newConfigs,
                    },
                };
            });
        },
        [activePill, project?.attributes?.reachability],
    );

    const handleAddSecondaryVariable = useCallback(
        (item: TableConfig) => {
            setReachabilityConfig((prev) => {
                if (!prev)
                    return {
                        [activePill]: {
                            primary: project?.attributes?.reachability?.[
                                activePill
                            ]?.primary ?? {
                                tableName: '',
                                dimensionName: '',
                            },
                            secondary: [item],
                        },
                    } as ReachabilityConfig;
                return {
                    ...prev,
                    [activePill]: {
                        primary: prev[activePill]?.primary,
                        secondary: [
                            ...(prev[activePill]?.secondary || []),
                            item,
                        ],
                    },
                };
            });
        },
        [activePill, project?.attributes?.reachability],
    );

    const primaryConfig = useMemo(
        () => reachabilityConfig?.[activePill]?.primary,
        [reachabilityConfig, activePill],
    );
    const secondaryConfig = useMemo(
        () => reachabilityConfig?.[activePill]?.secondary || [],
        [reachabilityConfig, activePill],
    );
    const secondaryVariables = useMemo(() => {
        const activeSecondaryConfig = secondaryConfig;

        return activeSecondaryConfig?.map((tableConfig, index) => (
            <ReachabilityVariable
                key={`${tableConfig?.tableName}_${tableConfig?.dimensionName}_${index}`}
                tableConfig={tableConfig}
                onChange={(eachTableConfig) => {
                    handleSecondaryVariableChange(
                        activeSecondaryConfig.map((_eachConfig, i) =>
                            i === index ? eachTableConfig : _eachConfig,
                        ),
                    );
                }}
                onDelete={() => {
                    handleSecondaryVariableChange(
                        activeSecondaryConfig.filter(
                            (_eachConfig, i) => i !== index,
                        ),
                    );
                }}
                isPrimary={false}
                isEditable={canEditReachabilityVariables}
            />
        ));
    }, [
        canEditReachabilityVariables,
        handleSecondaryVariableChange,
        secondaryConfig,
    ]);
    if (cannotViewReachabilityVariables) {
        history.push('/');
    }
    if (isInitialLoading) {
        return <SkeletonLoader height={10} />;
    }

    return (
        <Stack>
            <SettingsTitle
                title={t('workspace_settings.reachability_variables_title')}
            />
            <Tabs
                keepMounted={false}
                variant="pills"
                radius="lg"
                defaultValue={activePill}
                onTabChange={handlePillChange}
                className="pl-2 bg-gray-100/60"
            >
                <ChannelTabs activeTab={activePill} />
            </Tabs>
            <Group className="flex flex-row gap-1 flex-nowrap">
                <Text className="text-sm text-gray-500 uppercase">
                    {t('reachability_variables.primary')}
                </Text>
            </Group>
            {primaryConfig ? (
                <ReachabilityVariable
                    tableConfig={primaryConfig}
                    onChange={handlePrimaryVariableChange}
                    onDelete={undefined}
                    isPrimary={true}
                    isEditable={canEditReachabilityVariables}
                />
            ) : (
                canEditReachabilityVariables && (
                    <VariablePropertySelect
                        onSubmit={(item) => {
                            close();
                            handlePrimaryVariableChange({
                                tableName: item.table ?? '',
                                dimensionName: item.name ?? '',
                            });
                        }}
                        opened={primaryVariableOpened}
                        close={closePrimaryVariable}
                        open={openPrimaryVariable}
                        targetButton={
                            <Button
                                variant={ButtonVariant.OUTLINED}
                                leftIcon={<PlusCircle />}
                                className="w-fit"
                            >
                                {t(
                                    'reachability_variables.add_primary_variable',
                                )}
                            </Button>
                        }
                    />
                )
            )}

            <Group className="flex flex-row gap-1 flex-nowrap">
                <Text className="text-sm text-gray-500 uppercase">
                    {t('reachability_variables.secondary')}
                </Text>
            </Group>

            {secondaryVariables}

            <UnsavedChangesModal
                opened={!hasFormChanged && canEditReachabilityVariables}
                secondaryActionButtonClick={() =>
                    setReachabilityConfig(project?.attributes?.reachability)
                }
                primaryActionButtonClick={() => {
                    if (reachabilityConfig) {
                        updateReachabilityConfig(reachabilityConfig);
                    }
                }}
                disableButtons={isUpdatingReachabilityConfig}
            />
            {canEditReachabilityVariables && (
                <VariablePropertySelect
                    onSubmit={(item) => {
                        close();
                        handleAddSecondaryVariable({
                            tableName: item.table ?? '',
                            dimensionName: item.name ?? '',
                        });
                    }}
                    opened={opened}
                    close={close}
                    open={open}
                    targetButton={
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            leftIcon={<PlusCircle />}
                            className="w-fit"
                        >
                            {(reachabilityConfig?.[activePill]?.secondary
                                ?.length ?? 0) > 0
                                ? t(
                                      'reachability_variables.add_another_secondary_variable',
                                  )
                                : t(
                                      'reachability_variables.add_secondary_variable',
                                  )}
                        </Button>
                    }
                />
            )}
        </Stack>
    );
};

export default ReachabilityVariables;
