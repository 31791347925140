import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import {
    PeriodType as PeriodTypeEnum,
    type PeriodType,
} from '@lightdash/common';
import { Box, Group, Stack } from '@mantine/core';
import { ClockCountdown } from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ActionTitle from '../ActionTitle';

interface DurationDelayProps {
    actionTitle: React.ReactNode;
    selectedDuration: number;
    onDurationChange: (duration: number, granularity: PeriodType) => void;
    granularity: PeriodType;
    durationOptions: PeriodType[];
    withLeftPadding?: boolean;
}

const DurationDelay: React.FC<DurationDelayProps> = ({
    actionTitle,
    withLeftPadding,
    selectedDuration,
    onDurationChange,
    granularity,
    durationOptions,
}) => {
    const { t } = useTranslation();

    const errorMessage = useMemo(() => {
        if (selectedDuration === null) {
            return t('common.error.empty_text_input');
        }
        if (selectedDuration === 0) {
            return t('duration_delay.enter_a_valid_value');
        }
        return '';
    }, [selectedDuration, t]);

    return (
        <Stack className="gap-2">
            {actionTitle ? (
                actionTitle
            ) : (
                <>
                    <Group className="gap-1">
                        <ClockCountdown color={'rgb(var(--color-gray-500))'} />
                        <ActionTitle title={t('journey.delay.time_delay')} />
                    </Group>
                </>
            )}

            <Box className={`${withLeftPadding ? 'pl-6' : ''}`}>
                <TimeInputWithOptions
                    selectedDuration={selectedDuration}
                    onDurationChange={onDurationChange ?? (() => {})}
                    selectedGranularity={granularity ?? PeriodTypeEnum.MINUTE}
                    granularityOptions={durationOptions ?? []}
                    focusOnMount={true}
                    onBlur={(value) => {
                        if (Number.isNaN(value) && onDurationChange) {
                            onDurationChange(
                                null as unknown as number,
                                PeriodTypeEnum.MINUTE,
                            ); //Typecasting the value here as null since the common type doesn't support null/undefined and we can use null/undefined to check if the time is missing
                        }
                    }}
                    error={errorMessage}
                    disabled={false}
                />
            </Box>
        </Stack>
    );
};

export default DurationDelay;
