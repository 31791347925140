import { Box, Grid, Stack, Text } from '@mantine/core';
import { type Sector } from 'grapesjs';
import GrapesInputField from './GrapesInputField';

const HALF_WIDTH_PROPERTIES = [
    'width',
    'height',
    'max-height',
    'max-width',
    'font-weight',
    'font-size',
    'letter-spacing',
    'line-height',
];

const SHOW_ICON_PROPERTIES = [
    'width',
    'height',
    'max-height',
    'max-width',
    'font-family',
    'letter-spacing',
    'line-height',
    'background-color',
];

const CustomStyleManager = ({ sectors }: { sectors: Sector[] }) => {
    const getShowIcon = (type: string) => {
        return SHOW_ICON_PROPERTIES.includes(type);
    };
    return (
        <Box
            className="style-manager p-[0.875rem] bg-black"
            style={{ paddingBottom: 90 }}
        >
            {sectors &&
                sectors.map((sector, index) => (
                    <>
                        <Text
                            key={sector.getId()}
                            className="grapes-input-field-header text-left mb-4"
                        >
                            {sector.getName()}
                        </Text>
                        <Grid gutter={'xs'}>
                            {sector.getProperties().map((property) => (
                                <Grid.Col
                                    span={
                                        HALF_WIDTH_PROPERTIES.includes(
                                            property.getName(),
                                        )
                                            ? 6
                                            : 12
                                    }
                                    key={property.getId()}
                                    className="mb-2"
                                >
                                    <Stack spacing={0}>
                                        <Text className="grapes-input-field-header mb-2 text-left">
                                            {property.getLabel()}
                                        </Text>
                                        <GrapesInputField
                                            showIcon={getShowIcon(
                                                property.getName(),
                                            )}
                                            data={property}
                                        />
                                    </Stack>
                                </Grid.Col>
                            ))}
                        </Grid>
                        {index !== sectors.length - 1 && (
                            <div className="grapes-divider" />
                        )}
                    </>
                ))}
        </Box>
    );
};

export default CustomStyleManager;
