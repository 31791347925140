import { useLocale } from '@hooks/useLocale';
import { Button } from '@mantine/core';
import { Archive, Check } from '@phosphor-icons/react';
import { ButtonVariant } from '../../mantineTheme';

interface HiddenButtonProps {
    isHiddenActive: boolean;
    onHiddenChange: (isHiddenActive: boolean) => void;
    activeHideText: string;
}

export type HideProps = Pick<
    HiddenButtonProps,
    'isHiddenActive' | 'onHiddenChange'
> & { hideHiddenButton: boolean };

const HiddenButton: React.FC<HiddenButtonProps> = ({
    isHiddenActive,
    onHiddenChange,
    activeHideText,
}) => {
    const { t } = useLocale();
    return (
        <Button
            variant={
                isHiddenActive
                    ? ButtonVariant.SUBTLE_ACCENTED
                    : ButtonVariant.OUTLINED
            }
            leftIcon={
                <Archive
                    weight="duotone"
                    color={isHiddenActive ? 'rgb(var(--color-blu-800))' : ''}
                />
            }
            rightIcon={
                isHiddenActive ? (
                    <Check weight="bold" color="rgb(var(--color-blu-800))" />
                ) : null
            }
            onClick={() => onHiddenChange(!isHiddenActive)}
        >
            {isHiddenActive ? activeHideText : t('common.hidden')}
        </Button>
    );
};
export default HiddenButton;
