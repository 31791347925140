import { JourneyNodeEnum } from '@components/Journeys/Builder/types';
import { useLocale } from '@hooks/useLocale';
import { Group, Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import React from 'react';
import NodeContainer from '../NodeContainer';

const TriggerPlaceholderNode: React.FC<{}> = ({}) => {
    const { t } = useLocale();

    return (
        <NodeContainer
            nodeType={JourneyNodeEnum.PLACEHOLDER}
            isFocused={true}
            isSelected={false}
            nodeBanner={undefined}
            allowAddNode={false}
        >
            <Group className="items-center justify-center w-full h-full gap-1.5">
                <PlusCircle color={'rgb(var(--color-gray-500))'} />
                <Text className="text-sm font-medium text-gray-500">
                    {t('journey_builder.add_trigger_node')}
                </Text>
            </Group>
        </NodeContainer>
    );
};

export default TriggerPlaceholderNode;
