import { type DimensionType } from '@lightdash/common';
import { Box } from '@mantine/core';
import React from 'react';
import { useAudiencePreviewColumns } from './useAudiencePreviewTableUtils';

import LoadingState from '@components/common/LoadingState';
import VirtualTable from '@components/Table/VirtualTable';
import { useLocale } from '@hooks/useLocale';

interface AudiencePreviewTableProps {
    data: Record<string, unknown>[];
    fields: Record<string, { type: DimensionType }>;
    isFetching: boolean;
    generationStrategy: string;
    cellHeight?: number;
    customClass: string;
}
const CELL_HEIGHT = 44;
const PREVIEW_LIMIT = 50;

const AudiencePreviewTable: React.FC<AudiencePreviewTableProps> = ({
    data,
    fields,
    isFetching,
    generationStrategy,
    cellHeight = CELL_HEIGHT,
    customClass,
}) => {
    const { columns: audiencePreviewColumns } = useAudiencePreviewColumns(
        fields,
        generationStrategy,
    );
    const { t } = useLocale();

    if (isFetching) {
        return <LoadingState title="" />;
    }
    return (
        <Box>
            {audiencePreviewColumns && (
                <VirtualTable<Record<string, unknown>>
                    data={data}
                    columns={audiencePreviewColumns}
                    customClass={customClass}
                    isLoading={isFetching}
                    rowSize={cellHeight}
                    paginationText={
                        data.length < PREVIEW_LIMIT
                            ? undefined
                            : t('preview_data.pagination_text', {
                                  limit: PREVIEW_LIMIT,
                              })
                    }
                />
            )}
        </Box>
    );
};

export default React.memo(AudiencePreviewTable);
