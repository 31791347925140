import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import TemplateContentPreview from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent/TemplateContentPreview';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { getJourneyEventPropertySelectList } from '@components/Journeys/Builder/JourneyFilters/useJourneyProperties';
import { useLocale } from '@hooks/useLocale';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    type ExternalCampaignTriggerRequest,
    type Template,
} from '@lightdash/common';
import { Box, Button, Group, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useCallback, useMemo } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import { useJourneyNodeParams } from '../../../useJourneyNodeParams';
import ActionTitle from '../../ActionTitle';
import JourneyContentSetupManagerModal from './SetupManager';

interface CampaignActionContentProps {
    setJourneyContentPayload: (
        messageConfig: ExternalCampaignTriggerRequest,
    ) => void;
    nodeId: string;
    messageConfig: ExternalCampaignTriggerRequest;
}

const CampaignActionContent: React.FC<CampaignActionContentProps> = ({
    setJourneyContentPayload,
    nodeId,
    messageConfig,
}) => {
    const { t } = useLocale();
    const {
        isLoading: isJourneyNodeParamsLoading,
        warehouseFields,
        journeyDataSchema,
    } = useJourneyNodeParams(nodeId);

    const { nodes, edges, journeyPayload, isEditable } =
        useJourneyBuilderContext((context) => context.state);

    const {
        data: templateMetadata,
        isInitialLoading: isTemplateMetadataLoading,
    } = useGetTemplateById(
        messageConfig?.templateDetails?.id ?? '',
        messageConfig.channel !== CommunicationChannel.WHATSAPP,
    );

    const {
        data: syncedTemplateMetadata,
        isLoading: isSyncedTemplateMetadataLoading,
    } = useGetSyncedTemplateByTemplateName(
        messageConfig.communicationDetails?.id ?? '',
        messageConfig?.templateDetails?.id ?? '',
        messageConfig.channel === CommunicationChannel.WHATSAPP,
    );

    const getTemplateMetadata = useCallback(() => {
        if (messageConfig.channel === CommunicationChannel.WHATSAPP) {
            return {
                ...syncedTemplateMetadata,
                contents: syncedTemplateMetadata?.languages[0]?.content ?? [],
                channel: CommunicationChannel.WHATSAPP,
            };
        }
        if (templateMetadata) {
            return templateMetadata;
        }
        return null;
    }, [messageConfig.channel, syncedTemplateMetadata, templateMetadata]);

    const [
        opened,
        { open: openContentSetupManager, close: closeContentSetupManager },
    ] = useDisclosure(false);

    const hasChannel = useMemo(() => {
        return (
            messageConfig.communicationDetails &&
            messageConfig.communicationDetails.id
        );
    }, [messageConfig.communicationDetails]);

    const renderContentElement = useMemo(() => {
        // Case where a communication channel is selected but no template is selected
        if (!messageConfig?.templateDetails?.id) {
            return (
                <Button
                    variant={ButtonVariant.DEFAULT}
                    className="w-fit"
                    onClick={openContentSetupManager}
                >
                    {/* {t('journey_builder.channel_element_select_channel_btn')} */}
                    Setup
                </Button>
            );
        }

        // Case where a communication channel and a template is selected but the template metadata for preview is still loading
        if (
            isTemplateMetadataLoading ||
            isJourneyNodeParamsLoading ||
            (messageConfig?.channel === CommunicationChannel.WHATSAPP &&
                isSyncedTemplateMetadataLoading)
        )
            return (
                <Box className="p-3">
                    <SkeletonLoader height={70} />
                </Box>
            );

        // Case where a communication channel and a template is selected and the template metadata is loaded but empty
        if (!getTemplateMetadata()) return null;

        // Case where a communication channel and a template is selected and the template metadata is loaded
        return (
            <Box className=" w-full">
                <TemplateContentPreview
                    templateMetadata={getTemplateMetadata() as Template}
                    grapesContainerId="html-preview-journeys-action"
                    showDeviceAndThemeSwitcher={false}
                    showModifyButton={false}
                    onTemplateSelect={undefined}
                    showSetupStrip={true}
                    isSetupComplete={!!messageConfig.contentDetails}
                    openContentSetupManager={openContentSetupManager}
                    showHeader={false}
                />
            </Box>
        );
    }, [
        messageConfig,
        isTemplateMetadataLoading,
        isJourneyNodeParamsLoading,
        isSyncedTemplateMetadataLoading,
        getTemplateMetadata,
        openContentSetupManager,
    ]);

    const journeyEventsPropertySelectList = useMemo(() => {
        if (!journeyDataSchema) return [];
        return getJourneyEventPropertySelectList({
            journeyDataSchema,
            nodes,
            edges,
            nodeId,
            journeyNodes: journeyPayload.config?.nodes ?? [],
        });
    }, [journeyDataSchema, nodes, edges, journeyPayload, nodeId]);

    return (
        <>
            <Stack>
                <Group className="justify-between">
                    <ActionTitle
                        title={t('journey_builder.content_element_title')}
                    />
                </Group>

                {!hasChannel && isEditable ? (
                    <Button
                        variant={ButtonVariant.DEFAULT}
                        className="w-fit"
                        onClick={openContentSetupManager}
                        disabled
                    >
                        {t(
                            'journey_builder.channel_element_select_integration_btn_disabled',
                        )}
                    </Button>
                ) : (
                    <>
                        {renderContentElement}
                        <JourneyContentSetupManagerModal
                            opened={opened}
                            closeContentSetupManager={closeContentSetupManager}
                            messageConfig={messageConfig}
                            setJourneyContentPayload={setJourneyContentPayload}
                            fields={{
                                ...warehouseFields,
                            }}
                            additionalPropertySelectList={
                                journeyEventsPropertySelectList as PropertySelectListType<
                                    FieldWithSelectStatus,
                                    PropertySelectGroupType
                                >[]
                            }
                        />
                    </>
                )}
            </Stack>
        </>
    );
};

export default React.memo(CampaignActionContent);
