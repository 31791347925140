import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { type EventMapperSchema } from '@lightdash/common';
import { Box } from '@mantine/core';
import Fuse from 'fuse.js';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDebounce } from 'react-use';
import { ModalInputTitle } from '../Steps/EventBuilderProperties/AddEventKeysModal';
import EventsTable from '../Table/EventsTable';
import { EventManagerType } from '../types';
interface EventsBaseModalProps {
    opened: boolean;
    onClose: () => void;
    events: EventMapperSchema[];
}
interface ExternalEventsModalProp extends EventsBaseModalProps {
    inProvider: false;
}
interface InternalEventsModalProp extends EventsBaseModalProps {
    inProvider: true;
    setJsonString: (jsonData: string) => void;
    setSource: (source: string) => void;
    setJsonPayload: (jsonData: Record<string, unknown>) => void;
}
type EventsModalProps = ExternalEventsModalProp | InternalEventsModalProp;
const EventsModal: React.FC<EventsModalProps> = ({
    opened,
    onClose,
    events,
    inProvider,
    ...props
}) => {
    const { setJsonString, setSource, setJsonPayload } =
        props as InternalEventsModalProp;
    const [search, setSearch] = useState<string>('');
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const history = useHistory();
    const [searchDebounce, setSearchDebounce] = useState<string>('');
    useDebounce(
        () => {
            setSearchDebounce(search);
        },
        300,
        [search],
    );
    const filterItems = useCallback(() => {
        if (searchDebounce !== '') {
            const fuse = new Fuse(events, {
                keys: ['eventName'],
                threshold: 0.3,
            });
            return fuse.search(searchDebounce).map(({ item }) => item);
        }
        return events;
    }, [events, searchDebounce]);
    return (
        <Modal
            size={1000}
            opened={opened}
            onClose={onClose}
            closeButtonProps={{ className: 'absolute top-4 right-3' }}
            title={
                <ModalInputTitle
                    setSearch={setSearch}
                    title={t('events.title')}
                    inputPlaceholder={t(
                        'event_manager_modal.search_placeholder',
                    )}
                />
            }
        >
            <Box className="h-[31rem]">
                <EventsTable
                    events={filterItems() ?? []}
                    managerType={EventManagerType.CLONE}
                    handleRowClick={(row) => {
                        if (inProvider) {
                            setSource(row.original.source);
                            setJsonString(
                                JSON.stringify(
                                    row.original.sampleEventPayload,
                                    null,
                                    2,
                                ),
                            );
                            setJsonPayload(row.original.sampleEventPayload);
                        } else {
                            history.push(
                                `/projects/${projectUuid}/events/create?cloneId=${row.original.uuid}&source=${row.original.source}`,
                            );
                        }
                        onClose();
                    }}
                    //This lines to hanldle the pagination from backend currently there is no backend pagination
                    // onPageChange={async (newPage) => {
                    //     setCurrentPage(newPage);
                    // }}
                />
            </Box>
        </Modal>
    );
};
export default EventsModal;
