import ChannelTabs from '@components/ChannelTabs';
import { useLocale } from '@hooks/useLocale';
import {
    type CategoriesWithGroups,
    type CategoriesWithGroupsAndUserPreference,
    type CommunicationChannel,
} from '@lightdash/common';
import { Box, Flex, Loader, Tabs, Text } from '@mantine/core';
import React, { useMemo } from 'react';
import SubscriptionGroupModal from '../SubscriptionGroupModal';
import SubscriptionBody from './SubscriptionBody';

interface SubscriptionDetailsProps {
    onClose: () => void;
    isOpen: boolean;
    onGroupCreate: () => void;
    activeTab: CommunicationChannel;
    setActiveTab: (pill: CommunicationChannel) => void;
    panelWidth: number;
    isViewOnly: boolean;
    allGroups: CategoriesWithGroupsAndUserPreference | CategoriesWithGroups;
    isFetchingData: boolean;
    globalPreference: boolean;
    showGlobalPreference: boolean;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({
    isViewOnly,
    panelWidth,
    onClose,
    isOpen,
    onGroupCreate,
    activeTab,
    setActiveTab,
    allGroups,
    isFetchingData,
    globalPreference,
    showGlobalPreference,
}) => {
    const { t } = useLocale();
    const handlePillChange = (pill: CommunicationChannel) => {
        setActiveTab(pill);
    };

    const subscriptionBodyContent = useMemo(() => {
        if (isFetchingData) {
            return (
                <Flex className="p-3.5" align="center">
                    <Loader size={11} />
                    <Text className="text-sm font-normal text-blu-800 px-1.5">
                        {t('subscription_body.loading')}
                    </Text>
                </Flex>
            );
        }
        return (
            <>
                {Boolean(showGlobalPreference) && (
                    <Box className="flex items-center justify-between  px-2 py-3">
                        <Text className="text-sm font-medium text-gray-800">
                            {t(
                                'subscription_details.channel_subscription_status',
                                {
                                    channel:
                                        activeTab.charAt(0).toUpperCase() +
                                        activeTab.slice(1),
                                },
                            )}
                        </Text>
                        {globalPreference ? (
                            <Text className="text-sm font-medium text-blu-800">
                                {t('subscription_details.subscribed')}
                            </Text>
                        ) : (
                            <Text className="text-sm font-medium text-halt-800">
                                {t('subscription_details.not_subscribed')}
                            </Text>
                        )}
                    </Box>
                )}
                <SubscriptionBody
                    onGroupCreate={onGroupCreate}
                    activeTab={activeTab}
                    panelWidth={panelWidth}
                    isViewOnly={isViewOnly}
                    allGroups={allGroups}
                />
            </>
        );
    }, [
        isFetchingData,
        showGlobalPreference,
        t,
        activeTab,
        globalPreference,
        onGroupCreate,
        panelWidth,
        isViewOnly,
        allGroups,
    ]);

    return (
        <>
            <Tabs
                keepMounted={false}
                variant="pills"
                radius="lg"
                defaultValue={activeTab}
                onTabChange={handlePillChange}
            >
                <ChannelTabs activeTab={activeTab} />
            </Tabs>
            {subscriptionBodyContent}
            <SubscriptionGroupModal
                activeTab={activeTab}
                onClose={onClose}
                isOpen={isOpen}
            />
        </>
    );
};

export default SubscriptionDetails;
