import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import { useLocale } from '@hooks/useLocale';
import { type CommunicationChannel } from '@lightdash/common';
import { Box, Flex, Skeleton, Stack, Text } from '@mantine/core';
import { useProjectContext } from '@providers/ProjectProvider';
import { useCallback, useEffect } from 'react';
import { type InsightsCallbackType } from '.';
import useAudienceReachabilityData from './useAudienceReachabilityData';

const Reachability = ({
    audienceCount,
    setRefreshCallback,
}: {
    audienceCount: number;
    setRefreshCallback: (payload: InsightsCallbackType) => void;
}) => {
    const { t } = useLocale();
    const { communicationChannels } = useProjectContext();
    const { loading, reachabilityData, getReachability } =
        useAudienceReachabilityData();

    const getPercentage = (metric: number, total: number) => {
        if (total === 0) {
            return 0;
        }
        return Math.floor(((total - metric) / total) * 100);
    };

    const refreshCallback = useCallback(async () => {
        await getReachability();
    }, [getReachability]);

    useEffect(() => {
        setRefreshCallback({
            reachability: refreshCallback,
        });
    }, [setRefreshCallback, refreshCallback]);

    return (
        <Box className="p-3">
            <Text className="text-xs text-gray-500 font-medium mb-4 uppercase">
                {t('audience_builder.insights.channel_reachability')}
            </Text>
            <Flex justify="space-between" className="px-3">
                {loading ? (
                    <Skeleton width={200} height={20} />
                ) : (
                    communicationChannels?.map((channel) => {
                        return (
                            <Stack key={channel.value} spacing={0}>
                                <Text className="text-[16px] font-medium text-gray-800">
                                    {isNaN(
                                        getPercentage(
                                            reachabilityData?.[channel.value] ??
                                                0,
                                            audienceCount ?? 0,
                                        ),
                                    )
                                        ? 'NA'
                                        : getPercentage(
                                              reachabilityData?.[
                                                  channel.value
                                              ] ?? 0,
                                              audienceCount ?? 0,
                                          )}
                                    %
                                </Text>
                                <ChannelIcon
                                    labelClassName="text-xs"
                                    channel={
                                        channel.value as CommunicationChannel
                                    }
                                />
                            </Stack>
                        );
                    })
                )}
            </Flex>
        </Box>
    );
};

export default Reachability;
