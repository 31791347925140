import MantineIcon from '@components/common/MantineIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { useDeleteUserAlertPreferencesMutation } from '@hooks/user/useUser';
import { type UserAlertPreferenceResponse } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight, DotsThree, Trash } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import ButtonGroup from '../DeliveryControls/ButtonGroup';

interface AlertMenuItemProps {
    rowData: Row<UserAlertPreferenceResponse | null>;
}

const AlertMenuItem: React.FC<AlertMenuItemProps> = ({ rowData }) => {
    const { user } = useApp();
    const { t } = useLocale();
    const { mutateAsync: deleteAlert, isLoading } =
        useDeleteUserAlertPreferencesMutation();
    const [
        openedDeleteConfirmModal,
        { open: openDeleteConfirmModal, close: closeDeleteConfirmModal },
    ] = useDisclosure();

    const handleDelete = useCallback(async () => {
        await deleteAlert(rowData.original?.id ?? '');
        closeDeleteConfirmModal();
    }, [closeDeleteConfirmModal, deleteAlert, rowData.original?.id]);

    const deleteItem = useMemo(
        () => ({
            leftSection: (
                <Trash
                    size={14}
                    weight="duotone"
                    color="rgb(var(--color-halt-800))"
                />
            ),
            children: (
                <Text className="text-halt-800">{t('common.delete')}</Text>
            ),
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openDeleteConfirmModal(),
        }),
        [t, openDeleteConfirmModal],
    );

    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<UserAlertPreferenceResponse | null>[] =
            [deleteItem];
        return items;
    }, [deleteItem]);

    return (
        <>
            {user.data?.userUuid === rowData.original?.userUuid && (
                <>
                    <CustomMenu<UserAlertPreferenceResponse | null>
                        menuItems={menuItems}
                        data={rowData.original}
                        icon={
                            <MantineIcon
                                icon={() => (
                                    <DotsThree
                                        size={14}
                                        weight="bold"
                                        color="rgb(var(--color-gray-600))"
                                    />
                                )}
                            />
                        }
                    />
                    <Modal
                        opened={openedDeleteConfirmModal}
                        onClose={closeDeleteConfirmModal}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        title={
                            <Box>
                                <Text>
                                    {t('alert_settings.confirm_delete_title')}
                                </Text>
                                <Text className="text-sm font-normal text-gray-600">
                                    {`${rowData.original?.entity} alerts for ${rowData.original?.channel}`}
                                </Text>
                            </Box>
                        }
                        footerRightSection={
                            <ButtonGroup
                                primaryButtonLabel={t('common.delete')}
                                secondaryButtonLabel={t('common.cancel')}
                                handlePrimaryButtonClick={handleDelete}
                                handleSecondaryButtonClick={
                                    closeDeleteConfirmModal
                                }
                                primaryButtonVariant={
                                    ButtonVariant.FILLED_DESTRUCTIVE
                                }
                                primaryButtonRightIcon={
                                    <CaretRight color="white" />
                                }
                                isUpdating={isLoading}
                            />
                        }
                        closeButtonProps={{
                            className: 'absolute top-3 right-3',
                        }}
                    >
                        <Flex direction={'column'} gap={12}>
                            <Text className="font-semibold text-gray-800 text-sm">
                                {t('alert_settings.confirm_delete_header')}
                            </Text>
                            <Text className="text-gray-800 text-sm">
                                {t('alert_settings.confirm_delete_description')}
                            </Text>
                        </Flex>
                    </Modal>
                </>
            )}
        </>
    );
};

export function useAlertColumns() {
    const [columns, setColumns] =
        useState<ColumnDef<UserAlertPreferenceResponse | null>[]>();
    const { t } = useLocale();

    useEffect(() => {
        setColumns([
            {
                accessorKey: 'type',
                header: t('alert_settings.th_alert_type'),
                cell: ({ row }) => (
                    <Box className="text-sm text-gray-800">
                        <Text>{row.original?.entity}</Text>
                    </Box>
                ),
            },
            {
                accessorKey: 'user',
                header: t('alert_settings.th_user'),
                cell: ({ row }) => (
                    <Box className="text-sm text-gray-800">
                        <Text>{`${row.original?.firstName} ${row.original?.lastName}`}</Text>
                    </Box>
                ),
            },
            {
                accessorKey: 'channel',
                header: t('alert_settings.th_channel'),
                cell: ({ getValue }) => (
                    <Box className="text-sm text-gray-800">
                        <Text>{getValue<string>()}</Text>
                    </Box>
                ),
            },
            {
                accessorKey: 'actions',
                header: '',
                cell: ({ row }) => <AlertMenuItem rowData={row} />,
            },
        ]);
    }, [t]);

    return columns;
}
