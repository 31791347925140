import { type SupportedDbtAdapter } from '../dbt';
import {
    type Explore,
    type ExploreJoin,
    type SummaryExploreFields,
    type Table,
    type UuidField,
} from '../explore';
import { type CompiledDimension, type CompiledMetric } from '../field';
import { type CompiledRelationTable } from './compiledTables';
import { type RelationSchemaColumn } from './dtos';
import { type JoinType, type RelationTableType } from './enums';
import { type RelationTable } from './tables';

export type CachedColumnValues = {
    name: string;
    label: string;
};

export type DimensionConfigurationResult = {
    hidden: boolean;
    masked: boolean;
    cached: boolean;
    cachedColumnValues: CachedColumnValues[];
    cron: string | undefined;
    schedulerUuid: string | undefined;
};

export type DbRelation = {
    uuid: string;
    project_uuid: string;
    relation: CompiledRelation;
    uncompiled_relation: UncompiledRelation;
    created_by: string;
    created_at: Date;
    updated_by: string;
    updated_at: Date;
};

type UncompiledExplore = {
    name: string;
    label: string;
    tags: string[];
    baseTable: string;
    groupLabel?: string;
    joinedTables: ExploreJoin[];
    tables: Record<string, Table>;
    targetDatabase: SupportedDbtAdapter;
    sqlWhere?: string;
};

export type SummaryRelation = Pick<CompiledRelation, SummaryExploreFields> &
    UuidField;

export type JoinTableData = {
    table: string;
    field: string;
    alias?: string;
    type?: string;
};

export type CompiledRelation = Omit<Explore, 'tables' | 'joinedTables'> & {
    tables: { [tableName: string]: CompiledRelationTable };
    joinedTables: CompiledRelationJoin[];
};

// Helper function to get a list of all dimensions in an relation
export const getRelDimensions = (
    relation: CompiledRelation,
): CompiledDimension[] =>
    Object.values(relation.tables).flatMap((t) => Object.values(t.dimensions));

// Helper function to get a list of all metrics in an relation
export const getRelMetrics = (relation: CompiledRelation): CompiledMetric[] =>
    Object.values(relation.tables).flatMap((t) => Object.values(t.metrics));

export type UncompiledRelation = Omit<
    UncompiledExplore,
    'tables' | 'joinedTables'
> & {
    tables: { [tableName: string]: RelationTable };
    joinedTables: RelationJoin[];
    joinAliases?: Record<string, Record<string, string>>;
};

export type CompiledRelationJoin = Pick<
    RelationJoin,
    'table' | 'sqlOn' | 'type' | 'source' | 'target' | 'relationType'
> & {
    compiledSqlOn: string; // Sql on clause with template variables resolved
};

export type RelationJoin = ExploreJoin & RelationSchemaJoin;

export type RelationSchemaJoin = {
    source: JoinTableData;
    target: JoinTableData;
    relationType: JoinType;
};

export type RelationSchemaTable = {
    name: string;
    label: string;
    description: string | undefined;
    type: RelationTableType;
    primaryKey: string | undefined;
    columns: RelationSchemaColumn[];
    relationships: RelationSchemaJoin[];
};

export type SyncRelationTable = {
    userUuid: string;
    projectUuid: string;
    relationUuid: string;
    tableId: string;
};

export enum RelationPermissions {
    Relation = 'Relation',
    Cache = 'Cache',
    Joins = 'Joins',
    Hidden = 'Hidden',
    Masked = 'Masked',
    Table = 'Table',
    TableAttributes = 'TableAttributes',
    TableSchema = 'TableSchema',
}
