import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SubscriptionComponent, {
    DEFAULT_PROVIDER,
} from '@components/SubscriptionGroups';
import { useGetAllSubscriptionGroups } from '@hooks/subscriptionGroups/useSubscriptionGroups';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { ProjectSettings, type CommunicationChannel } from '@lightdash/common';
import { useApp } from '@providers/AppProvider';
import React, { useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';

const Subscription: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const ability = useAbilityContext();
    const { user } = useApp();

    const [activeTab, setActiveTab] =
        useState<CommunicationChannel>(DEFAULT_PROVIDER);

    const { data: allGroups, isFetching } = useGetAllSubscriptionGroups({
        channel: activeTab,
    });

    const canViewSubscription = ability.can(
        'view',
        subject('ProjectSettings', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const canOnlyViewSubscirption =
        ability.can(
            'view',
            subject(ProjectSettings.subscriptionGroups, { projectUuid }),
        ) &&
        ability.cannot(
            'manage',
            subject(ProjectSettings.subscriptionGroups, {
                organizationUuid: user.data?.organizationUuid,
                projectUuid,
            }),
        );

    if (!canViewSubscription) {
        showToastError({ title: t('authorization_access_error.message') });
        return <Redirect to="/no-access" />;
    }

    return (
        <SubscriptionComponent
            showTitle={true}
            panelWidth={840}
            isViewOnly={canOnlyViewSubscirption}
            allGroups={allGroups ?? []}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            isFetchingData={isFetching}
            globalPreference={false}
            showGlobalPreference={false}
        />
    );
};

export default React.memo(Subscription);
