import { NavLink, type NavLinkProps } from '@mantine/core';
import { type FC } from 'react';
import {
    NavLink as ReactRouterSidebarLink,
    useRouteMatch,
    type NavLinkProps as ReactRouterSidebarLinkProps,
} from 'react-router-dom';

type RouterSidebarLinkProps = Omit<NavLinkProps, 'component' | 'active'> &
    Omit<ReactRouterSidebarLinkProps, 'component'>;

const RouterSidebarLink: FC<RouterSidebarLinkProps> = (props) => {
    const pathOnly = props.to.toString().split('?')[0];
    const match = useRouteMatch(pathOnly);

    const isActive = match
        ? props.exact
            ? props.exact === match.isExact
            : true
        : false;

    return (
        <NavLink
            {...props}
            component={ReactRouterSidebarLink}
            active={isActive}
            styles={(theme) => ({
                icon: {
                    color: isActive
                        ? 'rgb(var(--color-gray-800))'
                        : 'rgb(var(--color-gray-600))',
                    strokeWidth: 2.5,
                },
                label: {
                    color: isActive
                        ? 'rgb(var(--color-gray-800))'
                        : 'rgb(var(--color-gray-600))',
                    fontSize: theme.fontSizes.sm,
                    fontWeight: 600,
                },
                root: {
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                    ...(isActive && {
                        boxShadow: '0px -1px 1px 0px rgba(0, 0, 0, 0.04) inset',
                        backgroundColor:
                            'rgb(var(--color-black)/0.06) !important',
                        borderRadius: theme.radius.rounded,
                    }),
                },
            })}
        />
    );
};

export default RouterSidebarLink;
