import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import SkeletonLoader from '@components/common/SkeletonLoader';
import useNotify from '@hooks/toaster/useNotify';
import { useGetCampaignById } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { Box, Group, Stack, Text } from '@mantine/core';
import React, { useEffect } from 'react';
import { ButtonVariant } from '../../mantineTheme';

interface CampaignFailureReasonModalProps {
    campaignId: string;
    showModal: boolean;
    closeModal: () => void;
}

const CampaignFailureReasonModal: React.FC<CampaignFailureReasonModalProps> = ({
    campaignId,
    showModal,
    closeModal,
}) => {
    const {
        data: campaignData,
        isInitialLoading: isCampaignDataLoading,
        isError,
    } = useGetCampaignById(campaignId);
    const { showToastError } = useNotify();
    const { t } = useLocale();

    useEffect(() => {
        if (!isCampaignDataLoading && isError) {
            showToastError({
                title: t('campaigns_builder.failed_to_fetch_campaign_data'),
            });
            closeModal();
        }
    }, [isCampaignDataLoading, isError, showToastError, t, closeModal]);

    if (!campaignId) {
        closeModal();
        return null;
    }

    return (
        <Modal
            opened={showModal}
            onClose={closeModal}
            keepMounted={false}
            title={t('campaign_failure_reason_modal.title')}
            size="xl"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={closeModal}
                    secondaryText={t('common.okay')}
                    showPrimaryButton={false}
                    primaryButtonClick={undefined}
                    primaryText={undefined}
                    primaryLeftIcon={undefined}
                    isLoading={undefined}
                    primaryRightIcon={undefined}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    primaryButtonDisabled={undefined}
                    primaryButtonCustomClass={undefined}
                />
            }
        >
            {isCampaignDataLoading ? (
                <Box className="flex items-center justify-center w-full h-20 my-4">
                    <SkeletonLoader height={100} />
                </Box>
            ) : (
                <Stack className="gap-3 text-sm text-gray-800">
                    <Group className="gap-1">
                        <Text>Campaign Name:</Text>
                        <Text>{campaignData?.name}</Text>
                    </Group>
                    <Group className="gap-1">
                        <Text>Campaign ID:</Text>
                        <Text>{campaignData?.id}</Text>
                    </Group>
                    <Box className="p-4 border-base max-h-[20rem] overflow-y-auto overflow-x-hidden break-words">
                        {campaignData?.failureReason ||
                            'Failure reason not available'}
                    </Box>
                </Stack>
            )}
        </Modal>
    );
};

export default CampaignFailureReasonModal;
