import JourneyFilters from '@components/Journeys/Builder/JourneyFilters';
import { type JourneyFiltersConfig } from '@lightdash/common';
import { Group, Stack } from '@mantine/core';
import { Subtract } from '@phosphor-icons/react';
import { t } from 'i18next';
import React from 'react';
import { useParams } from 'react-router-dom';
import ActionTitle from '../ActionTitle';

export interface FilterActionProps {
    filters: JourneyFiltersConfig;
    nodeId: string;
    setFilters: (value: JourneyFiltersConfig) => void;
    actionTitle: React.ReactNode | undefined;
}

const FilterAction: React.FC<FilterActionProps> = ({
    filters,
    nodeId,
    setFilters,
    actionTitle,
}) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    return (
        <Stack className="w-[40vw]">
            {actionTitle ? (
                <>{actionTitle}</>
            ) : (
                <>
                    <Group className="gap-1">
                        <Subtract color={'rgb(var(--color-gray-500))'} />
                        <ActionTitle
                            title={t('journey_builder.filter_action_title')}
                        />
                    </Group>
                </>
            )}

            <JourneyFilters
                onChange={() => {
                    //FIXME: This is a temporary solution to update the filters.
                    // We need to update the filters to match the journey filter group type
                    // setFilters({
                    //     and: value,
                    // } as unknown as JourneyFilterGroup);
                }}
                filters={filters}
                setFilters={(values) => {
                    setFilters(values);
                }}
                projectUuid={projectUuid}
                nodeId={nodeId}
            />
        </Stack>
    );
};

export default React.memo(FilterAction);
