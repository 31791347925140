import { subject } from '@casl/ability';
import { createIntegratedProviderObject } from '@components/Campaigns/utils';
import ChannelTabs from '@components/ChannelTabs';
import { useAbilityContext } from '@components/common/Authorization';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useGetChannelIntegrations } from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannel,
    CommunicationChannel as CommunicationChannelEnum,
    ProjectSettings,
    type Integration,
} from '@lightdash/common';
import { Stack, Tabs, Text } from '@mantine/core';
import { useApp } from '@providers/AppProvider';
import { useChannelContext } from '@providers/ChannelProvider';
import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import TabBody from './TabBody';

export const DEFAULT_PROVIDER: CommunicationChannel =
    CommunicationChannelEnum.EMAIL;

interface TabComponentProps {
    showChannelList?: boolean;
}

const TabComponent: React.FC<TabComponentProps> = ({ showChannelList }) => {
    const { state, actions } = useChannelContext((context) => context);
    const { allChannels, showSetupModal } = state;
    const { handleShowSetUpModal, handleIntegratedProvider } = actions;
    const { user } = useApp();

    const getChannelEnum = useMemo(() => {
        switch (state.selectedChannel) {
            case CommunicationChannelEnum.EMAIL:
                return CommunicationChannel.EMAIL;
            case CommunicationChannelEnum.SMS:
                return CommunicationChannel.SMS;
            case CommunicationChannelEnum.WHATSAPP:
                return CommunicationChannel.WHATSAPP;
            default:
                return CommunicationChannel.EMAIL;
        }
    }, [state.selectedChannel]);

    const [activePill, setActivePill] = useState<CommunicationChannel>(
        getChannelEnum ?? DEFAULT_PROVIDER,
    );

    const handlePillChange = (pill: CommunicationChannel) => {
        setActivePill(pill);
    };

    const {
        data: ChannelIntegration,
        isLoading: isFetchingChannelIntegrations,
    } = useGetChannelIntegrations(activePill);

    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const canOnlyViewChannel =
        ability.can(
            'view',
            subject(ProjectSettings.channels, {
                organizationUuid: user.data?.organizationUuid,
                projectUuid,
            }),
        ) &&
        ability.cannot(
            'update',
            subject(ProjectSettings.channels, {
                organizationUuid: user.data?.organizationUuid,
                projectUuid,
            }),
        );
    const { t } = useLocale();

    const provider: Integration[] | undefined = React.useMemo(() => {
        if (allChannels?.[activePill] && ChannelIntegration) {
            const obj = createIntegratedProviderObject(
                allChannels?.[activePill],
                ChannelIntegration,
                false,
            );
            if (obj) {
                handleIntegratedProvider(obj);
            }
            return obj;
        }
    }, [activePill, ChannelIntegration, allChannels, handleIntegratedProvider]);

    const handleClickHere = () => {
        handleShowSetUpModal(!showSetupModal);
    };

    return (
        <Tabs
            keepMounted={false}
            variant="pills"
            radius="lg"
            defaultValue={activePill}
            onTabChange={handlePillChange}
        >
            {showChannelList && <ChannelTabs activeTab={activePill} />}
            {isFetchingChannelIntegrations ? (
                <Stack>
                    <SkeletonLoader height={60} />
                    <SkeletonLoader height={60} />
                </Stack>
            ) : (
                <>
                    {provider ? (
                        <TabBody activePill={activePill} provider={provider} />
                    ) : (
                        <Text className="flex items-center justify-center py-12 border rounded-lg text-sm font-normal text-gray-600">
                            {t('channel_settings.no_integrations_found', {
                                channel: activePill,
                            })}
                            {canOnlyViewChannel && (
                                <>
                                    <div
                                        className="mx-0.5 text-blu-800 font-semibold cursor-pointer"
                                        onClick={handleClickHere}
                                    >
                                        {t('channel_tabs_click_here')}
                                    </div>

                                    {t('channel_tabs_to_add_a_channel')}
                                </>
                            )}
                        </Text>
                    )}
                </>
            )}
        </Tabs>
    );
};

export default TabComponent;
