import FieldListItem from '@components/Audience/Filters/FieldListItem';
import { convertFieldsIntoPropertySelectListType } from '@components/Audience/Filters/FieldListItem/utils';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import PropertySelect from '@components/common/Select/PropertySelect';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex, Group, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight } from '@phosphor-icons/react';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useMemo } from 'react';
import { JourneyEventGroupType, type JourneyProperty } from '../types';
import { getJourneyEventPropertySelectList } from '../useJourneyProperties';
import { useJourneyFiltersContext } from './JourneyFiltersProvider';

interface FiltersPropertySelectorFieldItemProps {
    item: JourneyProperty;
    rightHoverSection?: React.ReactNode;
    showFieldSource?: boolean;
}

export const FiltersPropertySelectorFieldItem: React.FC<
    FiltersPropertySelectorFieldItemProps
> = ({ item, rightHoverSection, showFieldSource = true }) => {
    if (item.groupKey !== JourneyEventGroupType.JOURNEY_PARAMS) {
        return (
            <FieldListItem
                item={item}
                checked={item.isChecked ?? false}
                showCheckbox={false}
                disabled={item.isDisabled ?? false}
                showFieldSource={showFieldSource}
            />
        );
    }

    return (
        <Box
            sx={{
                position: 'relative',
                '&:hover .icon': {
                    opacity: 1,
                },
            }}
            className="w-full p-2.5 rounded cursor-pointer hover:bg-gray-50"
        >
            <Group className="gap-1">
                <FieldIcon type={item?.type} strokeWidth={2.5} />
                <Box className={`max-w-[120px] truncate`}>
                    {item?.label && (
                        <Text className="text-sm font-medium text-gray-800">
                            {item?.label}
                        </Text>
                    )}
                </Box>
            </Group>

            <Box
                className="icon"
                sx={{
                    position: 'absolute',
                    right: '10px',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    opacity: 0,
                    transition: 'opacity 0.1s ease-in-out',
                }}
            >
                <Flex align="center" gap={4}>
                    {rightHoverSection}
                    <CaretRight weight="bold" />
                </Flex>
            </Box>
        </Box>
    );
};

interface FiltersPropertySelectorProps {
    targetElement: React.ReactNode;
    onSubmit: (item: JourneyProperty) => void;
    showAudienceFields: boolean;
    showEventsFields: boolean;
    disabled?: boolean;
}

const FiltersPropertySelector: React.FC<FiltersPropertySelectorProps> = ({
    targetElement,
    onSubmit,
    showAudienceFields,
    showEventsFields,
    disabled = false,
}) => {
    const [opened, { open, close }] = useDisclosure();
    const { t } = useLocale();

    const {
        warehouseFields,
        audienceFields,
        eventsData,
        journeyEventFields,
        journeyDataSchema,
        nodeId,
    } = useJourneyFiltersContext();

    const { nodes, edges, journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const journeyEventsPropertySelectList = useMemo(() => {
        if (
            !journeyEventFields ||
            !journeyEventFields.length ||
            !journeyDataSchema
        )
            return [];
        return getJourneyEventPropertySelectList({
            journeyDataSchema,
            nodes,
            edges,
            nodeId,
            journeyNodes: journeyPayload.config?.nodes ?? [],
        });
    }, [
        journeyEventFields,
        journeyDataSchema,
        nodes,
        edges,
        journeyPayload,
        nodeId,
    ]);

    return (
        <>
            <PropertySelect<JourneyProperty>
                items={[
                    ...convertFieldsIntoPropertySelectListType(
                        [
                            ...warehouseFields,
                            ...(showAudienceFields ? audienceFields : []),
                            ...(showEventsFields ? eventsData : []),
                        ],
                        true,
                    ),
                    ...journeyEventsPropertySelectList,
                ]}
                showGroup={true}
                headerRightSection={null}
                onSubmit={(field: JourneyProperty[]) => {
                    if (!field[0]) return;
                    close();
                    onSubmit(field[0]);
                }}
                itemTemplate={({ item }) => {
                    return <FiltersPropertySelectorFieldItem item={item} />;
                }}
                opened={opened}
                close={close}
                open={open}
                withinPortal={true}
                targetButton={targetElement}
                showSearch={true}
                searchKeys={['label', 'name', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
                showAllItemsGroup={true}
                width={500}
                height={350}
                disabled={disabled}
            />
        </>
    );
};

export default FiltersPropertySelector;
