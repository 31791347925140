import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useLocale } from '@hooks/useLocale';
import { useProject } from '@hooks/useProject';
import { ProjectSettings } from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PencilSimple, PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { isInvalidCronExpression } from '@utils/fieldValidators';
import cronstrue from 'cronstrue';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../mantineTheme';
import UserProfileSyncModal from './UserPropertiesSyncModal';

export const cronHelperText = (cron: string) => {
    const validationError = isInvalidCronExpression('Cron expression')(cron);
    const cronHumanString = cronstrue.toString(cron, {
        verbose: true,
        throwExceptionOnParseError: false,
    });
    return validationError ?? cronHumanString;
};

const UserPropertiesSync: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: project, isInitialLoading } = useProject(projectUuid);
    const [cronExpression, setCronExpression] = useState('0 0 * * *');
    const ability = useAbilityContext();
    const { user } = useApp();
    const canEditSyncSchedule = ability.can(
        'update',
        subject(ProjectSettings.syncSchedules, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    useEffect(() => {
        if (project && project.attributes?.syncConfig) {
            setCronExpression(project.attributes.syncConfig.cron);
        }
    }, [project]);
    const isSyncScheduleCreated = useMemo(
        () => Boolean(project?.attributes?.syncConfig?.cron),
        [project],
    );

    if (isInitialLoading) {
        return (
            <Flex direction={'column'} gap={8}>
                <SkeletonLoader height={10} />
                <SkeletonLoader width={500} height={15} />
            </Flex>
        );
    }

    return (
        <>
            <Box className="border border-shade-4 rounded-2xl p-0.5 mt-4">
                <Box className="w-full text-gray-700 bg-white border border-gray-50 rounded-xl">
                    <Flex
                        className="p-3 border-b border-shade-4"
                        justify="space-between"
                        align="center"
                    >
                        <Text className="font-semibold ">
                            {t('user_properties_sync.title')}
                        </Text>
                        {canEditSyncSchedule &&
                            (isSyncScheduleCreated ? (
                                <Button
                                    variant={ButtonVariant.OUTLINED}
                                    leftIcon={<PencilSimple />}
                                    onClick={open}
                                >
                                    {t('user_properties_sync.edit_button')}
                                </Button>
                            ) : (
                                <Button
                                    variant={ButtonVariant.FILLED}
                                    leftIcon={<PlusCircle color="white" />}
                                    onClick={open}
                                >
                                    {t('user_properties_sync.create_button')}
                                </Button>
                            ))}
                    </Flex>
                    {isSyncScheduleCreated &&
                    project?.attributes?.syncConfig?.cron ? (
                        <Text className="p-3">
                            {' '}
                            {cronHelperText(project.attributes.syncConfig.cron)
                                .length < 50
                                ? `${t(
                                      'user_properties_sync.update_message',
                                  )} ${
                                      cronHelperText(
                                          project.attributes.syncConfig.cron,
                                      )
                                          .charAt(0)
                                          .toLowerCase() +
                                      cronHelperText(
                                          project.attributes.syncConfig.cron,
                                      ).slice(1)
                                  }.`
                                : cronHelperText(
                                      project.attributes.syncConfig.cron,
                                  )}
                        </Text>
                    ) : (
                        <Text className="p-3">
                            {t('user_properties_sync.create_message')}
                        </Text>
                    )}
                </Box>
            </Box>
            <UserProfileSyncModal
                opened={opened}
                onClose={() => {
                    close();
                    setCronExpression(
                        project?.attributes?.syncConfig?.cron ?? '0 0 * * *',
                    );
                }}
                cronExpression={cronExpression}
                setCronExpression={setCronExpression}
            />
        </>
    );
};
export default React.memo(UserPropertiesSync);
