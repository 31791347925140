import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, CloseButton, Flex, Text } from '@mantine/core';
import { CornersOut, PaintBrush } from '@phosphor-icons/react';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import JsonEditorWithMentions, {
    type JsonEditorWithMentionsRef,
} from '../JsonEditorWithMentions';
import {
    type CallAPIFieldAndSchemaType,
    type FieldsDataForMentions,
} from '../types';
import {
    parseStringWithVariablesToObject,
    stringifyObjectWithVariables,
} from '../utils';

interface RequestBodyProps extends FieldsDataForMentions {
    payloadMapper: string;
    handlePayloadMapperChange: (
        value: CallAPIFieldAndSchemaType<string>,
    ) => void;
    disabled: boolean;
}

const RequestBody: React.FC<RequestBodyProps> = ({
    payloadMapper,
    handlePayloadMapperChange,
    fieldIds,
    journeyProperties,
    warehouseFieldsMap,
    journeyDataSchema,
    disabled,
}) => {
    const [jsonString, setJsonString] = useState<string>(
        JSON.stringify(parseStringWithVariablesToObject(payloadMapper)),
    );
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isValidJson, setIsValidJson] = useState(true);
    const jsonEditorRef = useRef<JsonEditorWithMentionsRef>(null);
    const modalJsonEditorRef = useRef<JsonEditorWithMentionsRef>(null);
    const { t } = useLocale();

    const handleJsonChange = useCallback(
        (newJson: string) => {
            try {
                setJsonString(newJson);
                const parsedJson = JSON.parse(newJson);
                setIsValidJson(true);
                const parsedObject = stringifyObjectWithVariables(parsedJson);
                handlePayloadMapperChange({
                    value: parsedObject,
                    journeyDataSchema: journeyDataSchema,
                });
            } catch (error) {
                setIsValidJson(false);
            }
        },
        [handlePayloadMapperChange, journeyDataSchema],
    );

    const beautifyJson = useCallback(() => {
        if (isModalOpen && modalJsonEditorRef.current) {
            modalJsonEditorRef.current.formatJson();
        } else if (jsonEditorRef.current) {
            jsonEditorRef.current.formatJson();
        }
    }, [isModalOpen]);

    const editorComponent = useMemo(() => {
        return (
            <JsonEditorWithMentions
                ref={jsonEditorRef}
                value={jsonString}
                onChange={handleJsonChange}
                items={journeyProperties}
                fieldIds={fieldIds}
                warehouseFieldsMap={warehouseFieldsMap}
                journeyDataSchema={journeyDataSchema}
                journeyProperties={journeyProperties}
                isOneLiner={false}
                setError={() => {}}
                isEditMode={!disabled}
                handleInnerFocus={(isFocused) => {
                    if (isFocused) {
                        setIsValidJson(true);
                    }
                }}
            />
        );
    }, [
        handleJsonChange,
        journeyProperties,
        fieldIds,
        warehouseFieldsMap,
        journeyDataSchema,
        jsonString,
        disabled,
    ]);

    const modalEditorComponent = useMemo(() => {
        return (
            <JsonEditorWithMentions
                ref={modalJsonEditorRef}
                value={jsonString}
                onChange={handleJsonChange}
                items={journeyProperties}
                fieldIds={fieldIds}
                warehouseFieldsMap={warehouseFieldsMap}
                journeyDataSchema={journeyDataSchema}
                journeyProperties={journeyProperties}
                isOneLiner={false}
                setError={() => {}}
                isEditMode={!disabled}
                handleInnerFocus={(isFocused) => {
                    if (isFocused) {
                        setIsValidJson(true);
                    }
                }}
            />
        );
    }, [
        handleJsonChange,
        journeyProperties,
        fieldIds,
        warehouseFieldsMap,
        journeyDataSchema,
        jsonString,
        disabled,
    ]);

    return (
        <Flex direction="column" gap="md" className="w-full">
            <Flex align="center" gap={12} justify="space-between">
                <Text>{t('journey_builder.call_api_request_body_label')}</Text>
                <Flex gap={12}>
                    <Button
                        onClick={beautifyJson}
                        variant={ButtonVariant.SUBTLE_ACCENTED}
                    >
                        <PaintBrush
                            weight="duotone"
                            color={'rgb(var(--color-blu-800))'}
                        />
                        {t('common.tidy')}
                    </Button>
                    <Button
                        onClick={() => setIsModalOpen(true)}
                        variant={ButtonVariant.SUBTLE_ACCENTED}
                    >
                        <CornersOut
                            weight="duotone"
                            color={'rgb(var(--color-blu-800))'}
                        />
                        {t('common.expand')}
                    </Button>
                </Flex>
            </Flex>
            <Box
                className={`w-full min-h-[11rem] border pt-1 rounded-xl overflow-hidden  border-${
                    isValidJson ? 'blu-800' : 'halt-800'
                }`}
                onClick={() => jsonEditorRef.current?.focus()}
            >
                {!isModalOpen && editorComponent}
            </Box>
            <Modal
                opened={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                }}
                size="xl"
                withCloseButton={false}
            >
                <Flex
                    align="center"
                    justify="space-between"
                    w="100%"
                    className="mb-4 sticky top-0 !bg-white z-10 border-b border-gray-200 pb-4 pt-2 "
                >
                    <Text fw={500} fz="lg" className="text-gray-800">
                        {t('journey_builder.call_api_request_body_label')}
                    </Text>
                    <Flex gap={4} align="center">
                        <Button
                            onClick={beautifyJson}
                            variant={ButtonVariant.SUBTLE_ACCENTED}
                        >
                            <PaintBrush
                                weight="duotone"
                                color={'rgb(var(--color-blu-800))'}
                            />
                            {t('common.tidy')}
                        </Button>
                        <CloseButton onClick={() => setIsModalOpen(false)} />
                    </Flex>
                </Flex>

                <Box
                    className={` w-full min-h-[11rem] border pt-1 rounded-xl overflow-hidden border-${
                        isValidJson ? 'blu-800' : 'halt-800'
                    }`}
                    onClick={() => modalJsonEditorRef.current?.focus()}
                >
                    {modalEditorComponent}
                </Box>
            </Modal>
        </Flex>
    );
};

export default React.memo(RequestBody);
