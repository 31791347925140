import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { fuzzyFilter, TableTypeOptions } from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType, type JourneyDataSchema } from '@lightdash/common';
import { Box, Button } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import {
    getCoreRowModel,
    useReactTable,
    type ColumnDef,
} from '@tanstack/react-table';
import React, { useMemo, useRef } from 'react';
import { type HeadersType } from '..';
import { ButtonVariant } from '../../../../../../../../mantineTheme';
import JsonEditorWithMentions, {
    type JsonEditorWithMentionsRef,
} from '../../JsonEditorWithMentions';
import InputCell from '../../ResponsePayloadMapper/InputCell';
import {
    defaultJourneyDataSchema,
    revertStringifyStringWithVariables,
    stringifyStringWithVariables,
} from '../../utils';

interface HeaderTableProps {
    headers: HeadersType[];
    updateHeaders: (index: number, { key, value }: HeadersType) => void;
    deleteHeader: (index: number) => void;
    containerRef: React.RefObject<HTMLDivElement>;
    fieldIds: string[];
    journeyProperties: PropertySelectListType<JourneyProperty>[];
    warehouseFieldsMap: FieldsWithSuggestions;
    journeyDataSchema: JourneyDataSchema | undefined;
    disabled: boolean;
}

const HeaderTable: React.FC<HeaderTableProps> = ({
    headers,
    updateHeaders,
    deleteHeader,
    containerRef,
    fieldIds,
    journeyProperties,
    warehouseFieldsMap,
    journeyDataSchema,
    disabled,
}) => {
    const { t } = useLocale();
    const jsonEditorRef = useRef<JsonEditorWithMentionsRef>(null);
    const columns: ColumnDef<HeadersType>[] = useMemo(() => {
        return [
            {
                header: t('common.uppercase_key'),
                accessorKey: 'key',
                cell: ({ row }) => {
                    return (
                        <InputCell
                            updateTable={(value) => {
                                updateHeaders(row.index, {
                                    key: value,
                                    value: row.original.value,
                                });
                            }}
                            inputValue={row.original.key}
                            disabled={disabled}
                        />
                    );
                },
            },
            {
                header: t('journey_builder.header_table_value_header'),
                accessorKey: 'type',
                cell: ({ row }) => {
                    return (
                        <Box
                            className={`w-[10rem] rounded-lg overflow-hidden p-1.5 hover:bg-shade-2`}
                        >
                            <JsonEditorWithMentions
                                value={revertStringifyStringWithVariables(
                                    row.original.value,
                                )}
                                ref={jsonEditorRef}
                                onChange={(value) => {
                                    updateHeaders(row.index, {
                                        key: row.original.key,
                                        value: stringifyStringWithVariables(
                                            value,
                                        ),
                                    });
                                }}
                                fieldIds={fieldIds}
                                journeyProperties={journeyProperties}
                                warehouseFieldsMap={warehouseFieldsMap}
                                journeyDataSchema={
                                    journeyDataSchema ??
                                    defaultJourneyDataSchema
                                }
                                items={journeyProperties}
                                setError={undefined}
                                isOneLiner={true}
                                placeholder={t(
                                    'journey_builder.header_table_value_placeholder',
                                )}
                                isEditMode={!disabled}
                                handleInnerFocus={undefined}
                            />
                        </Box>
                    );
                },
            },
            {
                header: '',
                accessorKey: 'cancel',
                cell: ({ row }) => {
                    // Check if the component is disabled
                    if (disabled) {
                        return null; // Render nothing if disabled
                    }
                    return (
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            className={`p-2`}
                            onClick={() => {
                                deleteHeader(row.index);
                            }}
                        >
                            <X
                                weight="bold"
                                color={'rgb(var(--color-gray-700))'}
                            />
                        </Button>
                    );
                },
            },
        ];
    }, [
        t,
        deleteHeader,
        updateHeaders,
        disabled,
        fieldIds,
        journeyProperties,
        warehouseFieldsMap,
        journeyDataSchema,
    ]);
    const table = useReactTable({
        data: headers,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Box className="border border-shade-4 rounded-2xl p-0.5">
            <Box className="overflow-hidden border rounded-xl border-gray-50">
                <Box
                    className="max-h-[38rem] overflow-auto "
                    ref={containerRef}
                >
                    <TableTypeOptions
                        viewType={ShowDataType.LIST}
                        table={table}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default React.memo(HeaderTable);
