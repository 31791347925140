import FilterGroupForm from '@components/Audience/Filters/FilterGroupForm';
import { categorizeDimensionsAndEvents } from '@components/Audience/utils';
import { useFilterFields } from '@hooks/useFilterFields';
import {
    type FilterGroup,
    type Filters,
    type JourneyFilterGroup,
    type JourneyFiltersConfig,
} from '@lightdash/common';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { sortRelationFields } from '@utils/relation';
import React, { useMemo } from 'react';
import { useJourneyFiltersContext } from './JourneyFiltersProvider';

interface JourneyFiltersGroupProps {
    journeyFilters: JourneyFiltersConfig['journeyFilters'];
    setJourneyFilters: (value: JourneyFiltersConfig['journeyFilters']) => void;
}

const JourneyFiltersGroup: React.FC<JourneyFiltersGroupProps> = ({
    journeyFilters,
    setJourneyFilters,
}) => {
    const {
        warehouseFieldsMap,
        eventsData,
        audienceFields,
        warehouseRelation,
        journeyEventFields,
    } = useJourneyFiltersContext();
    const { dimensions } = useFilterFields();

    const { isEditable } = useJourneyBuilderContext((context) => context.state);

    const filters = useMemo(() => {
        if (!journeyFilters) return;

        return { dimensions: journeyFilters } as Filters;
    }, [journeyFilters]);

    const groupFilter = useMemo(
        () =>
            categorizeDimensionsAndEvents(
                journeyFilters as FilterGroup,
                warehouseFieldsMap,
                eventsData,
            ),
        [journeyFilters, warehouseFieldsMap, eventsData],
    );

    const handleJourneyFiltersChange = (value: JourneyFilterGroup) => {
        setJourneyFilters(value);
    };

    if (!journeyFilters || !filters) return null;

    return (
        <>
            {filters.dimensions && groupFilter && (
                <FilterGroupForm
                    allowConvertToGroup
                    hideLine
                    hideButtons
                    conditionLabel="dimension"
                    filterGroup={groupFilter}
                    fields={sortRelationFields([
                        ...dimensions,
                        ...eventsData,
                        ...audienceFields,
                        ...journeyEventFields,
                    ])}
                    isEditMode={isEditable}
                    onChange={(value) =>
                        handleJourneyFiltersChange(value as JourneyFilterGroup)
                    }
                    onDelete={() => setJourneyFilters(undefined)}
                    filters={filters}
                    setFilters={(value) => {
                        setJourneyFilters(
                            value.dimensions as JourneyFilterGroup,
                        );
                    }}
                    groupIndex={0}
                    relation={warehouseRelation}
                    customCss="!w-12"
                    additionalMetrics={undefined}
                    setAdditionalMetrics={undefined}
                />
            )}
        </>
    );
};

export default JourneyFiltersGroup;
