import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import { useLocale } from '@hooks/useLocale';
import { type FilterableField } from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import FieldListItem from './FieldListItem';
import { convertFieldsIntoPropertySelectListType } from './FieldListItem/utils';
import FilterFieldSelect from './FilterInputs/FilterFieldSelect';
import { type FieldWithSuggestions } from './FiltersProvider';

interface FilterRuleFormPropertySelectProps {
    fields: FieldWithSuggestions[];
    onSubmit: (items: FieldWithSuggestions[]) => void;
    isEditMode: boolean;
    activeField: FilterableField | undefined;
    withinPortal?: boolean;
    showFieldSource?: boolean;
}
const FilterRuleFormPropertySelect: React.FC<
    FilterRuleFormPropertySelectProps
> = ({
    fields,
    onSubmit,
    isEditMode,
    activeField,
    withinPortal = false,
    showFieldSource = true,
}) => {
    const [opened, { open, close }] = useDisclosure();
    const { t } = useLocale();
    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={convertFieldsIntoPropertySelectListType(fields, true)}
                showGroup={false}
                headerRightSection={null}
                onSubmit={(field: FieldWithSuggestions[]) => {
                    if (!field[0]) return;
                    onSubmit(field);
                    close();
                }}
                itemTemplate={({ item }) => {
                    return (
                        <FieldListItem
                            item={item}
                            checked={item.isChecked ?? false}
                            showCheckbox={false}
                            disabled={item.isDisabled ?? false}
                            showFieldSource={showFieldSource}
                        />
                    );
                }}
                opened={opened}
                close={close}
                open={open}
                withinPortal={withinPortal}
                targetButton={
                    <FilterFieldSelect
                        activeField={activeField}
                        isEditMode={isEditMode}
                        showFieldSource={showFieldSource}
                        activeRelation={undefined}
                        showHoverIcon={false}
                    />
                }
                showSearch={true}
                searchKeys={['label', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={false}
                disabled={!isEditMode}
            />
        </>
    );
};
export default FilterRuleFormPropertySelect;
