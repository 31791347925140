import { useLocale } from '@hooks/useLocale';
import { SubscriptionGroupType } from '@lightdash/common/src/types/subscriptionGroup';
import { Box, Menu, Text } from '@mantine/core';
import {
    CheckCircle,
    CircleDashed,
    DotsSixVertical,
    DotsThree,
    FolderOpen,
    Gavel,
} from '@phosphor-icons/react';
import { REACT_ARBORIST_PARENT_ID } from '@utils/constants';
import React, {
    useCallback,
    useMemo,
    type CSSProperties,
    type ReactNode,
} from 'react';
import { type NodeApi, type TreeApi } from 'react-arborist';
import { type NodeData } from './SubscriptionBody';

interface NodeProps<T> {
    node: NodeApi<T>;
    style: CSSProperties;
    dragHandle?: (el: HTMLDivElement | null) => void;
    tree: TreeApi<T>;
    onGroupCallback: (id: string) => void;
    onCategoryCallback: (id: string) => void;
    onUpdateGroupCategory: (id: string) => void;
    onMoveUp: (id: string) => void;
    onMoveDown: (id: string) => void;
    isViewOnly: boolean;
}

const Node: React.FC<NodeProps<NodeData>> = ({
    node,
    style,
    dragHandle,
    onGroupCallback,
    onCategoryCallback,
    onUpdateGroupCategory,
    onMoveUp,
    onMoveDown,
    isViewOnly,
}) => {
    const { t } = useLocale();
    const getClassNames = useMemo(() => {
        const parent = node.parent;
        const isRoot = parent && parent.id === REACT_ARBORIST_PARENT_ID;
        const children = parent && parent.children;
        const isLastChild =
            children && node.id === children[children.length - 1].id;
        const isNotFirstChild = children && node.id !== children[0].id;

        return `
            ${isRoot ? 'rounded-lg' : ''}
            ${isLastChild ? 'rounded-b-lg' : ''}
            ${!isRoot && isNotFirstChild ? '!border-t-0' : ''}
            border-[1px] border-shade-4 bg-white flex flex-row justify-between px-3.5 mx-1.5
        `;
    }, [node.id, node.parent]);

    const getStyles = useMemo(() => {
        return {
            height:
                node.data && node.data.children
                    ? `${(node.data.children.length + 1) * 64}px`
                    : '',
            alignItems:
                node.data && node.data.children ? 'baseline' : 'initial',
        };
    }, [node.data]);

    const renderTypeComponent = useCallback(
        (icon: ReactNode, textKey: string) => (
            <>
                {icon}
                <Text className="text-gray-600 text-xs font-normal ml-1">
                    {textKey}
                </Text>
            </>
        ),
        [],
    );

    const renderTypeDetails = useMemo(() => {
        switch (node.data.type) {
            case SubscriptionGroupType.OPT_OUT:
                return renderTypeComponent(
                    <CheckCircle weight="duotone" />,
                    t('subscription_group_content.opt_out'),
                );
            case SubscriptionGroupType.OPT_IN:
                return renderTypeComponent(
                    <CircleDashed weight="duotone" />,
                    t('subscirption_group_content.opt_in'),
                );

            case SubscriptionGroupType.REQUIRED:
                return renderTypeComponent(
                    <Gavel weight="duotone" />,
                    t('subscription_group_content.required'),
                );
            default:
                return null;
        }
    }, [node.data.type, renderTypeComponent, t]);

    const renderCategoryMenuItem = useMemo(() => {
        if (node.children) {
            return (
                <Menu.Item onClick={() => onCategoryCallback(node.id)}>
                    {t('node.edit_category')}
                </Menu.Item>
            );
        }
        if (node.parent && node.parent.id === REACT_ARBORIST_PARENT_ID) {
            return (
                <Menu.Item onClick={() => onUpdateGroupCategory(node.id)}>
                    {t('group_category_update_modal.add_to_category')}
                </Menu.Item>
            );
        }
        return (
            <Menu.Item onClick={() => onUpdateGroupCategory(node.id)}>
                {t('group_category_update_modal.change_category')}
            </Menu.Item>
        );
    }, [
        node.children,
        node.id,
        node.parent,
        onCategoryCallback,
        onUpdateGroupCategory,
        t,
    ]);

    const NodeMenu = useMemo(() => {
        return (
            <Menu.Dropdown>
                <Menu.Item onClick={() => onMoveUp(node.id)}>
                    {t('node.move_up')}
                </Menu.Item>
                <Menu.Item onClick={() => onMoveDown(node.id)}>
                    {t('node.move_down')}
                </Menu.Item>
                {renderCategoryMenuItem}
                {!node.children && (
                    <Menu.Item onClick={() => onGroupCallback(node.id)}>
                        {t('node.edit_group')}
                    </Menu.Item>
                )}
            </Menu.Dropdown>
        );
    }, [
        node.children,
        node.id,
        onGroupCallback,
        onMoveDown,
        onMoveUp,
        renderCategoryMenuItem,
        t,
    ]);

    return (
        <Box className="node-container !pl-0" style={style} ref={dragHandle}>
            <Box className={getClassNames} style={getStyles}>
                <Box className="flex flex-row items-center h">
                    {!isViewOnly && <DotsSixVertical size={13} weight="bold" />}
                    <Box
                        className={`py-2.5 ${
                            node.parent &&
                            node.parent.id !== REACT_ARBORIST_PARENT_ID
                                ? 'pl-[1.62rem]'
                                : 'pl-3.5'
                        }`}
                    >
                        <Box className="flex mb-1 items-center">
                            {node.children && (
                                <FolderOpen size={13} className="mr-1.5" />
                            )}
                            <Text className="text-sm text-gray-800 font-medium">
                                {node.data.name}
                            </Text>
                        </Box>
                        {node.data.description ? (
                            <Text className="text-gray-600 text-xs font-normal">
                                {node.data.description}
                            </Text>
                        ) : (
                            <Text className="text-gray-600 text-xs font-normal">
                                {t('node.no_description')}
                            </Text>
                        )}
                    </Box>
                </Box>

                <Box className="flex justify-between py-2.5 w-[8.5rem]">
                    <Box className="flex">{renderTypeDetails}</Box>
                    {node.data.userPreference !== undefined && (
                        <Text
                            className={`text-sm font-medium ${
                                node.data.userPreference
                                    ? 'text-blu-800'
                                    : 'text-gray-600'
                            }`}
                        >
                            {node.data.userPreference
                                ? t('subscription_group_content.yes')
                                : t('subscription_group_content.no')}
                        </Text>
                    )}

                    {!isViewOnly && (
                        <Menu position="left-start" width={150} shadow="md">
                            <Menu.Target>
                                <DotsThree
                                    size={17}
                                    className="hover:cursor-pointer"
                                    weight="bold"
                                />
                            </Menu.Target>
                            {NodeMenu}
                        </Menu>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Node;
