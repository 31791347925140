import DataTable from '@components/Table';

import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import { useLocale } from '@hooks/useLocale';
import {
    ShowDataType,
    type Pagination,
    type ReducedTemplate,
} from '@lightdash/common';
import React from 'react';
import { useTemplateColumns, useTemplateGrid } from './useTemplateTableUtils';

type BaseTemplateTableProps = {
    templates: ReducedTemplate[];
    pagination: Pagination;
    onPageChange: (page: number) => void;
    isDataFetching: boolean;
    handleRowPress: (id: string) => void;
    withBorder?: boolean;
    tableBackgroundColor?: string;
    handleSearchInput: (value: string) => void;
    searchValue: string;
    activeTab: string;
};
type ManagerTemplateTableProps = BaseTemplateTableProps &
    HideProps & {
        isManager: true;
        hideHiddenButton: boolean;
    };
type NonManagerTemplateTableProps = BaseTemplateTableProps & {
    isManager: false;
};

type TemplateTableProps =
    | ManagerTemplateTableProps
    | NonManagerTemplateTableProps;
const TemplateTable: React.FC<TemplateTableProps> = ({
    templates,
    pagination,
    onPageChange,
    isDataFetching,
    handleRowPress,
    withBorder,
    tableBackgroundColor,
    handleSearchInput,
    searchValue,
    activeTab,
    isManager,
    ...hideProps
}) => {
    const { hideHiddenButton, isHiddenActive, onHiddenChange } =
        hideProps as ManagerTemplateTableProps;
    const templateColumns = useTemplateColumns();
    const gridColumns = useTemplateGrid({
        handleClick: handleRowPress,
    });
    const { t } = useLocale();

    return (
        <div>
            {templates && gridColumns && (
                <DataTable<ReducedTemplate>
                    name={t('templates')}
                    tableData={templates}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: templateColumns,
                        },
                        {
                            format: ShowDataType.GRID,
                            formatData: gridColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'template_manager.table_search_placeholder',
                        { channel: activeTab },
                    )}
                    paginationText={t('template_manager.pagination_text')}
                    isManualPagination={true}
                    onPageChange={onPageChange}
                    pagination={pagination}
                    isDataFetching={isDataFetching}
                    gridItemBorder={false}
                    withBorder={withBorder}
                    pageSize={8}
                    tableBackgroundColor={tableBackgroundColor}
                    isEditable={false}
                    onSearchChange={handleSearchInput}
                    searchValue={searchValue}
                    leftSection={
                        isManager &&
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={onHiddenChange}
                                activeHideText={t(
                                    'template_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                    handleRowClick={(row) => {
                        handleRowPress(row.original.id);
                    }}
                />
            )}
        </div>
    );
};

export default React.memo(TemplateTable);
