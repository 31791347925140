import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import {
    CommunicationChannelName,
    type CommunicationChannel,
} from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import {
    CHANNEL_SETUP_STATES,
    useChannelContext,
} from '@providers/ChannelProvider';
import React, { useMemo } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import AddChannel from '../AddChannel';
import Loader from '../Loader';

const AddChannelModal: React.FC<{
    loading: boolean;
}> = ({ loading }) => {
    const { t } = useLocale();
    const { state, actions } = useChannelContext((context) => context);
    const { channelSetupState, selectedProvider, showSetupModal } = state;
    const { handleShowSetUpModal, updateChannelSetupStateAction } = actions;

    const getModalTitle = useMemo(() => {
        if (channelSetupState === CHANNEL_SETUP_STATES.ADD_CHANNEL_DETAILS) {
            return t(
                'channel_settings_add_channel_modal.add_connection_title',
                {
                    provider: selectedProvider?.providerName,
                },
            );
        }
        if (channelSetupState === CHANNEL_SETUP_STATES.UPDATE_CHANNEL_DETAILS) {
            return t(
                'channel_settings_add_channel_modal.edit_connection_title',
                {
                    provider: selectedProvider?.providerName,
                },
            );
        }
        return t('channels_container_add_channel');
    }, [channelSetupState, selectedProvider, t]);

    const renderModalTitleComponent = useMemo(() => {
        return (
            <Box>
                <Text>{getModalTitle}</Text>
                {channelSetupState !== CHANNEL_SETUP_STATES.SELECT_PROVIDER && (
                    <Text className="flex items-center gap-1">
                        <ChannelIcon
                            channel={
                                selectedProvider?.channelName as CommunicationChannel
                            }
                            showChannelName={false}
                        />
                        <Text className="text-sm text-gray-600">
                            {
                                CommunicationChannelName[
                                    selectedProvider?.channelName as CommunicationChannel
                                ]
                            }{' '}
                            channel
                        </Text>
                    </Text>
                )}
            </Box>
        );
    }, [selectedProvider, channelSetupState, getModalTitle]);

    const renderModalFooterRightSection = useMemo(() => {
        if (channelSetupState === CHANNEL_SETUP_STATES.SELECT_PROVIDER) {
            return (
                <Flex gap="md">
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => handleShowSetUpModal(false)}
                    >
                        {t(
                            'channel_settings_add_channel_modal.secondary_button',
                        )}
                    </Button>
                </Flex>
            );
        }
        return null;
    }, [channelSetupState, handleShowSetUpModal, t]);

    const handleModalClose = () => {
        handleShowSetUpModal(false);
        setTimeout(
            () =>
                updateChannelSetupStateAction(
                    CHANNEL_SETUP_STATES.SELECT_PROVIDER,
                ),
            200,
        );
    };

    return (
        <Modal
            styles={(_params) => ({
                content: {
                    maxHeight: '600px !important',
                    minHeight: '300px',
                    overflowY: 'auto',
                    scrollbarWidth: 'none', // Hide scrollbar in Firefox
                    msOverflowStyle: 'none', // Hide scrollbar in IE 10+
                },
                'content::-webkit-scrollbar': {
                    display: 'none',
                },
            })}
            trapFocus
            opened={showSetupModal}
            onClose={handleModalClose}
            centered
            size="xl"
            title={renderModalTitleComponent}
            footerRightSection={renderModalFooterRightSection}
        >
            {loading ? <Loader length={4} /> : <AddChannel />}
        </Modal>
    );
};

export default AddChannelModal;
