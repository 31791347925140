import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { useGetCampaignRuns } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import { Box, Loader } from '@mantine/core';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';
import ViewRunsTable from './ViewRuns/ViewRunsTable';

interface ViewRunsModalProps {
    showViewModal: boolean;
    viewModalClose: () => void;
}

export interface ViewRuns {
    executionTime: string;
    audienceName: string;
    status: string;
}

const ViewRunsModal: React.FC<ViewRunsModalProps> = ({
    showViewModal,
    viewModalClose,
}) => {
    const { t } = useLocale();
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const [currentPage, setCurrentPage] = useState(1);
    const { data, isFetching, isInitialLoading } = useGetCampaignRuns({
        campaignId: campaignUuid,
        perPage: 10,
        currentPage,
    });

    return (
        <Modal
            opened={showViewModal}
            onClose={() => viewModalClose()}
            keepMounted={false}
            title={t('view_runs.view_runs')}
            size="calc(75vw)"
            footerRightSection={
                <ModalFooter
                    showSecondaryButton={true}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    secondaryButtonClick={() => viewModalClose()}
                    secondaryText={t('common.close')}
                    showPrimaryButton={false}
                    primaryButtonClick={undefined}
                    primaryText={undefined}
                    primaryLeftIcon={undefined}
                    isLoading={undefined}
                    primaryRightIcon={undefined}
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    primaryButtonDisabled={undefined}
                    primaryButtonCustomClass={undefined}
                />
            }
        >
            {isInitialLoading ? (
                <Box className="flex items-center justify-center w-full ">
                    <Loader />
                </Box>
            ) : (
                <ViewRunsTable
                    isDataFetching={isFetching}
                    viewRuns={data}
                    onPageChange={(newPage) => {
                        setCurrentPage(newPage);
                    }}
                />
            )}
        </Modal>
    );
};

export default ViewRunsModal;
