import MembersPanel from '@components/OrganizationSettings/MembersPanel';
import OverviewPanel from '@components/OrganizationSettings/OverviewPanel';
import { t as translate } from 'i18next';
import { Users } from 'react-feather';
export interface SettingListItemType {
    label: string;
    redirectTo: string;
    routeMatch: string;
    children: React.ReactNode;
    leftSection?: React.ReactNode;
}

const baseSettingsReditectPath = `/organization`;

export const organizationSettingsMenuItems = (
    redirect: string | null,
): SettingListItemType[][] => {
    return [
        [
            {
                label: translate('organization_settings.menu_item_overview'),
                redirectTo: `${baseSettingsReditectPath}/overview${
                    redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''
                }`,
                routeMatch: `${baseSettingsReditectPath}/overview`,
                children: <OverviewPanel />,
            },
        ],
        [
            {
                label: translate('organization_settings.menu_item_members'),
                redirectTo: `${baseSettingsReditectPath}/members${
                    redirect ? `?redirect=${encodeURIComponent(redirect)}` : ''
                }`,
                routeMatch: `${baseSettingsReditectPath}/members`,
                children: <MembersPanel />,
                leftSection: <Users size={13} />,
            },
        ],
    ];
};
