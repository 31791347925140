import { Button, Group, type GroupPosition } from '@mantine/core';
import React from 'react';

interface ModalFooterProps {
    primaryText: string | undefined;
    secondaryText: string | undefined;
    primaryButtonClick: (() => void) | undefined;
    secondaryButtonClick: (() => void) | undefined;
    showPrimaryButton: boolean | undefined;
    showSecondaryButton: boolean | undefined;
    primaryLeftIcon: React.ReactNode | undefined;
    primaryRightIcon: React.ReactNode | undefined;
    secondaryLeftIcon: React.ReactNode | undefined;
    secondaryRightIcon: React.ReactNode | undefined;
    primaryButtonVariant: string | undefined;
    secondaryButtonVariant: string | undefined;
    isLoading: boolean | undefined;
    primaryButtonDisabled: boolean | undefined;
    primaryButtonCustomClass: string | undefined;
    justify?: GroupPosition;
}

const ModalFooter: React.FC<ModalFooterProps> = ({
    primaryText,
    secondaryText,
    primaryButtonClick,
    secondaryButtonClick,
    showPrimaryButton,
    showSecondaryButton,
    primaryLeftIcon,
    primaryRightIcon,
    secondaryLeftIcon,
    secondaryRightIcon,
    primaryButtonVariant,
    secondaryButtonVariant,
    isLoading,
    primaryButtonDisabled,
    primaryButtonCustomClass,
    justify,
}) => {
    return (
        <Group className="flex gap-1.5" position={justify}>
            {showSecondaryButton && (
                <Button
                    variant={secondaryButtonVariant}
                    onClick={secondaryButtonClick}
                    leftIcon={secondaryLeftIcon}
                    rightIcon={secondaryRightIcon}
                >
                    {secondaryText}
                </Button>
            )}
            {showPrimaryButton && (
                <Button
                    loading={isLoading}
                    variant={primaryButtonVariant}
                    onClick={primaryButtonClick}
                    leftIcon={primaryLeftIcon}
                    rightIcon={primaryRightIcon}
                    disabled={primaryButtonDisabled}
                    className={primaryButtonCustomClass}
                >
                    {primaryText}
                </Button>
            )}
        </Group>
    );
};

export default ModalFooter;
