import { TextInput, type TextInputProps } from '@mantine/core';

interface TextInputVariableWidthProps extends TextInputProps {
    minWidth?: number;
    maxWidth?: number;
}

const TextInputVariableWidth = ({
    value,
    minWidth = 40,
    maxWidth = 100,
    ...rest
}: TextInputVariableWidthProps) => {
    return (
        <TextInput
            sx={() => ({
                '& input': {
                    minWidth,
                    width: `${
                        minWidth +
                        (value
                            ? value.toString().length
                            : rest.placeholder
                            ? rest.placeholder.length
                            : 0) *
                            5
                    }px`,
                    maxWidth,
                },
            })}
            value={value}
            {...rest}
        />
    );
};

export default TextInputVariableWidth;
