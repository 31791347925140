import {
    type AndNestedMetricQuery,
    type NestedMetricQueryGroup,
    type OrNestedMetricQuery,
} from '@lightdash/common';
import { metricQueryDefaultState } from '@providers/AudienceProvider';
import { v4 as uuidv4 } from 'uuid';

export const formatNestedMetricQuery = (
    nestedMetricQuery: NestedMetricQueryGroup | undefined,
) => {
    if (!nestedMetricQuery) {
        return {
            and: [
                {
                    ...metricQueryDefaultState.metricQuery,
                    limit: -1,
                },
            ],
            id: uuidv4(),
        };
    }
    const formattedNestedMetricQuery = { ...nestedMetricQuery };
    let formattedAndFilters = (
        nestedMetricQuery as AndNestedMetricQuery
    )?.and?.map((query) => {
        return {
            ...query,
            limit: -1,
        };
    });
    let formattedOrFilters = (
        nestedMetricQuery as OrNestedMetricQuery
    )?.or?.map((query) => {
        return {
            ...query,
            limit: -1,
        };
    });

    if (formattedAndFilters && formattedAndFilters?.length) {
        return {
            ...formattedNestedMetricQuery,
            and: formattedAndFilters,
        };
    }

    return {
        ...formattedNestedMetricQuery,
        or: formattedOrFilters,
    };
};
