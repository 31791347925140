import { type Filters } from './filter';
import { type MetricQuery, type SortField } from './metricQuery';
import { type LightdashUser } from './user';
import { type WarehouseTableColumn } from './warehouse';

export const CUSTOM_MATERIALIZATION_PREFIX = 'srt_deduped_events_';

export enum CustomMaterialisationRunStatus {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    RUNNING = 'RUNNING',
    SCHEDULED = 'SCHEDULED',
}

export type InsertCustomMaterialisation = Pick<
    CustomMaterialisation,
    'projectUuid' | 'relationId'
> &
    Partial<
        Pick<
            CustomMaterialisation,
            | 'name'
            | 'description'
            | 'metricQuery'
            | 'sqlQuery'
            | 'primaryKey'
            | 'incrementalTimestamp'
            | 'cron'
            | 'cronStartAt'
            | 'cronEndAt'
            | 'isFilterable'
            | 'queryableDimensions'
        >
    >;

export type UpdateCustomMaterialisation = Partial<CustomMaterialisation>;

export type CustomMaterialisation = {
    id: string;
    name: string;
    description: string | undefined;
    projectUuid: string;
    relationId: string;
    metricQuery: MetricQuery | undefined;
    sqlQuery: string | undefined;
    primaryKey: string | undefined;
    incrementalTimestamp: string | undefined;
    createdAt: Date;
    createdBy: Pick<LightdashUser, 'userUuid' | 'firstName' | 'lastName'>;
    updatedAt: Date;
    updatedBy:
        | Pick<LightdashUser, 'userUuid' | 'firstName' | 'lastName'>
        | undefined;
    lastRunAt: Date | undefined;
    lastRunStatus: CustomMaterialisationRunStatus;
    cron: string | undefined;
    cronStartAt: Date | undefined;
    cronEndAt: Date | undefined;
    lastMaterialisationName: string | undefined;
    schedulerUuid: string | undefined;
    lastSuccessfulRunAt: Date | undefined;
    isFilterable: boolean | undefined;
    queryableDimensions: Array<WarehouseTableColumn> | undefined;
};

export type GetCustomMaterialisationnResponseDto = {
    status: string;
    results: CustomMaterialisation;
};

export type GetCustomMaterialisationsResponseDto = {
    status: string;
    results: CustomMaterialisation[];
};

export type InsertCustomer360Materialisation = Omit<
    InsertCustomMaterialisation,
    'name' | 'description'
>;

export type SetCacheCustomProperties = {
    properties: string[];
};

export enum CustomMaterialisationNames {
    CUSTOMER_360 = 'CUSTOMER_360',
    CACHED_PROPERTIES = 'CACHED_PROPERTIES',
}

export type SearchCustomer360Payload = {
    limit: number;
    offset: number;
    filters?: Filters;
    sorts?: SortField[];
};
