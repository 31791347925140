import { subject } from '@casl/ability';
import { Can } from '@components/common/Authorization';
import ErrorState from '@components/common/ErrorState';
import PageSpinner from '@components/PageSpinner';
import { useCommunicationChannels } from '@hooks/useCommunicationChannels';
import { useProject } from '@hooks/useProject';
import { useProjects } from '@hooks/useProjects';
import { useApp } from '@providers/AppProvider';
import { ProjectProvider } from '@providers/ProjectProvider';
import { RelationProvider } from '@providers/RelationProvider';
import { type ComponentProps, type FC } from 'react';
import { Redirect, Route, useParams } from 'react-router-dom';

// interface RedirectLogicProps {
//     projectData: Project | undefined;
//     location: ReturnType<typeof useLocation>;
//     projectUuid: string | undefined;
// }

// const useRedirectPath = ({
//     projectData,
// }: RedirectLogicProps): string | null => {
//     if (!projectData) {
//         return '/no-access';
//     }

//     // const isWarehousePath = location.pathname.includes(
//     //     `/projects/${projectUuid}/warehouse`,
//     // );

//     // if (!projectData.warehouseConnection && !isWarehousePath) {
//     //     return `/projects/${projectUuid}/warehouse`;
//     // } else if (!projectData.warehouseConnection && isWarehousePath) {
//     //     return null;
//     // } else if (projectData.warehouseConnection && isWarehousePath) {
//     //     return `/projects/${projectUuid}`;
//     // }

//     // const isBlobPath = location.pathname.includes(
//     //     `/projects/${projectUuid}/blob`,
//     // );

//     // if (projectData?.blobConnection && isBlobPath) {
//     //     return `/projects/${projectUuid}`;
//     // } else if (!projectData?.blobConnection && !isBlobPath) {
//     //     return `/projects/${projectUuid}/blob`;
//     // } else if (!projectData?.blobConnection && isBlobPath) {
//     //     return null;
//     // }

//     return null;
// };

const AuthorizedProjectRoute: FC<
    React.PropsWithChildren<ComponentProps<typeof Route>>
> = ({ children }) => {
    const { projectUuid } = useParams<{ projectUuid?: string }>();
    // const location = useLocation<{ from?: Location } | undefined>();
    const { isInitialLoading, data: projectData } = useProject(projectUuid);
    const {
        isInitialLoading: communicationChannelsLoading,
        data: communicationChannels,
    } = useCommunicationChannels(
        projectData && projectData.needsRelation ? false : true,
    );

    // const redirectTo = useRedirectPath({ projectData, location, projectUuid });

    if (isInitialLoading || communicationChannelsLoading) {
        return <PageSpinner />;
    }

    // if (redirectTo) {
    //     return (
    //         <Redirect
    //             to={{
    //                 pathname: redirectTo,
    //                 state: { from: location.state?.from },
    //             }}
    //         />
    //     );
    // }

    return (
        <ProjectProvider
            projectData={projectData}
            communicationChannels={communicationChannels}
        >
            <RelationProvider>{children}</RelationProvider>
        </ProjectProvider>
    );
};

const ProjectRoute: FC<
    React.PropsWithChildren<ComponentProps<typeof Route>>
> = ({ children, ...rest }) => {
    const { user } = useApp();
    const { data: projects, isInitialLoading, isError, error } = useProjects();

    return (
        <Route
            {...rest}
            render={(location) => {
                if (isInitialLoading) {
                    return <PageSpinner />;
                }

                if (isError && error) {
                    return <ErrorState error={error.error} />;
                }

                if (!projects || projects.length <= 0) {
                    return <Redirect to="/no-access" />;
                }

                return (
                    <Can
                        I="view"
                        this={subject('Project', {
                            organizationUuid: user.data?.organizationUuid,
                            projectUuid: location.match.params.projectUuid,
                        })}
                        passThrough
                    >
                        {(isAllowed) => {
                            return isAllowed ? (
                                <AuthorizedProjectRoute>
                                    {children}
                                </AuthorizedProjectRoute>
                            ) : (
                                <Redirect to="/no-project-access" />
                            );
                        }}
                    </Can>
                );
            }}
        />
    );
};

export default ProjectRoute;
