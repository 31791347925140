import Modal from '@components/common/modal/Modal';
import SelectAudience from '@components/common/SelectAudience';
import { useGetAudienceOverlap } from '@hooks/useAudience';
import { useAudiences } from '@hooks/useGetAudience';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceInternalTags,
    AudienceRunStatus,
    AudienceStatus,
    CommonReservedTags,
    RelationTableType,
    type Audience,
    type CompiledRelationTable,
    type NestedMetricQueryGroup,
} from '@lightdash/common';
import {
    Box,
    Button,
    CloseButton,
    Group,
    Loader,
    Skeleton,
    Stack,
    Text,
    Tooltip,
} from '@mantine/core';
import { Info, PlusCircle, UsersThree } from '@phosphor-icons/react';
import { useAudienceContext } from '@providers/AudienceProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { isNaN } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { type ListSelectOptionsProp } from 'types/ListSelect';
import { type InsightsCallbackType } from '.';
import { ButtonVariant } from '../../../mantineTheme';
import { formatNestedMetricQuery } from './utils';

const AUDIENCE_OVERLAP_MAX_COUNT = 5;

const AudienceOverlapItem = ({
    audience,
    overlap,
    loading,
    handleDeleteAudience,
    overlapCount,
}: {
    audience: Audience;
    overlap: string | number;
    loading: boolean;
    handleDeleteAudience: () => void;
    overlapCount: number;
}) => {
    return (
        <Stack className="border-base pb-1 mb-2" spacing={0}>
            <Group position="apart" className="p-2 border-b">
                <Stack spacing={2}>
                    <Group spacing={4}>
                        <UsersThree color={'rgb(var(--color-pink-800)'} />
                        <Text className="max-w-[200px] truncate">
                            {audience.name}
                        </Text>
                    </Group>
                    <Text className="ml-5 text-xs font-medium text-gray-800">
                        {audience.totalCount} users
                    </Text>
                </Stack>
                <CloseButton onClick={handleDeleteAudience} />
            </Group>
            {loading ? (
                <Box className="m-2">
                    <Skeleton width={100} height={20} />
                </Box>
            ) : (
                <Stack justify="center" align="center" spacing={2}>
                    <Text className="text-[23px] text-blu-800 font-medium">
                        {overlap}%
                    </Text>
                    <Text className="text-xs font-medium text-gray-800">
                        {overlapCount} users in both audiences
                    </Text>
                </Stack>
            )}
        </Stack>
    );
};

const Overlap = ({
    audienceCount,
    disableEdits,
    setRefreshCallback,
}: {
    audienceCount: number;
    disableEdits: boolean;
    setRefreshCallback: (payload: InsightsCallbackType) => void;
}) => {
    const initialRender = useRef(false);
    const { t } = useLocale();
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const [showSelectAudienceModal, setShowSelectAudienceModal] =
        useState<boolean>(false);
    const [selectedAudiences, setSelectedAudiences] = useState<Audience[]>([]);
    const [overlapData, setOverlapData] = useState<Record<string, number>>({});
    const { activeRelation } = useRelationContext();
    const { state, actions } = useAudienceContext((context) => context);
    const { audiencePayload, initialAudiencePayload } = state;
    const { setInsightsOverlapPayload } = actions;

    const primaryTable: CompiledRelationTable | undefined = useMemo(() => {
        if (activeRelation) {
            return Object.values(activeRelation.tables).find(
                (table) => table.type === RelationTableType.PRIMARY,
            );
        }
        return undefined;
    }, [activeRelation]);

    const {
        data,
        refetch: refetchAudiences,
        isLoading,
        isFetching,
    } = useAudiences({
        query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}&excludesTags=${AudienceInternalTags.INTERNAL},${CommonReservedTags.HIDDEN}`,
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });

    const filteredAudiences = useMemo(() => {
        const selectedAudNames = selectedAudiences.map(
            (audience) => audience.audienceLastMaterializationName ?? '',
        );
        return data?.data?.filter(
            (audience: Audience) =>
                !selectedAudNames.includes(
                    audience.audienceLastMaterializationName ?? '',
                ),
        );
    }, [data?.data, selectedAudiences]);

    const getPercentage = (metric: number, total: number) => {
        return ((metric / total) * 100).toFixed(2);
    };

    const {
        mutateAsync: getAudienceOverlapMetric,
        isLoading: isAudienceOverlapMetricLoading,
    } = useGetAudienceOverlap();

    useEffect(() => {
        if (
            initialAudiencePayload?.insights?.overlapFilters &&
            selectedAudiences.length === 0 &&
            data?.data?.length &&
            !initialRender.current
        ) {
            const initialAudiences = data?.data?.filter((audience: Audience) =>
                initialAudiencePayload?.insights?.overlapFilters?.includes(
                    audience.audienceLastMaterializationName ?? '',
                ),
            );
            if (initialAudiences?.length) {
                setSelectedAudiences(initialAudiences);
            }
            initialRender.current = true;
        }
    }, [
        data?.data,
        initialAudiencePayload?.insights?.overlapFilters,
        selectedAudiences.length,
    ]);

    const handleDeleteAudience = (index: number) => {
        const newSelectedAudiences = [...selectedAudiences];
        newSelectedAudiences.splice(index, 1);
        setSelectedAudiences([...newSelectedAudiences]);
        setInsightsOverlapPayload(
            newSelectedAudiences.map(
                (item) => item.audienceLastMaterializationName ?? '',
            ),
        );
    };

    const handleAddAudience = (value: ListSelectOptionsProp) => {
        const audience = data?.data?.find(
            (item: Audience) => item.id === value.uuid,
        );
        if (audience && primaryTable) {
            const newSelectedAudiences = [...selectedAudiences, audience];
            setSelectedAudiences(newSelectedAudiences);
            setInsightsOverlapPayload(
                newSelectedAudiences.map(
                    (item) => item.audienceLastMaterializationName ?? '',
                ),
            );
            setShowSelectAudienceModal(false);
            const formattedNestedMetricQuery = formatNestedMetricQuery(
                audiencePayload.nestedMetricQuery,
            );
            getAudienceOverlapMetric({
                relationName: activeRelation?.name as string,
                filters: {
                    metricQuery:
                        formattedNestedMetricQuery as unknown as NestedMetricQueryGroup,
                },
                materialisedAudNames:
                    newSelectedAudiences.map(
                        (item) => item.audienceLastMaterializationName ?? '',
                    ) ?? [],
            })
                .then((response) => {
                    setOverlapData({ ...response });
                })
                .catch(() => {});
        }
    };

    const refreshCallback = useCallback(() => {
        if (selectedAudiences.length === 0) return;
        setIsRefreshing(true);
        const formattedNestedMetricQuery = formatNestedMetricQuery(
            audiencePayload.nestedMetricQuery,
        );
        getAudienceOverlapMetric({
            relationName: activeRelation?.name as string,
            filters: {
                metricQuery:
                    formattedNestedMetricQuery as unknown as NestedMetricQueryGroup,
            },
            materialisedAudNames:
                selectedAudiences.map(
                    (audience) =>
                        audience.audienceLastMaterializationName ?? '',
                ) ?? [],
        })
            .then((response) => {
                setOverlapData({ ...response });
            })
            .catch(() => {})
            .finally(() => setIsRefreshing(false));
    }, [
        activeRelation?.name,
        audiencePayload.nestedMetricQuery,
        getAudienceOverlapMetric,
        selectedAudiences,
    ]);

    useEffect(() => {
        setRefreshCallback({
            overlap: refreshCallback,
        });
    }, [refreshCallback, setRefreshCallback]);

    return (
        <Box className="p-3">
            <Group align="center" className="mb-4" spacing={4}>
                <Text className="text-xs text-gray-500 font-medium uppercase">
                    {t('audience_builder.insights.overlap')}
                </Text>
                <Tooltip
                    withinPortal
                    label={t('audience_builder.insights.overlap_tooltip')}
                >
                    <Info size={13} className="cursor-pointer" />
                </Tooltip>
            </Group>
            {selectedAudiences.map((audience, index) => {
                const overlapPercentage = getPercentage(
                    overlapData[
                        audience.audienceLastMaterializationName ?? ''
                    ] ?? 0,
                    audienceCount,
                );
                return (
                    <AudienceOverlapItem
                        key={audience.id}
                        audience={audience}
                        overlap={
                            // INFO - Added an explicit check for 'NaN' as isNaN was returning false for 'NaN'
                            isNaN(overlapPercentage) ||
                            overlapPercentage === 'NaN'
                                ? 0
                                : overlapPercentage
                        }
                        overlapCount={
                            overlapData[
                                audience.audienceLastMaterializationName ?? ''
                            ] ?? 0
                        }
                        loading={
                            isRefreshing ||
                            (index === selectedAudiences.length - 1
                                ? isAudienceOverlapMetricLoading
                                : false)
                        }
                        handleDeleteAudience={() => handleDeleteAudience(index)}
                    />
                );
            })}
            {selectedAudiences.length < AUDIENCE_OVERLAP_MAX_COUNT && (
                <Button
                    onClick={() => setShowSelectAudienceModal(true)}
                    variant={ButtonVariant.OUTLINED}
                    leftIcon={<PlusCircle color="rgb(var(--color-gray-700))" />}
                    disabled={disableEdits}
                >
                    {t('audience_builder.insights.add_audience')}
                </Button>
            )}
            <Modal
                opened={showSelectAudienceModal}
                onClose={() => setShowSelectAudienceModal(false)}
                title={t('audience_builder.insights.select_audience')}
                size="lg"
            >
                {isLoading ? (
                    <Loader />
                ) : (
                    <SelectAudience
                        audiences={filteredAudiences as Audience[]}
                        handleFieldSelect={handleAddAudience}
                        isFetching={isFetching}
                        onRefresh={refetchAudiences}
                    />
                )}
            </Modal>
        </Box>
    );
};

export default Overlap;
