import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import { CampaignBuilderStep } from '@components/Campaigns/Builder/types';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import { Box, Stack } from '@mantine/core';
import { useRef } from 'react';
import CampaignDetails from './CampaignDetails';
// import { useParams } from 'react-router-dom';

const CampaignBuilderScheduler: React.FC<{}> = () => {
    // const { campaignUuid } = useParams<{ campaignUuid: string }>();
    // const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid);
    const title = CampaignBuilderSteps.find(
        (step) => step.key === CampaignBuilderStep.SCHEDULE,
    )?.pageHeader;

    const submitFormRef = useRef<() => Promise<boolean>>(() =>
        Promise.resolve(true),
    );

    return (
        <Box>
            <StepTitle title={title || ''} />
            <Stack className="mt-6 max-w-[800px] w-[600px] gap-10">
                <CampaignDetails validateFormRef={submitFormRef} />
            </Stack>
        </Box>
    );
};

export default CampaignBuilderScheduler;
