import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';

import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Page from '@components/Page/Page';
import { useAllEvents } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import { useDisclosure } from '@mantine/hooks';
import { useApp } from '@providers/AppProvider';
import { useHistory, useParams } from 'react-router-dom';
import EventManagerRightSection from '../components/EventsManager/EventManagerRightSection';
import EventsModal from '../components/EventsManager/EventsModal';
import EventsTable from '../components/EventsManager/Table/EventsTable';
import { EventManagerType } from '../components/EventsManager/types';

const EventsBuilderContainer: React.FC<{}> = () => {
    const { t } = useLocale();

    const [opened, { open, close }] = useDisclosure();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const ability = useAbilityContext();
    const canEditEventsMapper = ability.can(
        'update',
        subject('EventsMapper', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const history = useHistory();
    //TODO: below code is commented is to support the backend pagination
    // const [searchString, setSearchString] = useState('');
    // const [currentPage, setCurrentPage] = useState(1);
    const { data: events, isInitialLoading } = useAllEvents();
    // perPage: 10,
    // currentPage,
    // query: `name=${searchString}`,

    if (isInitialLoading) {
        return <SuboptimalState loading />;
    }
    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('events.title')}
            rightSection={
                canEditEventsMapper && (
                    <EventManagerRightSection onCloneClick={open} />
                )
            }
        >
            <EventsTable
                events={events ?? []}
                managerType={EventManagerType.VIEW}
                handleRowClick={(row) => {
                    if (canEditEventsMapper) {
                        history.push(
                            `/projects/${projectUuid}/events/${row.original.uuid}/${row.original.source}/edit`,
                        );
                    } else {
                        history.push(
                            `/projects/${projectUuid}/events/${row.original.uuid}/${row.original.source}/view`,
                        );
                    }
                }}
                // onPageChange={async (newPage) => {
                //     setCurrentPage(newPage);
                // }}
                // onSearchChange={(newSearchString) => {
                //     setSearchString(newSearchString);
                // }}
            />
            <EventsModal
                opened={opened}
                onClose={close}
                events={events ?? []}
                inProvider={false}
            />
        </ManagerBuilderContainer>
    );
};

const EventsManager = () => {
    const { t } = useLocale();
    return (
        <>
            <Page
                title={t('events')}
                withFullHeight
                withPaddedContent
                withNavbar
                withFixedContent
            >
                <EventsBuilderContainer />
            </Page>
        </>
    );
};
export default EventsManager;
