import { useLocale } from '@hooks/useLocale';
import { type HttpConfig } from '@lightdash/common';
import { Button, Flex, Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import React, { useCallback, useRef, useState } from 'react';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import {
    type CallAPIFieldAndSchemaType,
    type FieldsDataForMentions,
} from '../types';
import { convertHeadersToObject, filterAndReduceHeaders } from '../utils';
import HeaderTable from './HeaderTable';

interface HeadersProps extends FieldsDataForMentions {
    httpConfig: HttpConfig;
    handleHttpConfigChange: (
        value: CallAPIFieldAndSchemaType<HttpConfig>,
    ) => void;
    disabled: boolean;
}

export type HeadersType = {
    key: string;
    value: string;
};

const Headers: React.FC<HeadersProps> = ({
    httpConfig,
    handleHttpConfigChange,
    fieldIds,
    journeyProperties,
    warehouseFieldsMap,
    journeyDataSchema,
    disabled,
}) => {
    const { t } = useLocale();
    const [headers, setHeaders] = useState<HeadersType[]>(
        convertHeadersToObject(JSON.parse(httpConfig.headers || '{}')),
    );
    const containerRef = useRef<HTMLDivElement>(null);

    const setHeadersAndConfig = useCallback(
        (newHeaders: HeadersType[]) => {
            setHeaders(newHeaders);
            const filteredHeaders = filterAndReduceHeaders(newHeaders);
            handleHttpConfigChange({
                value: {
                    ...httpConfig,
                    headers: JSON.stringify(filteredHeaders),
                },
                journeyDataSchema,
            });
        },
        [httpConfig, handleHttpConfigChange, journeyDataSchema],
    );

    const updateHeaders = useCallback(
        (index: number, { key, value }: HeadersType) => {
            const newHeaders = [...headers];
            newHeaders[index] = { key, value };
            setHeadersAndConfig(newHeaders);
        },
        [headers, setHeadersAndConfig],
    );

    const deleteHeader = useCallback(
        (index: number) => {
            const newHeaders = [...headers];
            newHeaders.splice(index, 1);
            setHeadersAndConfig(newHeaders);
        },
        [headers, setHeadersAndConfig],
    );

    return (
        <Flex direction="column" gap="md">
            <Text>{t('journey_builder.call_api_headers_label')}</Text>
            {headers.length > 0 && (
                <HeaderTable
                    headers={headers}
                    updateHeaders={updateHeaders}
                    deleteHeader={deleteHeader}
                    containerRef={containerRef}
                    fieldIds={fieldIds}
                    journeyProperties={journeyProperties}
                    warehouseFieldsMap={warehouseFieldsMap}
                    journeyDataSchema={journeyDataSchema}
                    disabled={disabled}
                />
            )}
            {!disabled && (
                <Button
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={<PlusCircle color="rgb(var(--color-blu-800))" />}
                    className="w-[8rem]"
                    onClick={() => {
                        const newHeaders = [...headers, { key: '', value: '' }];
                        setHeadersAndConfig(newHeaders);
                        if (containerRef.current) {
                            containerRef.current.scrollTop =
                                containerRef.current.scrollHeight + 64;
                        }
                    }}
                >
                    {t('journey_builder.call_api_headers_add_btn')}
                </Button>
            )}
        </Flex>
    );
};

export default React.memo(Headers);
