import Checkbox from '@components/common/Checkbox';
import TimeInputWithOptions from '@components/common/Time/TimeInputWithOptions';
import { useLocale } from '@hooks/useLocale';
import { PeriodType } from '@lightdash/common';
import { Box, Group, Stack } from '@mantine/core';
import { ArrowArcLeft } from '@phosphor-icons/react';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ActionTitle from '../ActionTitle';

enum FormFields {
    Reentry = 'reentry',
    Duration = 'duration',
    Granularity = 'granularity',
}
interface ReEntryActionProps {
    duration: number | undefined;
    granularity: PeriodType;
    checked: boolean;
    onDurationChange: (duration: number, granularity: PeriodType) => void;
    onReEntryChange: (checked: boolean) => void;
    isEditable: boolean;
}

const ReEntryAction: React.FC<ReEntryActionProps> = ({
    duration,
    granularity,
    checked,
    onDurationChange,
    onReEntryChange,
    isEditable,
}) => {
    const { t } = useLocale();

    const {
        control,
        watch,
        formState: { errors },
        setError,
        setValue,
    } = useForm({
        defaultValues: {
            reentry: checked,
            duration: duration,
            granularity: granularity,
        },
    });
    useEffect(() => {
        setValue(FormFields.Duration, duration);
        if (duration === null) {
            setError(FormFields.Duration, {
                type: 'manual',
                message: t('common.error.empty_text_input'),
            });
            return;
        }
        if (duration === 0) {
            setError(FormFields.Duration, {
                type: 'manual',
                message: t('duration_delay.enter_a_valid_value'),
            });
            return;
        }
        setError(FormFields.Duration, {
            type: 'manual',
            message: '',
        });
    }, [duration, setError, setValue, t]);

    const reentry = watch(FormFields.Reentry);

    return (
        <Stack className="gap-2">
            <Group className="gap-1">
                <ArrowArcLeft color={'rgb(var(--color-gray-500))'} />
                <ActionTitle title={t('journey_builder.reentry_block_title')} />
            </Group>
            <Box>
                <Controller
                    name="reentry"
                    control={control}
                    render={({ field }) => (
                        <Checkbox
                            label={t(
                                'journey_builder.reentry_block_allow_users_option_label',
                            )}
                            checked={field.value}
                            onChange={() => {
                                field.onChange(!field.value);
                                onReEntryChange(!field.value);
                            }}
                            disabled={!isEditable}
                        />
                    )}
                />
            </Box>

            {reentry && (
                <Stack className={`gap-1`}>
                    <ActionTitle
                        title={t('journey_builder.reeligibility_block_title')}
                    />
                    <Controller
                        name={FormFields.Duration}
                        control={control}
                        render={({ field }) => (
                            <TimeInputWithOptions
                                selectedDuration={field.value}
                                onDurationChange={(
                                    newDuration,
                                    newGranularity,
                                ) => {
                                    field.onChange(newDuration);
                                    onDurationChange(
                                        newDuration,
                                        newGranularity,
                                    );
                                    if (Number.isNaN(newDuration)) {
                                        setError(FormFields.Duration, {
                                            type: 'manual',
                                            message: t(
                                                'common.error.empty_text_input',
                                            ),
                                        });
                                        return;
                                    }
                                    setError(FormFields.Duration, {
                                        type: 'manual',
                                        message: '',
                                    });
                                }}
                                selectedGranularity={granularity}
                                granularityOptions={[
                                    PeriodType.MINUTE,
                                    PeriodType.HOUR,
                                    PeriodType.DAY,
                                    PeriodType.WEEK,
                                ]}
                                error={errors.duration?.message}
                                focusOnMount={true}
                                onBlur={(value) => {
                                    if (Number.isNaN(value)) {
                                        field.onChange(null);
                                        onDurationChange(
                                            null as unknown as number, //Typecasting the value here as null since the common type doesn't support null/undefined and we can use null/undefined to check if the time is missing
                                            granularity,
                                        );
                                    }
                                }}
                                disabled={!isEditable}
                            />
                        )}
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default React.memo(ReEntryAction);
