import Checkbox from '@components/common/Checkbox';
import TextInput from '@components/common/Inputs/TextInput';
import { type Action } from '@components/Templates/TemplateActions';
import { useLocale } from '@hooks/useLocale';
import { type SmsTemplateContentDetails } from '@lightdash/common';
import { Box, Group, Stack, Text } from '@mantine/core';
import { useTemplateBuilderContext } from '@providers/TemplateBuilderProvider';
import React, { useCallback, useState } from 'react';
import SMSPreview from '../SMSPreview';
import SMSContentArea from './SMSContentArea';
import SMSEditorHeader from './SMSEditorHeader';
import SMSTypeSelection from './SMSTypeSelection';

interface SMSEditorProps {
    onTitleChange: (title: string) => void;
    onHeaderAction: (action: Action) => void;
    handleTemplateSave: () => void;
}

const SMSEditor: React.FC<SMSEditorProps> = ({
    onTitleChange,
    onHeaderAction,
    handleTemplateSave,
}) => {
    const { t } = useLocale();
    const { templatePayload } = useTemplateBuilderContext(
        (context) => context.state,
    );

    const { content: smsContent } = templatePayload as {
        content: SmsTemplateContentDetails;
    };

    const { setTemplatePayload } = useTemplateBuilderContext(
        (context) => context.actions,
    );

    const [isValidContent, setIsValidContent] = useState<boolean>(true);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState<boolean>(
        Boolean(smsContent.dltTemplateId),
    );
    const [isDLTInputValid, setIsDLTInputValid] = useState<boolean>(true);

    const handleContentChange = useCallback(
        (content: string, isUnicode: boolean) => {
            setTemplatePayload({
                ...templatePayload,
                content: {
                    ...templatePayload.content,
                    body: content,
                    unicode: isUnicode,
                },
            });

            if (content.trim()) {
                setIsValidContent(true);
            }
        },
        [templatePayload, setTemplatePayload],
    );

    const handleSmsTypeChange = useCallback(
        (isFlash: boolean) => {
            setTemplatePayload({
                ...templatePayload,
                content: {
                    ...templatePayload.content,
                    flash: isFlash,
                },
            });
        },
        [templatePayload, setTemplatePayload],
    );

    const handleSaveTemplate = async () => {
        if (!smsContent.body.trim()) {
            setIsValidContent(false);
            return;
        }
        if (isCheckboxChecked && !smsContent.dltTemplateId) {
            setIsDLTInputValid(false);
            return;
        }
        handleTemplateSave();
    };

    const handleDLTChange = useCallback(
        (dltTemplateId: string) => {
            setTemplatePayload({
                ...templatePayload,
                content: {
                    ...templatePayload.content,
                    dltTemplateId,
                },
            });
            setIsDLTInputValid(!!dltTemplateId);
        },
        [templatePayload, setTemplatePayload],
    );

    const handleCheckboxChange = useCallback((checked: boolean) => {
        setIsCheckboxChecked(checked);
    }, []);

    return (
        <Box className="relative w-full">
            <SMSEditorHeader
                onTitleChange={onTitleChange}
                onAction={onHeaderAction}
                onSave={handleSaveTemplate}
            />

            <Group
                className="py-6 pl-[220px] pr-[50px] items-start gap-12 max-w-[1300px] m-auto"
                noWrap
            >
                <Stack className="w-8/12 gap-6">
                    <SMSTypeSelection
                        isFlash={smsContent.flash || false}
                        onChange={handleSmsTypeChange}
                    />

                    <SMSContentArea
                        content={smsContent.body}
                        onChange={handleContentChange}
                        error={!isValidContent}
                    />

                    <Stack className="gap-0.5">
                        <Checkbox
                            label={t(
                                'sms_template_builder.dlt_template_id_label',
                            )}
                            checked={isCheckboxChecked}
                            onChange={(e) =>
                                handleCheckboxChange(e.target.checked)
                            }
                        />
                        <Stack className="gap-0.5 pl-5">
                            <Text className="text-xs text-gray-600">
                                {t('sms_template_builder.dlt_template_id_desc')}
                            </Text>
                            <TextInput
                                placeholder={t(
                                    'sms_template_builder.dlt_template_id_input_placeholder',
                                )}
                                w={`50%`}
                                value={smsContent.dltTemplateId}
                                onChange={(e) =>
                                    handleDLTChange(e.target.value)
                                }
                                error={
                                    !isDLTInputValid
                                        ? t('sms_template_builder.dlt_error')
                                        : false
                                }
                                disabled={!isCheckboxChecked}
                            />
                        </Stack>
                    </Stack>
                </Stack>

                <Box>
                    <SMSPreview
                        content={smsContent.body}
                        isFlash={smsContent.flash || false}
                        isSetupComplete={undefined}
                        openContentSetupManager={() => {}}
                        showSetupStrip={false}
                        showHeader={true}
                    />
                </Box>
            </Group>
        </Box>
    );
};

export default React.memo(SMSEditor);
