import { sortmentApi } from '@api/index';
import { Ability } from '@casl/ability';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import {
    type ApiError,
    type ApiResponse,
    type LightdashUser,
    type LightdashUserWithAbilityRules,
    type UpdateUserArgs,
    type UserAlertPreferenceCreateRequest,
} from '@lightdash/common';
import { useErrorLogs } from '@providers/ErrorLogsProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

export type UserWithAbility = LightdashUserWithAbilityRules & {
    ability: Ability;
};
const getUserState = async (): Promise<UserWithAbility> => {
    const user = await sortmentApi<LightdashUserWithAbilityRules>({
        url: `/user`,
        method: 'GET',
        body: undefined,
    });

    return {
        ...user,
        ability: new Ability(user.abilityRules),
    };
};

const useUser = (isAuthenticated: boolean) => {
    return useQuery<UserWithAbility, ApiError>({
        queryKey: [QueryKeys.USER],
        queryFn: getUserState,
        enabled: isAuthenticated,
        retry: false,
    });
};

const updateUserQuery = async (data: Partial<UpdateUserArgs>) =>
    sortmentApi<LightdashUser>({
        url: `/user/me`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

export const useUserUpdateMutation = () => {
    const queryClient = useQueryClient();
    const { showToastSuccess } = useNotify();
    const { t } = useLocale();
    const { appendError } = useErrorLogs();
    return useMutation<LightdashUser, ApiError, Partial<UpdateUserArgs>>(
        updateUserQuery,
        {
            mutationKey: [QueryKeys.USER_UPDATE],
            onSuccess: async () => {
                await queryClient.refetchQueries([QueryKeys.USER]);
                await queryClient.refetchQueries([QueryKeys.EMAIL_STATUS]);
                showToastSuccess({
                    title: t('workspace_settings.profile_updates_toast'),
                });
            },
            onError: (error: ApiError) => {
                const [title, ...rest] = error.error.message.split('\n');
                appendError({
                    title,
                    body: rest.join('\n'),
                });
            },
        },
    );
};

const createUserAlertPreferences = async (
    alertPreference: UserAlertPreferenceCreateRequest,
) =>
    sortmentApi<ApiResponse>({
        url: `/user/alert-preferences`,
        method: 'POST',
        body: JSON.stringify(alertPreference),
    });

export const useCreateUserAlertPreferencesMutation = () => {
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();

    return useMutation<ApiResponse, ApiError, UserAlertPreferenceCreateRequest>(
        createUserAlertPreferences,
        {
            onSuccess: async () => {
                showToastSuccess({
                    title: t('alert_create.success_toast'),
                });
                await queryClient.invalidateQueries([
                    QueryKeys.PROJECT_USER_ALERT_PREFERENCES,
                ]);
            },
            onError: () => {
                showToastError({
                    title: t('alert_create.error_toast'),
                });
            },
        },
    );
};

const deleteUserAlertPreferences = async (id: string) =>
    sortmentApi<ApiResponse>({
        url: `/user/alert-preferences?id=${id}`,
        method: 'DELETE',
        body: undefined,
    });

export const useDeleteUserAlertPreferencesMutation = () => {
    const queryClient = useQueryClient();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiResponse, ApiError, string>(
        deleteUserAlertPreferences,
        {
            onSuccess: async () => {
                showToastSuccess({
                    title: t('alert_delete.success_toast'),
                });
                await queryClient.invalidateQueries([
                    QueryKeys.PROJECT_USER_ALERT_PREFERENCES,
                ]);
            },
            onError: () => {
                showToastError({
                    title: t('alert_delete.error_toast'),
                });
            },
        },
    );
};

export default useUser;
