import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { CheckCircle, WarningCircle } from '@phosphor-icons/react';
import React from 'react';

interface ContentSetupStripProps {
    isSetupComplete: boolean;
    openContentSetupManager: () => void;
}

const ContentSetupStrip: React.FC<ContentSetupStripProps> = ({
    isSetupComplete,
    openContentSetupManager,
}: ContentSetupStripProps) => {
    const { t } = useLocale();
    return (
        <Box
            className={`py-2 px-3 flex justify-between ${
                !isSetupComplete ? 'bg-halt-400/60' : ''
            }  rounded-lg rounded-b-none`}
        >
            <Box className="flex items-center gap-2">
                {!isSetupComplete ? (
                    <WarningCircle color="rgb(var(--color-halt-800))" />
                ) : (
                    <CheckCircle color="rgb(var(--color-blu-800))" />
                )}
                <Text
                    className={`${
                        !isSetupComplete ? 'text-halt-800' : 'text-gray-800'
                    } text-sm font-medium`}
                >
                    {t('custom_setup_strip.setup')}{' '}
                    {!isSetupComplete
                        ? t('custom_setup_strip.incomplete')
                        : t('custom_setup_strip.complete')}
                </Text>
            </Box>
            <Text
                className="text-sm px-2 py-1 rounded-lg font-semibold cursor-pointer bg-white text-gray-700 border border-shade-6"
                onClick={openContentSetupManager}
            >
                {!isSetupComplete
                    ? t('custom_setup_strip.fix')
                    : t('custom_setup_strip.edit')}
            </Text>
        </Box>
    );
};

export default ContentSetupStrip;
