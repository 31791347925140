import { sortmentApi } from '@api/index';
import { type ApiError } from '@lightdash/common';
import {
    useMutation,
    useQuery,
    type UseMutationOptions,
} from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';

const getUserHasPassword = async (): Promise<boolean> =>
    sortmentApi<boolean>({
        url: `/user/password`,
        method: 'GET',
        body: undefined,
    });

export const useUserHasPassword = () =>
    useQuery<boolean, ApiError>({
        queryKey: [QueryKeys.USER_HAS_PASSWORD],
        queryFn: getUserHasPassword,
    });

type UserPasswordUpdate = {
    password?: string;
    newPassword: string;
};

const updateUserPasswordQuery = (data: UserPasswordUpdate) =>
    sortmentApi<null>({
        url: `/user/password`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useUserUpdatePasswordMutation = (
    useMutationOptions?: UseMutationOptions<null, ApiError, UserPasswordUpdate>,
) => {
    return useMutation<null, ApiError, UserPasswordUpdate>(
        updateUserPasswordQuery,
        {
            mutationKey: [QueryKeys.USER_PASSWORD_UPDATE],
            ...useMutationOptions,
        },
    );
};
