import { useCallback, useState } from 'react';
import { handleStream } from '../api/index';

interface UseStreamedDataProps {
    url: string;
    method?: 'GET' | 'POST';
    onChunk?: (chunk: any) => void;
}

interface StreamedDataState<T> {
    data: T[];
    error: string | null;
    isLoading: boolean;
    fetchData: (payload: any) => void;
    latestMessage: T[];
}

/**
 * hook to fetch and process streamed data.
 * @param url - The URL to fetch the stream from.
 * @returns An object containing the streamed data, loading state, and error state.
 */
export const useStreamedData = <T>({
    url,
    method = 'GET',
    onChunk,
}: UseStreamedDataProps): StreamedDataState<T> => {
    const [data, setData] = useState<T[]>([]);
    const [latestMessage, setLatestMessage] = useState<T[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchStreamedData = useCallback(
        (payload: any) => {
            void handleStream(
                url,
                (chunk) => {
                    setData((prevData) => [...prevData, ...chunk]);
                    setLatestMessage(chunk);
                    if (onChunk) onChunk(chunk);
                },
                () => {
                    setIsLoading(false);
                },
                (err) => {
                    setError(err.message);
                    setIsLoading(false);
                },
                payload,
                method,
            );
        },
        [url, method, onChunk],
    );

    // useEffect(() => {
    //     fetchStreamedData(undefined);
    // }, [fetchStreamedData]);

    const fetchData = (payload: any) => {
        setIsLoading(true);
        fetchStreamedData(payload);
    };

    return { data, error, isLoading, fetchData, latestMessage };
};
