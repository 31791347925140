import { PeriodType } from '@lightdash/common';
export const PAGE_CONTENT_WIDTH = 1300;
export const PAGE_MIN_CONTENT_WIDTH = 1000;
export const FOOTER_HEIGHT = 70;
export const FOOTER_MARGIN = 32;
export const AUDIENCE_PREVIEW_LIMIT = 50;
export const AUDIENCE_PREVIEW_BY_ID_LIMIT = 50;
export const NAVBAR_HEIGHT = 50;
export const SIDEBAR_DEFAULT_WIDTH = 400;
export const SIDEBAR_MIN_WIDTH = 300;
export const SIDEBAR_MAX_WIDTH = 400;
export const CUSTOMNAME = 'customName';
export const TOASTER_AUTOCLOSE = 4000;
export const LIST_REFETCH_TIME = 10000;
export const PAGE_HEADER_HEIGHT = 50;
export const CAMPAIGN_EDIT_DELTA_GAP = 900000;
export const REACT_ARBORIST_PARENT_ID = '__REACT_ARBORIST_INTERNAL_ROOT__';
export const SEARCH_INPUT_DEBOUNCE_TIME = 500;
export const CSV_AUDIENCE_PREVIEW_LIMIT = 20;
export const METICULOUS_KEY = 'Z2ExlDodrSaCN14s4JvovPk48tzQKzO4hG29MU6p';
export const PROFILE_ACTIVITY_PAGE_SIZE = 10;

export const AI_BANNER_SESSION_KEY = 'aiBanner';

type DurationTypeOptions = {
    label: string;
    value: PeriodType;
    placeholder: string;
};

export const durationTypeOptions: DurationTypeOptions[] = [
    {
        label: 'Minutes',
        value: PeriodType.MINUTE,
        placeholder: 'No. of minutes',
    },
    {
        label: 'Hours',
        value: PeriodType.HOUR,
        placeholder: 'No. of hours',
    },
    {
        label: 'Days',
        value: PeriodType.DAY,
        placeholder: 'No. of days',
    },
    {
        label: 'Weeks',
        value: PeriodType.WEEK,
        placeholder: 'No. of weeks',
    },
];
export enum SearchParams {
    REDIRECT = 'redirect',
}
