import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import RelationsReactFlow from '@components/SchemaBuilder/RelationsReactFlow';
import { useSchemaInitialData } from '@components/SchemaBuilder/useSchemaInitialData';
import SetupPage from '@components/SetupPage';
import { useLocale } from '@hooks/useLocale';
import { useProjectContext } from '@providers/ProjectProvider';
import { SchemaProvider } from '@providers/SchemaProvider';

// import { useHistory } from 'react-router-dom';

const Relations = () => {
    // const history = useHistory();
    const { projectData: activeProject } = useProjectContext();
    const initialSchemaState = useSchemaInitialData(activeProject);
    const { t } = useLocale();

    // if (activeProject?.needsRelation) {
    //     history.push(
    //         `/projects/${activeProject?.projectUuid}/relations/create`,
    //     );
    //     return null;
    // }
    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t('set_up_page.type_description.schema'),
                })}
            />
        );
    }
    return (
        <SchemaProvider initialState={initialSchemaState}>
            <RelationsReactFlow />
        </SchemaProvider>
    );
};

export default Relations;
