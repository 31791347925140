import { useLocale } from '@hooks/useLocale';
import { Box, Card, Group, Stack, Text } from '@mantine/core';
import {
    CaretDoubleLeft,
    CaretLeft,
    Microphone,
    UserCircle,
} from '@phosphor-icons/react';
import React from 'react';

function getCurrentTime(): string {
    const now: Date = new Date();
    let hours: number = now.getHours();
    const minutes: number = now.getMinutes();
    const ampm: string = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    const hoursStr: string = hours < 10 ? '0' + hours : hours.toString();
    const minutesStr: string =
        minutes < 10 ? '0' + minutes : minutes.toString();

    const strTime: string = hoursStr + ':' + minutesStr + ' ' + ampm;
    return strTime;
}

interface SMSPreviewProps {
    content: string;
}

const NormalSMSPreview: React.FC<SMSPreviewProps> = ({ content }) => {
    const { t } = useLocale();
    return (
        <Box className="rounded-[inherit] relative flex flex-col h-full">
            <Group className="gap-1 p-3 bg-shade-2">
                <CaretLeft weight="bold" size={13} />
                <UserCircle size={32} />
                <Text className="text-sm font-semibold text-gray-800">
                    {t('sms_template_preview.normal_sms_sender')}
                </Text>
            </Group>
            <Stack className="my-3 gap-2.5 min-h-[24rem] max-h-[33rem] overflow-scroll px-3 flex-grow mb-[4rem]">
                <Stack className="items-center w-full gap-0 text-xs text-gray-600">
                    <Text>SMS</Text>
                    <Text>Today, {getCurrentTime()}</Text>
                </Stack>

                <Card className="p-3 overflow-visible break-words border border-gray-200 rounded bg-gray-50 rounded-t-xl rounded-br-xl">
                    {content ? (
                        <Text className="whitespace-pre-wrap">{content}</Text>
                    ) : (
                        <Group className="gap-1">
                            <CaretDoubleLeft />
                            <Text className="text-sm text-gray-500">
                                {t(
                                    'sms_template_preview.normal_sms_write_message_placeholder',
                                )}
                            </Text>
                        </Group>
                    )}
                </Card>
            </Stack>

            <Group
                className="p-2.5 my-3 border-base absolute bottom-0 left-0 right-0 mx-3"
                position="apart"
            >
                <Text className="text-sm font-medium text-gray-500 ">
                    {t('sms_template_preview.normal_sms_write_message')}
                </Text>
                <Microphone />
            </Group>
        </Box>
    );
};

export default React.memo(NormalSMSPreview);
