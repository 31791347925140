import UserCount from '@components/common/UserCount';
import { useGetCampaignAnalyticsOverview } from '@hooks/useCampaigns';
import {
    CampaignAnalyticsOverviewStatuses,
    CampaignStatus,
    CampaignType,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box, Group, Skeleton, Text } from '@mantine/core';
import { PaperPlaneTilt } from '@phosphor-icons/react';
import { type Row } from '@tanstack/react-table';
import { useMemo } from 'react';
import AudienceCount from './AudienceCount';

const AudienceAndAnalyticsCell = ({
    row,
    count,
}: {
    row: Row<ReducedCampaign>;
    count: number;
}) => {
    const isAnalyticsOverviewEnabled = useMemo(
        () =>
            row.original.status === CampaignStatus.COMPLETED ||
            (row.original.status === CampaignStatus.SCHEDULED &&
                row.original.type === CampaignType.RECURRING),
        [row.original.status, row.original.type],
    );
    const { data: analyticsOverview, isLoading: isAnalyticsOverviewLoading } =
        useGetCampaignAnalyticsOverview(
            row.original.id ?? '',
            isAnalyticsOverviewEnabled,
        );

    if (isAnalyticsOverviewLoading && isAnalyticsOverviewEnabled) {
        return (
            <Box>
                <Skeleton height={20} width={100} className="mb-2" />
                <Skeleton height={10} width={50} />
            </Box>
        );
    }

    if (analyticsOverview && Object.keys(analyticsOverview).length) {
        const totalRequests =
            analyticsOverview[
                CampaignAnalyticsOverviewStatuses.TOTAL_REQUESTS
            ] ?? 0;
        const delivered =
            analyticsOverview[CampaignAnalyticsOverviewStatuses.DELIVERED] ?? 0;
        const deliveryPercentage = totalRequests
            ? parseFloat(((delivered / totalRequests) * 100).toFixed(1))
            : 0;
        return (
            <Box>
                <Group spacing={4} align="center">
                    <PaperPlaneTilt color="rgb(var(--color-blu-800))" />
                    <Text className="text-gray-800 font-medium">
                        Sent to {count} user{count > 1 ? 's' : ''}
                    </Text>
                </Group>
                <Text className="text-xs text-gray-600">
                    {deliveryPercentage}% delivered
                </Text>
            </Box>
        );
    }
    return (
        <Box>
            {row.original.audienceName || row.original.csvUploadDetails ? (
                <>
                    <AudienceCount data={row.original} />
                    <UserCount
                        count={count}
                        withRightSection={false}
                        leftSection={false}
                    />
                </>
            ) : (
                <Text>-</Text>
            )}
        </Box>
    );
};

export default AudienceAndAnalyticsCell;
