import CampaignBuilderForm from '@components/Campaigns/Builder';
import PreviewCampaign from '@components/Campaigns/Preview';
import { useCampaignInitialData } from '@components/Campaigns/useCampaignInitialData';
import PageSpinner from '@components/PageSpinner';
import { useGetCampaignById } from '@hooks/useCampaigns';
import useSearchParams from '@hooks/useSearchParams';
import { CampaignProvider } from '@providers/CampaignProvider';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const CampaignBuilder: React.FC<{}> = ({}) => {
    const { campaignUuid, mode } = useParams<{
        campaignUuid: string;
        mode?: string;
    }>();

    const location = useLocation();
    const isNewMode = location.pathname.includes('create');
    const isEditMode = isNewMode || mode === 'edit';
    const campaignTemplateId = useSearchParams('templateId');
    const isPreviewMode = mode === 'preview';

    const {
        data: campaignDataById,
        isInitialLoading: isCampaignByIdLoading,
        isError,
    } = useGetCampaignById(campaignUuid ?? campaignTemplateId);

    const initialState = useCampaignInitialData(campaignDataById, isEditMode);

    if (isCampaignByIdLoading) return <PageSpinner />;

    if ((isPreviewMode || isEditMode) && isError) return null;
    return (
        <CampaignProvider
            isNewMode={isNewMode}
            isEditMode={isEditMode}
            initialState={initialState}
            uuid={campaignDataById?.id}
        >
            {isPreviewMode && campaignDataById ? (
                <>
                    <PreviewCampaign campaignData={campaignDataById} />
                </>
            ) : (
                <CampaignBuilderForm
                    isNewMode={isNewMode}
                    isEditMode={isEditMode}
                />
            )}
        </CampaignProvider>
    );
};

export default CampaignBuilder;
