import { type FieldWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import {
    FieldType,
    isCustomDimension,
    isTableCalculation,
    RelationTableType,
    type CompiledDimension,
    type CompiledRelation,
    type CompiledRelationTable,
    type JoinType,
} from '@lightdash/common';
import { isEmpty } from 'lodash';

export const sortRelationFields = (
    fields: FieldWithSuggestions[],
): FieldWithSuggestions[] => {
    return fields.sort((itemA, itemB) => {
        if (
            isTableCalculation(itemA) ||
            isTableCalculation(itemB) ||
            isCustomDimension(itemA) ||
            isCustomDimension(itemB)
        ) {
            return 0;
        }
        // First, sort by tableType
        if (
            itemA.tableType === RelationTableType.PRIMARY &&
            itemB.tableType !== RelationTableType.PRIMARY
        ) {
            return -1;
        }
        if (
            itemA.tableType !== RelationTableType.PRIMARY &&
            itemB.tableType === RelationTableType.PRIMARY
        ) {
            return 1;
        }

        // If both have the same tableType, sort by table
        if (itemA.tableType === itemB.tableType) {
            const tableSortOrderA = itemA.table.toLowerCase();
            const tableSortOrderB = itemB.table.toLowerCase();

            if (tableSortOrderA !== tableSortOrderB) {
                return tableSortOrderA.localeCompare(tableSortOrderB);
            }

            // If both have the same tableType and table, sort by fieldType
            if (
                itemA.fieldType === FieldType.DIMENSION &&
                itemB.fieldType !== FieldType.DIMENSION
            ) {
                return -1;
            }
            if (
                itemA.fieldType !== FieldType.DIMENSION &&
                itemB.fieldType === FieldType.DIMENSION
            ) {
                return 1;
            }

            // If both have the same tableType and table, sort by label
            const labelSortOrderA = itemA.label.toLowerCase();
            const labelSortOrderB = itemB.label.toLowerCase();
            return labelSortOrderA.localeCompare(labelSortOrderB);
        }

        // Sort by alphabetical order of tableType
        const typeSortOrderA = itemA?.tableType?.toLowerCase() ?? '';
        const typeSortOrderB = itemB?.tableType?.toLowerCase() ?? '';
        return typeSortOrderA.localeCompare(typeSortOrderB);
    });
};

/**
 * Gets all tables that match the specified table type.
 *
 * @param {Object} param - The parameters for the function.
 * @param {CompiledRelation['tables']} param.tables - The collection of tables to search through.
 * @param {RelationTableType} param.tableType - The type of table to match against.
 * @returns {T[]} - An array of tables that match the specified table type. If no tables match, an empty array is returned.
 */
interface GetTablesByTableTypeParam {
    tables: CompiledRelation['tables'];
    tableType: RelationTableType;
}

export const getTablesByTableType = <T extends CompiledRelationTable>({
    tables,
    tableType,
}: GetTablesByTableTypeParam): T[] => {
    if (!tables || !tableType) return [];
    return Object.values(tables).filter(
        (table) => table.type === tableType,
    ) as T[];
};

export const filterTablesFromRelation = (
    activeRelation: CompiledRelation,
    tableIds: string[],
): CompiledRelation | undefined => {
    if (!activeRelation || !tableIds || isEmpty(tableIds)) return;
    const filteredTables = Object.fromEntries(
        Object.entries(activeRelation.tables).filter(([key]) =>
            tableIds.includes(key),
        ),
    );
    const filteredJoinedTables = activeRelation.joinedTables.filter(
        (joinedTable) => tableIds.includes(joinedTable.table),
    );

    return {
        ...activeRelation,
        tables: filteredTables,
        joinedTables: filteredJoinedTables,
    };
};

/**
 * Retrieves the relation type for a specified table from the compiled relation.
 *
 * @param {string} tableName - The name of the table for which to retrieve the relation type.
 * @param {CompiledRelation} compiledRelation - The compiled relation object containing joined tables. This is the active relation.
 * @returns {JoinType | undefined} - The relation type of the specified table, or undefined if the table is not found.
 */
export const getTableRelationType = (
    tableName: string,
    compiledRelation: CompiledRelation,
): JoinType | undefined => {
    const table = compiledRelation.joinedTables.find(
        (joinedTable) => joinedTable.table === tableName,
    );
    if (!table) return;
    return table.relationType;
};

/**
 * Retrieves the primary field of a specified table from the compiled relation.
 *
 * @param {string} tableName - The name of the table for which to retrieve the primary field.
 * @param {CompiledRelation} compiledRelation - The compiled relation object containing tables.
 * @returns {string | undefined} - The primary field of the specified table, or undefined if the table or primary key is not found.
 */
export const getTablePrimaryField = (
    tableName: string,
    compiledRelation: CompiledRelation,
): CompiledDimension | undefined => {
    const table = compiledRelation.tables[tableName];
    if (!table) return;
    const primaryKey = table.primaryKey;
    if (!primaryKey) return;
    return table.dimensions[primaryKey];
};

/**
 * Retrieves all tables joined to a specified table using specified join types.
 * If a different join type is encountered, it terminates the depth search for that branch.
 *
 * @param {string} startTable - The name of the table to start the search from.
 * @param {CompiledRelation} compiledRelation - The compiled relation object containing joined tables.
 * @param {JoinType[]} allowedJoinTypes - An array of join types that are allowed for traversal.
 * @returns {string[]} - An array of table names that are joined to the start table using the specified join types.
 */
export const getJoinedTablesByJoinTypes = (
    startTable: string,
    compiledRelation: CompiledRelation,
    allowedJoinTypes: JoinType[],
): string[] => {
    if (!startTable || !compiledRelation || !allowedJoinTypes) return [];

    const visited = new Set<string>();
    const queue: string[] = [startTable];
    const result: string[] = [];

    while (queue.length > 0) {
        const currentTable = queue.shift();
        if (!currentTable || visited.has(currentTable)) continue;

        visited.add(currentTable);

        const joinedTables = compiledRelation.joinedTables.filter(
            (joinedTable) =>
                joinedTable.source.table === currentTable &&
                allowedJoinTypes.includes(joinedTable.relationType),
        );

        for (const joinedTable of joinedTables) {
            if (!visited.has(joinedTable.target.table)) {
                result.push(joinedTable.target.table);
                queue.push(joinedTable.target.table);
            }
        }
    }

    return result;
};
