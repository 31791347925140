import Modal from '@components/common/modal/Modal';

import { type Integration } from '@lightdash/common';
import {
    EmailBuilderType,
    useTemplateBuilderContext,
} from '@providers/TemplateBuilderProvider';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ChannelTemplateBuilder from '../ChannelTemplateBuilder';

interface CreateTemplateModalProps {
    integration: Integration;
    integrationId: string;
    onClose: (() => void) | undefined;
    editMode: boolean;
}

const CreateTemplateModal: React.FC<CreateTemplateModalProps> = ({
    integration,
    integrationId,
    onClose,
    editMode,
}) => {
    const location = useLocation();
    const { openCreateTemplateModal, emailBuilderType } =
        useTemplateBuilderContext((context) => context.state);

    const { setOpenCreateTemplateModal } = useTemplateBuilderContext(
        (context) => context.actions,
    );

    useEffect(() => {
        if (location.pathname.includes('/templates') && editMode) {
            setOpenCreateTemplateModal(true);
        }
    }, [location, setOpenCreateTemplateModal, editMode]);

    return (
        <Modal
            styles={() => ({
                content: {
                    maxHeight: '100% !important',
                },
                body: {
                    padding: 0,
                },
            })}
            opened={openCreateTemplateModal}
            onClose={() => {
                setOpenCreateTemplateModal(false);
                if (onClose) onClose();
            }}
            withCloseButton={emailBuilderType === EmailBuilderType.BEEFREE}
            withOverlay={false}
            fullScreen
            withContentPadding={false}
            closeOnEscape={false}
        >
            <ChannelTemplateBuilder
                integration={integration}
                integrationId={integrationId}
                onClose={onClose}
            />
        </Modal>
    );
};

export default React.memo(CreateTemplateModal);
