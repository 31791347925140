import { sortmentApi } from '@api/index';
import {
    type AgentMessage,
    type ApiError,
    type ApiInsightsCountQueryDto,
    type ApiSqlQueryCountResults,
    type ApiSqlQueryResults,
    type APIUpdateAudience,
    type Audience,
    type AudienceCountPayload,
    type AudienceDescription,
    type AudienceDescriptionRequest,
    type AudienceInsightsBreakdownPayload,
    type AudienceOverlapPayload,
    type AudienceReachabilityPayload,
    type InsertAudience,
    type ScheduleOpts,
} from '@lightdash/common';
import { useRelationContext } from '@providers/RelationProvider';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const postAudience = async (
    data: InsertAudience,
    projectId: string,
    relationId: string,
) =>
    sortmentApi<Audience>({
        url: `/projects/${projectId}/relations/${relationId}/audiences`,
        method: 'POST',
        body: JSON.stringify(data),
    });
const updateAudience = async (
    data: APIUpdateAudience,
    projectId: string,
    relationId: string,
    audienceId: string,
) =>
    sortmentApi<Audience>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

export const useAudienceCreateMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        Audience,
        ApiError,
        { payload: APIUpdateAudience; relationUuid: string }
    >(
        ({ payload, relationUuid }) =>
            postAudience(payload as InsertAudience, projectUuid, relationUuid),
        {
            mutationKey: [QueryKeys.CREATE_AUDIENCE],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
                await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
                showToastSuccess({
                    title: t('use_audience.success_audience_created'),
                    subtitle: data.description,
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('use_audience.error_audience_created'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
export const useAudienceUpdation = (hideToast?: boolean) => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        Audience,
        ApiError,
        { payload: APIUpdateAudience; relationUuid: string; audienceId: string }
    >(
        ({ payload, relationUuid, audienceId }) =>
            updateAudience(payload, projectUuid, relationUuid, audienceId),
        {
            mutationKey: [QueryKeys.UPDATE_AUDIENCE],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
                await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
                if (!hideToast) {
                    showToastSuccess({
                        title: t('use_audience.success_audience_updated'),
                        subtitle: data.description,
                    });
                }
            },
            onError: (error) => {
                if (!hideToast) {
                    showToastError({
                        title: t('use_audience.error_audience_updated'),
                        subtitle: error.error.message,
                    });
                }
            },
        },
    );
};
const activateAudience = async (
    projectId: string,
    relationId: string,
    audienceId: string,
) =>
    sortmentApi<Audience>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/activate`,
        method: 'POST',
        body: undefined,
    });

export const useAudienceActivateMutation = () => {
    const { showToastError, showToastSuccess } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        Audience,
        ApiError,
        { audienceUuid: string; relationUuid: string }
    >(
        ({ audienceUuid, relationUuid }) =>
            activateAudience(projectUuid, relationUuid, audienceUuid),
        {
            mutationKey: [QueryKeys.ACTIVATE_AUDIENCE],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
                await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
                showToastSuccess({
                    title: t('use_audience.success_audience_activated'),
                    subtitle: data.description,
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('use_audience.error_audience_activated'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const scheduleAudience = async (
    projectId: string,
    relationId: string,
    audienceId: string,
    data: ScheduleOpts,
) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/schedule`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAudienceScheduleMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        { audienceId: string; data: ScheduleOpts }
    >(
        ({ audienceId, data }) =>
            scheduleAudience(projectId, relationId, audienceId, data),
        {
            mutationKey: ['audience_scheduler'],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('audience_scheduler.successful'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_scheduler.failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const scheduleUpdateAudience = async (
    projectId: string,
    relationId: string,
    audienceId: string,
    data: ScheduleOpts,
) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/schedule`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useScheduleUpdateMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<
        ApiSqlQueryResults,
        ApiError,
        { audienceId: string; data: ScheduleOpts }
    >(
        ({ audienceId, data }) =>
            scheduleUpdateAudience(projectId, relationId, audienceId, data),
        {
            mutationKey: ['audience_scheduler'],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('audience_scheduler.success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_scheduler.failed'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const deleteSchedule = async (
    projectId: string,
    relationId: string,
    audienceId: string,
) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/${audienceId}/schedule`,
        method: 'DELETE',
        body: undefined,
    });

export const useScheduleDeleteMutation = () => {
    const { t } = useLocale();
    const { projectUuid: projectId } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<ApiSqlQueryResults, ApiError, { audienceId: string }>(
        ({ audienceId }) => deleteSchedule(projectId, relationId, audienceId),
        {
            mutationKey: ['audience_scheduler'],
            onSuccess: async () => {
                showToastSuccess({
                    title: t(
                        'audience_scheduler.schedule_turned_off_successfully',
                    ),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('audience_scheduler.failed_to_turn_off_schedule'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const getAudienceCountByPayload = async (
    projectId: string,
    relationId: string,
    data: AudienceCountPayload,
) =>
    sortmentApi<ApiSqlQueryCountResults>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/count`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAudienceCountByPayload = ({
    disableToasts = false,
}: { disableToasts?: boolean } = {}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<
        ApiSqlQueryCountResults,
        ApiError,
        { payload: AudienceCountPayload; relationUuid: string }
    >(
        ({ payload, relationUuid }) =>
            getAudienceCountByPayload(projectUuid, relationUuid, payload),
        {
            mutationKey: [QueryKeys.AUDIENCE_COUNT_PAYLOAD],
            onError: (error) => {
                if (!disableToasts) {
                    showToastError({
                        title: t('use_audience.error_get_audience_count'),
                        subtitle: error.error.message,
                    });
                }
            },
        },
    );
};

const getAiGeneratedAudience = async (projectId: string, data: AgentMessage) =>
    sortmentApi<AgentMessage>({
        url: `/projects/${projectId}/ai/agents`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useAiGeneratedAudience = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useMutation<
        AgentMessage,
        ApiError,
        { payload: AgentMessage; relationUuid: string }
    >(({ payload }) => getAiGeneratedAudience(projectUuid, payload), {
        mutationKey: [QueryKeys.GENERATE_VISUAL_AI_AUDIENCE],
    });
};

const generateAudienceDescriptionWithAi = async (
    projectId: string,
    data: AudienceDescriptionRequest,
) =>
    sortmentApi<AudienceDescription>({
        url: `/projects/${projectId}/ai/audiences/description`,
        method: 'POST',
        body: JSON.stringify(data),
    });

const getReachability = async (
    projectId: string,
    relationId: string,
    data: AudienceReachabilityPayload,
) =>
    sortmentApi<ApiInsightsCountQueryDto>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/insights/reachability/runQuery`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useGenerateAudienceDescriptionWithAi = ({
    relationUuid,
}: {
    relationUuid: string;
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutate: updateAudienceDescription } = useAudienceUpdation(true);
    return useMutation<
        AudienceDescription,
        ApiError,
        { payload: AudienceDescriptionRequest }
    >(
        ({ payload }) =>
            generateAudienceDescriptionWithAi(projectUuid, payload),
        {
            mutationKey: [QueryKeys.GENERATE_AI_AUDIENCE_DESCRIPTION],
            onSuccess: (response) => {
                updateAudienceDescription({
                    payload: {
                        description: response.audienceDescription,
                    },
                    relationUuid,
                    audienceId: response.audienceId,
                });
            },
        },
    );
};
export const useGetReachability = ({ relationId }: { relationId: string }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return useMutation<
        ApiInsightsCountQueryDto,
        ApiError,
        AudienceReachabilityPayload
    >({
        mutationFn: (payload: AudienceReachabilityPayload) =>
            getReachability(projectUuid, relationId, payload),
        mutationKey: [QueryKeys.AUDIENCE_REACHABILITY],
    });
};

const getAudienceOverlap = async (
    projectId: string,
    relationId: string,
    data: AudienceOverlapPayload,
) =>
    sortmentApi<ApiInsightsCountQueryDto['results']>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/insights/overlap/runQuery`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useGetAudienceOverlap = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();

    return useMutation<
        ApiInsightsCountQueryDto['results'],
        ApiError,
        AudienceOverlapPayload
    >({
        mutationFn: (payload: AudienceOverlapPayload) =>
            getAudienceOverlap(projectUuid, relationId, payload),
        mutationKey: [QueryKeys.AUDIENCE_OVERLAP],
    });
};

const getAudienceBreakdown = async (
    projectId: string,
    relationId: string,
    data: AudienceInsightsBreakdownPayload,
) =>
    sortmentApi<ApiInsightsCountQueryDto['results']>({
        url: `/projects/${projectId}/relations/${relationId}/audiences/insights/breakdown/runQuery`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useGetAudienceBreakdown = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { activeRelationUuid: relationId } = useRelationContext();

    return useMutation<
        ApiInsightsCountQueryDto['results'],
        ApiError,
        AudienceInsightsBreakdownPayload
    >({
        mutationFn: (payload: AudienceInsightsBreakdownPayload) =>
            getAudienceBreakdown(projectUuid, relationId, payload),
        mutationKey: [QueryKeys.AUDIENCE_BREAKDOWN],
    });
};
