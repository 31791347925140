import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type CreateProjectMember,
    type ProjectMemberProfile,
    type ProjectMemberProfileWithInviteInformation,
    type UpdateProjectMember,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import useQueryError from './useQueryError';

const getProjectAccessQuery = async (projectUuid: string) =>
    sortmentApi<ProjectMemberProfile[]>({
        url: `/projects/${projectUuid}/access`,
        method: 'GET',
        body: undefined,
    });

export const useProjectAccess = (projectUuid: string) => {
    const setErrorResponse = useQueryError();
    return useQuery<ProjectMemberProfile[], ApiError>({
        queryKey: [QueryKeys.PROJECT_ACCESS_USER, projectUuid],
        queryFn: () => getProjectAccessQuery(projectUuid),
        onError: (result) => setErrorResponse(result),
    });
};
const getProjectAccessWithLinkInfoQuery = async (projectUuid: string) =>
    sortmentApi<ProjectMemberProfileWithInviteInformation[]>({
        url: `/projects/${projectUuid}/accessWithLinkInfo`,
        method: 'GET',
        body: undefined,
    });
export const useProjectAccessWithLinkInfo = (projectUuid: string) => {
    const setErrorResponse = useQueryError();
    return useQuery<ProjectMemberProfileWithInviteInformation[], ApiError>({
        queryKey: [QueryKeys.PROJECT_ACCESS_USER_WITH_LINK_INFO, projectUuid],
        queryFn: () => getProjectAccessWithLinkInfoQuery(projectUuid),
        onError: (result) => setErrorResponse(result),
    });
};
const removeProjectAccessQuery = async (
    projectUuid: string,
    userUuid: string,
) =>
    sortmentApi<null>({
        url: `/projects/${projectUuid}/access/${userUuid}`,
        method: 'DELETE',
        body: undefined,
    });

export const useRevokeProjectAccessMutation = (projectUuid: string) => {
    const queryClient = useQueryClient();
    const { showToastSuccess, showToastError } = useNotify();
    return useMutation<null, ApiError, string>(
        (data) => removeProjectAccessQuery(projectUuid, data),
        {
            mutationKey: ['project_access_revoke'],
            onSuccess: async () => {
                await queryClient.refetchQueries(['project_access_users']);
                await queryClient.invalidateQueries([
                    QueryKeys.PROJECT_ACCESS_USER_WITH_LINK_INFO,
                ]);
                showToastSuccess({
                    title: `Revoked project access`,
                });
            },
            onError: (error) => {
                showToastError({
                    title: `Failed to revoke project access`,
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const createProjectAccessQuery = async (
    projectUuid: string,
    data: CreateProjectMember,
) =>
    sortmentApi<null>({
        url: `/projects/${projectUuid}/access`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateProjectAccessMutation = (projectUuid: string) => {
    const queryClient = useQueryClient();
    const { showToastError, showToastSuccess } = useNotify();
    return useMutation<null, ApiError, CreateProjectMember>(
        (data) => createProjectAccessQuery(projectUuid, data),
        {
            mutationKey: [QueryKeys.PROJECT_ACCESS_CREATE],
            onSuccess: async () => {
                await queryClient.refetchQueries([
                    QueryKeys.PROJECT_ACCESS_USER,
                ]);
                showToastSuccess({
                    title: 'Created new project access',
                });
            },
            onError: async (error1) => {
                const [title, ...rest] = error1.error.message.split('\n');
                showToastError({
                    title,
                    subtitle: rest.join('\n'),
                });
            },
        },
    );
};

const updateProjectAccessQuery = async (
    projectUuid: string,
    userUuid: string,
    data: UpdateProjectMember,
) =>
    sortmentApi<null>({
        url: `/projects/${projectUuid}/access/${userUuid}`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

export const useUpdateProjectAccessMutation = (projectUuid: string) => {
    const queryClient = useQueryClient();
    const { showToastError, showToastSuccess } = useNotify();
    return useMutation<
        null,
        ApiError,
        UpdateProjectMember & { userUuid: string }
    >(
        ({ userUuid, ...rest }) =>
            updateProjectAccessQuery(projectUuid, userUuid, rest),
        {
            mutationKey: ['project_access_update'],
            onSuccess: async () => {
                await queryClient.refetchQueries(['project_access_users']);
                await queryClient.invalidateQueries([
                    QueryKeys.PROJECT_ACCESS_USER_WITH_LINK_INFO,
                ]);
                showToastSuccess({
                    title: 'Updated project access role',
                });
            },
            onError: async (error1) => {
                const [title, ...rest] = error1.error.message.split('\n');
                showToastError({
                    title,
                    subtitle: rest.join('\n'),
                });
            },
        },
    );
};
