import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import {
    CommunicationChannel,
    TemplateType,
    type ExternalCampaignTriggerRequest,
    type ProviderTemplateDetails,
} from '@lightdash/common';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useCallback } from 'react';
import TemplateSelectionModal from './JourneyTemplateSelectionModal';
import TemplateSetupModal from './JourneyTemplateSetupModal';

interface JourneyContentSetupManagerModalProps {
    opened: boolean;
    closeContentSetupManager: () => void;
    messageConfig: ExternalCampaignTriggerRequest;
    setJourneyContentPayload: (
        messageConfig: ExternalCampaignTriggerRequest,
    ) => void;
    fields: FieldsWithSuggestions;
    additionalPropertySelectList: PropertySelectListType<
        FieldWithSelectStatus,
        PropertySelectGroupType
    >[];
}

const JourneyContentSetupManagerModal: React.FC<
    JourneyContentSetupManagerModalProps
> = ({
    opened,
    closeContentSetupManager,
    messageConfig,
    setJourneyContentPayload,
    fields,
    additionalPropertySelectList,
}) => {
    const { setTemplateDetails, resetContentMappings } = useCampaignContext(
        (context) => context.actions,
    );

    const handleTemplateSelect = useCallback(
        (value: ProviderTemplateDetails) => {
            if (!value) return;
            setJourneyContentPayload({
                ...messageConfig,
                templateDetails: {
                    id:
                        messageConfig?.channel === CommunicationChannel.WHATSAPP
                            ? value?.name
                            : value?.id,
                    type:
                        messageConfig?.channel === CommunicationChannel.WHATSAPP
                            ? TemplateType.EXTERNAL
                            : TemplateType.INTERNAL,
                },
                contentMappings: {},
                contentDetails: undefined,
            });
            setTemplateDetails({
                id:
                    messageConfig?.channel === CommunicationChannel.WHATSAPP
                        ? value?.name
                        : value?.id,
                type:
                    messageConfig?.channel === CommunicationChannel.WHATSAPP
                        ? TemplateType.EXTERNAL
                        : TemplateType.INTERNAL,
            });
            resetContentMappings();
        },
        [
            setJourneyContentPayload,
            messageConfig,
            setTemplateDetails,
            resetContentMappings,
        ],
    );

    return messageConfig?.templateDetails?.id ? (
        <TemplateSetupModal
            opened={opened}
            closeTemplateSetup={closeContentSetupManager}
            setJourneyContentPayload={setJourneyContentPayload}
            fields={fields}
            additionalPropertySelectList={additionalPropertySelectList}
            messageConfig={messageConfig}
            onTemplateSelect={handleTemplateSelect}
        />
    ) : (
        <TemplateSelectionModal
            opened={opened}
            closeTemplateSelection={closeContentSetupManager}
            messageConfig={messageConfig}
            onTemplateSelect={handleTemplateSelect}
        />
    );
};

export default React.memo(JourneyContentSetupManagerModal);
