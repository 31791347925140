import { RelationTableType } from '@lightdash/common';
import {
    CursorClick,
    Graph,
    UserCircle,
    UsersThree,
    type IconProps,
} from '@phosphor-icons/react';
import { type CSSProperties } from 'styled-components';

interface TableIconProps extends IconProps {
    type: RelationTableType;
    style?: CSSProperties;
}

const TableIcon = ({ type, color, ...rest }: TableIconProps) => {
    switch (type) {
        case RelationTableType.PRIMARY: {
            return (
                <UserCircle
                    strokeWidth={2.5}
                    color={color ?? 'rgb(var(--color-blu-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case RelationTableType.RELATED: {
            return (
                <Graph
                    strokeWidth={2.5}
                    color={color ?? 'rgb(var(--color-green))'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case RelationTableType.EVENT: {
            return (
                <CursorClick
                    strokeWidth={2.5}
                    color={color ?? 'rgb(var(--color-mustard-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        case RelationTableType.AUDIENCE: {
            return (
                <UsersThree
                    strokeWidth={2.5}
                    color={color ?? 'rgb(var(--color-pink-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
        }
        default:
            return (
                <UserCircle
                    color={color ?? 'rgb(var(--color-blu-800)'}
                    weight="duotone"
                    {...rest}
                />
            );
    }
    return null;
};

export default TableIcon;
