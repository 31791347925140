import { subject } from '@casl/ability';
import AudienceTable from '@components/Audience/Table/AudienceTable';
import { useAbilityContext } from '@components/common/Authorization';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import LinkButton from '@components/common/LinkButton';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Page from '@components/Page/Page';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';

import SetupPage from '@components/SetupPage';
import { useAudiences } from '@hooks/useGetAudience';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceBuilderHashParams,
    AudienceInternalTags,
    AudienceRunStatus,
    CommonReservedTags,
} from '@lightdash/common';
import { Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../mantineTheme';

const AudienceBuilderContainer: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const { user } = useApp();
    const [currentPage, setCurrentPage] = useState(1);
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [pollingActive, setPollingActive] = useState<boolean>(true);
    const { data: audiences, isInitialLoading } = useAudiences({
        perPage: 10,
        currentPage,
        query: `name=${searchString}&${
            isHiddenActive
                ? `includesTags=${CommonReservedTags.HIDDEN}&excludesTags=${AudienceInternalTags.INTERNAL}`
                : `excludesTags=${CommonReservedTags.HIDDEN},${AudienceInternalTags.INTERNAL}`
        }`,
        polling: pollingActive,
    });
    const { data: hiddenAudiences } = useAudiences({
        query: `includesTags=${CommonReservedTags.HIDDEN}&excludesTags=${AudienceInternalTags.INTERNAL}`,
        perPage: 10,
        currentPage: 1,
        polling: false,
    });
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData: activeProject } = useProjectContext();
    const ability = useAbilityContext();
    const canCreateAudience = ability.can(
        'manage',
        subject('VisualAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const handleSearchInput = (value: string) => {
        setSearchString(value);
        setCurrentPage(1);
    };
    useEffect(() => {
        if ((hiddenAudiences?.data?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [hiddenAudiences]);
    useEffect(() => {
        if (audiences?.data) {
            const allowedPollingStatus = [
                AudienceRunStatus.RUNNING,
                AudienceRunStatus.SCHEDULED,
            ];
            const res = audiences.data.some((audience) =>
                allowedPollingStatus.includes(audience.lastRunStatus),
            );
            setPollingActive(res);
        }
    }, [audiences]);
    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.audiences',
                    ),
                })}
            />
        );
    }

    if (isInitialLoading) {
        return <SuboptimalState />;
    }

    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('audiences')}
            rightSection={
                audiences?.data?.length || isHiddenActive
                    ? canCreateAudience && (
                          <LinkButton
                              href={`/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.VISUAL}`}
                              variant={ButtonVariant.PRIMARY}
                              leftIcon={<PlusCircle color="white" />}
                          >
                              <Text className="text-sm font-semibold">
                                  {t('audience.create.title')}
                              </Text>
                          </LinkButton>
                      )
                    : null
            }
        >
            {!isHiddenActive &&
            audiences?.data &&
            audiences?.data.length === 0 &&
            searchString === '' ? (
                canCreateAudience && (
                    <LinkButton
                        className="m-3.5"
                        href={`/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.VISUAL}`}
                        variant={ButtonVariant.PRIMARY}
                        leftIcon={<PlusCircle color="white" />}
                    >
                        <Text className="text-sm font-semibold">
                            {t('audiences_manager.create_your_first_audience')}
                        </Text>
                    </LinkButton>
                )
            ) : (
                <AudienceTable
                    audiences={audiences?.data}
                    onPageChange={async (newPage) => {
                        setCurrentPage(newPage);
                    }}
                    searchValue={searchString}
                    onSearchChange={handleSearchInput}
                    pagination={
                        audiences?.paginate ?? {
                            total: 0,
                            lastPage: 0,
                            currentPage: 0,
                            perPage: 0,
                            from: 0,
                            to: 0,
                        }
                    }
                    isHiddenActive={isHiddenActive}
                    onHiddenChange={(isHiddenFlag: boolean) => {
                        setIsHiddenActive(isHiddenFlag);
                        setCurrentPage(1);
                    }}
                    hideHiddenButton={
                        (hiddenAudiences?.data?.length ?? 0) === 0
                    }
                />
            )}
        </ManagerBuilderContainer>
    );
};

const Audiences = () => {
    const { t } = useLocale();

    return (
        <Page
            title={t('audiences')}
            withFullHeight
            withNavbar
            withFixedContent
            withPaddedContent
        >
            <AudienceBuilderContainer />
        </Page>
    );
};

export default Audiences;
