import { CronInternalInputs } from '@components/Audience/Scheduler/ReactHookForm/CronInput';
import Modal from '@components/common/modal/Modal';
import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { Warning } from '@phosphor-icons/react';

import { isHourlyCron } from '@components/Audience/Scheduler/ReactHookForm/CronInput/cronInputUtils';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import { useProject, useUpdateSyncConfigMutation } from '@hooks/useProject';
import { useQueryClient } from '@tanstack/react-query';
import {
    useCallback,
    useMemo,
    type Dispatch,
    type SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { cronHelperText } from './UserPropertiesSync';

interface UserPropertiesSyncModalProps {
    onClose: () => void;
    opened: boolean;
    cronExpression: string;
    setCronExpression: Dispatch<SetStateAction<string>>;
}

const UserPropertiesSyncModal: React.FC<UserPropertiesSyncModalProps> = ({
    onClose,
    opened,
    cronExpression,
    setCronExpression,
}) => {
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();

    const queryClient = useQueryClient();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { data: project } = useProject(projectUuid);
    const { mutate: updateSyncConfig, isLoading } =
        useUpdateSyncConfigMutation(projectUuid);
    const isSyncScheduleCreated = useMemo(
        () => Boolean(project?.attributes?.syncConfig?.cron),
        [project],
    );

    const handleSave = useCallback(() => {
        updateSyncConfig(
            {
                cron: cronExpression,
            },
            {
                onSuccess: async () => {
                    onClose();
                    showToastSuccess({
                        title: isSyncScheduleCreated
                            ? t('project_sync_config.update_success_msg')
                            : t('project_sync_config.create_success_msg'),
                    });
                    await queryClient.invalidateQueries({
                        queryKey: [QueryKeys.PROJECT],
                    });
                },
                onError: () => {
                    showToastError({
                        title: isSyncScheduleCreated
                            ? t('project_sync_config.update_error_msg')
                            : t('project_sync_config.create_error_msg'),
                    });
                },
            },
        );
    }, [
        cronExpression,
        isSyncScheduleCreated,
        onClose,
        queryClient,
        showToastError,
        showToastSuccess,
        t,
        updateSyncConfig,
    ]);

    return (
        <Modal
            opened={opened}
            onClose={onClose}
            title={t('user_properties_sync_modal.title')}
            size={'lg'}
            footerRightSection={
                <ButtonGroup
                    primaryButtonLabel={t('common.save')}
                    secondaryButtonLabel={undefined}
                    handlePrimaryButtonClick={() => {
                        handleSave();
                    }}
                    handleSecondaryButtonClick={onClose}
                    isUpdating={isLoading}
                    isPrimaryButtonDisabled={
                        project?.attributes?.syncConfig?.cron ===
                            cronExpression || isLoading
                    }
                />
            }
        >
            {isHourlyCron(cronExpression) && (
                <Text className="text-warn border border-mustard-800/40 rounded-lg p-2 flex gap-1.5 items-center">
                    <Warning color={'rgb(var(--color-mustard-800))'} />
                    {t('user_properties_sync_modal.high_frequency_warning')}
                </Text>
            )}
            <Box className="py-3.5">
                <Text className="text-gray-800 text-sm font-medium mb-1">
                    {t('audience_scheduler.cron_label')}
                </Text>
                <CronInternalInputs
                    disabled={isLoading}
                    value={cronExpression}
                    onChange={(event) => setCronExpression(event)}
                    name="cron"
                    disableHourly
                />
                <Text className="text-sm font-medium text-gray-600 pt-3">
                    {t('user_properties_sync.update_message')}{' '}
                    {cronHelperText(cronExpression).length < 50
                        ? cronHelperText(cronExpression)
                        : '--'}
                </Text>
            </Box>
        </Modal>
    );
};

export default UserPropertiesSyncModal;
