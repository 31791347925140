import Modal from '@components/common/modal/Modal';
import Select from '@components/common/Select';
import SettingsTitle from '@components/common/Settings/SettingsTitle';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import { useLocale } from '@hooks/useLocale';

import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import { useProjectUserAlertPreferences } from '@hooks/useProject';
import { useCreateUserAlertPreferencesMutation } from '@hooks/user/useUser';
import {
    AlertEntities,
    AlertingChannels,
    type UserAlertPreferenceCreateRequest,
} from '@lightdash/common';
import { Button, Flex, Stack } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle } from '@phosphor-icons/react';
import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';
import AlertTable from './AlertTable';

const AlertSettings = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [opened, { open, close }] = useDisclosure(false);

    const {
        data: userAlertPreferences,
        isLoading: isLoadingUserAlertPreferences,
    } = useProjectUserAlertPreferences(projectUuid || '');
    const [selectedEntity, setSelectedEntity] = useState<AlertEntities | null>(
        null,
    );

    const [selectedChannel, setSelectedChannel] =
        useState<AlertingChannels | null>(null);
    const { mutate: createUserAlertPreference, isLoading } =
        useCreateUserAlertPreferencesMutation();

    const entityOptions = useMemo(
        () => [
            {
                value: AlertEntities.CAMPAIGNS,
                label: t('alert.entity_campaigns'),
            },
            {
                value: AlertEntities.AUDIENCES,
                label: t('alert.entity_audiences'),
            },
        ],
        [t],
    );

    const channelOptions = useMemo(
        () => [
            { value: AlertingChannels.EMAIL, label: t('alert.channel.email') },
        ],
        [t],
    );

    const handleCreateAlert = useCallback(async () => {
        if (!selectedEntity || !selectedChannel) return;

        const newAlert: UserAlertPreferenceCreateRequest = {
            projectUuid,
            channel: selectedChannel,
            entity: selectedEntity,
        };

        await createUserAlertPreference(newAlert);

        // Reset form and close modal
        setSelectedEntity(null);
        setSelectedChannel(null);
        close();
    }, [
        selectedEntity,
        selectedChannel,
        projectUuid,
        close,
        createUserAlertPreference,
    ]);
    if (isLoadingUserAlertPreferences || isLoading)
        return <SuboptimalState loading />;

    return (
        <>
            <Stack>
                <Flex justify="space-between" align="center">
                    <SettingsTitle title={t('alert_title')} />
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        leftIcon={<PlusCircle color="white" />}
                        onClick={open}
                    >
                        {t('alert.add_alert')}
                    </Button>
                </Flex>
                <AlertTable alerts={userAlertPreferences || []} />
            </Stack>
            <Modal
                opened={opened}
                onClose={close}
                title={t('alert.add_alert')}
                size="md"
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t('common.create')}
                        secondaryButtonLabel={t('common.cancel')}
                        handlePrimaryButtonClick={handleCreateAlert}
                        handleSecondaryButtonClick={close}
                        isPrimaryButtonDisabled={
                            !selectedEntity || !selectedChannel
                        }
                        isUpdating={isLoading}
                    />
                }
            >
                <Stack>
                    <Select
                        label={t('alert.entity_label')}
                        placeholder={t('alert.select_entity')}
                        data={entityOptions}
                        value={selectedEntity}
                        onChange={(value) =>
                            setSelectedEntity(value as AlertEntities)
                        }
                        clearable
                    />
                    <Select
                        label={t('alert.channel_label')}
                        placeholder={t('alert.select_channel')}
                        data={channelOptions}
                        value={selectedChannel}
                        onChange={(value) =>
                            setSelectedChannel(value as AlertingChannels)
                        }
                        clearable
                    />
                </Stack>
            </Modal>
        </>
    );
};

export default AlertSettings;
