import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSqlQueryResults,
    type CustomMaterialisation,
    type InsertCustomer360Materialisation,
    type SearchCustomer360Payload,
} from '@lightdash/common';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

const updateProfile = async (
    data: InsertCustomer360Materialisation,
    projectUuid: string,
) => {
    const response = await sortmentApi<CustomMaterialisation>({
        url: `/projects/${projectUuid}/custom-materialisations/customer360`,
        method: 'PUT',
        body: JSON.stringify(data),
    });
    return response;
};

export const useUpdateProfile = (projectUuid: string) => {
    const { showToastSuccess, showToastError } = useNotify();
    const queryClient = useQueryClient();
    const { t } = useLocale();
    return useMutation<
        CustomMaterialisation,
        ApiError,
        InsertCustomer360Materialisation
    >((data) => updateProfile(data, projectUuid), {
        mutationKey: [QueryKeys.UPDATE_PROFILE],
        onSuccess: async () => {
            showToastSuccess({
                title: t('common.update_toast_message'),
            });
            await queryClient.invalidateQueries([QueryKeys.GET_PROFILES]);
            await queryClient.invalidateQueries([QueryKeys.GET_PROFILE]);
        },
        onError: async () => {
            showToastError({
                title: t('common.update_toast_error_message'),
            });
        },
    });
};
const createProfile = async (
    data: InsertCustomer360Materialisation,
    projectUuid: string,
) => {
    const response = await sortmentApi<CustomMaterialisation>({
        url: `/projects/${projectUuid}/custom-materialisations/customer360`,
        method: 'POST',
        body: JSON.stringify(data),
    });
    return response;
};
export const useCreateProfile = (projectUuid: string) => {
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<
        CustomMaterialisation,
        ApiError,
        InsertCustomer360Materialisation
    >((data) => createProfile(data, projectUuid), {
        mutationKey: [QueryKeys.CREATE_PROFILE],
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKeys.GET_PROFILES]);
            await queryClient.invalidateQueries([QueryKeys.GET_PROFILE]);
            showToastSuccess({
                title: t('user_profile.creation_success_toast'),
            });
        },
        onError: async () => {
            showToastError({
                title: t('user_profile.creation_error_toast'),
            });
        },
    });
};

const getProfileConfig = async (projectUuid: string) => {
    return sortmentApi<CustomMaterialisation>({
        url: `/projects/${projectUuid}/custom-materialisations/customer360`,
        method: 'GET',
        body: undefined,
    });
};
export const useGetProfileConfig = (projectUuid: string) => {
    return useQuery<CustomMaterialisation, ApiError>({
        queryKey: [QueryKeys.GET_PROFILE, projectUuid],
        queryFn: () => getProfileConfig(projectUuid),
        retry: false,
    });
};

const getProfiles = async ({
    projectUuid,
    data,
}: {
    projectUuid: string;
    data?: SearchCustomer360Payload;
}) => {
    return sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectUuid}/custom-materialisations/customer360/search`,
        method: 'POST',
        body: JSON.stringify(data),
    });
};
export const useGetProfiles = ({
    projectUuid,
    data,
}: {
    projectUuid: string;
    data?: SearchCustomer360Payload;
}) => {
    return useQuery<ApiSqlQueryResults, ApiError>({
        queryKey: [QueryKeys.GET_PROFILES, projectUuid, data],
        queryFn: () => getProfiles({ projectUuid, data }),
        retry: false,
        keepPreviousData: true,
    });
};

const getUserHistory = async (
    projectUuid: string,
    data: SearchCustomer360Payload,
) => {
    return sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectUuid}/custom-materialisations/event-history`,
        method: 'POST',
        body: JSON.stringify(data),
    });
};
export const useGetUserHistory = (
    projectUuid: string,
    data: SearchCustomer360Payload,
) => {
    return useQuery<ApiSqlQueryResults, ApiError>({
        queryKey: [QueryKeys.GET_USER_HISTORY, projectUuid, data],
        queryFn: () => getUserHistory(projectUuid, data),
        retry: false,
        keepPreviousData: true,
    });
};
