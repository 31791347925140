import Document from '@tiptap/extension-document';
import Mention, { type MentionNodeAttrs } from '@tiptap/extension-mention';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import {
    EditorContent,
    useEditor,
    type Editor,
    type Range,
} from '@tiptap/react';
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useState,
} from 'react';
import MentionChip from './MentionChip';
import { suggestion } from './suggestion';

import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import { useLocale } from '@hooks/useLocale';
import { Box } from '@mantine/core';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';

import ReactDOM from 'react-dom';
import { type FieldsDataForMentions } from '../types';

interface JsonEditorWithMentionsProps extends FieldsDataForMentions {
    value: string;
    onChange: (value: string) => void;
    items: (
        | PropertySelectListType<JourneyProperty>
        | PropertySelectListType<FieldWithSelectStatus, PropertySelectGroupType>
    )[];
    setError: undefined | ((error: string) => void);
    isOneLiner: boolean;
    placeholder?: string;
    isEditMode: boolean;
    handleInnerFocus: undefined | ((isFocused: boolean) => void);
}
interface ReactToDomOutputSpecProps {
    ReactComponent: React.FC<{
        id: string;
        warehouseFieldsMap: FieldsWithSuggestions;
        journeyProperties: PropertySelectListType<JourneyProperty>[];
    }>;
    id: string;
    warehouseFieldsMap: FieldsWithSuggestions;
    journeyProperties: PropertySelectListType<JourneyProperty>[];
    isOneLiner: boolean;
}

const OneLiner = Document.extend({
    content: 'block', // Ensure there's always at least a paragraph
    addNodeView() {
        return () => {
            const dom = document.createElement('div');
            dom.style.minHeight = '1em'; // Ensure there's space for the cursor
            return {
                dom,
            };
        };
    },
});
const preprocessContent = (text: string) => {
    const mentionRegex = /\{\{([^}]+)\}\}/g;
    let processedText = text.replace(
        mentionRegex,
        (match, name) =>
            `<span data-type="mention" data-id="{{${name.trim()}}}"></span>`,
    );

    return processedText;
};
const customTextStyle = TextStyle.extend({
    addAttributes() {
        return {
            ...this.parent?.(),
            style: {
                default: null,
                parseHTML: (element: Element) => element.getAttribute('style'),
                renderHTML: (attributes: Record<string, string>) => {
                    if (!attributes.style) return {};
                    return {
                        style: attributes.style,
                    };
                },
            },
        };
    },
});

function reactToDomOutputSpec({
    ReactComponent,
    id,
    warehouseFieldsMap,
    journeyProperties,
    isOneLiner,
}: ReactToDomOutputSpecProps) {
    // Create a temporary container for rendering
    const container = document.createElement('span');
    if (isOneLiner) {
        // Add necessary styles to ensure the mention is inline and prevents wrapping
        container.style.display = 'inline';
        container.style.whiteSpace = 'nowrap';
        container.style.overflow = 'hidden';
    }

    // Render the React component into the container
    ReactDOM.render(
        <ReactComponent
            id={id}
            warehouseFieldsMap={warehouseFieldsMap}
            journeyProperties={journeyProperties}
        />,
        container,
    );

    // Ensure the first child of the container is a valid DOM node
    // const domNode = container.firstChild || document.createTextNode('');

    // Return the DOMOutputSpec
    return { dom: container };
}

const formatJsonToHtml = (
    jsonObject: Record<string, any>,
    indentLevel: number,
): string => {
    let htmlContent =
        '<div style="padding-left: ' + indentLevel * 20 + 'px;">{</div>';
    const keys = Object.keys(jsonObject);
    keys.forEach((key, index) => {
        const value = jsonObject[key];
        const isLast = index === keys.length - 1; // Check if it is the last key in the object
        let valueContent = '';

        if (typeof value === 'object' && value !== null) {
            if (Array.isArray(value)) {
                // Check if the array contains simple values or complex objects
                const isSimpleArray = value.every(
                    (item) => typeof item !== 'object' || item === null,
                );
                if (isSimpleArray) {
                    // Format simple arrays inline
                    const simpleArrayContent = value
                        .map((item) => JSON.stringify(item))
                        .join(', ');
                    valueContent = '[ ' + simpleArrayContent + ' ]';
                } else {
                    // Handle complex arrays by formatting each element on a new line
                    valueContent =
                        '<div style="padding-left: ' +
                        (indentLevel + 1) * 20 +
                        'px;">[</div>';
                    value.forEach((item, itemIndex) => {
                        const itemContent = formatJsonToHtml(
                            item,
                            indentLevel + 2,
                        ); // Recursively format each item in the array
                        valueContent += itemContent;
                        if (itemIndex !== value.length - 1) {
                            valueContent +=
                                '<div style="padding-left: ' +
                                (indentLevel + 2) * 20 +
                                'px;">,</div>';
                        }
                    });
                    valueContent +=
                        '<div style="padding-left: ' +
                        (indentLevel + 1) * 20 +
                        'px;">]</div>';
                }
            } else {
                // Recursively call formatJsonToHtml for nested objects
                valueContent = formatJsonToHtml(value, indentLevel + 1);
            }
        } else {
            valueContent = `<span style="color: rgba(0, 128, 0, 1);
    ">${JSON.stringify(value)}</span>`;
        }

        // Append the key-value pair with or without a comma
        htmlContent +=
            '<div style="padding-left: ' +
            (indentLevel + 1) * 20 +
            'px;"><strong>"' +
            key +
            '":</strong> ' +
            valueContent +
            (isLast ? '' : ',') +
            '</div>';
    });
    htmlContent +=
        '<div style="padding-left: ' + indentLevel * 20 + 'px;">}</div>';
    return htmlContent;
};

const customMentionExtension = Mention.extend({
    addOptions() {
        const parent = this.parent?.();
        if (!parent)
            return {
                HTMLAttributes: {},
                renderText: () => '',
                renderHTML: () => ['span', { class: 'mention' }],
                deleteTriggerWithBackspace: true,
                suggestion: {},
            };
        return {
            ...parent,
            renderText({ node }) {
                return `${node.attrs.label ?? node.attrs.id}`;
            },
            suggestion: {
                ...parent.suggestion,
                command: ({
                    editor,
                    range,
                    props,
                }: {
                    editor: Editor;
                    range: Range;
                    props: MentionNodeAttrs;
                }) => {
                    if (props.id) {
                        editor
                            .chain()
                            .focus()
                            .insertContentAt(
                                range,
                                [{ type: 'mention', attrs: props }],
                                {
                                    updateSelection: false,
                                },
                            )
                            .run();
                    }
                },
            },
        };
    },
});

// Define a custom type for the ref
export interface JsonEditorWithMentionsRef {
    formatJson: () => void;
    focus: () => void;
    blur: () => void;
}

const JsonEditorWithMentions = forwardRef<
    JsonEditorWithMentionsRef,
    JsonEditorWithMentionsProps
>(
    (
        {
            value,
            onChange,
            items,
            fieldIds,
            warehouseFieldsMap,
            journeyProperties,
            isOneLiner,
            placeholder,
            isEditMode,
            handleInnerFocus,
        },
        ref,
    ) => {
        const [isSuggestionFocused, setIsSuggestionFocused] = useState(false);
        const { t } = useLocale();
        const editor = useEditor({
            extensions: [
                isOneLiner ? OneLiner : Document,
                Paragraph,
                Text,
                Color,
                customTextStyle,
                customMentionExtension.configure({
                    HTMLAttributes: {
                        class: 'inline',
                    },
                    suggestion: suggestion(
                        items,
                        fieldIds,
                        setIsSuggestionFocused,
                    ),
                    renderHTML: ({ node }) => {
                        return reactToDomOutputSpec({
                            ReactComponent: MentionChip,
                            id: node.attrs.id,
                            warehouseFieldsMap,
                            journeyProperties,
                            isOneLiner,
                        });
                    },
                }),
            ],
            editable: isEditMode,
            content: preprocessContent(value),
            editorProps: {
                attributes: {
                    class: `focus:outline-none ${
                        isOneLiner ? 'overflow-x-auto whitespace-nowrap' : ''
                    }`,
                    style: `
                        min-width: 100px; 
                        min-height: 1.5rem; 
                        padding-left: 0.25rem; 
                        ${
                            isOneLiner
                                ? 'white-space: nowrap; overflow-x: auto; min-height: 1.5rem; max-width: 100%;'
                                : "white-space: normal; overflow: auto; font-family: 'Andale Mono', monospace;"
                        }
                    `,
                },
            },
        });

        useEffect(() => {
            return () => {
                if (editor) {
                    editor.destroy();
                }
            };
        }, [editor]);
        useEffect(() => {
            if (handleInnerFocus && editor) {
                handleInnerFocus(editor.isFocused || isSuggestionFocused);
            }
        }, [editor, isSuggestionFocused, handleInnerFocus]);

        const handleDataChange = useCallback(() => {
            if (!editor) return;
            let textString = editor.getText();
            if (textString !== value) {
                onChange(textString);
            }
            if (!handleInnerFocus) {
                setIsSuggestionFocused(false);
            }
        }, [editor, onChange, value, handleInnerFocus]);

        useImperativeHandle(ref, () => ({
            formatJson: () => {
                if (!editor) return;
                try {
                    const currentText = editor.getText();
                    const jsonObject = JSON.parse(
                        currentText.replaceAll('\n', '').replaceAll(' ', ''),
                    );
                    let formattedHtml = formatJsonToHtml(jsonObject, 0);
                    editor.commands.setContent(
                        preprocessContent(formattedHtml),
                    );
                } catch (e) {
                    // Handle JSON parse error silently
                }
            },
            focus: () => {
                if (editor) {
                    editor.commands.focus();
                }
            },
            blur: () => {
                if (editor) {
                    editor.commands.blur();
                }
                handleDataChange();
            },
        }));

        if (!editor) {
            return null;
        }

        return (
            <Box className="relative w-full h-full">
                <Box
                    className={`absolute inset-0 pointer-events-none ${
                        editor.isEmpty ? 'flex' : 'hidden'
                    } items-start justify-start px-1 py-0.5 text-gray-400`}
                >
                    {placeholder || t('common.enter_json_here')}
                </Box>
                <Box>
                    <EditorContent editor={editor} onBlur={handleDataChange} />
                </Box>
            </Box>
        );
    },
);

export default JsonEditorWithMentions;
