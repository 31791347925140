import GenericOption from '@components/common/Card/GenericOption';
import SearchInput from '@components/SearchInput';
import { useGetAllEventSources } from '@hooks/useEvents';
import { useLocale } from '@hooks/useLocale';
import { type SourceEventKeyMapping } from '@lightdash/common';
import { Box, Text } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import { NAVBAR_HEIGHT } from '@utils/constants';
import Fuse from 'fuse.js';
import { useMemo, useState } from 'react';

interface EventSourceProps {
    onSourceSelect: (item: SourceEventKeyMapping) => void;
}

const EventSources: React.FC<EventSourceProps> = ({ onSourceSelect }) => {
    const { t } = useLocale();
    const { data: eventSources } = useGetAllEventSources();
    const [searchDebounce, setSearchDebounce] = useState<string>('');

    const filterItems = useMemo(() => {
        let fieldsData = eventSources;
        if (searchDebounce !== '' && fieldsData) {
            const fuse = new Fuse(fieldsData, {
                keys: ['source'],
                threshold: 0.3,
            });

            fieldsData = fuse.search(searchDebounce).map((res) => res.item);
        }

        return fieldsData;
    }, [eventSources, searchDebounce]);
    return (
        <Box>
            <SearchInput
                value={searchDebounce}
                onChange={(e) => setSearchDebounce(e.target.value)}
                className="w-52"
                placeholder={t('event_source.search_event_sources')}
            />
            <Box
                className={`!h-[calc(100vh-${
                    NAVBAR_HEIGHT / 16 + 13
                }rem)] mt-3.5`}
            >
                {filterItems &&
                    filterItems.map((item, index) => (
                        <GenericOption
                            customClass={'!w-full !mb-3.5'}
                            key={index}
                            title={
                                <Text className="text-sm font-medium text-gray-800">
                                    {item.source}
                                </Text>
                            }
                            onClick={() => {
                                onSourceSelect(item);
                            }}
                            //info - this code is for souce type
                            // subtitle={
                            //     <Flex className="space-x-1" align="center">
                            //         {getIconBySource(item.type)}
                            //         <Group className="gap-1.5 text-xs text-gray-600">
                            //             <Text>{item.type}</Text>
                            //         </Group>
                            //     </Flex>
                            // }
                            rightSection={<CaretRight weight="regular" />}
                        />
                    ))}
            </Box>
        </Box>
    );
};

export default EventSources;
