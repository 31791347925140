import { FieldConfig, MapperType, type MapperSchema } from '@lightdash/common';
import { DimensionType } from '@lightdash/common/src/types/field';
import {
    BracketsSquare,
    CalendarBlank,
    CircleHalf,
    Hash,
    TextAa,
} from '@phosphor-icons/react';
import { t as translate } from 'i18next';
import _ from 'lodash';
import EventBuilderKeys from './Steps/EventBuilderKeys';
import EventBuilderPayload from './Steps/EventBuilderPayload';
import EventBuilderProperties from './Steps/EventBuilderProperties';
import EventBuilderReview from './Steps/EventBuilderReview';
import {
    EventBuilderStep,
    type EvenTableRequiredColumnsProps,
    type EventBuilderStepMap,
    type EventLabelDataTypeOptionsProps,
    type PropertiesType,
} from './types';
export const EventBuilderSteps: EventBuilderStepMap[] = [
    {
        key: EventBuilderStep.PAYLOAD,
        label: translate('event_create.event_builder_step_payload_label'),
        component: EventBuilderPayload,
        pageHeader: translate('event_create.event_builder_step_payload_header'),
    },

    {
        key: EventBuilderStep.KEYS,
        label: translate('event_create.event_builder_step_keys_label'),
        component: EventBuilderKeys,
        pageHeader: translate('event_create.event_builder_step_keys_label'),
    },
    {
        key: EventBuilderStep.PROPERTIES,
        label: translate('event_create.event_builder_step_properties_label'),
        component: EventBuilderProperties,
        pageHeader: translate(
            'event_create.event_builder_step_properties_label',
        ),
    },
    {
        key: EventBuilderStep.REVIEW,
        label: translate('event_create.event_builder_step_review_label'),
        component: EventBuilderReview,
        pageHeader: translate('event_create.event_builder_step_review_header'),
    },
];
export const EvenTableRequiredColumns: EvenTableRequiredColumnsProps[] = [
    {
        value: 'srt_user_id',
        label: translate('event_create.reserved_keys_user_id_label'),
        description: translate(
            'event_create.reserved_keys_user_id_description',
        ),
        placeholder: translate(
            'event_create.reserved_keys_user_id_placeholder',
        ),
        type: DimensionType.STRING,
    },
    {
        value: 'srt_client_event_id',
        label: translate('event_create.reserved_keys_distinct_id_label'),
        description: translate(
            'event_create.reserved_keys_distinct_id_description',
        ),
        placeholder: translate(
            'event_create.reserved_keys_distinct_id_placeholder',
        ),
        type: DimensionType.STRING,
    },
    {
        value: 'srt_client_timestamp',
        label: translate('event_create.reserved_keys_timestamp_label'),
        description: translate(
            'event_create.reserved_keys_timestamp_description',
        ),
        placeholder: translate(
            'event_create.reserved_keys_timestamp_placeholder',
        ),
        type: DimensionType.TIMESTAMP,
    },
];

export const EventLabelDataTypeOptions: Record<
    string,
    EventLabelDataTypeOptionsProps
> = {
    [DimensionType.STRING]: {
        type: DimensionType.STRING,
        icon: <TextAa weight="duotone" />,
        label: translate('event_create.event_datatype_string_label'),
    },
    [DimensionType.NUMBER]: {
        type: DimensionType.NUMBER,
        icon: <Hash />,
        label: translate('event_create.event_datatype_number_label'),
    },
    [DimensionType.TIMESTAMP]: {
        type: DimensionType.TIMESTAMP,
        icon: <CalendarBlank weight="duotone" />,
        label: translate('event_create.event_datatype_datetime_label'),
    },
    // [DimensionType.DATE]: {
    //     type: DimensionType.DATE,
    //     icon: <CalendarBlank weight="duotone" />,
    //     label: 'Date-time',
    // },
    [DimensionType.BOOLEAN]: {
        type: DimensionType.BOOLEAN,
        icon: <CircleHalf weight="duotone" />,
        label: translate('event_create.event_datatype_boolean_label'),
    },
    array: {
        type: 'array',
        icon: <BracketsSquare weight="duotone" />,
        label: translate('event_create.event_datatype_array_label'),
    },
};

/**
 * Determines the type of the given value and returns a corresponding string representation.
 * @param value - The value to determine the type for.
 * @returns A string representing the type of the value.
 */
const determineType = (value: any): string => {
    const timeRegex = /^([01]?\d|2[0-3]):([0-5]?\d)(:([0-5]?\d))?$/;
    const isoTimestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

    if (Array.isArray(value)) {
        return 'array';
    }

    if (
        typeof value === 'string' &&
        (timeRegex.test(value) || isoTimestampRegex.test(value))
    ) {
        return DimensionType.TIMESTAMP;
    }

    if (typeof value === 'string') {
        return DimensionType.STRING;
    }

    if (typeof value === 'number') {
        return DimensionType.NUMBER;
    }

    if (typeof value === 'boolean') {
        return DimensionType.BOOLEAN;
    }

    return typeof value;
};
/**
 * Processes a nested object recursively to collect key-value pairs and their types.
 * Nested arrays containing objects are also processed recursively.
 *
 * @param currentObj - The current object being processed.
 * @param currentPrefix - The current prefix used for constructing nested keys.
 * @param result - An array to collect objects with keys and their corresponding types.
 */
const processObject = (
    currentObj: any,
    currentPrefix: string,
    result: PropertiesType[],
) => {
    Object.keys(currentObj).forEach((key) => {
        const newKey = currentPrefix ? `${currentPrefix}.${key}` : key;
        const type = determineType(currentObj[key]);

        if (type !== 'object') {
            if (!Array.isArray(currentObj[key])) {
                result.push({
                    key: newKey,
                    type,
                    label: '',
                    required: true,
                    reservedKey: false,
                    columnName: '',
                    nestedLevel: 0,
                    selectedProperty: false,
                });
            }
        }

        if (typeof currentObj[key] === 'object' && currentObj[key] !== null) {
            if (Array.isArray(currentObj[key])) {
                const arrayItem = currentObj[key][0];
                if (arrayItem && typeof arrayItem === 'object') {
                    processObject(arrayItem, newKey, result);
                }
                //TODO stoped array of simple data types traverse.
                // } else {
                //     result.push({
                //         key: `${newKey}[0]`,
                //         type: determineType(arrayItem),
                //     });
                // }
            } else {
                processObject(currentObj[key], newKey, result);
            }
        } else if (currentObj[key] === null) {
            result.push({
                key: newKey,
                type: DimensionType.STRING,
                label: '',
                required: true,
                reservedKey: false,
                columnName: '',
                nestedLevel: 0,
                selectedProperty: false,
            });
        }
    });
};

/**
 * Recursively converts object keys to an array of objects suitable for the Select component.
 * @param obj - The object whose keys need to be converted.
 * @param prefix - The prefix to append to keys (used for nested keys).
 * @returns An array of objects with `key` and `type` properties.
 */
export const convertKeysToSelectData = (
    obj: Record<string, unknown>,
    prefix: string = '',
) => {
    let result: PropertiesType[] = [];

    processObject(obj, prefix, result);
    return result;
};
/**
 * Capitalizes the first letter of every word in a string, except for specified words.
 * @param data - The input string.
 * @returns The input string with the first letter of every word capitalized, except for specified words.
 */
export const capitalizeWords = (data: string): string => {
    return data
        .split('_')
        .map((word) => {
            return _.capitalize(word);
        })
        .join(' ');
};

interface createKeysPropertiesProps {
    keys: { key: string; type: string; label: string }[];
    disableCancel: boolean;
}

/**
 * *It converts the input keys into array of objects which contains key,label,required,nestedLevel,reservedKey
 * @param keys the keys to convert into array of objects
 * @returns An array of objects which contains key,label,required,nestedLevel,reservedKey
 */
export const createKeysProperties = ({
    keys,
    disableCancel,
}: createKeysPropertiesProps): PropertiesType[] => {
    const keysProperties: PropertiesType[] = [];
    keys.forEach((key) => {
        const nested = key.key.split('.');
        keysProperties.push({
            key: key.key,
            label: capitalizeWords(key.label ?? ''),
            required: true,
            nestedLevel: nested.length - 1,
            reservedKey: disableCancel || false,
            type: key.type,
            columnName: key.label,
            selectedProperty: true,
        });
    });
    return keysProperties;
};

/**
 * *It checks weather we need to show to create new label for property
 * @param it takes query,array of suggested labels
 * @returns it gives boolean true or false weather can we able to create the new label or not
 */
export const canCreateNewLabel = (
    query: string,
    suggestedLabels: string[],
): boolean => {
    const trimmedQuery = query?.trim()?.toLowerCase();
    if (query === '') return false;
    return !suggestedLabels.some(
        (label) => label?.trim()?.toLowerCase() === trimmedQuery,
    );
};

export interface convertToMapperSchemaProps {
    label: string;
    type: DimensionType;
    required: boolean;
    path: string;
}
/**
 * convert the give keys and type to mapperschema
 * @param keys - Keys contains label,type and required
 * @returns it returns object where key is key and value is type of mapperschema
 */
export const convertToMapperSchema = (
    keys: convertToMapperSchemaProps[],
): Record<string, MapperSchema> => {
    const mapperSchema: Record<string, MapperSchema> = {};
    keys.forEach((key) => {
        mapperSchema[
            key.label.trim().toLocaleLowerCase().replaceAll(' ', '_')
        ] = {
            required: key.required,
            mapperType: MapperType.VALUE,
            mapperConfig: { path: `$.${key.path}`, type: MapperType.VALUE },
            fieldConfig:
                key.type === DimensionType.TIMESTAMP
                    ? {
                          hasTimezone: true,
                          offset: null,
                          type: FieldConfig.TIMESTAMPTZ,
                      }
                    : null,
            dataType: key.type,
            isUrlEncoded: false,
        };
    });
    return mapperSchema;
};

/***
 * convert input mapper schema to type of properties type
 * @param mapperSchema[] it takes array of mapper scemas
 * @return properties[] is returned
 */
export const convertMapperSchemaToProperties = (
    mapperSchema: Record<string, MapperSchema>,
): PropertiesType[] => {
    const reservedKeys = new Set(
        EvenTableRequiredColumns.map((key) => key.value),
    );
    const properties: PropertiesType[] = Object.entries(mapperSchema)
        .map(([key, schema]) => {
            const path = schema.mapperConfig.path;
            const formattedKey =
                typeof path === 'string'
                    ? path.slice(2)
                    : path.join('').slice(2);

            const isReserved = reservedKeys.has(key);
            const label = isReserved
                ? EvenTableRequiredColumns.find((obj) => obj.value === key)
                      ?.label || key
                : capitalizeWords(key);

            //Enabling the event properties modification in edit flow
            // const isKeyReserved = isDuplicate
            //     ? isReserved
            //     : jsonPayloadKeys.some((keyObj) => keyObj.key === formattedKey);

            return {
                key: formattedKey,
                label: label, // Ensured to be a string
                type: schema.dataType,
                required: schema.required,
                nestedLevel: 0,
                reservedKey: isReserved,
                columnName: key,
                selectedProperty: true,
            };
        })
        .sort((a, b) => {
            // Sort reserved keys to the top
            return (b.reservedKey ? 1 : 0) - (a.reservedKey ? 1 : 0);
        });
    return properties;
};

/**
 * It checks weather distinct id and timestamp properties are filled or not.
 * @param jsonPayloadKeys   it contains all the keys from jsonPayload
 * @returns  returns boolean which tell weather we can go further or not.
 */

export const isValidStepForEventKeyBuilder = (
    jsonPayloadKeys: PropertiesType[],
) => {
    return (
        jsonPayloadKeys.filter((keyObj) => keyObj.reservedKey).length >= 2 &&
        jsonPayloadKeys.filter(
            (key) =>
                key.reservedKey &&
                key.columnName !== EvenTableRequiredColumns[0].value,
        ).length >= 2
    );
};

/* get the nested value from the object
 * @param obj - The object to get the nested value from.
 * @param path - The path to the nested value.
 * @returns The nested value.
 */
export const getNestedValue = (
    obj: Record<string, unknown>,
    path: string,
): string => {
    return path.split('.').reduce((acc, part) => {
        if (acc && typeof acc === 'object' && part in acc) {
            return (acc as Record<string, unknown>)[part] as unknown;
        }
        return undefined;
    }, obj as unknown) as string;
};

/**
 * concat the properties in events
 * @param properties - properties to concat
 * @param newProperties - new properties to concat
 * @returns properties
 */
export const concatPropertiesInEvents = (
    properties: PropertiesType[],
    newProperties: PropertiesType[],
) => {
    return newProperties.reduce((acc, property) => {
        const existingProperties = properties.filter(
            (p) => p.key === property.key,
        );
        if (existingProperties.length > 0) {
            return [...acc, ...existingProperties];
        }
        return [...acc, property];
    }, [] as PropertiesType[]);
};

/**
 * isValidJson fun to detect valid json
 * @param json - json string
 * @returns boolean
 */
export const isValidJson = (json: string) => {
    try {
        JSON.parse(json);
        return true;
    } catch (error) {
        return false;
    }
};
