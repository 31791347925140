import FilterSelectWrapper from '@components/common/Filters/FilterInputs/FilterSelectWrapper';
import FieldIcon from '@components/common/IconPack/FieldIcon';
import { TextWithTooltip } from '@components/common/TextWithTooltip';
import { useLocale } from '@hooks/useLocale';
import { DimensionType, type CommunicationChannel } from '@lightdash/common';
import { Box, Group, Text } from '@mantine/core';
import { CaretDown, CaretRight } from '@phosphor-icons/react';
import React from 'react';
import { InternalEventItem } from '../ControlPanel/TriggerPanel/EventsList';
import { JourneyEventType, type ActionEventField } from './types';

interface JourneyActiveFieldProps {
    activeEventField: ActionEventField | undefined;
    isEditMode: boolean;
}

const JourneyActiveEventField: React.FC<JourneyActiveFieldProps> = ({
    activeEventField,
    isEditMode,
}) => {
    const { t } = useLocale();

    if (!activeEventField)
        return (
            <FilterSelectWrapper isEditMode={isEditMode}>
                <Text className="font-normal">
                    {t('filter_select.empty_even_select_label')}
                </Text>
                <CaretDown
                    color="rgb(var(--color-gray-600))"
                    weight="regular"
                    size={16}
                    className="ml-2"
                />
            </FilterSelectWrapper>
        );

    if (activeEventField.eventType === JourneyEventType.INTERNAL_EVENT) {
        return (
            <FilterSelectWrapper isEditMode={isEditMode}>
                <InternalEventItem
                    event={{
                        sourceLabel:
                            activeEventField.eventSourceLabel as CommunicationChannel,
                        label: activeEventField.eventLabel,
                        eventName: activeEventField.eventName,
                        uuid: '',
                        projectUuid: '',
                        source: '',
                        mapperSchema: {},
                        sampleEventPayload: {},
                        createdBy: '',
                        createdAt: null,
                        updatedBy: '',
                        updatedAt: null,
                        persistToWh: false,
                        isInternal: false,
                    }}
                />
            </FilterSelectWrapper>
        );
    }
    return (
        <FilterSelectWrapper isEditMode={isEditMode}>
            <Group className="gap-1">
                <Box className="px-2 !p-0">
                    <div className="flex items-center">
                        <div className="flex items-center justify-center gap-10 rounded-md ">
                            {activeEventField.eventSourceIcon}
                        </div>
                        <span
                            className={`text-sm pl-1.5 font-medium max-w-[120px] truncate`}
                        >
                            {activeEventField.eventSourceLabel}
                        </span>
                    </div>
                </Box>
                <CaretRight />

                <FieldIcon type={DimensionType.EVENT} strokeWidth={2.5} />
                <Box className={`max-w-[160px] truncate`}>
                    {activeEventField.eventLabel && (
                        <TextWithTooltip
                            text={activeEventField.eventLabel}
                            className="!m-0 py-2 rounded text-sm text-gray-800 font-medium"
                        />
                    )}
                </Box>
            </Group>
        </FilterSelectWrapper>
    );
};

export default JourneyActiveEventField;
