import OrganizationSettingsSidebar from '@components/OrganizationSettings/Sidebar';
import { organizationSettingsMenuItems } from '@components/OrganizationSettings/Sidebar/organizationSettingsMenuItems';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import { SearchParams } from '@utils/constants';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

interface SettingListItemType {
    label: string;
    redirectTo: string;
    routeMatch: string;
    children: React.ReactNode;
    leftSection?: React.ReactNode;
}

const OrganizationSettings: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const redirect = searchParams.get(SearchParams.REDIRECT);

    const renderSettingRoute = (
        setting: SettingListItemType,
        index: number,
    ) => (
        <Route key={index} path={setting.routeMatch}>
            {setting.children}
        </Route>
    );

    const renderedSettingRoutes = organizationSettingsMenuItems(
        redirect,
    ).flatMap((group) => group.map(renderSettingRoute));

    return (
        <Page
            withFitContent
            withPaddedContent
            title={t('organization_settings.page_title')}
            sidebar={<OrganizationSettingsSidebar />}
            className="h-[100vh]"
        >
            <Switch>{renderedSettingRoutes}</Switch>
        </Page>
    );
};

export default OrganizationSettings;
