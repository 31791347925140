import { Group } from '@mantine/core';
import React from 'react';

interface FilterRuleFormWrapperProps {
    children: React.ReactNode;
}

const FilterRuleFormWrapper = ({ children }: FilterRuleFormWrapperProps) => {
    return (
        <Group className="flex flex-row items-center gap-1.5 w-full flex-nowrap">
            {children}
        </Group>
    );
};

export default React.memo(FilterRuleFormWrapper);
