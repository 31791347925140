import {
    type CampaignContentDetails,
    type ContentStepComponentProps,
} from '@components/Campaigns/Builder/types';
import { getNextContentStep } from '@components/Campaigns/Builder/utils';
import LeftLabelInput from '@components/common/Inputs/LeftLabelInput';
import TextInput from '@components/common/Inputs/TextInput';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { CustomComponentType } from '@components/Templates/HTMLManager/HTMLEditor';
import { useGetAllSubscriptionGroups } from '@hooks/subscriptionGroups/useSubscriptionGroups';
import useNotify from '@hooks/toaster/useNotify';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useGetIntegratedProviderConfig } from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import { useGetTemplateById } from '@hooks/useTemplate';
import {
    AudienceType,
    CommunicationChannel,
    unsubUrl,
    type CampaignUpdateRequest,
    type EmailTemplateContentDetails,
} from '@lightdash/common';
import { Box, Group, Radio, Stack, Text } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { FontWeights } from '../../../../../../mantineTheme';
import SubscriptionGroupFilter, {
    type Option,
} from './SubscriptionGroupFilter';
import { LabelIconType } from './SubscriptionGroupFilter/LabelIcon';

const initialErrorState = {
    subscriptionGroupId: '',
    subject: '',
};

const SetupContentDetails: React.FC<ContentStepComponentProps> = ({
    setActiveContentStep,
    activeContentStep,
}) => {
    const initialData = useRef<any>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const [errors, setErrors] = useState<{
        subscriptionGroupId: string;
        subject: string;
    }>(initialErrorState);
    const { t } = useLocale();
    const { showToastError } = useNotify();
    const { campaignPayload, isEditMode } = useCampaignContext(
        (context) => context.state,
    );
    const { communicationDetails, templateDetails, subscriptionGroupId } =
        campaignPayload;

    const { setContentDetails, setSubscriptionGroup, setCurrentStepCallback } =
        useCampaignContext((context) => context.actions);

    const {
        data: integrationConfig,
        isInitialLoading: isIntegrationConfigLoading,
    } = useGetIntegratedProviderConfig(
        communicationDetails?.providerId ?? '',
        communicationDetails?.id ?? '',
    );

    const { data: templateMetadata, isFetching: isTemplateMetadataLoading } =
        useGetTemplateById(
            templateDetails?.id ?? '',
            campaignPayload?.channel !== CommunicationChannel.WHATSAPP,
        );

    const { data: subscriptionGroups } = useGetAllSubscriptionGroups({
        channel: campaignPayload?.channel as string,
    });

    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid ?? '');

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    const subscriptionFilterMenuItems = useMemo(() => {
        if (subscriptionGroups) {
            const temp = subscriptionGroups?.reduce((accum: Option[], curr) => {
                const categoryItems: Option[] = [];
                const categoryName = curr.category.name;
                curr.groups.forEach((group, index) => {
                    categoryItems.push({
                        leftLabel:
                            categoryName.toLowerCase() === 'global'
                                ? ''
                                : categoryName,
                        leftLabelIcon: LabelIconType.CATEGORY,
                        rightLabel: group.name,
                        rightLabelIcon: LabelIconType.GROUP,
                        value: group.id,
                        divider: index === curr.groups.length - 1,
                    });
                });
                if (accum) return [...accum, ...categoryItems];
                return [...categoryItems];
            }, []);
            return temp;
        }
        return [];
    }, [subscriptionGroups]);

    const handleSubscriptionGroupChange = (value: string) => {
        if (errors.subscriptionGroupId) {
            setErrors({
                ...errors,
                subscriptionGroupId: '',
            });
        }
        setSubscriptionGroup(value);
    };

    const defaultValues = useMemo(
        () => ({
            sendAsName: integrationConfig?.fromName ?? '',
            sendAsEmail: integrationConfig?.fromEmail ?? '',
            replyToSameAsSender: Boolean(
                integrationConfig?.replyTo === integrationConfig?.fromEmail,
            ),
            emailSubject:
                (campaignPayload?.contentDetails as EmailTemplateContentDetails)
                    ?.subject ??
                (
                    templateMetadata?.contents?.[0]
                        ?.content as EmailTemplateContentDetails
                )?.subject ??
                '', //FIXME: This is hard coded to EMAIL content type, should be removed for other channels
            previewText: '',
        }),
        [integrationConfig, campaignPayload?.contentDetails, templateMetadata],
    );

    const form = useForm<CampaignContentDetails>({
        defaultValues,
    });

    const { control, handleSubmit, register, reset } = form;

    const onSubmit = useCallback(() => {
        const nextStep = getNextContentStep(
            activeContentStep,
            campaignPayload?.channel,
            templateMetadata?.contents[0].parsedVariables,
        );
        if (
            !subscriptionGroupId &&
            campaignPayload.audienceType === AudienceType.WAREHOUSE
        ) {
            setErrors({
                ...errors,
                subscriptionGroupId: t('common.empty_select_error'),
            });
            return;
        }
        if (!form.getValues().emailSubject) {
            setErrors({
                ...errors,
                subject: t('common.error.empty_text_input'),
            });
            return;
        }
        if (
            campaignPayload?.channel === CommunicationChannel.EMAIL &&
            !(
                (
                    templateMetadata?.contents?.[0]
                        ?.content as EmailTemplateContentDetails
                )?.html?.includes(
                    `data-srt-id="${CustomComponentType.UNSUBSCRIBE_BLOCK}"`,
                ) ||
                (
                    templateMetadata?.contents?.[0]
                        ?.content as EmailTemplateContentDetails
                )?.html?.includes(unsubUrl)
            ) &&
            campaignPayload.audienceType === AudienceType.WAREHOUSE
        ) {
            showToastError({
                title: t('campaign.unsubscribe_block_error_toast'),
                autoClose: 3000,
            });
            return;
        }
        const submitForm = async () => {
            if (nextStep) {
                setActiveContentStep(nextStep);
                let payload: CampaignUpdateRequest = {
                    subscriptionGroupId:
                        campaignPayload.audienceType === AudienceType.WAREHOUSE
                            ? subscriptionGroupId
                            : undefined,
                };
                if (campaignPayload?.channel === CommunicationChannel.EMAIL) {
                    const templateDetailsPayload = {
                        subject: form.getValues().emailSubject,
                        html: (
                            templateMetadata?.contents?.[0]
                                ?.content as EmailTemplateContentDetails
                        )?.html,
                    };
                    setContentDetails(templateDetailsPayload);
                    payload = {
                        ...payload,
                        contentDetails: templateDetailsPayload,
                    };
                    if (
                        !isEqual(
                            {
                                subscriptionGroupId:
                                    initialData.current.subscriptionGroupId,
                                contentDetails:
                                    initialData.current.contentDetails,
                            },
                            payload,
                        ) &&
                        campaignUuid
                    ) {
                        updateCampaign(payload);
                    }
                } else {
                    if (
                        initialData.current.subscriptionGroupId !==
                            subscriptionGroupId &&
                        campaignUuid
                    ) {
                        updateCampaign(payload);
                    }
                }
            }
        };
        void handleSubmit(submitForm)();
    }, [
        activeContentStep,
        campaignPayload?.channel,
        campaignPayload.audienceType,
        templateMetadata?.contents,
        subscriptionGroupId,
        form,
        handleSubmit,
        errors,
        t,
        showToastError,
        setActiveContentStep,
        setContentDetails,
        campaignUuid,
        updateCampaign,
    ]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: onSubmit,
            skipExecutionAfterCallback: true,
        });
    }, [
        activeContentStep,

        handleSubmit,
        onSubmit,
        setActiveContentStep,
        setCurrentStepCallback,
    ]);

    useEffect(() => {
        if (isIntegrationConfigLoading) return;
        if (!defaultValues) return;
        reset({
            ...defaultValues,
        });
    }, [isIntegrationConfigLoading, defaultValues, reset]);

    const handleEmailSubjectChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;
            const templateDetailsPayload = {
                subject: value,
                html: (
                    templateMetadata?.contents?.[0]
                        ?.content as EmailTemplateContentDetails
                )?.html,
            };
            setContentDetails(templateDetailsPayload);
        },
        [setContentDetails, templateMetadata?.contents],
    );

    if (isIntegrationConfigLoading || isTemplateMetadataLoading)
        return (
            <Stack className="gap-6">
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
                <SkeletonLoader />
            </Stack>
        );

    if (campaignPayload?.channel !== CommunicationChannel.EMAIL) {
        return (
            <Box>
                <SubscriptionGroupFilter
                    label={t('subscription_group_filter_label')}
                    options={subscriptionFilterMenuItems}
                    value={subscriptionGroupId ?? ''}
                    onChange={handleSubscriptionGroupChange}
                    error={errors.subscriptionGroupId}
                    width={undefined}
                />
            </Box>
        );
    }

    return (
        <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack className="gap-6">
                    <Stack className="gap-1.5">
                        {campaignPayload.audienceType ===
                            AudienceType.WAREHOUSE && (
                            <SubscriptionGroupFilter
                                width={undefined}
                                label={t('subscription_group_filter_label')}
                                options={subscriptionFilterMenuItems}
                                value={subscriptionGroupId ?? ''}
                                onChange={handleSubscriptionGroupChange}
                                error={errors.subscriptionGroupId}
                            />
                        )}
                        <Text className="text-sm font-medium text-gray-800">
                            Send as
                        </Text>
                        <Group className="flex-nowrap">
                            <Controller
                                name="sendAsName"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <LeftLabelInput
                                        label="Name"
                                        placeholder="John doe"
                                        type="name"
                                        value={value || ''}
                                        onChange={(name) => onChange(name)}
                                        disabled
                                    />
                                )}
                            />

                            <Controller
                                name="sendAsEmail"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <LeftLabelInput
                                        label="Email"
                                        placeholder="email@company.com"
                                        type="email"
                                        value={value || ''}
                                        onChange={(email) => onChange(email)}
                                        disabled
                                    />
                                )}
                            />
                        </Group>
                        <Text className="text-sm font-medium text-gray-500">
                            Sender name and email which recipients will see
                        </Text>
                    </Stack>

                    <Stack className="gap-1.5">
                        <Text className="text-sm font-medium text-gray-800">
                            Reply to
                        </Text>

                        <Controller
                            name="replyToSameAsSender"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <Radio
                                    label="Same as ‘Send as’ email address"
                                    styles={(theme) => ({
                                        label: {
                                            color: 'rgb(var(--color-gray-800))',
                                            fontSize: theme.fontSizes.sm,
                                            fontWeight: FontWeights.medium,
                                        },
                                    })}
                                    checked={value}
                                    onChange={(e) => onChange(e.target.checked)}
                                    disabled
                                />
                            )}
                        />
                        <Text className="text-sm font-medium text-gray-500">
                            Email to which recipients can reply to
                        </Text>
                    </Stack>

                    <Stack className="gap-1.5">
                        <Controller
                            name="emailSubject"
                            control={control}
                            render={({ field: { onChange, value } }) => (
                                <TextInput
                                    label={t(
                                        'campaigns_builder.setup_content_subject_label',
                                    )}
                                    type="text"
                                    onChange={(text) => {
                                        onChange(text);
                                        handleEmailSubjectChange(text);
                                        if (text && errors.subject) {
                                            setErrors({
                                                ...errors,
                                                subject: '',
                                            });
                                        }
                                    }}
                                    required
                                    withAsterisk={false}
                                    value={value || ''}
                                    disabled={!isEditMode}
                                    error={errors.subject}
                                />
                            )}
                        />

                        <Text className="text-sm font-medium text-gray-500">
                            {t(
                                'campaigns_builder.setup_content_subject_description',
                            )}
                        </Text>
                    </Stack>

                    <Stack className="gap-1.5">
                        <Text className="text-sm font-medium text-gray-800">
                            Preview text{' '}
                            <span className="text-gray-600">(optional)</span>
                        </Text>

                        <TextInput
                            type="text"
                            {...register('previewText')}
                            disabled
                        />
                        <Text className="text-sm font-medium text-gray-500">
                            This shows up in inboxes of most popular email
                            providers
                        </Text>
                    </Stack>
                </Stack>
            </form>
        </Box>
    );
};

export default SetupContentDetails;
