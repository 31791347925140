const BorderBottomRightRadius = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.2"
                d="M11 2C11.5523 2 12 2.44772 12 3V11C12 11.5523 11.5523 12 11 12H3C2.44772 12 2 11.5523 2 11V3C2 2.44772 2.44772 2 3 2H11Z"
                fill="#9B9B9B"
            />
            <path
                d="M13 12.2302L12.9995 8.49434C12.9995 8.39224 12.96 8.29432 12.8897 8.22213C12.8194 8.14993 12.724 8.10937 12.6245 8.10937C12.5251 8.10937 12.4297 8.14993 12.3593 8.22213C12.289 8.29432 12.2495 8.39224 12.2495 8.49434L12.2499 11.23C12.2499 11.7824 11.8022 12.2302 11.2498 12.2301L8.37451 12.23C8.27506 12.23 8.17967 12.2705 8.10935 12.3427C8.03902 12.4149 7.99951 12.5128 7.99951 12.6149C7.99951 12.717 8.03902 12.815 8.10935 12.8872C8.17967 12.9594 8.27506 12.9999 8.37451 12.9999L12.25 13.0001C12.4489 13.0001 12.6397 12.919 12.7803 12.7746C12.921 12.6302 13 12.4344 13 12.2302Z"
                fill="#343330"
            />
        </svg>
    );
};

export default BorderBottomRightRadius;
