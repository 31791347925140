import {
    isField,
    type AndFilterGroup,
    type Audience,
    type DashboardFilters,
    type FilterableDimension,
    type FilterableField,
    type FilterableItem,
    type FilterRule,
    type RelationTableType,
    type WeekDay,
} from '@lightdash/common';
import { type PopoverProps } from '@mantine/core';
import { uuid4 } from '@sentry/utils';
import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    type FC,
    type ReactNode,
} from 'react';
import { convertAudienceToFilterableDimension } from './utils';

export type FieldWithSuggestions = FilterableField & {
    suggestions?: string[];
    tableType?: RelationTableType;
    uniqueIdentifier?: string;
    label?: string;
    defaultFieldRender?: ReactNode; //Info: This can be used to render the field in a default way
};

export type FieldsWithSuggestions = Record<string, FieldWithSuggestions>;

type FiltersContext = {
    projectUuid?: string;
    fieldsMap: FieldsWithSuggestions;
    eventsMap: FieldWithSuggestions[];
    eventTables: string[];
    startOfWeek?: WeekDay;
    getField: (filterRule: FilterRule) => FieldWithSuggestions | undefined;
    getAutocompleteFilterGroup: (
        filterId: string,
        item: FilterableItem,
    ) => AndFilterGroup | undefined;
    popoverProps?: Omit<PopoverProps, 'children'>;
    audienceMap: FilterableDimension[];
    isLoading: boolean;
};

const Context = createContext<FiltersContext | undefined>(undefined);

type Props = {
    projectUuid?: string;
    fieldsMap?: Record<string, FieldWithSuggestions>;
    eventsMap?: FieldWithSuggestions[];
    eventTables?: string[];
    startOfWeek?: WeekDay;
    dashboardFilters?: DashboardFilters;
    popoverProps?: Omit<PopoverProps, 'children'>;
    audienceData?: Audience[];
    isLoading?: boolean;
};

export const FiltersProvider: FC<React.PropsWithChildren<Props>> = ({
    projectUuid,
    fieldsMap = {},
    eventsMap = [],
    eventTables = [],
    audienceData = [],
    startOfWeek,
    dashboardFilters,
    popoverProps,
    children,
    isLoading = false,
}) => {
    const getField = useCallback(
        (filterRule: FilterRule) => {
            if (fieldsMap) {
                return fieldsMap[filterRule.target.fieldId];
            }
        },
        [fieldsMap],
    );

    const getAutocompleteFilterGroup = useCallback(
        (filterId: string, item: FilterableItem) => {
            if (!dashboardFilters || !isField(item)) {
                return undefined;
            }
            return {
                id: uuid4(),
                and: dashboardFilters.dimensions.filter(
                    (dimensionFilterRule) => {
                        const isNotSelectedFilter =
                            dimensionFilterRule.id !== filterId;
                        const hasSameTable =
                            dimensionFilterRule.target.tableName === item.table;
                        return isNotSelectedFilter && hasSameTable;
                    },
                ),
            };
        },
        [dashboardFilters],
    );

    const audienceMap = useMemo(() => {
        if (!audienceData) return [];
        return convertAudienceToFilterableDimension(audienceData);
    }, [audienceData]);

    return (
        <Context.Provider
            value={{
                projectUuid,
                fieldsMap,
                eventsMap,
                startOfWeek,
                getField,
                getAutocompleteFilterGroup,
                popoverProps,
                eventTables,
                audienceMap,
                isLoading,
            }}
        >
            {children}
        </Context.Provider>
    );
};

export function useFiltersContext(): FiltersContext {
    const context = useContext(Context);
    if (context === undefined) {
        throw new Error(
            'useFiltersContext must be used within a FiltersProvider',
        );
    }
    return context;
}
