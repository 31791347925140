import BeeFreeSDK from '@beefree.io/sdk';
import TextArea from '@components/common/Inputs/TextArea';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import { useCreateTemplate, useUpdateTemplate } from '@hooks/useTemplate';
import { CommunicationChannel } from '@lightdash/common';
import { Stack } from '@mantine/core';
import { useTemplateBuilderContext } from '@providers/TemplateBuilderProvider';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import { type HtmlTemplateDetails } from '../HTMLManager/types';
import useBeeFreeConfig from './useBeeFreeConfig';

// TODO - Move to env
const clientId = 'ead575b2-da72-4433-869b-dfa4747a8161';
const clientSecret = '0kICtKiEs5mLAcSZ6Hq5qtBNgvRUV0qVz6yvEWKO22l0LqEPsRv9';

type BeeFreeEditorProps = {
    templateDetail: HtmlTemplateDetails;
};
const BeeFreeEditor = ({ templateDetail }: BeeFreeEditorProps) => {
    const query = useQueryClient();
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateDescription, setTemplateDescription] = useState('');
    const [templateJson, setTemplateJson] = useState<Record<string, any>>({});
    const [templateHtml, setTemplateHtml] = useState<string>('');
    const { setOpenCreateTemplateModal } = useTemplateBuilderContext(
        (context) => context.actions,
    );
    const { mutateAsync: mutateAsyncCreateTemplate } = useCreateTemplate();

    const { mutateAsync: mutateAsyncUpdateTemplate } = useUpdateTemplate(
        templateDetail.id ?? '',
        1,
    );

    const handleSave = (jsonFile: unknown, htmlFile: unknown) => {
        setTemplateJson(JSON.parse(jsonFile as string));
        setTemplateHtml(htmlFile as string);
        if (templateDetail.id) {
            mutateAsyncUpdateTemplate({
                html: htmlFile as string,
                json: JSON.parse(jsonFile as string),
                subject: '',
            })
                .then(() => {
                    setOpenCreateTemplateModal(false);
                    void query.refetchQueries([
                        QueryKeys.GET_TEMPLATE,
                        templateDetail.id,
                    ]);
                })
                .catch(() => {});
        } else {
            setShowSaveModal(true);
        }
    };

    const handleSaveTemplate = () => {
        if (!templateName) {
            return;
        }
        mutateAsyncCreateTemplate({
            name: templateName,
            description: templateDescription,
            channel: CommunicationChannel.EMAIL,
            consideredInLibrary: true,
            tags: [],
            content: {
                html: templateHtml,
                subject: '',
                json: templateJson,
            },
            previewUrl: '',
        })
            .then(() => {
                setShowSaveModal(false);
                setOpenCreateTemplateModal(false);
            })
            .catch(() => {});
    };
    const beeConfig = useBeeFreeConfig({
        handleSave,
    });

    const beeTest = new BeeFreeSDK();
    useEffect(() => {
        beeTest
            .getToken(clientId, clientSecret)
            .then(() => {
                if (templateDetail?.json) {
                    void beeTest.start(beeConfig, templateDetail.json);
                } else {
                    void beeTest.start(beeConfig, {});
                }
            })
            .catch(() => {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div
                style={{
                    width: '100vw',
                    height: '100vh',
                }}
                id="beefree-sdk-container"
            ></div>
            <Modal
                opened={showSaveModal}
                title="Save Template"
                onClose={() => setShowSaveModal(false)}
            >
                <Stack className="mb-3">
                    <TextInput
                        value={templateName}
                        onChange={(e) => setTemplateName(e.target.value)}
                        label="Template Name"
                        placeholder="Enter template name"
                    />
                    <TextArea
                        label="Description (Optional)"
                        placeholder="Enter description"
                        value={templateDescription}
                        onChange={(e) => setTemplateDescription(e.target.value)}
                    />
                </Stack>
                <ModalFooter
                    justify="right"
                    secondaryLeftIcon={undefined}
                    secondaryRightIcon={undefined}
                    primaryButtonVariant={undefined}
                    secondaryButtonVariant={ButtonVariant.OUTLINED}
                    isLoading={false}
                    primaryButtonCustomClass=""
                    primaryButtonDisabled={false}
                    primaryButtonClick={handleSaveTemplate}
                    primaryText="Save"
                    secondaryButtonClick={() => setShowSaveModal(false)}
                    secondaryText="Cancel"
                    showPrimaryButton
                    showSecondaryButton
                    primaryLeftIcon={undefined}
                    primaryRightIcon={undefined}
                />
            </Modal>
        </>
    );
};

export default BeeFreeEditor;
