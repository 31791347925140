import { Flex, Text } from '@mantine/core';
import { Sparkle, X } from '@phosphor-icons/react';

const AiBanner = ({
    text,
    onClose,
    show,
}: {
    text: string;
    onClose: () => void;
    show: boolean;
}) => {
    if (!show) return null;
    return (
        <Flex
            gap={'xs'}
            className="bg-purple/6 px-3 py-2 mb-3 rounded-md border border-purple/6"
            align={'center'}
        >
            <Sparkle color="rgb(var(--color-purple))" />
            <Text className="text-purple font-medium w-[95%]">{text}</Text>
            <X
                color="rgb(var(--color-purple))"
                className="cursor-pointer"
                weight="regular"
                onClick={onClose}
            />
        </Flex>
    );
};

export default AiBanner;
