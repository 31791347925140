import { type NodeData } from '@components/SubscriptionGroups/SubscriptionDetails/SubscriptionBody';
import {
    type CategoriesWithGroups,
    type CategoriesWithGroupsAndUserPreference,
    type PartialCategoriesWithGroups,
    type SubscriptionCategory,
    type SubscriptionGroup,
} from '@lightdash/common';
import { useCallback } from 'react';
interface Category {
    category: Partial<SubscriptionCategory>;
    groups: Partial<SubscriptionGroup>[];
}
const useSubscription = () => {
    const transformData = useCallback(
        (
            data: CategoriesWithGroupsAndUserPreference | CategoriesWithGroups,
        ) => {
            const newData: NodeData[] = [];
            data.forEach((item) => {
                const { category, groups } = item;
                if (category.isGlobal) {
                    groups.forEach((group: NodeData) => {
                        newData.push({
                            id: group.id,
                            categoryId: group.categoryId,
                            name: group.name,
                            description: group.description,
                            weight: group.weight,
                            type: group.type,
                            ...(group.userPreference !== undefined && {
                                userPreference: group.userPreference,
                            }),
                        });
                    });
                } else {
                    const sortedGroups = groups
                        .map((group: NodeData) => ({
                            id: group.id,
                            categoryId: group.categoryId,
                            name: group.name,
                            description: group.description,
                            type: group.type,
                            weight: group.weight,
                            ...(group.userPreference !== undefined && {
                                userPreference: group.userPreference,
                            }),
                        }))
                        .sort((a, b) => {
                            if (!a.weight || !b.weight) {
                                return 0;
                            }
                            return a.weight - b.weight;
                        });

                    newData.push({
                        id: category.id,
                        categoryId: null,
                        name: category.name,
                        description: category.description,
                        weight: category.weight,
                        children: sortedGroups,
                    });
                }
            });
            newData.sort((a, b) => {
                if (!a.weight || !b.weight) {
                    return 0;
                }
                return a.weight - b.weight;
            });
            return [...newData];
        },
        [],
    );

    const removeNodes = useCallback(
        (nodes: NodeData[], idsToRemove: string[]): NodeData[] => {
            return nodes
                .filter((node) => !idsToRemove.includes(node.id))
                .map((node) => {
                    if (node.children) {
                        return {
                            ...node,
                            children: removeNodes(node.children, idsToRemove),
                        };
                    }
                    return node;
                });
        },
        [],
    );

    const addNodes = useCallback(
        (
            nodes: NodeData[],
            newNodes: NodeData[],
            parentId: string | null,
            index: number,
        ): NodeData[] => {
            if (!parentId) {
                nodes.splice(index, 0, ...newNodes);
                return nodes;
            }
            return nodes.map((node) => {
                if (node.id === parentId) {
                    const updatedChildren = node.children
                        ? [...node.children]
                        : [];
                    updatedChildren.splice(index, 0, ...newNodes);
                    return {
                        ...node,
                        children: updatedChildren,
                    };
                }
                if (node.children) {
                    return {
                        ...node,
                        children: addNodes(
                            node.children,
                            newNodes,
                            parentId,
                            index,
                        ),
                    };
                }
                return node;
            });
        },
        [],
    );

    const processTreeData = useCallback(
        (treeData: NodeData[]): PartialCategoriesWithGroups => {
            let globalCategory: Category = {
                category: {
                    isGlobal: true,
                },
                groups: [],
            };

            let categories: Category[] = [];

            treeData.forEach((item, index) => {
                let weight = index + 1;
                if (!item.children || item.children.length === 0) {
                    globalCategory.groups.push({
                        id: item.id,
                        weight: weight,
                    });
                } else {
                    let category: Category = {
                        category: {
                            id: item.id,
                            weight: weight,
                        },
                        groups: item.children.map((child, childIndex) => ({
                            id: child.id,
                            weight: childIndex + 1,
                        })),
                    };
                    categories.push(category);
                }
            });

            return { data: [globalCategory, ...categories] };
        },
        [],
    );

    const moveItemUp = useCallback(
        (
            treeData: NodeData[],
            setTreeData: (data: NodeData[]) => void,
            id: string,
        ) => {
            const moveUp = (nodes: NodeData[]): void => {
                for (let i = 0; i < nodes.length; i++) {
                    if (nodes[i].id === id) {
                        if (i > 0) {
                            [nodes[i - 1], nodes[i]] = [nodes[i], nodes[i - 1]];
                        }
                        return;
                    }
                    if (nodes[i].children) {
                        moveUp(nodes[i].children!);
                    }
                }
            };

            const newTreeData = [...treeData];
            moveUp(newTreeData);
            setTreeData(newTreeData);
        },
        [],
    );

    const moveItemDown = useCallback(
        (
            treeData: NodeData[],
            setTreeData: (data: NodeData[]) => void,
            id: string,
        ) => {
            const moveDown = (nodes: NodeData[]): void => {
                for (let i = 0; i < nodes.length; i++) {
                    if (nodes[i].id === id) {
                        if (i < nodes.length - 1) {
                            [nodes[i + 1], nodes[i]] = [nodes[i], nodes[i + 1]];
                        }
                        return;
                    }
                    if (nodes[i].children) {
                        moveDown(nodes[i].children!);
                    }
                }
            };

            const newTreeData = [...treeData];
            moveDown(newTreeData);
            setTreeData(newTreeData);
        },
        [],
    );

    return {
        transformData,
        removeNodes,
        addNodes,
        processTreeData,
        moveItemUp,
        moveItemDown,
    };
};

export default useSubscription;
