import CampaignBuilderFooter from '@components/Campaigns/Builder/CampaignBuilderFooter';
import CampaignBuilderHeader from '@components/Campaigns/Builder/CampaignBuilderHeader';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import { AudienceType, CampaignStatus } from '@lightdash/common';
import { useCampaignContext } from '@providers/CampaignProvider';
import { CSV_AUDIENCE_PREVIEW_LIMIT } from '@utils/constants';
import Papa from 'papaparse';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

interface CampaignBuilderFormProps {
    isNewMode: boolean;
    isEditMode: boolean;
}

const CampaignBuilderForm: React.FC<CampaignBuilderFormProps> = ({
    isNewMode,
    isEditMode,
}) => {
    const initialRender = useRef(false);
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { state, actions } = useCampaignContext((context) => context);
    const {
        currentStep,
        showFooterButtons,
        campaignPayload,
        uuid: campaignId,
        audienceCsvData,
        status,
    } = state;
    const { setAudienceCsvData } = actions;

    useEffect(() => {
        if (
            !initialRender.current &&
            campaignPayload?.audienceType === AudienceType.CSV &&
            (!audienceCsvData || audienceCsvData?.length === 0)
        ) {
            const fileUrl = `/api/v1/projects/${projectUuid}/campaigns/${campaignId}/csv`;
            Papa.parse(fileUrl, {
                header: true,
                download: true,
                preview: CSV_AUDIENCE_PREVIEW_LIMIT,
                complete(results) {
                    if (results.data) {
                        setAudienceCsvData(results.data);
                        initialRender.current = true;
                    }
                },
            });
        }
    }, [
        campaignId,
        campaignPayload?.audienceType,
        projectUuid,
        setAudienceCsvData,
        audienceCsvData,
    ]);
    const { t } = useLocale();

    const CurrentStepComponent = CampaignBuilderSteps.find(
        (step) => step.key === currentStep,
    )?.component;

    if (!CurrentStepComponent) {
        return null;
    }

    const showFooter = showFooterButtons.back || showFooterButtons.next;

    return (
        <Page
            withFullHeight={false}
            withPaddedContent
            backgroundColor="white"
            withFixedContent
            withNavbar={false}
            header={
                <CampaignBuilderHeader
                    isNew={isNewMode}
                    isViewOnly={!isNewMode && !isEditMode}
                    status={status ?? CampaignStatus.DRAFT}
                    channel={campaignPayload?.channel}
                />
            }
            footer={showFooter && <CampaignBuilderFooter />}
            withFixedFooter={showFooter}
            title={t('campaign.create.title')}
        >
            <CurrentStepComponent />
        </Page>
    );
};

export default CampaignBuilderForm;
