import { sortmentApi } from '@api/index';
import { type ApiError, type CreateOrganization } from '@lightdash/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
const createOrgQuery = async (data: CreateOrganization) =>
    sortmentApi<null>({
        url: `/org`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useOrganizationCreateMutation = () => {
    const queryClient = useQueryClient();
    return useMutation<null, ApiError, CreateOrganization>(createOrgQuery, {
        mutationKey: [QueryKeys.ORGANIZATION_CREATE],
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKeys.USER]);
            await queryClient.invalidateQueries([
                QueryKeys.USER_ALLOWED_ORGANIZATIONS,
            ]);
        },
    });
};

// const createGroupQuery = async (data: CreateGroup) =>
//     sortmentApi<Group>({
//         url: `/org/groups`,
//         method: 'POST',
//         body: JSON.stringify(data),
//     });

// export const useCreateGroupMutation = () => {
//     const queryClient = useQueryClient();
//     return useMutation<Group, ApiError, CreateGroup>(
//         (data) => createGroupQuery(data),
//         {
//             mutationKey: [QueryKeys.CREATE_GROUP],
//             onSuccess: async (_) => {
//                 await queryClient.invalidateQueries([
//                     QueryKeys.ORGANIZATION_GROUPS,
//                 ]);
//                 await queryClient.invalidateQueries([
//                     QueryKeys.ORGANIZATION_USERS,
//                 ]);
//             },
//         },
//     );
// };
