import { type WeekDay } from '../utils/timeFrames';
import {
    type BlobConfig,
    type WHBlobLocations,
    type WHBlobUnloadConfig,
} from './blob/blobConfigs';
import { type SupportedDbtAdapter } from './dbt';
import { type CreateEventColumn } from './events';
import { type DimensionType, type Metric } from './field';
import { type CreateWarehouseCredentials } from './projects';

export type WarehouseTableSchema = {
    [column: string]: DimensionType;
};

export type WarehouseCatalog = {
    [database: string]: {
        [schema: string]: {
            [table: string]: WarehouseTableSchema;
        };
    };
};

export type WarehouseTableInfoBase = {
    database: string;
    schema: string;
    table: string;
};

export type WarehouseTableColumn = {
    name: string;
    type: DimensionType;
    description?: string;
    label?: string;
};

export type WarehouseTableInfo = WarehouseTableInfoBase & {
    columns: Array<WarehouseTableColumn>;
};

export type WarehouseTablesCatalog = {
    [database: string]: {
        [schema: string]: {
            [table: string]: { partitionColumn?: PartitionColumn };
        };
    };
};

export type WarehouseTables = {
    database: string;
    schema: string;
    table: string;
    partitionColumn?: PartitionColumn;
}[];

export type WarehouseResults = {
    fields: Record<string, { type: DimensionType }>;
    rows: Record<string, any>[];
};

export interface WarehouseClient {
    credentials: CreateWarehouseCredentials;
    getCatalog: (
        config: {
            database: string;
            schema: string;
            table: string;
        }[],
    ) => Promise<WarehouseCatalog>;

    getFullCatalog: () => Promise<WarehouseCatalog>;

    streamQuery(
        query: string,
        streamCallback: (data: WarehouseResults) => void,
        options: {
            values?: any[];
            tags: Record<string, string>;
            timezone?: string;
        },
    ): Promise<void>;

    /**
     * Runs a query and returns all the results
     * @param sql
     * @param tags
     * @param timezone
     * @param values
     * @deprecated Use streamQuery() instead to avoid loading all results into memory
     */
    runQuery(
        sql: string,
        tags: Record<string, string>,
        timezone?: string,
        values?: any[],
    ): Promise<WarehouseResults>;

    test(): Promise<void>;

    getStartOfWeek(): WeekDay | null | undefined;

    getAdapterType(): SupportedDbtAdapter;

    getStringQuoteChar(): string;

    getEscapeStringQuoteChar(): string;

    getMetricSql(sql: string, metric: Metric): string;

    concatString(...args: string[]): string;

    createTable(
        table: string,
        columns: CreateEventColumn[],
        partitionColumn?: string,
    ): Promise<void>;

    addColumn(table: string, column: CreateEventColumn): Promise<void>;

    deleteColumn(table: string, columnName: string): Promise<void>;

    syncBlobToWarehouse(
        table: string,
        blobConfig: BlobConfig,
        fileFormat: string,
        path: string,
        frequency: number,
        credentials: { [keys: string]: string },
    ): Promise<void>;

    syncWarehouseToBlob(
        query: string,
        whBlobLocations: WHBlobLocations,
        blobConfig: BlobConfig,
        whBlobUnloadConfig?: WHBlobUnloadConfig,
    ): Promise<void>;

    loadCSVIntoWarehouseFromBlob(
        blobConfig: BlobConfig,
        table: string,
        filePath: string,
        credentials?: { [keys: string]: string },
    ): Promise<string>;

    deleteTable(table: string): Promise<void>;

    getAliasQuery(key: string, value: string, defaultValue: string): string;

    getTableResolution(tableName: string): string;

    getTables(
        schema?: string,
        tags?: Record<string, string>,
    ): Promise<WarehouseCatalog>;

    getAllTables(
        schema?: string,
        tags?: Record<string, string>,
    ): Promise<WarehouseTables>;

    getFields(
        tableName: string,
        schema?: string,
        database?: string,
        tags?: Record<string, string>,
    ): Promise<WarehouseCatalog>;

    parseWarehouseCatalog(
        rows: Record<string, any>[],
        mapFieldType: (type: string) => DimensionType,
    ): WarehouseCatalog;

    getTableInfo(
        database: string,
        schema: string,
        table: string,
    ): Promise<WarehouseTableInfo>;

    getSqlPipeSyntax(variable: string): string;

    getDateTruncateExpression(
        field: string,
        timezone?: string,
        dateFormat?: string,
    ): string;

    getStringTypeCast(value: string): string;

    getCurrentTimestamp(): string;

    parseError(error: Error): Error;
}

export type ApiWarehouseCatalog = {
    status: 'ok';
    results: WarehouseCatalog;
};

export type ApiWarehouseTablesCatalog = {
    status: 'ok';
    results: WarehouseTablesCatalog;
};

export type ApiWarehouseTableFields = {
    status: 'ok';
    results: WarehouseTableSchema;
};

export enum PartitionType {
    DATE = 'DATE',
    RANGE = 'RANGE',
}

export type PartitionColumn = {
    partitionType: PartitionType;
    field: string;
};
