import { fuzzyFilter, TableTypeOptions } from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType } from '@lightdash/common';
import { Box, Button } from '@mantine/core';
import { X } from '@phosphor-icons/react';
import {
    getCoreRowModel,
    useReactTable,
    type ColumnDef,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { type ResponseTableType } from '.';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import DataTypeMenu from './DataTypeMenu';
import InputCell from './InputCell';

interface ResponsePayloadKeysProps {
    responseTable: ResponseTableType[];
    updateResponseTable: (
        index: number,
        { key, type }: ResponseTableType,
    ) => void;
    deleteResponseTable: (index: number) => void;
    containerRef: React.RefObject<HTMLDivElement>;
    disabled: boolean;
}

const ResponsePayloadKeys: React.FC<ResponsePayloadKeysProps> = ({
    responseTable,
    updateResponseTable,
    deleteResponseTable,
    containerRef,
    disabled,
}) => {
    const { t } = useLocale();
    const columns: ColumnDef<ResponseTableType>[] = useMemo(() => {
        return [
            {
                header: t('common.uppercase_key'),
                accessorKey: 'key',
                cell: ({ row }) => {
                    return (
                        <InputCell
                            updateTable={(value) => {
                                updateResponseTable(row.index, {
                                    key: value,
                                    type: row.original.type,
                                });
                            }}
                            inputValue={row.original.key}
                            disabled={disabled}
                        />
                    );
                },
            },
            {
                header: t(
                    'journey_builder.call_api_response_payload_mapper_table_type_header',
                ),
                accessorKey: 'type',
                cell: ({ row }) => {
                    return (
                        <DataTypeMenu
                            onSelect={(type) => {
                                updateResponseTable(row.index, {
                                    key: row.original.key,
                                    type,
                                });
                            }}
                            value={row.original.type}
                            disabled={disabled}
                        />
                    );
                },
            },
            {
                header: '',
                accessorKey: 'cancel',
                cell: ({ row }) => {
                    if (disabled) {
                        return null;
                    }
                    return (
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            className={`p-2`}
                            onClick={() => deleteResponseTable(row.index)}
                        >
                            <X
                                weight="bold"
                                color={'rgb(var(--color-gray-700))'}
                            />
                        </Button>
                    );
                },
            },
        ];
    }, [t, disabled, updateResponseTable, deleteResponseTable]);
    const table = useReactTable({
        data: responseTable,
        columns,
        filterFns: {
            fuzzy: fuzzyFilter,
        },
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Box className="border border-shade-4 rounded-2xl p-0.5">
            <Box className="overflow-hidden border rounded-xl border-gray-50">
                <Box className="max-h-[38rem] overflow-auto" ref={containerRef}>
                    <TableTypeOptions
                        viewType={ShowDataType.LIST}
                        table={table}
                    />
                </Box>
            </Box>
        </Box>
    );
};
export default React.memo(ResponsePayloadKeys);
