import dagre from 'dagre';
import { useCallback, useEffect, useState } from 'react';

import ReactFlow, {
    Background,
    ConnectionLineType,
    Controls,
    MiniMap,
    ReactFlowProvider,
    type Edge,
    type Node,
    type Position,
} from 'reactflow';

import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import Page from '@components/Page/Page';
import SchemaBuilderDrawer from '@components/SchemaBuilder/Builder/SchemaBuilderDrawer';
import { getTableDetail } from '@hooks/useSchemaBuilder';
import {
    RelationPermissions,
    RelationTableType,
    type CompiledDimension,
    type RelationSchemaColumn,
} from '@lightdash/common';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useSchemaContext } from '@providers/SchemaProvider';
import { useParams } from 'react-router-dom';
import 'reactflow/dist/style.css';
import AddTableBtn from './AddTableBtn';
import CustomEdge from './CustomEdge';
import CustomNode from './CustomNode';

const nodeWidth = 375;
const nodeHeight = 36;

const nodeTypes = { customNode: CustomNode };
const edgeTypes = { customEdge: CustomEdge };

type relationData = {
    nodes: Node[];
    edges: Edge[];
};

const getLayoutedElements = (
    nodes: Node[],
    edges: Edge[],
    direction = 'LR',
) => {
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const isHorizontal = direction === 'LR';
    dagreGraph.setGraph({ rankdir: direction });

    nodes.forEach((node) => {
        dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });

    edges.forEach((edge) => {
        dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const graphWidth: number = dagreGraph.graph().width || 0;
    const graphHeight: number = dagreGraph.graph().height || 0;
    const screenWidth: number = window.innerWidth;
    const screenHeight: number = window.innerHeight;

    let newx: number = 0;
    let newy: number = 0;
    if (graphWidth && graphHeight) {
        newx = screenWidth > graphWidth ? (screenWidth - graphWidth) / 2 : 0;
        newy =
            screenHeight > graphHeight ? (screenHeight - graphHeight) / 2 : 0;
    }

    nodes.forEach((node) => {
        const nodeWithPosition = dagreGraph.node(node.id);

        node.targetPosition = (isHorizontal ? 'left' : 'top') as Position;
        node.sourcePosition = (isHorizontal ? 'right' : 'bottom') as Position;

        node.position = {
            x: nodeWithPosition.x - nodeWidth / 2 + newx,
            y: nodeWithPosition.y - nodeHeight / 2 + newy / 2,
        };
    });

    return { nodes, edges };
};

const RelationsReactFlow = () => {
    const [addTableMenu, setAddTableMenu] = useState<boolean>(false);
    const [relationData, setRelationData] = useState<
        relationData | undefined
    >();
    const { activeRelationUuid, activeRelation } = useRelationContext();
    const { activeProject, isDrawerOpen } = useSchemaContext(
        (context) => context.state,
    );
    const { addTable, editTable, setDatabase, setSchema } = useSchemaContext(
        (context) => context.actions,
    );
    const ability = useAbilityContext();
    const { user } = useApp();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const canCreateRelation = ability.can(
        'create',
        subject(RelationPermissions.Table, {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const onNodeClick = useCallback(
        async (node: any) => {
            const table = activeRelation?.tables[node.id];
            if (!table) return;

            setDatabase((activeProject?.warehouseConnection as any)?.database);
            setSchema((activeProject?.warehouseConnection as any)?.schema);
            if (!table.isConfigured) {
                const columns: RelationSchemaColumn[] = Object.values(
                    table.dimensions,
                ).map((dim: CompiledDimension) => {
                    return {
                        name: dim.name,
                        type: dim.type,
                        label: dim.label,
                        description: dim.description,
                        masked: false,
                        hidden: false,
                        cached: false,
                        cachedColumnValues: [],
                        lastSyncedAt: null,
                    };
                });

                editTable({
                    description: '',
                    ...table,
                    relationships: [],
                    columns,
                });
                return;
            }

            const tableDetail = await getTableDetail(
                activeProject?.projectUuid,
                activeRelationUuid,
                table?.name,
            );
            editTable({ ...table, ...tableDetail });
        },
        [
            activeProject,
            activeRelation?.tables,
            activeRelationUuid,
            editTable,
            setDatabase,
            setSchema,
        ],
    );

    const transformIntoReactFlowData = useCallback(
        (data: any) => {
            const position = { x: 0, y: 0 };
            const tables: Node[] = [];
            const joinedTables: Edge[] = [];

            data?.joinedTables?.forEach((element: any) => {
                const sourceTableType =
                    data?.tables[element?.source.table].type;
                if (sourceTableType === RelationTableType.AUDIENCE) return;

                const obj = {
                    id: element?.id,
                    source: element?.source.table,
                    target: element?.table,
                    label: element?.relationType,
                    type: 'customEdge',
                    animated: true,
                };
                joinedTables.push(obj);
            });

            Object.values(data?.tables)?.forEach((element: any) => {
                if (element?.type === RelationTableType.AUDIENCE) return;
                const obj = {
                    id: element?.name,
                    data: {
                        label: element?.label,
                        tableType: element?.type,
                        onClick: (node: any) => onNodeClick(node),
                    },
                    type: 'customNode',
                    position,
                };
                tables?.push(obj);
            });

            const { nodes: layoutedNodes, edges: layoutedEdges } =
                getLayoutedElements(tables, joinedTables);

            return {
                layoutedNodes,
                layoutedEdges,
            };
        },
        [onNodeClick],
    );

    useEffect(() => {
        if (activeRelation) {
            const {
                layoutedNodes: initialNodes,
                layoutedEdges: initialEdges,
            }: any =
                activeRelation && transformIntoReactFlowData(activeRelation);

            setRelationData({
                nodes: initialNodes,
                edges: initialEdges,
            });
        }
    }, [activeRelation, transformIntoReactFlowData]);

    const handleAddTableMenuClick = (val: RelationTableType) => {
        addTable(val);
        setAddTableMenu(false);
    };

    return (
        <Page title="Relations" withFullHeight>
            {canCreateRelation && (
                <AddTableBtn
                    onClick={handleAddTableMenuClick}
                    open={addTableMenu}
                    setOpen={setAddTableMenu}
                />
            )}
            <ReactFlowProvider>
                <ReactFlow
                    nodes={relationData?.nodes}
                    edges={relationData?.edges}
                    connectionLineType={ConnectionLineType.SmoothStep}
                    nodeTypes={nodeTypes}
                    edgeTypes={edgeTypes}
                    panOnScroll={true}
                    onClick={() => setAddTableMenu(false)}
                >
                    <MiniMap
                        zoomable
                        pannable
                        nodeStrokeWidth={3}
                        position="bottom-left"
                        style={{ left: '50px' }}
                    />
                    <Controls />
                    <Background
                        color="rgb(var(--color-gray-500))"
                        gap={10}
                        lineWidth={2}
                        className="bg-gray-50"
                    />
                </ReactFlow>
            </ReactFlowProvider>

            {isDrawerOpen && <SchemaBuilderDrawer />}
        </Page>
    );
};
export default RelationsReactFlow;
