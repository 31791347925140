import Select from '@components/common/Select';
import {
    type EvenTableRequiredColumnsProps,
    type PropertiesType,
} from '@components/EventsManager/types';
import { convertToMapperSchema } from '@components/EventsManager/utils';
import { useLocale } from '@hooks/useLocale';
import { type DimensionType } from '@lightdash/common';

import { useEventContext } from '@providers/EventsProvider';
import { useCallback, useMemo } from 'react';

interface EventKeySelectProps {
    column: EvenTableRequiredColumnsProps;
}

const EventKeySelect: React.FC<EventKeySelectProps> = ({ column }) => {
    const { t } = useLocale();
    const { state, actions } = useEventContext((context) => context);
    const { jsonPayloadKeys, isViewMode } = state;
    const { setJsonPayloadKeys, setMapperSchema } = actions;

    const handleChange = useCallback(
        (value: string) => {
            const newSourceKeys = jsonPayloadKeys.reduce((acc, keyObj) => {
                if (
                    keyObj.columnName === column.value &&
                    keyObj.key !== value
                ) {
                    acc.push({
                        ...keyObj,
                        columnName: '',
                        label: '',
                        reservedKey: false,
                        selectedProperty: false,
                    });
                } else if (keyObj.key === value) {
                    if (keyObj.reservedKey) {
                        acc.push(keyObj);
                    }
                    acc.push({
                        ...keyObj,
                        columnName: column.value,
                        label: column.label,
                        reservedKey: true,
                        selectedProperty: true,
                    });
                } else {
                    acc.push(keyObj);
                }
                return acc;
            }, [] as PropertiesType[]);
            const newJsonPayloadKeys = newSourceKeys.filter(
                (keyObj, index, self) =>
                    keyObj.columnName === '' ||
                    index ===
                        self.findIndex(
                            (obj) => obj.columnName === keyObj.columnName,
                        ),
            );
            setJsonPayloadKeys(newJsonPayloadKeys);
            const selectedKeys = newJsonPayloadKeys.filter(
                (keyObj) => keyObj.selectedProperty,
            );
            setMapperSchema(
                convertToMapperSchema(
                    selectedKeys.map((obj) => ({
                        label: obj.columnName,
                        type: obj.type as DimensionType,
                        required: true,
                        path: obj.key,
                    })),
                ),
            );
        },
        [column, jsonPayloadKeys, setJsonPayloadKeys, setMapperSchema],
    );

    const options = useMemo(() => {
        const uniqueKeys = [
            ...new Set(jsonPayloadKeys.map((keyObj) => keyObj.key)),
        ];
        return uniqueKeys;
    }, [jsonPayloadKeys]);
    const selectedKey = useMemo(
        () =>
            jsonPayloadKeys.find((item) => item.columnName === column.value)
                ?.key || '',
        [column.value, jsonPayloadKeys],
    );

    return (
        <Select
            label={column.label}
            data={options}
            styles={{
                wrapper: {
                    width: '15rem',
                },
            }}
            value={selectedKey}
            description={column.description}
            placeholder={column.placeholder}
            onChange={handleChange}
            searchable
            nothingFound={t('event_create.keys_select_nothing_found_text')}
            disabled={isViewMode}
            clearable
        />
    );
};

export default EventKeySelect;
