import { Group } from '@mantine/core';
import {
    AsteriskSimple,
    Function,
    type IconProps,
} from '@phosphor-icons/react';
import React from 'react';

interface TraitIconProps extends IconProps {
    showFunction?: boolean;
}

const TraitIcon = ({ showFunction = false, ...rest }: TraitIconProps) => {
    return (
        <Group className="gap-0.5 !items-center w-fit flex-nowrap">
            {showFunction && (
                <Function
                    size={14}
                    color="rgb(var(--color-orange-800))"
                    {...rest}
                />
            )}
            <AsteriskSimple
                size={14}
                color="rgb(var(--color-purple))"
                {...rest}
            />
        </Group>
    );
};

export default TraitIcon;
