import Modal from '@components/common/modal/Modal';
import Select from '@components/common/Select';
import SkeletonLoader from '@components/common/SkeletonLoader';
import { useGetChannelIntegrations } from '@hooks/useChannels';
import { useLocale } from '@hooks/useLocale';
import { useSyncWhatsappTemplates } from '@hooks/useTemplate';
import { CommunicationChannel, type Integration } from '@lightdash/common';
import { Box, Button, Flex, Loader } from '@mantine/core';
import { ArrowsClockwise } from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useMemo, useState } from 'react';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';

const TemplateSyncModal: React.FC<{
    opened: boolean;
    onClose: () => void;
}> = ({ opened, onClose }) => {
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const [selectedIntegration, setSelectedIntegration] = useState<
        string | null
    >(null);
    const { data: integrations, isLoading: isIntegrationLoading } =
        useGetChannelIntegrations(CommunicationChannel.WHATSAPP);
    const {
        mutate,
        isLoading: isSyncing,
        isSuccess: isSyncSuccess,
    } = useSyncWhatsappTemplates();
    const options = useMemo(() => {
        if (integrations) {
            return integrations.map((integration) => ({
                label: integration.customName,
                value: integration.integrationId,
            }));
        }
        return [];
    }, [integrations]);

    const handleSync = () => {
        if (integrations && selectedIntegration) {
            const { providerId } = integrations.find(
                (integration) =>
                    integration.integrationId === selectedIntegration,
            ) as Integration;
            mutate({
                providerId,
                integrationId: selectedIntegration,
            });
        }
    };

    useEffect(() => {
        if (isSyncSuccess) {
            void queryClient.invalidateQueries([
                QueryKeys.GET_SYNCED_TEMPLATES,
            ]);
            onClose();
        }
    }, [isSyncSuccess, onClose, queryClient]);

    return (
        <Modal
            opened={opened}
            title={t('template_manager.template_sync_modal_title')}
            footerRightSection={
                <Flex gap={'xs'}>
                    <Button variant={ButtonVariant.OUTLINED} onClick={onClose}>
                        {t('common.cancel')}
                    </Button>
                    <Button
                        onClick={handleSync}
                        disabled={isSyncing || !selectedIntegration}
                        leftIcon={
                            isSyncing ? (
                                <Loader
                                    size={14}
                                    color="rgb(var(--color-gray-500))"
                                />
                            ) : (
                                <ArrowsClockwise
                                    color="white"
                                    weight="regular"
                                />
                            )
                        }
                    >
                        {isSyncing ? t('common.syncing') : t('common.sync')}
                    </Button>
                </Flex>
            }
            onClose={onClose}
        >
            <Box className="w-full">
                {isIntegrationLoading ? (
                    <SkeletonLoader height={20} width={150} />
                ) : (
                    <Select
                        placeholder={t('common.select_integration')}
                        value={selectedIntegration}
                        className="!w-full"
                        withinPortal
                        data={options}
                        onChange={(value) => setSelectedIntegration(value)}
                        styles={{
                            dropdown: {
                                width: '400px !important',
                                maxWidth: '100% !important',
                            },
                            item: {
                                width: '100% !important',
                            },
                        }}
                    />
                )}
            </Box>
        </Modal>
    );
};

export default TemplateSyncModal;
