import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { BracketsCurly, PlusCircle, Warning } from '@phosphor-icons/react';
import React, { useCallback, useRef, useState } from 'react';
import AceEditor from 'react-ace';
import { ButtonVariant } from '../../../../../../../mantineTheme';
import {
    convertResponseConfigToResponseTable,
    convertResponseTableToResponseConfig,
    getResponseTable,
} from '../utils';
import ResponsePayloadKeys from './ResponsePayloadKeys';

interface ResponsePayloadMapperProps {
    onResponsePayloadMapperChange: (value: Record<string, any>) => void;
    responseConfig: Record<string, any>;
    disabled: boolean;
}

export type ResponseTableType = {
    key: string;
    type: string;
};

const ResponsePayloadMapper: React.FC<ResponsePayloadMapperProps> = ({
    responseConfig,
    onResponsePayloadMapperChange,
    disabled,
}) => {
    const { t } = useLocale();
    const [opened, { open, close }] = useDisclosure(false);
    const [responseTable, setResponseTable] = useState<ResponseTableType[]>(
        convertResponseConfigToResponseTable(responseConfig),
    );
    const editorRef = useRef<InstanceType<typeof AceEditor> | null>(null);
    const [jsonString, setJsonString] = useState<string>('');
    const [jsonStringError, setJsonStringError] = useState<string>('');
    const [borderColor, setBorderColor] = useState<string>('border-shade-4');
    const [arrayCount, setArrayCount] = useState<number>(0);
    const containerRef = useRef<HTMLDivElement>(null);

    const setResponseTableAndConfig = useCallback(
        (newResponseTable: ResponseTableType[]) => {
            setResponseTable(newResponseTable);
            onResponsePayloadMapperChange(
                convertResponseTableToResponseConfig(newResponseTable),
            );
        },
        [onResponsePayloadMapperChange],
    );

    const updateResponseTable = useCallback(
        (index: number, { key, type }: ResponseTableType) => {
            const newResponseTable = [...responseTable];
            newResponseTable[index] = { key, type };
            setResponseTableAndConfig(newResponseTable);
        },
        [responseTable, setResponseTableAndConfig],
    );

    const deleteResponseTable = useCallback(
        (index: number) => {
            const newResponseTable = [...responseTable];
            newResponseTable.splice(index, 1);
            setResponseTableAndConfig(newResponseTable);
        },
        [responseTable, setResponseTableAndConfig],
    );

    const handleJsonChange = useCallback(() => {
        try {
            JSON.parse(jsonString);
            setBorderColor('border-blu-800');
            setJsonStringError('');
        } catch (error) {
            setJsonStringError(
                t(
                    'journey_builder.call_api_response_payload_mapper_invalid_json',
                ),
            );
            setBorderColor('border-halt-800');
        }
    }, [jsonString, t]);

    return (
        <Flex direction="column" gap="xs">
            <Flex justify="space-between" align="center">
                <Text>
                    {t(
                        'journey_builder.call_api_response_payload_mapper_label',
                    )}
                </Text>
                {!disabled && (
                    <Button
                        leftIcon={
                            <BracketsCurly
                                color="rgb(var(--color-blu-800))"
                                weight="duotone"
                            />
                        }
                        variant={ButtonVariant.SUBTLE_ACCENTED}
                        onClick={open}
                    >
                        {t(
                            'journey_builder.call_api_response_payload_mapper_paste_json_btn',
                        )}
                    </Button>
                )}
            </Flex>

            <Text className="text-gray-600">
                {t(
                    'journey_builder.call_api_response_payload_mapper_description',
                )}
            </Text>
            {arrayCount > 0 && (
                <Flex
                    align="center"
                    gap={2}
                    className="p-2 border rounded-lg border-mustard-800/40"
                >
                    <Warning
                        color="rgb(var(--color-mustard-800))"
                        weight="duotone"
                    />
                    <Text className="text-warn">
                        {t(
                            'journey_builder.call_api_response_payload_mapper_array_warning',
                            {
                                number_of_array_keys: arrayCount,
                            },
                        )}
                    </Text>
                </Flex>
            )}
            {!disabled && responseTable.length === 0 ? (
                <Flex
                    className="h-[5rem] border border-gray-200 rounded-md  p-1.5"
                    justify="center"
                    align="center"
                    gap="xs"
                >
                    <Button
                        leftIcon={<PlusCircle />}
                        variant={ButtonVariant.OUTLINED}
                        onClick={() => {
                            setResponseTableAndConfig([
                                ...responseTable,
                                { key: '', type: '' },
                            ]);
                        }}
                    >
                        {t(
                            'journey_builder.call_api_response_payload_mapper_add_btn',
                        )}
                    </Button>
                    <Text className="text-gray-600">{t('common.or')}</Text>
                    <Button
                        leftIcon={<BracketsCurly />}
                        variant={ButtonVariant.OUTLINED}
                        onClick={open}
                    >
                        {t(
                            'journey_builder.call_api_response_payload_mapper_modal_title',
                        )}
                    </Button>
                </Flex>
            ) : (
                <Flex direction="column" gap="xs">
                    <ResponsePayloadKeys
                        responseTable={responseTable}
                        updateResponseTable={updateResponseTable}
                        deleteResponseTable={deleteResponseTable}
                        containerRef={containerRef}
                        disabled={disabled}
                    />
                    {!disabled && (
                        <Button
                            leftIcon={
                                <PlusCircle
                                    color="rgb(var(--color-blu-800))"
                                    weight="bold"
                                />
                            }
                            variant={ButtonVariant.OUTLINED_ACCENTED}
                            className="max-w-[9rem]"
                            onClick={() => {
                                setResponseTableAndConfig([
                                    ...responseTable,
                                    { key: '', type: '' },
                                ]);
                                if (containerRef.current) {
                                    containerRef.current.scrollTop =
                                        containerRef?.current?.scrollHeight +
                                        64;
                                }
                            }}
                        >
                            {t(
                                'journey_builder.call_api_response_payload_mapper_add_btn',
                            )}
                        </Button>
                    )}
                </Flex>
            )}
            <Modal
                opened={opened}
                onClose={close}
                title={t(
                    'journey_builder.call_api_response_payload_mapper_modal_title',
                )}
                size="xl"
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t('common.save')}
                        secondaryButtonLabel={t('common.discard')}
                        handleSecondaryButtonClick={close}
                        handlePrimaryButtonClick={() => {
                            handleJsonChange();
                            if (jsonStringError.length === 0) {
                                const {
                                    responseTable: responseTableTemp,
                                    arrayCount: arrayCountTemp,
                                } = getResponseTable(jsonString);
                                setResponseTableAndConfig(responseTableTemp);
                                setArrayCount(arrayCountTemp);
                                close();
                            }
                        }}
                        isUpdating={false}
                    />
                }
            >
                <Box
                    className={`w-full h-[31rem] border pt-1 rounded-xl overflow-hidden 
                        ${
                            jsonString !== undefined
                                ? borderColor
                                : 'border-blu-800 shadow shadow-blu-800/20'
                        } `}
                >
                    <AceEditor
                        ref={editorRef}
                        width="100%"
                        height="100%"
                        mode="json"
                        theme="textmate"
                        name="ccjson-editor"
                        onBlur={() => {
                            if (jsonString.length > 0) handleJsonChange();
                        }}
                        onChange={(newJson: string) => setJsonString(newJson)}
                        onFocus={() => {
                            setJsonStringError('');
                            setBorderColor('border-shade-4');
                        }}
                        showPrintMargin={false}
                        showGutter={true}
                        highlightActiveLine={true}
                        fontSize={14}
                        value={jsonString}
                        wrapEnabled={true}
                        setOptions={{
                            tabSize: 2,
                            lineHeight: 19,
                        }}
                    />
                </Box>
            </Modal>
        </Flex>
    );
};

export default React.memo(ResponsePayloadMapper);
