import { CampaignStatus } from '@lightdash/common';
import { Box, Loader, Text } from '@mantine/core';
import {
    CheckCircle,
    ClockCountdown,
    FileDashed,
    WarningCircle,
} from '@phosphor-icons/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface CampaignCurrentStatusProps {
    status: CampaignStatus | undefined;
    onFailureReasonClick: (() => void) | undefined;
}

const CampaignCurrentStatus: React.FC<CampaignCurrentStatusProps> = ({
    status,
    onFailureReasonClick,
}) => {
    const { t: translate } = useTranslation();

    const renderStatus = useMemo(() => {
        switch (status) {
            case CampaignStatus.COMPLETED:
                return (
                    <Box className="flex flex-row items-center justify-start gap-1">
                        <CheckCircle
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-blu-800))'}
                        />
                        <Text className="text-sm font-normal text-blu-800">
                            {translate('campaign_manager.tr_status_completed')}
                        </Text>
                    </Box>
                );

            case CampaignStatus.IN_PROGRESS:
                return (
                    <Box className="flex flex-row items-center justify-start gap-1">
                        <Loader size={14} color={'rgb(var(--color-blu-800))'} />
                        <Text className="text-sm font-normal text-blu-800">
                            {translate('campaign_manager.tr_status_progress')}
                        </Text>
                    </Box>
                );

            case CampaignStatus.READY:
                return (
                    <Box className="flex flex-row items-center justify-start gap-1">
                        <CheckCircle
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-blu-800))'}
                        />
                        <Text className="text-sm font-normal text-blu-800">
                            {translate('campaign_manager.tr_status_ready')}
                        </Text>
                    </Box>
                );

            case CampaignStatus.FAILURE:
                return (
                    <Box
                        className={`flex flex-row items-center justify-start gap-1  w-fit ${
                            onFailureReasonClick
                                ? 'hover:cursor-pointer group'
                                : ''
                        }`}
                        onClick={onFailureReasonClick}
                    >
                        <WarningCircle
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-halt-800))'}
                        />
                        <Text
                            className={`text-sm font-normal text-halt-800  ${
                                onFailureReasonClick
                                    ? 'group-hover:font-medium group-hover:decoration-dashed group-hover:underline-offset-2 underline decoration-halt-800'
                                    : ''
                            }`}
                        >
                            {translate('campaign_manager.tr_status_failed')}
                        </Text>
                    </Box>
                );

            case CampaignStatus.SCHEDULED:
                return (
                    <Box className="flex flex-row items-center justify-start gap-1">
                        <ClockCountdown
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-mustard-800))'}
                        />
                        <Text
                            className="text-sm font-normal"
                            style={{
                                color: 'rgb(var(--color-mustard-800))',
                            }}
                        >
                            {translate('journey_manager.tr_status_scheduled')}
                        </Text>
                    </Box>
                );

            case CampaignStatus.DRAFT:
                return (
                    <Box className="flex flex-row items-center justify-start gap-1">
                        <FileDashed
                            weight="duotone"
                            size={14}
                            color={'rgb(var(--color-gray-700))'}
                        />
                        <Text className="text-sm font-normal text-gray-700">
                            {translate('campaign_manager.tr_status_draft')}
                        </Text>
                    </Box>
                );

            default:
                return (
                    <Box className="flex flex-row items-center justify-start gap-1">
                        <FileDashed
                            weight="duotone"
                            size={14}
                            color={'rgb(var(--color-gray-700))'}
                        />
                        <Text className="text-sm font-normal text-gray-800">
                            {translate('campaign_manager.tr_status_draft')}
                        </Text>
                    </Box>
                );
        }
    }, [status, translate, onFailureReasonClick]);

    return <Box>{renderStatus}</Box>;
};

export default CampaignCurrentStatus;
