import { SortmentLogo } from '@assets/icons/Core';
import RouterNavLink from '@components/Router/RouterNavLink';
import { Box, Container, getDefaultZIndex, Header } from '@mantine/core';
import {
    AsteriskSimple,
    CursorClick,
    TreeStructure,
} from '@phosphor-icons/react';
import { NAVBAR_HEIGHT, PAGE_CONTENT_WIDTH } from '@utils/constants';
import React from 'react';
import { useParams } from 'react-router-dom';
import MenuItems from './MenuItems';
import WorkspaceSwitcher from './ProjectSwitcher';
import UserMenu from './UserMenu';

const activeClass = '!bg-white/30 rounded-full shadow-card';
const inactiveClass = 'hover:!bg-white/10 hover:rounded-full';
interface NavBarProps {
    showMenuItems?: boolean;
    showWorkspaceSwitcher?: boolean;
    showRelation?: boolean;
    showCustomMetric?: boolean;
    showEventsManager?: boolean;
}

export const BANNER_HEIGHT = 35;

const NavBar: React.FC<NavBarProps> = ({
    showMenuItems = true,
    showWorkspaceSwitcher = true,
    showRelation = true,
    showCustomMetric = true,
    showEventsManager = true,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return (
        <>
            <Box h={NAVBAR_HEIGHT} />
            <Header
                height={NAVBAR_HEIGHT}
                fixed
                zIndex={getDefaultZIndex('app')}
                className="flex bg-blu-800"
                withBorder={false}
            >
                <Container
                    className={`flex flex-row items-center justify-between shrink-0 px-28`}
                    style={{
                        width: `${PAGE_CONTENT_WIDTH}px`,
                        minWidth: `${PAGE_CONTENT_WIDTH}px`,
                    }}
                >
                    <div className="flex items-center gap-8">
                        <SortmentLogo color="white" />
                        {Boolean(showMenuItems) && <MenuItems />}
                    </div>
                    <div className="flex items-center gap-2.5">
                        {showEventsManager && (
                            <RouterNavLink
                                exact
                                to={`/projects/${projectUuid}/events`}
                                label=""
                                icon={
                                    <CursorClick
                                        color="white"
                                        weight="duotone"
                                    />
                                }
                                styles={{
                                    root: {
                                        padding: '0.325rem 0.325rem',
                                    },
                                    icon: {
                                        marginRight: 0,
                                    },
                                }}
                                activeclass={activeClass}
                                inactiveclass={inactiveClass}
                            />
                        )}
                        {showRelation && (
                            <RouterNavLink
                                exact
                                to={`/projects/${projectUuid}/relations`}
                                label=""
                                icon={
                                    <TreeStructure
                                        color="white"
                                        weight="duotone"
                                    />
                                }
                                styles={{
                                    root: {
                                        padding: '0.325rem 0.325rem',
                                    },
                                    icon: {
                                        marginRight: 0,
                                    },
                                }}
                                activeclass={activeClass}
                                inactiveclass={inactiveClass}
                            />
                        )}
                        {showCustomMetric && (
                            <RouterNavLink
                                exact
                                to={`/projects/${projectUuid}/custom-traits`}
                                label=""
                                icon={
                                    <AsteriskSimple
                                        color="white"
                                        weight="duotone"
                                    />
                                }
                                styles={{
                                    root: {
                                        padding: '0.325rem 0.325rem',
                                    },
                                    icon: {
                                        marginRight: 0,
                                    },
                                }}
                                activeclass={activeClass}
                                inactiveclass={inactiveClass}
                            />
                        )}
                        {showWorkspaceSwitcher && <WorkspaceSwitcher />}

                        <UserMenu />
                    </div>
                </Container>
            </Header>
        </>
    );
};

export default React.memo(NavBar);
