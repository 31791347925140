import { type IBeeConfig } from '@beefree.io/sdk/dist/types/bee';
import { unsubPreferencesUrl, unsubUrl } from '@lightdash/common';

const useBeeFreeConfig = ({
    handleSave,
}: {
    handleSave: (jsonFile: unknown, htmlFile: unknown) => void;
}) => {
    return {
        // TODO - use user uuid here
        uid: 'test1-clientside', //needed for identify resources of the that user and billing stuff
        language: 'en-US',
        sidebarPosition: 'left',
        // autosave: true,
        container: 'beefree-sdk-container',
        onSave: (jsonFile: unknown, htmlFile: unknown) => {
            // console.log('onSave', jsonFile, htmlFile);
            void handleSave(jsonFile, htmlFile);
        },
        onSaveAsTemplate: (jsonFile: unknown) => {
            console.log('onSaveAsTemplate', jsonFile);
        },
        onSend: (htmlFile: unknown) => {
            console.log('onSend', htmlFile);
        },
        onError: (errorMessage: unknown) => {
            console.log('onError ', errorMessage);
        },
        // customAttributes:{
        //   attributes: [
        //    {
        //       key: "Deeplink",
        //      value: true,
        //       target: "link"
        //     },
        //    {
        //       key: "data-segment",
        //      value: ['travel', 'luxury'],
        //       target: "link"
        //     },
        //    {
        //       key: "class",
        //       target: "tag"
        //     }
        //   ]
        // },
        specialLinks: [
            {
                type: 'Frequently used',
                label: 'Unsubscribe',
                link: unsubUrl,
            },
            {
                type: 'Frequently used',
                label: 'Unsubscribe Preferences',
                link: unsubPreferencesUrl,
            },
            /* Other special links */
        ],
        //   mergeTags: [
        //     {
        //       name: 'First Name',
        //       value: '[first-name]'
        //     }, {
        //       name: 'Latest order date',
        //       value: '[order-date]'
        //     }
        //   ],
        //   mergeContents: [
        //     {
        //       name: 'Headline news',
        //       value: '{headline}'
        //     }, {
        //       name: 'Image of last product viewed',
        //       value: '{last-product-viewed}'
        //     }
        //   ],
        //   contentDialog: {
        //     specialLinks: {
        //       label: 'Add an Example Link',
        //       handler: function(resolve, reject) {
        //         resolve({
        //           type: 'custom',
        //           label: 'external special link',
        //           link: 'http://www.example.com'
        //         })
        //       }
        //     },
        //     mergeTags: {
        //       label: 'Apply dynamic syntax',
        //       handler: function(resolve, reject) {
        //           //your function goes here
        //       }
        //   },
        //   }
    } as IBeeConfig;
};

export default useBeeFreeConfig;
