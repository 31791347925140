import Page from '@components/Page/Page';
import { getChannel } from '@components/SubscriptionGroups/utils';
import { useGetSubscriptionGroupById } from '@hooks/subscriptionGroups/useSubscriptionGroups';
import {
    useUnsubscribeGroup,
    useUserSubscriptionPreference,
} from '@hooks/subscriptionGroups/useSubscriptionPreference';
import { useLocale } from '@hooks/useLocale';
import { Box, Button, Flex, Loader, Text } from '@mantine/core';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';

const UnsubscribeGroup: React.FC = () => {
    const location = useLocation();
    const queryClient = useQueryClient();
    const searchParams = new URLSearchParams(location.search);
    // const campaign = searchParams.get('campaign') || '';
    const channel = searchParams.get('channel') || '';
    const subGroupId = searchParams.get('subGroupId') || '';
    const userId = searchParams.get('userId') || '';
    const projectId = searchParams.get('projectId') || '';
    const { t } = useLocale();

    const preferenceUrl = `/subscription-preferences/channel?channel=${channel}&userId=${userId}&projectId=${projectId}`;

    const { data: groupData } = useGetSubscriptionGroupById({
        groupId: subGroupId,
        projectId: projectId,
    });

    const {
        data: userSubscriptionPreference,
        isFetching: isUserPreferenceFetching,
    } = useUserSubscriptionPreference({
        channel: getChannel(channel),
        userId: userId,
        subGroupId: subGroupId,
    });

    const { mutateAsync: unsubscribeGroup } = useUnsubscribeGroup();

    const handleUnsubscribe = useCallback(async () => {
        await unsubscribeGroup({
            payload: {
                userId: userId,
                subscriptionGroupId: subGroupId,
                actionTaken: false,
                channel: getChannel(channel),
            },
        });
        await queryClient.invalidateQueries([
            QueryKeys.GET_USER_SUBSCRIPTION_PREFERENCE,
        ]);
    }, [userId, subGroupId, channel, unsubscribeGroup, queryClient]);

    if (isUserPreferenceFetching) {
        return (
            <Box className="w-screen h-screen flex justify-center items-center">
                <Flex className="p-3.5" align="center">
                    <Loader size={11} />
                    <Text className="text-sm font-normal text-blu-800 px-1.5">
                        {t('subscription_body.loading')}
                    </Text>
                </Flex>
            </Box>
        );
    }

    if (userSubscriptionPreference && !userSubscriptionPreference.actionTaken) {
        return (
            <Box className="w-screen h-screen flex justify-center items-center">
                <Box>
                    <Text className="text-gray-800 font-medium text-lg mb-3.5">
                        {t('unsubscribe_group.you_ve_been_unsubscribed_from')}{' '}
                        {groupData ? groupData.name : ''}
                    </Text>
                    <Text className="text-gray-600 text-sm font-medium">
                        {t(
                            'unsubscribe_group.manage_all_your_subscription_preferences',
                        )}{' '}
                        <Link
                            className=" hover:cursor-pointer underline"
                            to={preferenceUrl}
                        >
                            {t('unsubscribe_group.here')}{' '}
                        </Link>
                    </Text>
                </Box>
            </Box>
        );
    }

    return (
        <Box className="w-screen h-screen flex justify-center items-center">
            <Box>
                <Text className="text-gray-800 font-medium text-lg mb-3">
                    {t(
                        'unsubscribe_group.do_you_want_to_unsubscribe_from_these_kinds_of',
                    )}{' '}
                    {channel}{' '}
                </Text>
                <Text className="text-gray-600 text-sm font-medium mb-3.5">
                    {t('unsubscribe_group.you_will_stop_receiving')} {channel}{' '}
                    {t('unsubscribe_group.about')}{' '}
                    {groupData ? groupData.name : ''}{' '}
                </Text>
                <Button
                    onClick={() => handleUnsubscribe()}
                    className="mb-1.5 bg-black px-3 py-1.5 text-sm font-medium text-white hover:!bg-black  "
                >
                    {t('unsubscribe_group.unsubscribe')}
                </Button>
                <Text className="text-gray-600 text-sm font-medium">
                    {t(
                        'unsubscribe_group.manage_all_your_subscription_preferences',
                    )}{' '}
                    <Link
                        className="hover:cursor-pointer underline"
                        to={preferenceUrl}
                    >
                        {t('unsubscribe_group.here')}{' '}
                    </Link>
                </Text>
            </Box>
        </Box>
    );
};

const UnsubscribeGroupContainer: React.FC = () => {
    const { t } = useLocale();
    return (
        <Page title={t('subscription_group')} withFullHeight withNavbar>
            <UnsubscribeGroup />
        </Page>
    );
};

export default React.memo(UnsubscribeGroupContainer);
