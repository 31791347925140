import { useLocale } from '@hooks/useLocale';
import { Box, Card, Group, Text } from '@mantine/core';
import { CaretDoubleLeft } from '@phosphor-icons/react';
import React from 'react';

interface FlashSMSPreviewProps {
    content: string;
}

const FlashSMSPreview: React.FC<FlashSMSPreviewProps> = ({ content }) => {
    const { t } = useLocale();

    return (
        <Box className="min-w-[inherit] min-h-[inherit] bg-gray-100 rounded-[inherit] px-6 pt-12">
            <Card className="border border-gray-200 shadow-[0_0px_13px_0px_rgba(0,0,0,0.10)] bg-gray-50 rounded-xl flex flex-col">
                <Text className="text-sm font-semibold text-center text-gray-800">
                    {t('sms_template_preview.flash_sms_content_title')}
                </Text>
                <Box className="w-full py-2.5 flex items-center flex-grow transition-all duration-300">
                    {content ? (
                        <Text className="w-full text-sm text-center text-gray-800 break-words whitespace-pre-wrap">
                            {content}
                        </Text>
                    ) : (
                        <Group className="gap-1 h-[12rem]" noWrap>
                            <CaretDoubleLeft />
                            <Text className="text-sm text-gray-500">
                                {t(
                                    'sms_template_preview.normal_sms_write_message_placeholder',
                                )}
                            </Text>
                        </Group>
                    )}
                </Box>
                <Text className="text-sm font-semibold text-center text-gray-800 border-t pt-2.5 w-full">
                    Dismiss
                </Text>
            </Card>
        </Box>
    );
};

export default React.memo(FlashSMSPreview);
