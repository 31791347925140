import { useGetAllDefaultSubscriptionGroups } from '@hooks/subscriptionGroups/useSubscriptionGroups';
import { useGetReachability } from '@hooks/useAudience';
import {
    CommunicationChannel,
    type NestedMetricQueryGroup,
} from '@lightdash/common';
import { useAudienceContext } from '@providers/AudienceProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useEffect, useMemo, useState } from 'react';
import { formatNestedMetricQuery } from './utils';

const useAudienceReachabilityData = () => {
    const [res, setRes] = useState({});
    const { activeRelation, activeRelationUuid } = useRelationContext();
    const { audiencePayload } = useAudienceContext((context) => context.state);
    const { mutateAsync, isLoading } = useGetReachability({
        relationId: activeRelationUuid,
    });
    const { data: emailSubscriptionGroups, isLoading: isLoadingEmail } =
        useGetAllDefaultSubscriptionGroups({
            channel: CommunicationChannel.EMAIL,
        });

    const { data: smsSubscriptionGroups, isLoading: isLoadingSms } =
        useGetAllDefaultSubscriptionGroups({
            channel: CommunicationChannel.SMS,
        });

    const { data: whatsappSubscriptionGroups, isLoading: isLoadingWhatsapp } =
        useGetAllDefaultSubscriptionGroups({
            channel: CommunicationChannel.WHATSAPP,
        });

    const subGroupIds = useMemo(() => {
        return {
            [CommunicationChannel.EMAIL]: [emailSubscriptionGroups?.id ?? ''],
            [CommunicationChannel.SMS]: [smsSubscriptionGroups?.id ?? ''],
            [CommunicationChannel.WHATSAPP]: [
                whatsappSubscriptionGroups?.id ?? '',
            ],
        };
    }, [
        emailSubscriptionGroups,
        smsSubscriptionGroups,
        whatsappSubscriptionGroups,
    ]);

    const getReachability = async () => {
        const formattedNestedMetricQuery = formatNestedMetricQuery(
            audiencePayload.nestedMetricQuery,
        );
        const response = await mutateAsync({
            relationName: activeRelation?.name as string,
            channels: subGroupIds,
            filters: {
                metricQuery:
                    formattedNestedMetricQuery as unknown as NestedMetricQueryGroup,
            },
        });
        setRes(response);
    };

    useEffect(() => {
        if (
            !isLoadingEmail &&
            !isLoadingSms &&
            !isLoadingWhatsapp &&
            activeRelation?.name
        ) {
            void getReachability();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeRelation?.name, isLoadingEmail, isLoadingSms, isLoadingWhatsapp]);

    return {
        loading:
            isLoadingEmail || isLoadingSms || isLoadingWhatsapp || isLoading,
        reachabilityData: res as Record<string, number>,
        getReachability,
    };
};

export default useAudienceReachabilityData;
