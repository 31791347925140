import DataTable from '@components/Table';
import React, { useState } from 'react';

import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceBuilderHashParams,
    AudienceStatus,
    QueryGenerationStrategy,
    ShowDataType,
    type Audience,
    type Pagination,
} from '@lightdash/common';
import { useDisclosure } from '@mantine/hooks';
import { useHistory, useParams } from 'react-router-dom';
import SchedulersModal from '../Scheduler/SchedulerModal';
import { useAudienceColumns } from './useAudienceTableUtils';

type AudienceTableProps = HideProps & {
    audiences: Audience[] | null | undefined;
    pagination: Pagination;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    searchValue: string;
};

const AudienceTable: React.FC<AudienceTableProps> = ({
    audiences,
    pagination,
    onPageChange,
    onSearchChange,
    searchValue,
    isHiddenActive,
    onHiddenChange,
    hideHiddenButton,
}) => {
    const [modalOpen, { open: setModalOpen, close: setModalClose }] =
        useDisclosure(false);
    const [audienceData, setAudienceData] = useState<Audience>();
    const audienceColumns = useAudienceColumns(setModalOpen, setAudienceData);
    const history = useHistory();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    return (
        <div>
            {audiences && audienceColumns && (
                <DataTable<Audience>
                    name={t('audiences')}
                    tableData={audiences}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: audienceColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'audience_manager.table_search_placeholder',
                    )}
                    paginationText={t('audience_manager.pagination_text')}
                    isManualPagination={true}
                    onPageChange={onPageChange}
                    onSearchChange={onSearchChange}
                    pagination={pagination}
                    isEditable={false}
                    handleRowClick={(row) => {
                        history.push(
                            `/projects/${projectUuid}/audiences/${
                                row.original.id
                            }/${
                                row.original.status === AudienceStatus.DRAFT
                                    ? 'edit'
                                    : 'view'
                            }#${
                                row.original.generationStrategy ===
                                QueryGenerationStrategy.AUDIENCE_BUILDER
                                    ? AudienceBuilderHashParams.VISUAL
                                    : AudienceBuilderHashParams.SQL
                            }`,
                        );
                    }}
                    searchValue={searchValue}
                    leftSection={
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={onHiddenChange}
                                activeHideText={t(
                                    'audience_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                />
            )}
            <SchedulersModal
                isOpen={modalOpen}
                onClose={setModalClose}
                audienceData={audienceData}
            />
        </div>
    );
};

export default React.memo(AudienceTable);
