import { activeTabClass, tabClass } from '@components/ChannelTabs';
import { useLocale } from '@hooks/useLocale';
import { Box, Tabs } from '@mantine/core';
import { useCallback, useState } from 'react';
import ProfileActivity from './ProfileActivity';
import ProfileEngagement from './ProfileEngagement';
import { ProfilesActivityAndEngagementTabs } from './utils';
export const OUTER_CONTAINER_CLASS =
    'border border-shade-4 rounded-2xl p-0.5 bg-white';
export const INNER_CONTAINER_CLASS =
    'w-full p-0 bg-white border rounded-xl border-gray-200 ';

const ProfileActivityAndEngagement: React.FC<{}> = ({}) => {
    const { t } = useLocale();
    const [activeTab, setActiveTab] = useState(
        ProfilesActivityAndEngagementTabs.EVENT_HISTORY,
    );
    const getTabClass = useCallback(
        (isActive: boolean) =>
            isActive
                ? `${activeTabClass} rounded-lg`
                : `${tabClass} rounded-lg`,
        [],
    );

    return (
        <Box className={OUTER_CONTAINER_CLASS}>
            <Tabs
                variant="pills"
                value={activeTab}
                onTabChange={(value) =>
                    setActiveTab(value as ProfilesActivityAndEngagementTabs)
                }
            >
                <Tabs.List className="p-2">
                    <Tabs.Tab
                        value={ProfilesActivityAndEngagementTabs.EVENT_HISTORY}
                        className={getTabClass(
                            activeTab ===
                                ProfilesActivityAndEngagementTabs.EVENT_HISTORY,
                        )}
                    >
                        {t('profiles_builder.event_history')}
                    </Tabs.Tab>
                    <Tabs.Tab
                        value={ProfilesActivityAndEngagementTabs.ENGAGEMENT}
                        className={getTabClass(
                            activeTab ===
                                ProfilesActivityAndEngagementTabs.ENGAGEMENT,
                        )}
                    >
                        {t('profiles_builder.engagement')}
                    </Tabs.Tab>
                </Tabs.List>

                <Tabs.Panel
                    value={ProfilesActivityAndEngagementTabs.EVENT_HISTORY}
                    pt="xs"
                >
                    <ProfileActivity />
                </Tabs.Panel>

                <Tabs.Panel
                    value={ProfilesActivityAndEngagementTabs.ENGAGEMENT}
                    pt="xs"
                >
                    <ProfileEngagement />
                </Tabs.Panel>
            </Tabs>
        </Box>
    );
};

export default ProfileActivityAndEngagement;
