import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Timestamp from '@components/common/Timestamp';
import {
    useAudienceScheduleMutation,
    useScheduleDeleteMutation,
} from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceRunStatus,
    AudienceRunTypes,
    AudienceStatus,
    type Audience,
} from '@lightdash/common';
import { Box, Group, Text } from '@mantine/core';
import {
    CaretDown,
    Clock,
    Lightning,
    Play,
    StopCircle,
} from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import { getSchedule, stringToArray } from 'cron-converter';
import React, { useCallback, useMemo } from 'react';
import { QueryKeys } from 'types/UseQuery';

interface AudienceScheduleProps {
    audienceData: Audience;
    scheduleModalOpen: () => void;
}

const AudienceSchedule: React.FC<AudienceScheduleProps> = ({
    audienceData,
    scheduleModalOpen,
}) => {
    const { t } = useLocale();
    const queryClient = useQueryClient();
    const { mutateAsync: mutateAudienceSchedule } =
        useAudienceScheduleMutation();
    const { mutateAsync: mutateDeleteSchedule } = useScheduleDeleteMutation();

    const handleRunnow = useCallback(
        async (id: string) => {
            await mutateAudienceSchedule({
                audienceId: id,
                data: { runType: AudienceRunTypes.MANUAL },
            });

            await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
        },
        [mutateAudienceSchedule, queryClient],
    );

    const handleDeleteSchedule = useCallback(
        async (id: string) => {
            await mutateDeleteSchedule({
                audienceId: id,
            });

            await queryClient.invalidateQueries([QueryKeys.SAVED_AUDIENCE]);
        },
        [mutateDeleteSchedule, queryClient],
    );

    const runnowItem = useMemo(
        () => ({
            leftSection: <Play size={13} />,
            children: <Text>{t('audience_manager.menu_item_run')}</Text>,
            onClick: (values: Audience) => {
                return handleRunnow(values.id);
            },
        }),
        [handleRunnow, t],
    );
    const stopScheduleItem = useMemo(() => {
        if (
            audienceData.runType === AudienceRunTypes.SCHEDULED ||
            audienceData.runType === AudienceRunTypes.CRON
        )
            return {
                leftSection: <StopCircle size={13} />,
                children: t('scheduler_modal_content.turn_off_schedule'),
                onClick: (values: Audience) => {
                    return handleDeleteSchedule(values.id);
                },
            };
    }, [handleDeleteSchedule, audienceData.runType, t]);

    const changeRunScheduleItem = useMemo(() => {
        const menuItem = {
            leftSection: <Clock size={13} />,
            children:
                audienceData &&
                (audienceData.runType === AudienceRunTypes.SCHEDULED ||
                    audienceData?.runType === AudienceRunTypes.CRON)
                    ? t('audience_manager.menu_item_change_schedule')
                    : t('audience_manager.menu_item_add_schedule'),
            onClick: scheduleModalOpen,
        };

        return menuItem;
    }, [scheduleModalOpen, t, audienceData]);

    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<Audience>[] = [];
        items = [runnowItem];
        if (stopScheduleItem) {
            items.push(stopScheduleItem);
        }
        items = [...items, changeRunScheduleItem];

        return items;
    }, [runnowItem, stopScheduleItem, changeRunScheduleItem]);
    const nextRunValue = useMemo(() => {
        if (!audienceData) return null;
        if (audienceData.runType === AudienceRunTypes.SCHEDULED) {
            return (
                <Timestamp
                    customClass="text-gray-600 text-sm font-medium"
                    timestamp={`${audienceData.runAt}`}
                />
            );
        }
        if (
            audienceData.runType === AudienceRunTypes.CRON &&
            audienceData.cron
        ) {
            const arr = stringToArray(audienceData.cron);

            const schedule = getSchedule(arr, new Date());
            const { year, month, day, hour, minute, second, millisecond } =
                schedule.next().c;
            const nextCronSchedule = new Date(
                year,
                month - 1,
                day,
                hour,
                minute,
                second,
                millisecond,
            );

            if (
                audienceData.cronStartAt &&
                nextCronSchedule <= new Date(audienceData.cronStartAt)
            ) {
                return (
                    <Timestamp
                        customClass="text-gray-600 text-sm font-medium"
                        timestamp={`${audienceData.cronStartAt}`}
                    />
                );
            }
            if (
                audienceData.cronEndAt &&
                nextCronSchedule > audienceData.cronEndAt
            )
                return null;
            return (
                <Timestamp
                    customClass="text-gray-600 text-sm font-medium"
                    timestamp={`${nextCronSchedule.toISOString()}`}
                />
            );
        }
    }, [audienceData]);

    const renderAudienceDetails = useMemo(() => {
        if (!audienceData || !audienceData.runType) return null;
        return (
            <Box className="flex items-center">
                {audienceData.status !== AudienceStatus.DRAFT &&
                    audienceData.lastRunAt && (
                        <Box className="flex items-center mr-20">
                            <Lightning size={13} />
                            <Text className="text-gray-600 text-sm font-medium px-1">
                                {t('audience_manager.th_last_run')}
                            </Text>
                            <Timestamp
                                customClass="text-gray-600 text-sm font-medium"
                                timestamp={`${audienceData?.lastRunAt}`}
                            />
                            {audienceData.runType !==
                                AudienceRunTypes.MANUAL && (
                                <Text className="text-gray-600 text-sm font-medium pr-1">
                                    {t('audience_scheduler.next_run')}
                                </Text>
                            )}
                            {nextRunValue}
                        </Box>
                    )}
                <CustomMenu<Audience>
                    menuItems={menuItems}
                    data={audienceData}
                    icon={
                        <Group>
                            <Text className="pr-2">
                                {audienceData.lastRunStatus ===
                                AudienceRunStatus.RUNNING
                                    ? t('audience_scheduler.schedule')
                                    : t('audience_scheduler.run')}
                            </Text>
                            <CaretDown weight="regular" size={13} />
                        </Group>
                    }
                />
            </Box>
        );
    }, [t, audienceData, menuItems, nextRunValue]);

    return <Box>{renderAudienceDetails}</Box>;
};

export default AudienceSchedule;
