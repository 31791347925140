import { Box, Stack } from '@mantine/core';
import { CaretRight } from '@phosphor-icons/react';
import React, { useMemo } from 'react';

export interface GenericOptionProps {
    customClass?: String;
    title: string | React.ReactNode;
    subtitle?: React.ReactNode;
    rightSection?: React.ReactNode;
    iconComponent?: React.ReactNode;
    onClick?: () => void;
    border?: boolean;
    boxShadow?: boolean;
    disabled?: boolean;
}

const GenericOption: React.FC<GenericOptionProps> = ({
    customClass,
    iconComponent,
    title,
    rightSection,
    subtitle,
    onClick,
    border = true,
    boxShadow = true,
    disabled = false,
    ...rest
}) => {
    const borderClass = border ? 'rounded-lg border-base' : '';
    const boxShadowClass = boxShadow ? 'shadow-card' : '';
    const disabledClass = disabled
        ? 'opacity-50 !cursor-not-allowed pointer-event-none'
        : '';
    const optionClass = useMemo(
        () =>
            `w-[574px] p-3.5 justify-between items-center gap-[13px] inline-flex cursor-pointer hover:bg-shade-2 hover:rounded-md bg-white ${boxShadowClass} ${borderClass} ${customClass} ${disabledClass}`,
        [customClass, borderClass, boxShadowClass, disabledClass],
    );

    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    return (
        <Box className={optionClass} onClick={handleClick} {...rest}>
            <Box className="flex-row justify-center items-center gap-2.5 inline-flex ">
                {Boolean(iconComponent) && (
                    <Box className="w-8 h-8 p-1.5 border border-gray-250 rounded flex items-center justify-center">
                        {iconComponent}
                    </Box>
                )}
                <Stack className="gap-1.5">
                    <Box className="text-sm font-semibold text-gray-800">
                        {title}
                    </Box>
                    {Boolean(subtitle) && (
                        <Box className="text-xs text-gray-600">{subtitle}</Box>
                    )}
                </Stack>
            </Box>
            {Boolean(rightSection) ? (
                <div>{rightSection}</div>
            ) : (
                <CaretRight weight="duotone" />
            )}
        </Box>
    );
};

export default React.memo(GenericOption);
