import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import Timestamp from '@components/common/Timestamp';
import UserCount from '@components/common/UserCount';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useActivateJourney,
    useDeleteJourney,
    useUpdateJourneyTags,
} from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    JourneyStatus,
    type Journey,
    type JourneyAndExecutionCount,
} from '@lightdash/common';
import { Box, Flex, Loader, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowBendDownLeft,
    ArrowCounterClockwise,
    CaretRight,
    ClockCountdown,
    CopySimple,
    FileDashed,
    PauseCircle,
    PencilSimpleLine,
    Play,
    Record,
    SunHorizon,
    Trash,
    Umbrella,
    WarningCircle,
} from '@phosphor-icons/react';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonVariant } from '../../../mantineTheme';

const JOURNEY_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const JOURNEY_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;

interface JourneyMenuItemProps {
    rowData: Row<JourneyAndExecutionCount>;
    openDeactivateModal: () => void;
    setJourneyData: (data: Journey) => void;
}

const JourneyMenuItem: React.FC<JourneyMenuItemProps> = ({
    rowData,
    openDeactivateModal,
    setJourneyData,
}) => {
    const { t } = useLocale();
    const history = useHistory();
    const { showToastSuccess, showToastError } = useNotify();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateDeleteJourney } = useDeleteJourney();
    const { mutateAsync: activateJourney } = useActivateJourney();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const {
        mutateAsync: mutateAsyncUpdateJourneyTags,
        isLoading: isUpdatingJourneyTags,
    } = useUpdateJourneyTags(rowData.original.id ?? '');
    const handleClone = useCallback(
        (id: string) => {
            history.push(
                `/projects/${projectUuid}/journeys/create?templateId=${id}`,
            );
            showToastSuccess({
                title: t('journeys_clone.toast.title'),
                subtitle: t('journeys_clone.toast.subtitle'),
            });
        },
        [history, projectUuid, showToastSuccess, t],
    );

    const handleView = useCallback(
        (id: string) => {
            history.push(`/projects/${projectUuid}/journeys/${id}/view`);
        },
        [history, projectUuid],
    );

    const handleEdit = useCallback(
        (id: string) => {
            history.push(`/projects/${projectUuid}/journeys/${id}/edit`);
        },
        [history, projectUuid],
    );

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateDeleteJourney({ journeyId: id });
        },
        [mutateDeleteJourney],
    );

    const handleLaunchNow = useCallback(
        async (id: string) => {
            await activateJourney({
                data: {
                    status: JourneyStatus.ACTIVE,
                },
                uuid: id,
            });
        },
        [activateJourney],
    );

    const handleDeactivate = useCallback(
        async (values: Journey) => {
            openDeactivateModal();
            setJourneyData(values);
        },
        [openDeactivateModal, setJourneyData],
    );

    const editItem = useCallback(
        () => ({
            leftSection: (
                <PencilSimpleLine
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('audience_manager.menu_item_edit'),
            onClick: (values: Journey) => handleEdit(values.id),
        }),
        [handleEdit, t],
    );

    const viewItem = useCallback(
        () => ({
            leftSection: (
                <ArrowBendDownLeft
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('audience_manager.menu_item_view'),
            onClick: (values: Journey) => handleView(values.id),
        }),
        [handleView, t],
    );

    const cloneJourneyItem = useCallback(
        (isDivider: boolean) => ({
            leftSection: (
                <CopySimple
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_clone_journey'),
            isDivider: isDivider,
            onClick: (values: Journey) => handleClone(values.id),
        }),
        [handleClone, t],
    );

    const deactivateItem = useCallback(
        () => ({
            leftSection: (
                <PauseCircle
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_deactivate'),
            onClick: (values: Journey) => handleDeactivate(values),
        }),
        [handleDeactivate, t],
    );

    const launchNowItem = useCallback(
        () => ({
            leftSection: (
                <Play
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_launch_now'),
            onClick: (values: Journey) => handleLaunchNow(values.id),
        }),
        [handleLaunchNow, t],
    );

    const deleteItem = useCallback(
        () => ({
            leftSection: (
                <Trash
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                    weight="duotone"
                />
            ),
            children: t('journey_manager.menu_item_delete'),
            onClick: (values: Journey) => handleDelete(values.id),
        }),
        [handleDelete, t],
    );
    const hideItem = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: (
                <Text className={'text-halt-800'}>{t('common.hide')}</Text>
            ),
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItem = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async () => {
                await mutateAsyncUpdateJourneyTags(
                    {
                        tags: [
                            ...(rowData.original.tags ?? []).filter(
                                (tag) => tag !== CommonReservedTags.HIDDEN,
                            ),
                        ],
                    },
                    {
                        onSuccess: () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.journey'),
                                }),
                            });
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.journey'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            mutateAsyncUpdateJourneyTags,
            rowData.original,
            showToastError,
            showToastSuccess,
        ],
    );
    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<JourneyAndExecutionCount>[] = [];
        const status = rowData.original.status;
        switch (status) {
            case JourneyStatus.DRAFT:
                items = [editItem(), cloneJourneyItem(false), deleteItem()];
                break;
            case JourneyStatus.SCHEDULED:
                items = [
                    editItem(),
                    cloneJourneyItem(true),
                    launchNowItem(),
                    deactivateItem(),
                ];
                break;
            case JourneyStatus.ACTIVE:
                items = [viewItem(), cloneJourneyItem(true), deactivateItem()];
                break;
            //this default is for status type cancelled, but in UI cancelled status type will be visible as Stopped
            default:
                items = [viewItem(), cloneJourneyItem(true)];
                break;
        }
        if (rowData.original.tags?.includes(CommonReservedTags.HIDDEN)) {
            items.push(restoreItem);
        } else {
            items.push(hideItem);
        }
        return items;
    }, [
        cloneJourneyItem,
        deactivateItem,
        deleteItem,
        editItem,
        launchNowItem,
        rowData.original.status,
        viewItem,
        hideItem,
        restoreItem,
        rowData.original.tags,
    ]);
    return (
        <>
            <CustomMenu<JourneyAndExecutionCount>
                menuItems={menuItems}
                data={rowData.original}
            />
            <Modal
                opened={openedHideConfirmModal}
                onClose={closeHideConfirmModal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>{t('journeys_table.hide_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData.original.name}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t(
                            'journeys_table.hide_modal_confirm_button',
                        )}
                        secondaryButtonLabel={t('common.no')}
                        isUpdating={isUpdatingJourneyTags}
                        handlePrimaryButtonClick={async () => {
                            await mutateAsyncUpdateJourneyTags(
                                {
                                    tags: [
                                        ...(rowData.original.tags ?? []),
                                        CommonReservedTags.HIDDEN,
                                    ],
                                },
                                {
                                    onSuccess: () => {
                                        showToastSuccess({
                                            title: t('common.hidden_success', {
                                                entityName: t('common.journey'),
                                            }),
                                        });
                                    },
                                    onError: (error) => {
                                        showToastError({
                                            title: t('common.hidden_error', {
                                                entityName: t('common.journey'),
                                            }),
                                            subtitle: error.error.message,
                                        });
                                    },
                                },
                            );
                            closeHideConfirmModal();
                        }}
                        handleSecondaryButtonClick={closeHideConfirmModal}
                        primaryButtonVariant={ButtonVariant.FILLED_DESTRUCTIVE}
                        primaryButtonRightIcon={<CaretRight color="white" />}
                    />
                }
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
            >
                <Flex direction={'column'} gap={12}>
                    <Text className="font-medium text-gray-800 text-sm">
                        {t('journeys_table.hide_modal_confirm_header')}
                    </Text>
                </Flex>
            </Modal>
        </>
    );
};

export function useJourneyColumns(
    openDeactivateModal: () => void,
    setJourneyData: (data: Journey) => void,
) {
    const [columns, setColumns] =
        useState<ColumnDef<JourneyAndExecutionCount>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('journey_manager.th_journey_name'),
                    cell: ({ row, getValue }) => (
                        <Box className={JOURNEY_TABLE_CELL_CLASSNAME}>
                            <Flex align={'center'} gap={6}>
                                {row.original.tags?.includes(
                                    CommonReservedTags.HIDDEN,
                                ) && <Archive weight="duotone" />}
                                <Text className={JOURNEY_TABLE_CELL_NAME}>
                                    {row.original.status ===
                                        JourneyStatus.DRAFT && (
                                        <span className="text-pink-800">
                                            {t(
                                                'audience_manager.tr_audience_status_draft',
                                            )}{' '}
                                        </span>
                                    )}

                                    {getValue<string>()}
                                </Text>
                            </Flex>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'scheduledAt',
                    header: t('campaign_manager.th_status'),
                    cell: ({ row }) => {
                        const { original } = row;
                        const status = original.status;

                        let content;

                        switch (status) {
                            case JourneyStatus.DRAFT:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <FileDashed
                                            size={14}
                                            color={'rgb(var(--color-gray-600))'}
                                            weight="duotone"
                                        />
                                        <Text className="text-sm font-normal text-gray-600">
                                            {t(
                                                'journey_manager.tr_status_draft',
                                            )}
                                        </Text>
                                    </Box>
                                );
                                break;
                            case JourneyStatus.SCHEDULED:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <ClockCountdown
                                            color={
                                                'rgb(var(--color-mustard-800))'
                                            }
                                            size={14}
                                            weight="duotone"
                                        />
                                        <Text className="text-sm font-normal text-mustard-800">
                                            {t(
                                                'journey_manager.tr_status_scheduled',
                                            )}
                                        </Text>
                                    </Box>
                                );
                                break;
                            case JourneyStatus.ACTIVATION_FAILED:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <WarningCircle
                                            size={14}
                                            weight="duotone"
                                            color={'rgb(var(--color-halt-800))'}
                                        />
                                        <Text className="text-sm font-normal text-halt-800">
                                            {t(
                                                'campaign_manager.tr_status_failed',
                                            )}
                                        </Text>
                                    </Box>
                                );
                                break;
                            case JourneyStatus.ACTIVATING:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <Loader
                                            size={14}
                                            color={'rgb(var(--color-blu-800))'}
                                        />
                                        <Text className="text-sm font-normal text-blu-800">
                                            {t(
                                                'journey_manager.tr_status_activating',
                                            )}
                                        </Text>
                                    </Box>
                                );
                                break;
                            case JourneyStatus.ACTIVE:
                                const endDate =
                                    original.endDate &&
                                    new Date(original.endDate);
                                const currentDate = new Date();
                                if (endDate && endDate < currentDate) {
                                    content = (
                                        <Box className="flex flex-col justify-start">
                                            <Box className="flex flex-row items-center justify-start gap-1">
                                                <SunHorizon
                                                    color={
                                                        'rgb(var(--color-orange-800))'
                                                    }
                                                    size={14}
                                                    weight="duotone"
                                                />
                                                <Text className="text-sm font-normal text-orange-800">
                                                    {t(
                                                        'journey_manager.tr_status_sunset',
                                                    )}
                                                </Text>
                                            </Box>
                                            <Text className="text-gray-500 text-xs font-normal mt-0.5">
                                                {`${t(
                                                    'journey_manager.tr_status_sunset_last',
                                                )} ${
                                                    row.original
                                                        .currentExecutions
                                                } ${t(
                                                    'journey_manager.tr_status_sunset_users',
                                                )} `}
                                            </Text>
                                        </Box>
                                    );
                                    break;
                                }

                                content = (
                                    <Box className="flex flex-col justify-start">
                                        <Box className="flex flex-row items-center justify-start gap-1">
                                            <Record
                                                color={
                                                    'rgb(var(--color-blu-800))'
                                                }
                                                size={14}
                                                weight="duotone"
                                            />
                                            <Text className="text-sm font-normal text-blu-800">
                                                {t(
                                                    'journey_manager.tr_status_active',
                                                )}
                                            </Text>
                                        </Box>
                                        {!!row.original.currentExecutions && (
                                            <Text className="text-gray-500 text-xs font-normal mt-0.5">
                                                {`${
                                                    row.original
                                                        .currentExecutions
                                                } ${t(
                                                    'journey_manager.tr_status_sunset_users',
                                                )}`}
                                            </Text>
                                        )}
                                    </Box>
                                );
                                break;
                            //the default one is for cancelled state
                            default:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <Umbrella
                                            color={'rgb(var(--color-purple))'}
                                            size={14}
                                            weight="duotone"
                                        />
                                        <Text className="text-sm font-normal text-purple">
                                            {t(
                                                'journey_manager.tr_status_cancelled',
                                            )}
                                        </Text>
                                    </Box>
                                );
                        }
                        return <Box>{content}</Box>;
                    },
                },
                {
                    accessorKey: 'totalExecutions',
                    header: t('journey_manager.th_total_entered'),
                    cell: ({ getValue }) => {
                        return (
                            <Box className="text-sm text-gray-800">
                                {getValue<string>() ? (
                                    <UserCount
                                        withLeftSection={false}
                                        count={getValue<string>()}
                                        withRightSection={false}
                                        withApproximation={false}
                                    />
                                ) : (
                                    '-'
                                )}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'convertedExecutions',
                    header: t('journey_manager.th_total_converted'),
                    cell: ({ getValue, row }) => {
                        return (
                            <Box className="text-sm text-gray-800">
                                {getValue<string>() &&
                                row.original.totalExecutions
                                    ? `${(
                                          (Number(getValue<string>()) /
                                              row.original.totalExecutions) *
                                          100
                                      ).toFixed(2)}%`
                                    : '-'}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    row.original.createdBy?.firstName || ''
                                } ${row.original.createdBy?.lastName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('journey_manager.th_journey_last_modified'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {row.original.updatedBy &&
                                    row.original.updatedBy.firstName &&
                                    `by ${
                                        row.original.updatedBy?.firstName || ''
                                    } ${
                                        row.original.updatedBy?.lastName || ''
                                    }`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <JourneyMenuItem
                            openDeactivateModal={openDeactivateModal}
                            setJourneyData={setJourneyData}
                            rowData={row}
                        />
                    ),
                },
            ]);
        }
    }, [openDeactivateModal, projectUuid, setJourneyData, t]);

    return columns;
}
