import { sortmentApi } from '@api/index';
import {
    JourneyStatus,
    type ApiError,
    type ApiResponse,
    type ApiSqlQueryResults,
    type Journey,
    type JourneyAndExecutionCountList,
    type JourneyBlocksList,
    type JourneyCreatePayload,
    type JourneyDataSchema,
    type JourneyEventMapperSchema,
    type JourneyNodeDescription,
    type JourneyNodeDescriptionRequest,
    type JourneyPublishConfig,
    type JourneyTagsUpdateRequest,
} from '@lightdash/common';

import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';
import useQueryError from './useQueryError';

const getJourneyBlocksQuery = async (projectUuid: string) =>
    sortmentApi<JourneyBlocksList>({
        url: `/projects/${projectUuid}/journeys/blocks`,
        method: 'GET',
        body: undefined,
    });

export const useGetJourneyBlocks = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const setErrorResponse = useQueryError();
    const queryKey = [QueryKeys.GET_JOURNEY_BLOCKS, projectUuid];
    return useQuery<JourneyBlocksList, ApiError>({
        queryKey,
        queryFn: () => getJourneyBlocksQuery(projectUuid),
        enabled: !!projectUuid,
        onError: (result) => setErrorResponse(result),
        retry: false,
    });
};

const getJourneys = async (
    perPage: number,
    currentPage: number,
    projectId: string,
    query: string,
) =>
    sortmentApi<JourneyAndExecutionCountList>({
        url: `/projects/${projectId}/journeys?perPage=${perPage}&currentPage=${currentPage}&${query}`,
        method: 'GET',
        body: undefined,
    });

interface UseJourneysProps {
    perPage: number;
    currentPage: number;
    query: string | undefined;
}

export const useJourneys = ({
    perPage,
    currentPage,
    query,
}: UseJourneysProps) => {
    const params = useParams<{ projectUuid?: string }>();
    const journeys = useQuery<JourneyAndExecutionCountList, ApiError>({
        queryKey: [QueryKeys.GET_JOURNEYS, perPage, currentPage, query],
        queryFn: () =>
            getJourneys(
                perPage,
                currentPage,
                params?.projectUuid || '',
                query || '',
            ),
        keepPreviousData: true,
    });
    return journeys;
};

const createJourney = async (projectUuid: string, data: JourneyCreatePayload) =>
    sortmentApi<Journey>({
        url: `/projects/${projectUuid}/journeys`,
        method: 'POST',
        body: JSON.stringify(data),
    });

export const useCreateJourney = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const response = useMutation<Journey, ApiError, JourneyCreatePayload>(
        (payload: JourneyCreatePayload) => createJourney(projectUuid, payload),
        {
            mutationKey: [QueryKeys.CREATE_JOURNEY],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('journeys_hook.your_journey_has_been_saved'),
                });
            },
            onError: () => {
                showToastError({
                    title: t('journeys_hook.something_went_wrong'),
                    subtitle: t('journeys_hook.please_try_again'),
                });
            },
        },
    );
    return response;
};

const getJourneyById = async (journeyId: string, projectId: string) =>
    sortmentApi<Journey>({
        url: `/projects/${projectId}/journeys/${journeyId}`,
        method: 'GET',
        body: undefined,
    });

export const useGetJourneyById = (journeyId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const data = useQuery<Journey, ApiError>({
        queryKey: [QueryKeys.GET_JOURNEY_BY_ID, journeyId],
        queryFn: () => getJourneyById(journeyId, projectUuid),
        enabled: !!journeyId && !!projectUuid,
        cacheTime: 0,
    });
    return data;
};

const updateJourney = async (
    data: Partial<Journey>,
    projectId: string,
    journeyId: string,
) =>
    sortmentApi<Journey>({
        url: `/projects/${projectId}/journeys/${journeyId}`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateJourney = (journeyId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();
    return useMutation<Journey, ApiError, Partial<Journey>>(
        (payload: Partial<Journey>) =>
            updateJourney(payload, projectUuid, journeyId),
        {
            mutationKey: [QueryKeys.UPDATE_JOURNEY, journeyId, projectUuid],
            onSuccess: async () => {
                showToastSuccess({
                    title: t('journeys_hook.your_journey_has_been_updated'),
                });
                await queryClient.invalidateQueries([QueryKeys.GET_JOURNEYS]);
            },
            onError: () => {
                showToastError({
                    title: t('journeys_hook.something_went_wrong'),
                    subtitle: t('journeys_hook.please_try_again'),
                });
            },
        },
    );
};

const getJourneyNodeParams = async (
    projectUuid: string,
    nodeId: string,
    payload: JourneyCreatePayload,
) =>
    sortmentApi<JourneyDataSchema>({
        url: `/projects/${projectUuid}/journeys/journey-node-params/${nodeId}`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useGetJourneyNodeParams = (nodeId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return useMutation<JourneyDataSchema, ApiError, JourneyCreatePayload>(
        (payload: JourneyCreatePayload) =>
            getJourneyNodeParams(projectUuid, nodeId, payload),
        {
            mutationKey: [
                QueryKeys.GET_JOURNEY_NODE_PARAMS,
                projectUuid,
                nodeId,
            ],
        },
    );
};

const triggerJourney = async (projectUuid: string, journeyId: string) =>
    sortmentApi<ApiResponse>({
        url: `/projects/${projectUuid}/journeys/${journeyId}/trigger-journey`,
        method: 'GET',
        body: undefined,
    });

export const useTriggerJourney = (journeyId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const data = useQuery<ApiResponse, ApiError>({
        queryKey: [QueryKeys.GET_JOURNEY_BY_ID, journeyId],
        queryFn: () => triggerJourney(projectUuid, journeyId),
        enabled: !!journeyId && !!projectUuid,
    });
    return data;
};

const getJourneyEvents = async (projectUuid: string) =>
    sortmentApi<JourneyEventMapperSchema[]>({
        url: `/projects/${projectUuid}/journeys/events`,
        method: 'GET',
        body: undefined,
    });

export const useGetJourneyEvents = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const data = useQuery<JourneyEventMapperSchema[], ApiError>({
        queryKey: [QueryKeys.GET_JOURNEY_EVENTS, projectUuid],
        queryFn: () => getJourneyEvents(projectUuid),
        enabled: !!projectUuid,
    });
    return data;
};

interface activateJourneyPayload {
    data: JourneyPublishConfig;
    uuid: string;
}

const deactivateJourney = async (
    projectUuid: string,
    journeyId: string,
    payload: JourneyPublishConfig,
) =>
    sortmentApi<Journey>({
        url: `/projects/${projectUuid}/journeys/${journeyId}/schedule`,
        method: 'PUT',
        body: JSON.stringify(payload),
    });

export const useDeactivateJourney = () => {
    const queryClient = useQueryClient();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<Journey, ApiError, activateJourneyPayload>(
        (payload: activateJourneyPayload) =>
            deactivateJourney(projectUuid, payload.uuid, payload.data),
        {
            mutationKey: [QueryKeys.DEACTIVATE_JOURNEY, projectUuid],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([
                    QueryKeys.GET_JOURNEY_BY_ID,
                    data.id,
                ]);
                if (data.status === JourneyStatus.DRAFT)
                    showToastSuccess({
                        title: t(
                            'journeys_hook.your_journey_has_been_deactivated',
                        ),
                        subtitle: t(
                            'journeys_hook.journey_has_been_deactivated_subtitle',
                        ),
                    });
                if (data.status === JourneyStatus.CANCELED)
                    showToastSuccess({
                        title: t('journeys_hook.journey_has_been_stopped'),
                        subtitle: t(
                            'journeys_hook.journey_has_been_stopped_subtitle',
                        ),
                    });
                if (data.status === JourneyStatus.ACTIVE) {
                    if (data.endDate && new Date(data.endDate) <= new Date()) {
                        showToastSuccess({
                            title: t('journeys_hook.journey_has_been_sunset'),
                            subtitle: t(
                                'journeys_hook.journey_has_been_sunset_subtitle',
                            ),
                        });
                    }
                }
            },
            onError: () => {
                showToastError({
                    title: t('journeys_hook.something_went_wrong'),
                    subtitle: t('journeys_hook.please_try_again'),
                });
            },
        },
    );
};

const activateJourney = async (
    projectUuid: string,
    journeyId: string,
    payload: JourneyPublishConfig,
) =>
    sortmentApi<Journey>({
        url: `/projects/${projectUuid}/journeys/${journeyId}/schedule`,
        method: 'POST',
        body: JSON.stringify(payload),
    });

export const useActivateJourney = () => {
    const queryClient = useQueryClient();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<Journey, ApiError, activateJourneyPayload>(
        (payload: activateJourneyPayload) =>
            activateJourney(projectUuid, payload.uuid, payload.data),
        {
            mutationKey: [QueryKeys.ACTIVATE_JOURNEY, projectUuid],
            onSuccess: async (data) => {
                await queryClient.invalidateQueries([QueryKeys.GET_JOURNEYS]);
                if (data.status === JourneyStatus.DRAFT)
                    showToastSuccess({
                        title: t(
                            'journeys_hook.your_journey_has_been_deactivated',
                        ),
                        subtitle: t(
                            'journeys_hook.journey_has_been_deactivated_subtitle',
                        ),
                    });
                if (data.status === JourneyStatus.CANCELED)
                    showToastSuccess({
                        title: t('journeys_hook.journey_has_been_stopped'),
                        subtitle: t(
                            'journeys_hook.journey_has_been_stopped_subtitle',
                        ),
                    });
                if (data.status === JourneyStatus.ACTIVE) {
                    if (data.endDate && new Date(data.endDate) <= new Date()) {
                        showToastSuccess({
                            title: t('journeys_hook.journey_has_been_sunset'),
                            subtitle: t(
                                'journeys_hook.journey_has_been_sunset_subtitle',
                            ),
                        });
                        return;
                    }
                    showToastSuccess({
                        title: t(
                            'journeys_hook.your_journey_has_been_launched',
                        ),
                    });
                }
                if (data.status === JourneyStatus.SCHEDULED)
                    showToastSuccess({
                        title: t(
                            'journeys_hook.your_journey_has_been_scheduled',
                        ),
                    });
            },
            onError: () => {
                showToastError({
                    title: t('journeys_hook.something_went_wrong'),
                    subtitle: t('journeys_hook.please_try_again'),
                });
            },
        },
    );
};

const deleteJourney = async (journeyId: string, projectUuid: string) =>
    sortmentApi<ApiResponse<Journey>>({
        url: `/projects/${projectUuid}/journeys/${journeyId}`,
        method: 'DELETE',
        body: undefined,
    });

export const useDeleteJourney = () => {
    const queryClient = useQueryClient();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess, showToastError } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiResponse<Journey>, ApiError, { journeyId: string }>(
        ({ journeyId }) => deleteJourney(journeyId, projectUuid), // Removed extra parentheses
        {
            mutationKey: [QueryKeys.DELETE_JOURNEY],
            onSuccess: async () => {
                await queryClient.invalidateQueries([QueryKeys.GET_JOURNEYS]);
                showToastSuccess({
                    title: t('journey.delete_success'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('journey.delete_error'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

const updateJourneyTags = async (
    projectUuid: string,
    journeyId: string,
    payload: JourneyTagsUpdateRequest,
) =>
    sortmentApi<Journey>({
        url: `/projects/${projectUuid}/journeys/${journeyId}/tags`,
        method: 'PATCH',
        body: JSON.stringify(payload),
    });

export const useUpdateJourneyTags = (journeyId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const query = useQueryClient();

    const response = useMutation<Journey, ApiError, JourneyTagsUpdateRequest>(
        (payload: JourneyTagsUpdateRequest) =>
            updateJourneyTags(projectUuid, journeyId, payload),
        {
            mutationKey: [
                QueryKeys.UPDATE_JOURNEY_TAGS,
                journeyId,
                projectUuid,
            ],
            onSuccess: async () => {
                await query.invalidateQueries([QueryKeys.GET_JOURNEYS]);
            },
        },
    );
    return response;
};

const generateNodeDescription = (
    projectUuid: string,
    payload: JourneyNodeDescriptionRequest,
) => {
    return sortmentApi<JourneyNodeDescription>({
        url: `/projects/${projectUuid}/ai/journeys/node-description`,
        method: 'POST',
        body: JSON.stringify(payload),
    });
};

export const useGenerateNodeDescription = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useMutation<
        JourneyNodeDescription,
        ApiError,
        JourneyNodeDescriptionRequest
    >({
        mutationKey: [QueryKeys.GENERATE_NODE_DESCRIPTION, projectUuid],
        mutationFn: (body) => generateNodeDescription(projectUuid, body),
    });
};

const getJourneyAnalytics = async (projectUuid: string, journeyId: string) =>
    sortmentApi<ApiSqlQueryResults>({
        url: `/projects/${projectUuid}/journeys/${journeyId}/analytics`,
        method: 'POST',
        body: undefined,
    });

export const useGetJourneyAnalytics = (journeyId: string) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    return useMutation<ApiSqlQueryResults, ApiError>(
        () => getJourneyAnalytics(projectUuid, journeyId),
        {
            mutationKey: [
                QueryKeys.GET_JOURNEY_ANALYTICS,
                projectUuid,
                journeyId,
            ],
        },
    );
};
