const BorderBottomLeftRadius = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.2"
                d="M11 2C11.5523 2 12 2.44772 12 3V11C12 11.5523 11.5523 12 11 12H3C2.44772 12 2 11.5523 2 11V3C2 2.44772 2.44772 2 3 2H11Z"
                fill="#9B9B9B"
            />
            <path
                d="M1.76982 13L5.50566 12.9995C5.60776 12.9995 5.70568 12.96 5.77787 12.8897C5.85007 12.8194 5.89062 12.724 5.89062 12.6245C5.89062 12.5251 5.85007 12.4297 5.77787 12.3593C5.70568 12.289 5.60776 12.2495 5.50566 12.2495L2.77 12.2499C2.21765 12.2499 1.76984 11.8022 1.76987 11.2498L1.77002 8.37451C1.77002 8.27506 1.72946 8.17967 1.65727 8.10935C1.58507 8.03902 1.48715 7.99951 1.38506 7.99951C1.28296 7.99951 1.18504 8.03902 1.11284 8.10935C1.04065 8.17967 1.00009 8.27506 1.00009 8.37451L0.999889 12.25C0.999889 12.4489 1.08101 12.6397 1.2254 12.7803C1.36979 12.921 1.56562 13 1.76982 13Z"
                fill="#343330"
            />
        </svg>
    );
};

export default BorderBottomLeftRadius;
