import { useFiltersContext } from '@components/Audience/Filters/FiltersProvider';
import { useFilterFields } from '@hooks/useFilterFields';
import { useLocale } from '@hooks/useLocale';
import {
    getFilterRulesByFieldType,
    getTotalFilterRules,
    type AdditionalMetric,
    type Field,
    type Filters,
} from '@lightdash/common';
import {
    ActionIcon,
    Box,
    Button,
    Divider,
    Group,
    Menu,
    Text,
} from '@mantine/core';
import {
    CopySimple,
    DotsThree,
    PlusCircle,
    UsersThree,
    X,
} from '@phosphor-icons/react';
import { useAudienceContext } from '@providers/AudienceProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { sortRelationFields } from '@utils/relation';
import { useCallback, type FC } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import FilterFormPropertySelect from './FilterFormPropertySelect';
import FilterGroupForm from './FilterGroupForm';

type Props = {
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    isEditMode: boolean;
    index: number;
    additionalMetrics: AdditionalMetric[] | undefined;
    setAdditionalMetrics: (
        additionalMetrics: AdditionalMetric[],
        shouldFetchResults: boolean,
        index: number,
    ) => void;
};

const FiltersForm: FC<Props> = ({
    filters,
    setFilters,
    isEditMode,
    index,
    additionalMetrics,
    setAdditionalMetrics,
}) => {
    const { activeRelation } = useRelationContext();
    const { audienceMap } = useFiltersContext();
    const { t } = useLocale();
    const filterGroups = useAudienceContext(
        (context) => context.state.unsavedChartVersion,
    );
    const filterGroup = filterGroups[index];

    const { addUnsavedAudienceFilter, removeUnsavedAudienceFilter } =
        useAudienceContext((context) => context.actions);
    const { fields, dimensions, metrics, events } = useFilterFields();

    const totalFilterRules = getTotalFilterRules(filters);
    const { valid: validFilterRulesPerType } = getFilterRulesByFieldType(
        fields as Field[],
        totalFilterRules,
    );

    const duplicateFilterGroup = useCallback(() => {
        addUnsavedAudienceFilter(filterGroup, index);
    }, [filterGroup, addUnsavedAudienceFilter, index]);

    const removeFilterGroup = useCallback(() => {
        removeUnsavedAudienceFilter(index);
    }, [removeUnsavedAudienceFilter, index]);

    return (
        <Box className="p-3.5 border-base shadow-card ">
            <Group className="flex justify-between">
                <Group className="gap-1.5">
                    <UsersThree size={14} />
                    <Text className="text-sm font-medium text-gray-600 uppercase">
                        {t('audiences.all_users')}
                    </Text>
                </Group>
                {isEditMode && (
                    <Group className="gap-1">
                        <Menu
                            position="bottom-end"
                            shadow="md"
                            closeOnItemClick
                        >
                            <Menu.Target>
                                <ActionIcon
                                    variant={ButtonVariant.UNSTYLED}
                                    className="rounded-lg hover:bg-shade-4"
                                >
                                    <DotsThree
                                        color={'rgb(var(--color-gray-700))'}
                                        weight="bold"
                                    />
                                </ActionIcon>
                            </Menu.Target>

                            <Menu.Dropdown>
                                <Menu.Item
                                    onClick={duplicateFilterGroup}
                                    className="hover:bg-shade-4"
                                >
                                    <Group className="gap-1.5 font-medium text-gray-800 text-sm">
                                        <CopySimple
                                            weight="duotone"
                                            color={'rgb(var(--color-gray-800))'}
                                        />
                                        <Text>
                                            {t(
                                                'audience_builder.duplicate_group',
                                            )}
                                        </Text>
                                    </Group>
                                </Menu.Item>
                            </Menu.Dropdown>
                        </Menu>

                        {filterGroups.length > 1 && (
                            <ActionIcon
                                variant={ButtonVariant.UNSTYLED}
                                className="rounded-lg hover:bg-shade-4"
                                onClick={removeFilterGroup}
                            >
                                <X
                                    size={14}
                                    color={'rgb(var(--color-gray-700))'}
                                    weight="bold"
                                />
                            </ActionIcon>
                        )}
                    </Group>
                )}
            </Group>

            {totalFilterRules.length >= 1 && (
                <>
                    <Box
                        style={{ position: 'relative' }}
                        className="flex flex-col gap-6 mt-2"
                    >
                        {filters.dimensions &&
                            validFilterRulesPerType.dimensions.length >= 1 && (
                                <FilterGroupForm
                                    allowConvertToGroup
                                    hideLine
                                    hideButtons
                                    conditionLabel="dimension"
                                    filterGroup={filters.dimensions}
                                    fields={sortRelationFields([
                                        ...dimensions,
                                        ...audienceMap,
                                    ])}
                                    isEditMode={isEditMode}
                                    onChange={(value) =>
                                        setFilters(
                                            {
                                                ...filters,
                                                dimensions: value,
                                            },
                                            false,
                                            index,
                                        )
                                    }
                                    onDelete={() => {
                                        if (filterGroups.length <= 1)
                                            setFilters(
                                                {
                                                    ...filters,
                                                    dimensions: undefined,
                                                },
                                                true,
                                                index,
                                            );
                                        else removeFilterGroup();
                                    }}
                                    filters={filters}
                                    setFilters={setFilters}
                                    groupIndex={index}
                                    relation={activeRelation}
                                    additionalMetrics={additionalMetrics}
                                    setAdditionalMetrics={setAdditionalMetrics}
                                />
                            )}
                        {filters.dimensions &&
                            validFilterRulesPerType.dimensions.length >= 1 &&
                            filters.metrics &&
                            validFilterRulesPerType.metrics.length >= 1 && (
                                <Group className="flex flex-row gap-1 flex-nowrap">
                                    <Divider className="w-6 border-t-2 border-t-shade-6" />
                                    <Text className="text-sm text-gray-500 uppercase">
                                        and
                                    </Text>
                                    <Divider className="w-full border-t-2 border-t-shade-6" />
                                </Group>
                            )}
                        {filters.metrics &&
                            validFilterRulesPerType.metrics.length >= 1 && (
                                <FilterGroupForm
                                    filters={filters}
                                    setFilters={setFilters}
                                    isEditMode={isEditMode}
                                    additionalMetrics={additionalMetrics}
                                    setAdditionalMetrics={setAdditionalMetrics}
                                    conditionLabel="metric"
                                    fields={[...metrics, ...events]}
                                    filterGroup={filters.metrics}
                                    onChange={(value) =>
                                        setFilters(
                                            {
                                                ...filters,
                                                metrics: value,
                                            },
                                            false,
                                            index,
                                        )
                                    }
                                    onDelete={() => {
                                        if (filterGroups.length <= 1)
                                            setFilters(
                                                {
                                                    ...filters,
                                                    metrics: undefined,
                                                },
                                                true,
                                                index,
                                            );
                                        else removeFilterGroup();
                                    }}
                                    groupIndex={index}
                                    relation={activeRelation}
                                />
                            )}
                    </Box>
                </>
            )}
            {isEditMode && (
                <Box
                    className={`px-0 py-3  border-none ${
                        totalFilterRules.length >= 1 ? 'ml-0' : ''
                    }`}
                >
                    <FilterFormPropertySelect
                        filters={filters}
                        setFilters={setFilters}
                        index={index}
                        additionalMetrics={additionalMetrics}
                        setAdditionalMetrics={setAdditionalMetrics}
                        targetButton={
                            <Button
                                variant={ButtonVariant.SUBTLE}
                                leftIcon={
                                    <PlusCircle
                                        color={'rgb(var(--color-gray-700))'}
                                    />
                                }
                                className="!font-semibold"
                            >
                                {t(
                                    'custom_metric.dimension_modal.filter_label',
                                )}
                            </Button>
                        }
                    />
                </Box>
            )}
        </Box>
    );
};

export default FiltersForm;
