import {
    type FieldsWithSuggestions,
    type FieldWithSuggestions,
} from '@components/Audience/Filters/FiltersProvider';
import {
    DimensionType,
    getItemId,
    type CompiledDimension,
    type ConditionalOperator,
    type CustomDimensionType,
    type CustomMaterialisation,
    type InsertCustomer360Materialisation,
    type MetricType,
    type TableCalculationType,
} from '@lightdash/common';
import { type tableRelationProps } from '@providers/RelationProvider';
import { v4 as uuidv4 } from 'uuid';

interface CreatePrfofilePayloadProps {
    projectUuid: string;
    relationUuid: string;
    newColumns?: string[];
    cron?: string;
}
export type ProfileSearchType = Record<
    string,
    { type: string; value: unknown }
>;
export const formatProfileData = ({
    projectUuid,
    relationUuid,
    newColumns,
    cron,
}: CreatePrfofilePayloadProps): InsertCustomer360Materialisation => {
    return {
        projectUuid: projectUuid,
        relationId: relationUuid,
        metricQuery: {
            limit: -1,
            sorts: [],
            filters: {},
            metrics: [],
            dimensions: newColumns?.filter(
                (column) => column !== '',
            ) as string[],
            exploreName: '',
            additionalMetrics: [],
            tableCalculations: [],
        },
        incrementalTimestamp: undefined,
        cron: cron ? cron : undefined,
        sqlQuery: '',
        primaryKey: undefined,
        cronStartAt: undefined,
        cronEndAt: undefined,
        isFilterable: undefined,
        queryableDimensions: undefined,
    };
};

export const toFieldsWithSuggestions = (
    relations: tableRelationProps[] | undefined,
    fieldsWithSuggestions: FieldsWithSuggestions,
): FieldsWithSuggestions => {
    const fields: FieldsWithSuggestions = {};
    if (relations)
        relations.forEach((table) => {
            table.dimensions.forEach((dimension: CompiledDimension) => {
                const key = getItemId(dimension);
                fields[key] = fieldsWithSuggestions[key];
            });
        });

    return fields;
};

export const updateProfilePayload = ({
    payload,
    newColumns,
    cron,
}: Pick<CreatePrfofilePayloadProps, 'cron' | 'newColumns'> & {
    payload: CustomMaterialisation;
}): InsertCustomer360Materialisation => {
    return {
        projectUuid: payload.projectUuid,
        relationId: payload.relationId,
        metricQuery: {
            ...payload.metricQuery,
            dimensions: newColumns ?? payload?.metricQuery?.dimensions ?? [],
            exploreName: payload.metricQuery?.exploreName ?? '',
            metrics: payload.metricQuery?.metrics ?? [],
            filters: payload.metricQuery?.filters ?? {},
            sorts: payload.metricQuery?.sorts ?? [],
            limit: payload.metricQuery?.limit ?? -1,
            additionalMetrics: payload.metricQuery?.additionalMetrics ?? [],
            tableCalculations: payload.metricQuery?.tableCalculations ?? [],
        },
        incrementalTimestamp: payload.incrementalTimestamp ?? undefined,
        cron: cron ?? payload.cron ?? undefined,
        sqlQuery: payload.sqlQuery,
        primaryKey: payload.primaryKey,
        cronStartAt: payload.cronStartAt ?? undefined,
        cronEndAt: payload.cronEndAt ?? undefined,
        isFilterable: payload.isFilterable ?? undefined,
        queryableDimensions: payload.queryableDimensions ?? undefined,
    };
};
type FieldWithSuggestionsWithDisable = FieldWithSuggestions & {
    isDisable?: boolean;
};

/**
 * Updates the fields with suggestions, marking specific fields as disabled
 * based on the provided configuration dimensions.
 *
 * @param {string[] | undefined} configDimensions - The dimensions from the configuration
 * that should be marked as disabled in the fields.
 * @param {Record<string, FieldWithSuggestionsWithDisable>} fields - The fields with suggestions
 * that need to be updated.
 * @returns {FieldWithSuggestionsWithDisable[]} The updated fields with suggestions, with specific
 * fields marked as disabled based on the configuration dimensions.
 */
export const getUpdatedFields = (
    configDimensions: string[] | undefined,
    fields: Record<string, FieldWithSuggestionsWithDisable>,
): FieldWithSuggestionsWithDisable[] => {
    return Object.values({
        ...fields,
        ...(configDimensions &&
            configDimensions.reduce((tempFields, field) => {
                if (fields[field] !== undefined) {
                    tempFields = {
                        ...tempFields,
                        [field]: {
                            ...fields[field],
                            isDisabled: true,
                        },
                    };
                }
                return tempFields;
            }, {})),
    });
};

interface GetFilterForProfileSearchProps {
    primaryKey: string;
    profileId: string | number;
    operator: ConditionalOperator;
    fieldType:
        | CustomDimensionType.SQL
        | TableCalculationType
        | MetricType
        | DimensionType
        | undefined;
}
/**
 * @param {string } primaryKey - The primary key of the profile
 * @param {string | undefined} profileId - The profile ID
 * @returns {Filters} The filters for the profile search
 */
export const getFilterForProfileSearch = ({
    primaryKey,
    profileId,
    operator,
    fieldType,
}: GetFilterForProfileSearchProps) => {
    return {
        dimensions: {
            id: uuidv4(),
            and: [
                {
                    id: uuidv4(),
                    target: {
                        fieldId: primaryKey,
                    },
                    operator: operator,
                    values: [
                        fieldType === DimensionType.NUMBER
                            ? Number(profileId)
                            : profileId,
                    ],
                },
            ],
        },
    };
};
