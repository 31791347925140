import FilterFieldSelect from '@components/Audience/Filters/FilterInputs/FilterFieldSelect';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type TableConfig } from '@lightdash/common';
import { ActionIcon, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { X } from '@phosphor-icons/react';
import { useRelationContext } from '@providers/RelationProvider';
import { useMemo } from 'react';
import VariablePropertySelect from './VariablePropertySelect';

interface ReachabilityVariableProps {
    tableConfig: TableConfig | undefined;
    onChange: (tableConfig: TableConfig) => void;
    onDelete: (() => void) | undefined;
    isPrimary: boolean;
    isEditable: boolean;
}

const ReachabilityVariable = ({
    tableConfig,
    onChange,
    onDelete,
    isPrimary,
    isEditable,
}: ReachabilityVariableProps) => {
    const [opened, { open, close }] = useDisclosure(false);
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });
    const activeField = useMemo(
        () =>
            fieldsWithSuggestions[
                `${tableConfig?.tableName}_${tableConfig?.dimensionName}`
            ],
        [
            fieldsWithSuggestions,
            tableConfig?.dimensionName,
            tableConfig?.tableName,
        ],
    );

    return (
        activeField && (
            <VariablePropertySelect
                onSubmit={(item) => {
                    close();
                    onChange({
                        tableName: item.table ?? '',
                        dimensionName: item.name ?? '',
                    });
                }}
                opened={opened}
                close={close}
                open={open}
                targetButton={
                    <Flex gap="xs" className="items-center">
                        <FilterFieldSelect
                            activeField={activeField}
                            isEditMode={isEditable}
                            showFieldSource={true}
                            activeRelation={undefined}
                            showHoverIcon={false}
                        />
                        {!isPrimary && (
                            <ActionIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    if (onDelete) {
                                        onDelete();
                                    }
                                }}
                            >
                                <X className="text-gray-500 " weight="bold" />
                            </ActionIcon>
                        )}
                    </Flex>
                }
            />
        )
    );
};

export default ReachabilityVariable;
