import {
    CommunicationChannel as CommunicationChannelEnum,
    CommunicationChannelName,
    type CommunicationChannel,
} from '@lightdash/common';
import { Group, Text } from '@mantine/core';
import {
    ChatCircleText,
    Envelope,
    Megaphone,
    Notification,
    WhatsappLogo,
} from '@phosphor-icons/react';
import React, { useMemo } from 'react';

interface ChannelIconProps {
    channel: CommunicationChannel | undefined;
    showIcon?: boolean;
    showChannelName?: boolean;
    className?: string;
    color?: string;
    labelClassName?: string;
}
const ChannelIcon: React.FC<ChannelIconProps> = ({
    channel,
    showIcon = true,
    showChannelName = true,
    className,
    color = 'rgb(var(--color-gray-600)',
    labelClassName,
}) => {
    const renderIcon = useMemo(() => {
        switch (channel) {
            case CommunicationChannelEnum.EMAIL: {
                return <Envelope color={color} size={14} />;
            }
            case CommunicationChannelEnum.SMS: {
                return <ChatCircleText color={color} size={14} />;
            }
            case CommunicationChannelEnum.WHATSAPP: {
                return <WhatsappLogo color={color} size={14} />;
            }
            case CommunicationChannelEnum.SLACK: {
                return <Notification color={color} size={14} />;
            }
            default: {
                return <Megaphone color={color} size={14} />;
            }
        }
    }, [channel, color]);

    return (
        <Group align="center" className={`gap-1 ${className}`}>
            {showIcon && renderIcon}

            {showChannelName && channel && (
                <Text
                    className={labelClassName}
                    style={{
                        color: color,
                    }}
                >
                    {CommunicationChannelName[channel]}
                </Text>
            )}
        </Group>
    );
};

export default React.memo(ChannelIcon);
