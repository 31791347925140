import {
    ChatContainer,
    Message,
    MessageInput,
    MessageList,
    TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import { useLocale } from '@hooks/useLocale';
import { useStreamedData } from '@hooks/useStreamedData';
import {
    AIMessageContext,
    AiMessageTypes,
    AudienceBuilderHashParams,
    Author,
    type AgentJsonContent,
    type AgentMessage,
    type AndNestedMetricQuery,
    type Chart,
    type MetricQuery,
    type OrNestedMetricQuery,
} from '@lightdash/common';
import { Button, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Sparkle } from '@phosphor-icons/react';
import { useAudienceContext } from '@providers/AudienceProvider';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Star } from 'react-feather';
import { useHistory, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ButtonVariant } from '../../mantineTheme';
import AiChart from './AiChart';
import { AiWidgetWrapper, TypingIndicatorStyled } from './AIStyle';
import { MessageValue } from './types';

const StreamingText: React.FC<{
    stream: boolean;
    html: string;
    streamedMessages: number[];
    setStreamedMessages: (value: number[]) => void;
    index: number;
}> = ({ stream, html, streamedMessages, setStreamedMessages, index }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [streamIndex, setStreamIndex] = useState(0);

    useEffect(() => {
        if (stream && !streamedMessages.includes(index)) {
            if (streamIndex < html.length) {
                const timeoutId = setTimeout(() => {
                    setDisplayedText((prev) => prev + html[streamIndex]);
                    setStreamIndex((prev) => prev + 1);
                }, 20); // Adjust the speed of the animation here
                return () => clearTimeout(timeoutId);
            } else {
                setStreamedMessages([...streamedMessages, index]);
            }
        } else {
            setDisplayedText(html);
        }
    }, [
        streamIndex,
        html,
        stream,
        setStreamedMessages,
        streamedMessages,
        index,
    ]);

    return <span dangerouslySetInnerHTML={{ __html: displayedText }} />;
};

const createUnorderedList = (text: string) => {
    if (!/^-\s(.*)$/gm.test(text)) {
        return text; // Return the original text if no matches are found
    }
    let html = text.replace(/^- (.*)$/gm, '<li>$1<ul>');
    html = html.replace(/^\s+- (.*)$/gm, '<li>$1</li>');
    html = html.replace(/<\/li>\n(?=<li>)/g, '</li></ul>');
    html = html.trim() + '</ul></li>';
    return html;
};

const replaceAsterisksWithBold = (text: string) => {
    return text.replace(/\*\*(.*?)\*\*/gm, '<b>$1</b>');
};

function AIWidget() {
    const history = useHistory();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [opened, { open, close }] = useDisclosure(false);
    const { actions } = useAudienceContext((contextVal) => contextVal);
    const { setFilters, setAdditionalMetrics, setSqlQuery } = actions;
    const [messages, setMessages] = useState<AgentMessage[]>([]);
    const { t } = useLocale();
    const { fetchData, isLoading, latestMessage } =
        useStreamedData<AgentMessage>({
            url: `/projects/${projectUuid}/ai/agents`,
            method: 'POST',
        });
    const threadId = useRef<string>('');
    const [msgInputValue, setMsgInputValue] = useState<string>('');
    const inputElementRef = useRef<HTMLInputElement | null>(null);
    const [streamedMessages, setStreamedMessages] = useState<number[]>([]);
    const [chart, setChart] = useState<Chart>();

    useEffect(() => {
        const id = sessionStorage.getItem('threadId');
        if (id) {
            threadId.current = id;
        } else {
            threadId.current = uuidv4();
            sessionStorage.setItem('threadId', threadId.current);
        }
    }, []);

    useEffect(() => {
        if (latestMessage) {
            setMessages((prevMessages) => [
                ...prevMessages,
                ...latestMessage.filter(
                    (item) => item.content.type === AiMessageTypes.TEXT,
                ),
            ]);

            const jsonMessage = latestMessage.filter(
                (item) => item.content.type === AiMessageTypes.JSON,
            );

            if (jsonMessage.length <= 0) {
                return;
            }

            // Only expect 1 json content
            const jsonContent = jsonMessage[0].content as AgentJsonContent;

            if (
                jsonContent.value.context === AIMessageContext.VISUAL_AUDIENCE
            ) {
                const val = jsonContent.value;
                const andFilters = (
                    (val?.nestedMetricQuery as AndNestedMetricQuery)
                        ?.and?.[0] as MetricQuery
                )?.filters;
                const orFilters = (
                    (val?.nestedMetricQuery as OrNestedMetricQuery)
                        ?.or?.[0] as MetricQuery
                )?.filters;
                if (andFilters) {
                    setFilters(andFilters, true, 0);
                    if (
                        (val as unknown as any)?.and?.[0]?.additionalMetrics
                            .length > 0
                    ) {
                        setAdditionalMetrics(
                            (val as unknown as any)?.and?.[0]
                                ?.additionalMetrics,
                            true,
                            0,
                        );
                    }
                } else if (orFilters) {
                    setFilters(orFilters, true, 0);
                    if (
                        (val as unknown as any)?.or?.[0]?.additionalMetrics
                            .length > 0
                    ) {
                        setAdditionalMetrics(
                            (val as unknown as any)?.or?.[0]?.additionalMetrics,
                            true,
                            0,
                        );
                    }
                }
                close();
                if (
                    history.location.hash ===
                    `#${AudienceBuilderHashParams.SQL}`
                ) {
                    history.push(
                        `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.VISUAL}`,
                    );
                }
            } else if (
                jsonContent.value.context === AIMessageContext.SQL_AUDIENCE
            ) {
                setSqlQuery(jsonContent.value.sql);
                if (
                    history.location.hash ===
                    `#${AudienceBuilderHashParams.VISUAL}`
                ) {
                    history.push(
                        `/projects/${projectUuid}/audiences/create#${AudienceBuilderHashParams.SQL}`,
                    );
                }
                close();
            } else if (jsonContent.value.context === AIMessageContext.CHART) {
                setChart(jsonContent.value);
            }
        }
    }, [
        close,
        history,
        latestMessage,
        projectUuid,
        setAdditionalMetrics,
        setFilters,
        setSqlQuery,
    ]);

    const sanitizeInputText = useCallback((inputText: string) => {
        const temporaryElement = document.createElement('div');
        temporaryElement.innerHTML = inputText;
        return temporaryElement.textContent || temporaryElement.innerText || '';
    }, []);

    const handleInputChange = useCallback(
        (innerText: string) => {
            const sanitizedValue = sanitizeInputText(innerText);
            setMsgInputValue(sanitizedValue);
        },
        [sanitizeInputText],
    );

    const setInputRef = (element: HTMLInputElement | null) => {
        inputElementRef.current = element;
    };

    const handleSend = useCallback(
        (inputText: string) => {
            const id = threadId.current;
            const newMessage: AgentMessage = {
                threadId: id,
                author: Author.USER,
                context:
                    history.location.hash ===
                    `#${AudienceBuilderHashParams.SQL}`
                        ? AIMessageContext.SQL_AUDIENCE
                        : AIMessageContext.VISUAL_AUDIENCE,
                content: {
                    type: AiMessageTypes.TEXT,
                    value: inputText,
                },
            };

            setMessages((prevMessages) => [...prevMessages, newMessage]);
            setMsgInputValue('');
            fetchData(newMessage);
        },
        [fetchData, history.location.hash],
    );

    const ChatMessageInput: React.FC<{}> = useCallback(() => {
        if (isLoading) return null;
        return (
            <MessageInput
                attachButton={false}
                value={msgInputValue}
                onChange={handleInputChange}
                placeholder={t('aiwidget.input_placeholder')}
                onSend={handleSend}
                ref={setInputRef}
            />
        );
    }, [isLoading, msgInputValue, handleInputChange, t, handleSend]);

    const getFormattedHtml = (html: string) => {
        let formattedText = replaceAsterisksWithBold(html);
        formattedText = createUnorderedList(formattedText);
        return formattedText;
    };

    return (
        <>
            <Modal
                styles={(_params) => ({
                    header: {
                        borderBottom:
                            '3px solid rgb(var(--color-black)/0.02) !important',
                    },
                    title: {
                        display: 'flex !important',
                        alignItems: 'center !important',
                        gap: '6px !important',
                    },
                    content: {
                        maxHeight: 'unset !important',
                    },
                    inner: {
                        paddingBottom: '2rem !important',
                        top: 'unset !important',
                    },
                    body: {
                        padding: '0px !important',
                    },
                })}
                opened={opened}
                onClose={close}
                closeOnClickOutside={false}
                title={
                    <>
                        <Sparkle color="rgb(var(--color-purple))" />{' '}
                        {t('aiwidget.ai_assist')}
                    </>
                }
                transitionProps={{
                    transition: 'slide-up',
                    duration: 200,
                    timingFunction: 'linear',
                }}
            >
                <AiWidgetWrapper
                    style={{
                        height: '500px',
                    }}
                >
                    <ChatContainer>
                        <MessageList
                            typingIndicator={
                                isLoading && (
                                    <TypingIndicator
                                        className="!p-3.5"
                                        content={t('aiwidget.ai_is_thinking')}
                                    />
                                )
                            }
                        >
                            {!messages.length && (
                                <div className="text-sm font-normal text-gray-600 ">
                                    {t('aiwidget.how_can_i_help')}
                                </div>
                            )}

                            {!!messages.length &&
                                messages.map((item, index) => {
                                    return (
                                        <Message
                                            key={item.threadId}
                                            model={{
                                                sender:
                                                    item.author === Author.USER
                                                        ? MessageValue.USER
                                                        : MessageValue.SYSTEM,
                                                direction:
                                                    item.author === Author.USER
                                                        ? MessageValue.OUTGOING_DIRECTION
                                                        : MessageValue.INCOMING_DIRECTION,
                                                position: MessageValue.POSITION,
                                            }}
                                            className={`${
                                                item.author === Author.SYSTEM
                                                    ? 'ai-chat-message'
                                                    : 'chat-message'
                                            }`}
                                        >
                                            <Message.CustomContent>
                                                <StreamingText
                                                    stream={
                                                        item.author ===
                                                        Author.SYSTEM
                                                    }
                                                    streamedMessages={
                                                        streamedMessages
                                                    }
                                                    setStreamedMessages={
                                                        setStreamedMessages
                                                    }
                                                    index={index}
                                                    html={getFormattedHtml(
                                                        item.content
                                                            .value as any,
                                                    )}
                                                />
                                            </Message.CustomContent>
                                        </Message>
                                    );
                                })}
                            {!isLoading && chart && (
                                <Message
                                    model={{
                                        sender: MessageValue.SYSTEM,
                                        direction:
                                            MessageValue.INCOMING_DIRECTION,
                                        position: MessageValue.POSITION,
                                    }}
                                    className={'ai-chat-message'}
                                >
                                    <Message.CustomContent>
                                        <AiChart
                                            metricQuery={chart.metricQuery}
                                            chartConfig={chart.chartConfig}
                                        />
                                    </Message.CustomContent>
                                </Message>
                            )}
                        </MessageList>
                        {ChatMessageInput({})}
                    </ChatContainer>
                </AiWidgetWrapper>
            </Modal>
            <div>
                <Button
                    onClick={open}
                    className="rounded-lg !bg-white border-shade-6 text-gray-500 p-2.5 w-64 flex justify-start h-9 text-sm font-normal hover:border-blu-800"
                    variant={ButtonVariant.OUTLINED}
                >
                    {isLoading ? (
                        <TypingIndicatorStyled>
                            <TypingIndicator
                                content={t('aiwidget.ai_is_thinking')}
                            />
                        </TypingIndicatorStyled>
                    ) : (
                        <>
                            <Star className="!w-3.5 !h-3.5 text-blu-800 pr-1.5 " />
                            <div className="text-sm font-normal text-gray-500">
                                {t('aiwidget.ai_assist')}
                            </div>
                        </>
                    )}
                </Button>
            </div>
        </>
    );
}

export default AIWidget;
