import { AudienceType, type ReducedCampaign } from '@lightdash/common';
import { Text, Tooltip } from '@mantine/core';
import { FileCsv, UsersThree } from '@phosphor-icons/react';
import React from 'react';

interface AudienceCountProps {
    data: ReducedCampaign;
}

const AudienceCount: React.FC<AudienceCountProps> = ({ data }) => {
    return (
        <Text className="flex items-center text-sm text-gray-800 gap-1">
            {data.audienceType === AudienceType.WAREHOUSE ? (
                <UsersThree size={14} color="rgb(var(--color-pink-800))" />
            ) : (
                <FileCsv size={18} color="rgb(var(--color-gray-700))" />
            )}
            <Tooltip label={data?.audienceName}>
                <Text className="truncate !w-36">{data?.audienceName}</Text>
            </Tooltip>
        </Text>
    );
};

export default AudienceCount;
