import {
    getWarehouseIcon,
    getWarehouseLabel,
} from '@components/ProjectConnection/ProjectConnectFlow/SelectWarehouse';
import { useLocale } from '@hooks/useLocale';
import { Box, Flex, Text } from '@mantine/core';
import { FontSizes } from '../../../../../mantineTheme';

const KeyValuePair: React.FC<{ label: string; value: string }> = ({
    label,
    value,
}) => {
    return (
        <Flex direction="row" gap={4} align="center" wrap="wrap">
            <Text size={FontSizes.sm} className="text-gray-500">
                {label}
            </Text>
            <Text size={FontSizes.sm} className="text-gray-600">
                {value}
            </Text>
        </Flex>
    );
};

const SnowflakeDetails: React.FC<any> = ({ snowflake }) => {
    const { t } = useLocale();
    return (
        <Box>
            <KeyValuePair
                label={t('snowflake.account_identifier')}
                value={snowflake?.account}
            />
            <KeyValuePair
                label={t('snowflake.warehouse')}
                value={snowflake?.region}
            />
            <KeyValuePair
                label={t('snowflake.database')}
                value={snowflake?.database}
            />
            <KeyValuePair
                label={t('snowflake.user')}
                value={snowflake?.schema}
            />
        </Box>
    );
};

const RedshiftDetails: React.FC<any> = ({ redshift }) => {
    return (
        <Box>
            <KeyValuePair label="Host" value={redshift?.cluster} />
            <KeyValuePair label="Port" value={redshift?.database} />
            <KeyValuePair label="Database" value={redshift?.schema} />
            <KeyValuePair label="User" value={redshift?.schema} />
        </Box>
    );
};

const WareHouse: React.FC<any> = ({ warehouse }) => {
    switch (warehouse?.type) {
        case 'snowflake':
            return <SnowflakeDetails snowflake={warehouse} />;
        case 'redshift':
            return <RedshiftDetails redshift={warehouse} />;
        default:
            return null;
    }
};

const DataSource: React.FC<any> = ({ dataSource }) => {
    if (!dataSource) return null;

    const { warehouseConnection } = dataSource;
    return (
        <>
            <Box className="bg-white border-base border border-solid border-gray-300 rounded-md overflow-hidden">
                <Flex
                    className="px-3.5 py-3 border-b-[1px] border-solid border-gray-300 overflow-hidden"
                    direction="row"
                    gap={4}
                    justify="flex-start"
                    align="center"
                    wrap="wrap"
                >
                    {getWarehouseIcon(warehouseConnection?.type, 'xs')}
                    <Text size={FontSizes.sm}>
                        {getWarehouseLabel(warehouseConnection.type)}
                    </Text>
                </Flex>

                <Box className="px-3.5 py-3 bg-gray-50">
                    <WareHouse warehouse={warehouseConnection} />
                </Box>
            </Box>
        </>
    );
};

export default DataSource;
