import { sortmentApi } from '@api/index';
import { useLocale } from '@hooks/useLocale';
import { type ApiError, type UpdateOrganization } from '@lightdash/common';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from '../toaster/useNotify';

const updateOrgQuery = async (data: UpdateOrganization) =>
    sortmentApi<null>({
        url: `/org`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

export const useOrganizationUpdateMutation = () => {
    const queryClient = useQueryClient();
    const { t } = useLocale();
    const { showToastApiError, showToastSuccess } = useNotify();
    return useMutation<null, ApiError, UpdateOrganization>(updateOrgQuery, {
        mutationKey: [QueryKeys.ORGANIZATION_UPDATE],
        onSuccess: async () => {
            await queryClient.invalidateQueries([QueryKeys.ORGANIZATION]);
            showToastSuccess({
                title: t('organization_settings.update_success'),
            });
        },
        onError: ({ error }) => {
            showToastApiError({
                title: 'Failed to update organization',
                apiError: error,
            });
        },
    });
};
