import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import { getPreviousContentStep } from '@components/Campaigns/Builder/utils';
import Modal from '@components/common/modal/Modal';
import { useTestCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import useUser from '@hooks/user/useUser';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    CommunicationChannelName,
    DefaultWhatsappHeaderVariablesMap,
    WAHeaderType,
    WhatsappHeaderVariable,
    type ContentMappingSection,
    type TestCampaignRequest,
} from '@lightdash/common';
import { Box, Button, Group, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { CaretRight } from '@phosphor-icons/react';
import { useCampaignContext } from '@providers/CampaignProvider';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Send } from 'react-feather';
import { ButtonVariant } from '../../../../../../mantineTheme';

const TestCommunication: React.FC<ContentStepComponentProps> = ({
    setActiveContentStep,
    activeContentStep,
}) => {
    const [isModalOpen, { open: openModal, close: closeModal }] =
        useDisclosure();
    const { t } = useLocale();

    const { setPreviousStepCallback } = useCampaignContext(
        (context) => context.actions,
    );
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { communicationDetails, templateDetails } = campaignPayload;

    const { mutateAsync: triggerTestMutation, isLoading } = useTestCampaign(
        communicationDetails?.providerId ?? '',
        communicationDetails?.id ?? '',
    );

    const { data: templateMetadata, isFetching: isTemplateMetadataLoading } =
        useGetTemplateById(
            templateDetails?.id ?? '',
            campaignPayload?.channel !== CommunicationChannel.WHATSAPP,
        );

    const { data: syncedTemplateMetadata } = useGetSyncedTemplateByTemplateName(
        communicationDetails?.id ?? '',
        templateDetails?.id ?? '',
        campaignPayload?.channel === CommunicationChannel.WHATSAPP,
    );

    const [recipientComm, setRecipientComm] = useState<string>('');

    const { data: user } = useUser(true);

    useEffect(() => {
        if (
            campaignPayload?.channel === CommunicationChannel.EMAIL &&
            user?.email
        ) {
            setRecipientComm(user.email);
        }
    }, [campaignPayload?.channel, user]);

    useEffect(() => {
        const prevStep = getPreviousContentStep(
            activeContentStep,
            campaignPayload?.channel,
            templateMetadata?.contents[0].parsedVariables,
        );
        const handlePrevStep = () => {
            if (prevStep) {
                setActiveContentStep(prevStep);
            }
            return;
        };

        setPreviousStepCallback({
            callback: handlePrevStep,
            skipExecutionAfterCallback: true,
        });
    }, [
        activeContentStep,
        setActiveContentStep,
        setPreviousStepCallback,
        communicationDetails,
        templateMetadata,
        campaignPayload?.channel,
    ]);

    const triggerTestCommunication = useCallback(async () => {
        if (!recipientComm) return;
        if (!campaignPayload.channel) return;

        const mappings = campaignPayload.contentMappings;

        // if (!isEmpty(templateDetails?.mappings) && isEmpty(mappings)) return;
        if (!mappings) return;
        if (!campaignPayload.channel) return;
        Object.keys(mappings).forEach((groupKey) => {
            const mapping = mappings[groupKey as ContentMappingSection] ?? {};
            Object.keys(mapping).forEach((key) => {
                if (
                    key === WhatsappHeaderVariable &&
                    campaignPayload.channel === CommunicationChannel.WHATSAPP &&
                    !mapping[key].defaultValue
                ) {
                    mapping[key].defaultValue =
                        DefaultWhatsappHeaderVariablesMap[
                            syncedTemplateMetadata?.languages?.[0]?.content
                                ?.header?.type ?? WAHeaderType.IMAGE
                        ];
                }
            });
        });
        let templateContent = undefined;
        let templateTestDetails = undefined;
        if (campaignPayload.channel === CommunicationChannel.EMAIL) {
            templateContent =
                campaignPayload.contentDetails &&
                !isEmpty(campaignPayload.contentDetails)
                    ? { ...campaignPayload.contentDetails }
                    : templateMetadata?.contents?.[0]?.content
                    ? { ...templateMetadata?.contents?.[0]?.content }
                    : {
                          subject: '',
                          html: '',
                      };
        } else if (campaignPayload.channel === CommunicationChannel.SMS) {
            templateContent = templateMetadata?.contents[0].content ?? {
                body: '',
                dltTemplateId: '',
                unicode: false,
                flash: false,
            };
        }
        if (campaignPayload.channel === CommunicationChannel.WHATSAPP) {
            templateTestDetails = {
                id: syncedTemplateMetadata?.name ?? '',
                integrationId: syncedTemplateMetadata?.integrationId ?? '',
            };
        }
        const payload: TestCampaignRequest = {
            to: {
                [campaignPayload.channel]: recipientComm,
            },
            channel: campaignPayload.channel,
            mappings: mappings,
            templateContent,
            templateDetails: templateTestDetails || {
                name: templateMetadata?.name || '',
            },
        };
        await triggerTestMutation({ payload });
        closeModal();
    }, [
        recipientComm,
        campaignPayload.channel,
        campaignPayload.contentMappings,
        campaignPayload.contentDetails,
        templateMetadata?.name,
        templateMetadata?.contents,
        triggerTestMutation,
        closeModal,
        syncedTemplateMetadata?.languages,
        syncedTemplateMetadata?.name,
        syncedTemplateMetadata?.integrationId,
    ]);

    const sendTestCommTitle = useMemo(() => {
        if (!campaignPayload.channel) return '';
        let channelLabel = CommunicationChannelName[campaignPayload.channel];
        if (campaignPayload.channel === CommunicationChannel.EMAIL) {
            channelLabel = channelLabel.toLowerCase();
        }
        return t('campaigns_builder.send_test_communication', {
            channel: channelLabel,
        });
    }, [campaignPayload.channel, t]);

    const sendTestCommInputLabel = useMemo(() => {
        return t('campaigns_builder.send_test_communication_input_label', {
            channel: campaignPayload.channel
                ? CommunicationChannelName[campaignPayload.channel]
                : '',
        });
    }, [campaignPayload.channel, t]);

    return (
        <>
            <Box>
                <Text className="text-sm text-gray-800">
                    Test your campaign by sending it to yourself.
                </Text>

                <Button
                    variant={ButtonVariant.OUTLINED_ACCENTED}
                    leftIcon={
                        <Send
                            size={13}
                            strokeWidth={2.5}
                            color={'rgb(var(--color-blu-800))'}
                        />
                    }
                    className="mt-6 font-semibold"
                    onClick={openModal}
                >
                    {sendTestCommTitle}
                </Button>
            </Box>
            <Modal
                opened={isModalOpen}
                onClose={closeModal}
                keepMounted={false}
                title={sendTestCommTitle}
                size="lg"
                footerRightSection={
                    <Group className="flex gap-1.5">
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            onClick={closeModal}
                            disabled={isLoading || isTemplateMetadataLoading}
                        >
                            {t('common.cancel')}
                        </Button>

                        <Button
                            disabled={isLoading}
                            type="submit"
                            rightIcon={
                                <CaretRight color={'white'} weight="regular" />
                            }
                            onClick={() => triggerTestCommunication()}
                        >
                            {sendTestCommTitle}
                        </Button>
                    </Group>
                }
            >
                <Box>
                    <TextInput
                        label={sendTestCommInputLabel}
                        placeholder={sendTestCommInputLabel}
                        disabled={isLoading || isTemplateMetadataLoading}
                        required
                        withAsterisk={false}
                        onChange={(e) =>
                            setRecipientComm(e.currentTarget.value)
                        }
                        value={recipientComm}
                    />
                </Box>
            </Modal>
        </>
    );
};

export default React.memo(TestCommunication);
