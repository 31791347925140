import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import Timestamp from '@components/common/Timestamp';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import TemplateGridItem from '@components/TemplateGridItem';
import useNotify from '@hooks/toaster/useNotify';
import { useLocale } from '@hooks/useLocale';
import { useUpdateTemplateTags } from '@hooks/useTemplate';
import { CommonReservedTags, type ReducedTemplate } from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    CaretRight,
} from '@phosphor-icons/react';
import { useQueryClient } from '@tanstack/react-query';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';

const TEMPLATE_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const TEMPLATE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const TEMPLATE_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !w-56`;

interface TemplateMenuItemProps {
    rowData: Row<ReducedTemplate>;
}

const TemplateMenuItem: React.FC<TemplateMenuItemProps> = ({ rowData }) => {
    const { t } = useLocale();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const { mutateAsync: updateTemplateTags, isLoading } =
        useUpdateTemplateTags(rowData.original.id);
    const query = useQueryClient();
    const { showToastSuccess, showToastError } = useNotify();
    const hideItem = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: <Text className="text-halt-800">{t('common.hide')}</Text>,
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItem = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async () => {
                await updateTemplateTags(
                    {
                        tags: [
                            ...(rowData.original.tags ?? []).filter(
                                (tag) => tag !== CommonReservedTags.HIDDEN,
                            ),
                        ],
                    },
                    {
                        onSuccess: async () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.template'),
                                }),
                            });
                            await query.invalidateQueries([
                                QueryKeys.GET_TEMPLATE,
                            ]);
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.template'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            updateTemplateTags,
            rowData.original.tags,
            showToastError,
            showToastSuccess,
            query,
        ],
    );
    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<ReducedTemplate>[] = [
            (rowData.original.tags ?? []).includes(CommonReservedTags.HIDDEN)
                ? restoreItem
                : hideItem,
        ];
        return items;
    }, [hideItem, restoreItem, rowData.original.tags]);

    return (
        <>
            <CustomMenu<ReducedTemplate>
                menuItems={menuItems}
                data={rowData.original}
            />
            <Modal
                opened={openedHideConfirmModal}
                onClose={closeHideConfirmModal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>{t('template_manager.hide_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData.original.name}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t(
                            'audience_manager.confirm_hide_prim_button',
                        )}
                        secondaryButtonLabel={t('common.no')}
                        isUpdating={isLoading}
                        handlePrimaryButtonClick={async () => {
                            await updateTemplateTags(
                                {
                                    tags: [
                                        ...(rowData.original.tags ?? []),
                                        CommonReservedTags.HIDDEN,
                                    ],
                                },
                                {
                                    onSuccess: async () => {
                                        showToastSuccess({
                                            title: t('common.hidden_success', {
                                                entityName:
                                                    t('common.template'),
                                            }),
                                        });
                                        await query.invalidateQueries([
                                            QueryKeys.GET_TEMPLATE,
                                        ]);
                                    },
                                    onError: (error) => {
                                        showToastError({
                                            title: t('common.hidden_error', {
                                                entityName:
                                                    t('common.template'),
                                            }),
                                            subtitle: error.error.message,
                                        });
                                    },
                                },
                            );
                            closeHideConfirmModal();
                        }}
                        handleSecondaryButtonClick={closeHideConfirmModal}
                        primaryButtonVariant={ButtonVariant.FILLED_DESTRUCTIVE}
                        primaryButtonRightIcon={<CaretRight color="white" />}
                    />
                }
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
            >
                <Flex direction={'column'} gap={12}>
                    <Text className="font-medium text-gray-800 text-sm">
                        {t('template_manager.hide_modal_header')}
                    </Text>
                </Flex>
            </Modal>
        </>
    );
};

export const useTemplateColumns = () => {
    const [columns, setColumns] = useState<ColumnDef<ReducedTemplate>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('templates'),
                    cell: ({ row, getValue }) => (
                        <Box className={TEMPLATE_TABLE_CELL_CLASSNAME}>
                            <Button variant={ButtonVariant.UNSTYLED}>
                                <Flex align={'center'} gap={6}>
                                    {row.original.tags?.includes(
                                        CommonReservedTags.HIDDEN,
                                    ) && <Archive weight="duotone" />}
                                    <Text className={TEMPLATE_TABLE_CELL_NAME}>
                                        {getValue<string>()}
                                    </Text>
                                </Flex>
                            </Button>
                            {row.original.description && (
                                <Text
                                    className={TEMPLATE_TABLE_CELL_DESCRIPTION}
                                >
                                    {row.original.description}
                                </Text>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${row.original.createdBy.firstName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('common.modified_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`${
                                    row.original.updatedBy?.firstName
                                        ? 'by'
                                        : ''
                                } ${row.original.updatedBy?.firstName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => <TemplateMenuItem rowData={row} />,
                },
            ]);
        }
    }, [projectUuid, t]);

    return columns;
};

export const useTemplateGrid = ({
    handleClick,
}: {
    handleClick: (id: string) => void;
}) => {
    {
        const [gridColumns, setGridColumns] =
            useState<ColumnDef<ReducedTemplate>[]>();
        const { projectUuid } = useParams<{ projectUuid: string }>();
        const { t } = useLocale();

        useEffect(() => {
            if (projectUuid) {
                setGridColumns([
                    {
                        accessorKey: 'name',
                        header: t('templates'),
                        cell: ({ row }) => {
                            const { original } = row;
                            return (
                                <TemplateGridItem
                                    channel={original.channel}
                                    template={original}
                                    handleTemplateSelect={() =>
                                        handleClick(original?.id)
                                    }
                                />
                            );
                        },
                    },
                ]);
            }
        }, [projectUuid, t, handleClick]);

        return gridColumns;
    }
};
