import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { ShowDataType, type CustomAttribute } from '@lightdash/common';
import { useDebouncedState } from '@mantine/hooks';
import { type Row } from '@tanstack/react-table';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import Fuse from 'fuse.js';
import React, { useCallback, useMemo, useState } from 'react';
import CreateCustomMetric from '../CreateCustomMetric';
import { useCustomMetricCoulumns } from './useCustomMetricTableUtils';
interface CustomMetricTableProps extends HideProps {
    customMetrics: CustomAttribute[];
}
const CustomMetricTable: React.FC<CustomMetricTableProps> = ({
    customMetrics,
    isHiddenActive,
    onHiddenChange,
    hideHiddenButton,
}) => {
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [customMetricId, setCustomMetricId] = useState<string>('');
    const filterItems = useMemo(() => {
        let customMetricsData = customMetrics;
        if (searchString !== '') {
            const fuse = new Fuse(customMetricsData, {
                keys: ['name'],
                threshold: 0.3,
            });
            customMetricsData = fuse
                .search(searchString ?? '')
                .map((res) => res.item);
        } else {
            setCustomMetricId('');
        }
        return customMetricsData;
    }, [customMetrics, searchString]);
    const customMetricColumns = useCustomMetricCoulumns();
    const [openViewMode, setOpenViewMode] = useState<boolean>(false);

    const { t } = useLocale();

    const handleRowClick = useCallback(
        (row: Row<CustomAttribute>) => {
            if (customMetricId === row.original.uuid) {
                setOpenViewMode(true);
            } else {
                setCustomMetricId(row.original.uuid);
                setOpenViewMode(true);
            }
        },
        [customMetricId],
    );

    return (
        <div>
            {customMetrics && customMetricColumns && (
                <DataTable<CustomAttribute>
                    tableData={filterItems}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: customMetricColumns,
                        },
                    ]}
                    searchPlaceholder={t(
                        'custom_metric_manager.table_search_placeholder',
                    )}
                    paginationText={t('custom_metric_manager.pagination_text')}
                    isManualPagination={false}
                    isEditable={false}
                    onSearchChange={setSearchString}
                    handleRowClick={handleRowClick}
                    leftSection={
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={onHiddenChange}
                                activeHideText={t(
                                    'custom_metric_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                />
            )}
            {customMetricId && (
                <CreateCustomMetric
                    isViewMode={openViewMode}
                    customMetricId={customMetricId}
                    customMetricData={filterItems.find(
                        (customMetric) => customMetric.uuid === customMetricId,
                    )}
                    openBaseTable={undefined}
                    tableName={undefined}
                    isDuplicate={undefined}
                    onModalClose={() => {
                        setOpenViewMode(false);
                    }}
                    disableTableChange={undefined}
                />
            )}
        </div>
    );
};
export default React.memo(CustomMetricTable);
