import PropertySelect from '@components/common/Select/PropertySelect';
import { type AddditionalPropertySelectListProps } from '@components/common/Select/PropertySelect/type';
import CreateCustomMetric from '@components/CustomMetric/CreateCustomMetric';
import { useLocale } from '@hooks/useLocale';
import { type CompiledRelationTable } from '@lightdash/common';
import { Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { PlusCircle, SquareSplitHorizontal } from '@phosphor-icons/react';
import { useRelationContext } from '@providers/RelationProvider';
import { useEffect, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import FieldListItem from '../Filters/FieldListItem';
import {
    convertFieldsIntoPropertySelectListType,
    getButtonHighlightClassName,
    type FieldWithSelectStatus,
} from '../Filters/FieldListItem/utils';
import { type FieldWithSuggestions } from '../Filters/FiltersProvider';

interface AudiencePreviewPropertySelectProps {
    fields: FieldWithSelectStatus[];
    previewSuccess: boolean;
    onSubmit: (items: FieldWithSuggestions[]) => void;
    isLoading: boolean;
}
const AudiencePreviewPropertySelect: React.FC<
    AudiencePreviewPropertySelectProps
> = ({ fields, previewSuccess, onSubmit, isLoading }) => {
    const [openCustomMetric, setOpenCustomMetric] = useState<
        CompiledRelationTable | undefined
    >(undefined);
    const [propertiesOpened, { open: propertiesOpen, close: propertiesClose }] =
        useDisclosure(false);
    const { activeRelation } = useRelationContext();
    const { t } = useLocale();
    useEffect(() => {
        if (previewSuccess) {
            propertiesClose();
        }
    }, [previewSuccess, propertiesClose]);
    return (
        <>
            <PropertySelect<
                FieldWithSuggestions & AddditionalPropertySelectListProps
            >
                items={convertFieldsIntoPropertySelectListType(fields, true)}
                showGroup={true}
                headerRightSection={
                    <Button
                        leftIcon={<PlusCircle />}
                        variant={ButtonVariant.OUTLINED}
                        className="my-2.5 me-2.5"
                        disabled={isLoading}
                        onClick={() => {
                            setOpenCustomMetric(
                                activeRelation?.tables[
                                    activeRelation.baseTable
                                ],
                            );
                            propertiesClose();
                        }}
                    >
                        {t('profiles_view.add_custom_metric_button')}
                    </Button>
                }
                onSubmit={onSubmit}
                itemTemplate={({ item }) => {
                    return (
                        <FieldListItem
                            item={item}
                            checked={item.isChecked ?? false}
                            showCheckbox={true}
                            disabled={item.isDisabled ?? false}
                        />
                    );
                }}
                opened={propertiesOpened}
                close={propertiesClose}
                open={propertiesOpen}
                targetButton={
                    <Button
                        variant={ButtonVariant.OUTLINED}
                        className={getButtonHighlightClassName(
                            propertiesOpened,
                        )}
                        leftIcon={
                            <SquareSplitHorizontal
                                color={
                                    propertiesOpened
                                        ? 'rgb(var(--color-blu-800))'
                                        : 'rgb(var(--color-gray-800))'
                                }
                                weight={'duotone'}
                            />
                        }
                    >
                        {t('audience_preview.choose_properties')}
                    </Button>
                }
                showSearch={true}
                searchKeys={['label', 'tableLabel']}
                searchPlaceholder={t('audience_filters.search_filter_label')}
                allowMultipleSelection={true}
                isLoading={isLoading}
                height={350}
                width={450}
            />
            <CreateCustomMetric
                tableName={openCustomMetric}
                onModalClose={() => {
                    setOpenCustomMetric(undefined);
                }}
                openBaseTable={undefined}
                isViewMode={undefined}
                isDuplicate={undefined}
                customMetricData={undefined}
                customMetricId={undefined}
                disableTableChange={false}
            />
        </>
    );
};
export default AudiencePreviewPropertySelect;
