import HiddenButton, { type HideProps } from '@components/Hidden/HiddenButton';
import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import {
    JourneyStatus,
    ShowDataType,
    type Journey,
    type JourneyAndExecutionCount,
    type Pagination,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { type Row } from '@tanstack/react-table';
import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import DeactivateJourneyModal from '../Settings/DeactivateJourneyModal';
import { useJourneyColumns } from './useJourneyTableUtils';

type JourneyTableProps = HideProps & {
    journeys: JourneyAndExecutionCount[] | null | undefined;
    pagination: Pagination;
    onPageChange: (page: number) => void;
    onSearchChange: (newSearchString: string) => void;
    isDataFetching: boolean;
};

const JourneyTable: React.FC<JourneyTableProps> = ({
    journeys,
    pagination,
    onPageChange,
    onSearchChange,
    isDataFetching,
    isHiddenActive,
    onHiddenChange,
    hideHiddenButton,
}) => {
    const [
        showDeactivateModal,
        { open: openDeactivateModal, close: closeDeactivateModal },
    ] = useDisclosure(false);
    const [journeyData, setJourneyData] = useState<Journey>();
    const journeyColumns = useJourneyColumns(
        openDeactivateModal,
        setJourneyData,
    );
    const { t } = useLocale();
    const history = useHistory();
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();

    const handleRowClick = useCallback(
        (row: JourneyAndExecutionCount) => {
            if (
                row.status === JourneyStatus.ACTIVE ||
                row.status === JourneyStatus.CANCELED
            ) {
                history.push(
                    `/projects/${projectUuid}/journeys/${row.id}/view`,
                );
                return;
            }
            history.push(`/projects/${projectUuid}/journeys/${row.id}/edit`);
        },
        [history, projectUuid],
    );

    return (
        <Box>
            {journeys && journeyColumns && (
                <DataTable<JourneyAndExecutionCount>
                    tableData={journeys}
                    options={[
                        {
                            format: ShowDataType.LIST,
                            formatData: journeyColumns,
                        },
                    ]}
                    searchPlaceholder={t('journeys_table.search_journey')}
                    paginationText={t('journeys_table.journeys')}
                    isManualPagination={true}
                    isDataFetching={isDataFetching}
                    onPageChange={onPageChange}
                    onSearchChange={onSearchChange}
                    pagination={pagination}
                    isEditable={false}
                    handleRowClick={(data: Row<JourneyAndExecutionCount>) => {
                        const journey = data.original;
                        handleRowClick(journey);
                    }}
                    leftSection={
                        !hideHiddenButton && (
                            <HiddenButton
                                isHiddenActive={isHiddenActive}
                                onHiddenChange={onHiddenChange}
                                activeHideText={t(
                                    'journeys_manager.active_hide_button_text',
                                )}
                            />
                        )
                    }
                />
            )}
            {journeyData && (
                <DeactivateJourneyModal
                    uuid={journeyData.id}
                    endDate={journeyData.endDate}
                    journeyStatus={journeyData.status}
                    showDeactivateModal={showDeactivateModal}
                    onClose={() => closeDeactivateModal()}
                />
            )}
        </Box>
    );
};

export default React.memo(JourneyTable);
