import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type TemplateListWithPagination,
} from '@lightdash/common';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';

export const getTemplate = async (projectId: string, query: string) =>
    sortmentApi<TemplateListWithPagination>({
        url: `/projects/${projectId}/templates${query}`,
        method: 'GET',
        body: undefined,
    });

export const useTemplates = (
    channel: string,
    perPage: number,
    currentPage: number,
    searchValue: string,
    enabled: boolean,
    query?: string,
) => {
    const { projectUuid } = useParams<{
        projectUuid: string;
    }>();
    const queryString = `?channel=${channel}&perPage=${perPage}&currentPage=${currentPage}&nameLike=${searchValue}&${
        query ?? ''
    }`;
    const templates = useQuery<TemplateListWithPagination, ApiError>({
        queryKey: [QueryKeys.GET_TEMPLATE, queryString || ''],
        queryFn: () => getTemplate(projectUuid || '', queryString || ''),
        enabled: !!projectUuid && enabled,
        keepPreviousData: true,
    });

    return templates;
};
