const BorderTopLeftRadius = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.2"
                d="M11 2C11.5523 2 12 2.44772 12 3V11C12 11.5523 11.5523 12 11 12H3C2.44772 12 2 11.5523 2 11V3C2 2.44772 2.44772 2 3 2H11Z"
                fill="#9B9B9B"
            />
            <path
                d="M1 1.76982L1.00049 5.50566C1.00049 5.60776 1.04 5.70568 1.11032 5.77787C1.18065 5.85007 1.27603 5.89063 1.37549 5.89062C1.47494 5.89062 1.57033 5.85007 1.64065 5.77787C1.71098 5.70568 1.75049 5.60776 1.75049 5.50566L1.75013 2.77C1.75006 2.21765 2.19783 1.76984 2.75018 1.76987L5.62549 1.77002C5.72494 1.77002 5.82033 1.72946 5.89065 1.65727C5.96098 1.58507 6.00049 1.48715 6.00049 1.38506C6.00049 1.28296 5.96098 1.18504 5.89065 1.11284C5.82033 1.04065 5.72494 1.00009 5.62549 1.00009L1.75 0.999889C1.55109 0.999889 1.36032 1.08101 1.21967 1.2254C1.07902 1.36979 1 1.56562 1 1.76982Z"
                fill="#343330"
            />
        </svg>
    );
};

export default BorderTopLeftRadius;
