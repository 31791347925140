import DataTable from '@components/Table';
import { useLocale } from '@hooks/useLocale';
import { useSyncedTemplates } from '@hooks/useTemplate';
import {
    ShowDataType,
    type SyncedTemplateBasicDetails,
} from '@lightdash/common';
import { ActionIcon, Box, Button, Text } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { ArrowsClockwise } from '@phosphor-icons/react';
import React, { useMemo, useState } from 'react';
import { ButtonVariant } from '../../../mantineTheme';
import TemplateSyncModal from './TemplateSyncModal';

const TEMPLATE_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start`;
const TEMPLATE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer`;

const SyncedWhatsappTemplates = ({
    handleRowClick,
    integrationId,
}: {
    handleRowClick: (payload: SyncedTemplateBasicDetails) => void;
    integrationId: string;
}) => {
    const [showSyncModal, setShowSyncModal] = useState(false);
    const [search, setSearch] = useDebouncedState('', 500);
    const [currentPage, setCurrentPage] = useState(1);

    const getQueryParams = () => {
        let query = `page=${currentPage}&limit=8`;
        if (integrationId) {
            query += `&integrationId=${integrationId}`;
        }
        if (search) {
            query += `&templateId=${search}`;
        }
        return query;
    };

    const { data: syncedTemplates, isLoading } = useSyncedTemplates({
        query: getQueryParams(),
    });

    const { t } = useLocale();

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    const templateColumns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: t('templates'),
                cell: ({
                    row,
                }: {
                    row: {
                        original: SyncedTemplateBasicDetails;
                    };
                }) => (
                    <Box className={TEMPLATE_TABLE_CELL_CLASSNAME}>
                        <Button
                            variant={ButtonVariant.UNSTYLED}
                            onClick={() => handleRowClick(row.original)}
                        >
                            <Text className={TEMPLATE_TABLE_CELL_NAME}>
                                {row.original.name}
                            </Text>
                        </Button>
                    </Box>
                ),
            },
            {
                accessorKey: 'customName',
                header: 'Integration',
                cell: ({
                    row,
                }: {
                    row: {
                        original: SyncedTemplateBasicDetails;
                    };
                }) => (
                    <Box className={TEMPLATE_TABLE_CELL_CLASSNAME}>
                        <Text className={TEMPLATE_TABLE_CELL_NAME}>
                            {row.original.customName}
                        </Text>
                    </Box>
                ),
            },
        ],
        [handleRowClick, t],
    );

    const handleSearchInput = (value: string) => {
        setSearch(value);
        setCurrentPage(1);
    };

    return (
        <div>
            <DataTable<SyncedTemplateBasicDetails>
                name={t('templates')}
                tableData={syncedTemplates || []}
                options={[
                    {
                        format: ShowDataType.LIST,
                        formatData: templateColumns,
                    },
                ]}
                searchPlaceholder={'Whatsapp Templates'}
                paginationText={t('template_manager.pagination_text')}
                isManualPagination={true}
                onPageChange={onPageChange}
                pagination={{
                    total: syncedTemplates?.length,
                    lastPage:
                        syncedTemplates?.length !== 8 ? currentPage : 9999,
                    currentPage,
                    perPage: 8,
                    from: 1,
                    to: 8,
                }}
                isDataFetching={isLoading}
                gridItemBorder={false}
                withBorder={false}
                pageSize={8}
                tableBackgroundColor={'gray'}
                isEditable={false}
                onSearchChange={handleSearchInput}
                searchValue={search}
                leftSection={
                    <ActionIcon
                        className="border-base bg-white"
                        onClick={() => setShowSyncModal(true)}
                    >
                        <ArrowsClockwise weight="regular" />
                    </ActionIcon>
                }
                disablePageButtons={true}
                disablePaginationText={true}
            />
            {showSyncModal && (
                <TemplateSyncModal
                    opened={showSyncModal}
                    onClose={() => setShowSyncModal(false)}
                />
            )}
        </div>
    );
};

export default SyncedWhatsappTemplates;
