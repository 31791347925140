import { ReactRenderer } from '@tiptap/react';
import tippy, { type Instance, type Props } from 'tippy.js';

import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import { type JourneyProperty } from '@components/Journeys/Builder/JourneyFilters/types';
import {
    type SuggestionKeyDownProps,
    type SuggestionProps,
} from '@tiptap/suggestion';
import { MentionList } from './MentionList';

export const suggestion = (
    items: (
        | PropertySelectListType<JourneyProperty>
        | PropertySelectListType<FieldWithSelectStatus, PropertySelectGroupType>
    )[],
    fieldIds: string[],
    setIsSuggestionFocused: (isFocused: boolean) => void,
) => ({
    items: () => {
        return fieldIds;
    },
    deleteTriggerWithBackspace: true,
    allowSpaces: true,
    allowedPrefixes: null,
    startOfLine: false,
    render: () => {
        let reactRenderer: ReactRenderer;
        let popup: Instance<Props>;

        return {
            items: () => {
                return fieldIds;
            },
            onStart: (props: SuggestionProps) => {
                if (!props?.clientRect) {
                    return;
                }

                reactRenderer = new ReactRenderer(MentionList, {
                    props: {
                        ...props,
                        journeyPropertiesItems: items,
                        setIsSuggestionFocused,
                    },
                    editor: props.editor,
                });

                popup = tippy(document.body, {
                    getReferenceClientRect: props.clientRect as () => DOMRect,
                    appendTo: () => document.body,
                    content: reactRenderer.element,
                    showOnCreate: true,
                    interactive: true,
                    trigger: 'manual',
                    placement: 'bottom-start',
                });
            },

            onUpdate(props: SuggestionProps) {
                reactRenderer.updateProps(props);

                if (!props.clientRect) {
                    return;
                }
                const { state } = props.editor;
                const { from } = state.selection;
                const inputText = state.doc.textBetween(0, from, '\n');

                // Check the character before the cursor
                if (inputText[from - 2] !== '@') {
                    popup.hide();
                    return;
                }

                popup.setProps({
                    getReferenceClientRect: props.clientRect as () => DOMRect,
                });
            },

            onKeyDown(props: SuggestionKeyDownProps) {
                if (props.event.key === 'Escape') {
                    popup.hide();

                    return true;
                }

                // Ensure that reactRenderer.ref is properly typed and initialized
                const ref = reactRenderer.ref as {
                    onKeyDown?: (props: SuggestionKeyDownProps) => boolean;
                };

                return ref.onKeyDown ? ref.onKeyDown(props) : false;
            },

            onExit() {
                popup.destroy();
                reactRenderer.destroy();
            },
        };
    },
});
