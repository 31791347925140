import { subject } from '@casl/ability';
import {
    LightdashMode,
    ResourceViewItemType,
    spaceToResourceViewItem,
    wrapResourceView,
} from '@lightdash/common';
import { Button, Flex, Group, Stack, Text } from '@mantine/core';
import { IconFolderPlus, IconFolders } from '@tabler/icons-react';
import Fuse from 'fuse.js';
import { useMemo, useState, type FC } from 'react';
import { useParams } from 'react-router-dom';
// import LoadingState from '../components/common/LoadingState';
// import PageBreadcrumbs from '../components/common/PageBreadcrumbs';
import PageSpinner from '@components/PageSpinner';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import SearchInput from '@components/SearchInput';
import { useLocale } from '@hooks/useLocale';
import { PlusCircle } from '@phosphor-icons/react';
import ResourceView, {
    ResourceViewType,
} from '../components/common/ResourceView';
import SpaceActionModal, {
    ActionType,
} from '../components/common/SpaceActionModal';
import ForbiddenPanel from '../components/ForbiddenPanel';
import Page from '../components/Page/Page';

import SetupPage from '@components/SetupPage';
import { useProject } from '../hooks/useProject';
import { useSpaceSummaries } from '../hooks/useSpaces';
import { useApp } from '../providers/AppProvider';
import { PinnedItemsProvider } from '../providers/PinnedItemsProvider';

const SortmentSpaces: FC = () => {
    const [searchValue, setSearchValue] = useState<string>('');
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
    const { data: spaces = [], isInitialLoading: spaceIsLoading } =
        useSpaceSummaries(projectUuid, true);
    const project = useProject(projectUuid);
    const isLoading = spaceIsLoading || project.isInitialLoading;

    const { user, health } = useApp();

    const { t } = useLocale();
    // const userCanManageProject = user.data?.ability?.can(
    //     'manage',
    //     subject('Project', {
    //         organizationUuid: user.data?.organizationUuid,
    //         projectUuid: projectUuid,
    //     }),
    // );

    const hasSpaces = spaces.length > 0;
    const isDemo = health.data?.mode === LightdashMode.DEMO;
    const userCannotViewSpace = user.data?.ability?.cannot(
        'view',
        subject('Space', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
            isPrivate: false,
        }),
    );
    const userCanManageSpace = user.data?.ability?.can(
        'create',
        subject('Space', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleCreateSpace = () => {
        setIsCreateModalOpen(true);
    };

    const filteredSpaces = useMemo(() => {
        const fuse = new Fuse(spaces, {
            keys: ['name'],
            threshold: 0.3,
        });
        if (searchValue) {
            return fuse.search(searchValue).map((space) => space.item);
        }
        return spaces;
    }, [spaces, searchValue]);
    if (project.data && !isProjectSetUpCompleted(project.data)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.analytics',
                    ),
                })}
            />
        );
    }

    if (userCannotViewSpace) {
        return <ForbiddenPanel />;
    }

    if (isLoading && !userCannotViewSpace) {
        return <PageSpinner />;
        // return <LoadingState title="Loading spaces" />;
    }

    return (
        <Page title={t('analytics.title')} withFixedContent withPaddedContent>
            <Stack spacing="xl">
                <Group position="apart" className="h-7">
                    <Text className="text-lg font-medium text-gray-800">
                        Analytics
                    </Text>
                    <Flex gap={'sm'} className="h-[28px]" align={'center'}>
                        <SearchInput
                            placeholder={t(
                                'analytics.search_analytics_placeholder',
                            )}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            size="xs"
                        />
                        {!isDemo && userCanManageSpace && hasSpaces && (
                            <Button
                                leftIcon={
                                    <PlusCircle size={14} color="white" />
                                }
                                onClick={handleCreateSpace}
                            >
                                New space
                            </Button>
                        )}
                    </Flex>
                </Group>

                <PinnedItemsProvider
                    projectUuid={projectUuid}
                    organizationUuid={user.data?.organizationUuid ?? ''}
                    pinnedListUuid={project.data?.pinnedListUuid ?? ''}
                >
                    <ResourceView
                        view={ResourceViewType.GRID}
                        items={wrapResourceView(
                            filteredSpaces.map(spaceToResourceViewItem),
                            ResourceViewItemType.SPACE,
                        )}
                        // tabs={
                        //     userCanManageProject
                        //         ? [
                        //               {
                        //                   id: 'shared',
                        //                   name: 'Shared with me',
                        //                   filter: (item) =>
                        //                       item.type ===
                        //                           ResourceViewItemType.SPACE &&
                        //                       (!item.data.isPrivate ||
                        //                           (!!user.data &&
                        //                               item.data.access.includes(
                        //                                   user.data.userUuid,
                        //                               ))),
                        //               },
                        //               {
                        //                   id: 'all',
                        //                   name: 'Admin Content View',
                        //                   infoTooltipText:
                        //                       'View all public and private spaces in your organization',
                        //               },
                        //           ]
                        //         : []
                        // }
                        headerProps={{
                            title: 'Spaces',
                        }}
                        emptyStateProps={
                            searchValue
                                ? {
                                      title: `No spaces found with the name "${searchValue}"`,
                                  }
                                : {
                                      icon: <IconFolders size={30} />,
                                      title: 'No spaces added yet',
                                      action:
                                          !isDemo && userCanManageSpace ? (
                                              <Button
                                                  onClick={handleCreateSpace}
                                              >
                                                  Create space
                                              </Button>
                                          ) : undefined,
                                  }
                        }
                    />
                </PinnedItemsProvider>
            </Stack>

            {isCreateModalOpen && (
                <SpaceActionModal
                    projectUuid={projectUuid}
                    actionType={ActionType.CREATE}
                    title="Create new space"
                    confirmButtonLabel="Create"
                    icon={IconFolderPlus}
                    onClose={() => setIsCreateModalOpen(false)}
                />
            )}
        </Page>
    );
};

export default SortmentSpaces;
