const BorderTopRightRadius = () => {
    return (
        <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.2"
                d="M11 2C11.5523 2 12 2.44772 12 3V11C12 11.5523 11.5523 12 11 12H3C2.44772 12 2 11.5523 2 11V3C2 2.44772 2.44772 2 3 2H11Z"
                fill="#9B9B9B"
            />
            <path
                d="M12.2129 1L8.39357 1.00049C8.28919 1.00049 8.18908 1.04 8.11527 1.11032C8.04146 1.18065 8 1.27603 8 1.37549C8 1.47494 8.04146 1.57033 8.11527 1.64065C8.18908 1.71098 8.28919 1.75049 8.39357 1.75049L11.2127 1.75013C11.765 1.75006 12.2128 2.19783 12.2128 2.75018L12.2127 5.62549C12.2127 5.72494 12.2541 5.82033 12.3279 5.89065C12.4017 5.96098 12.5018 6.00049 12.6062 6.00049C12.7106 6.00049 12.8107 5.96098 12.8845 5.89065C12.9583 5.82033 12.9998 5.72494 12.9998 5.62549L13 1.75C13 1.55109 12.9171 1.36032 12.7695 1.21967C12.6218 1.07902 12.4216 1 12.2129 1Z"
                fill="#343330"
            />
        </svg>
    );
};

export default BorderTopRightRadius;
