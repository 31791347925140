import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import TemplateBuilderManager from '@components/Templates/Builder/TemplateBuilderManager';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useGetProviderMetada } from '@hooks/useChannels';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    TemplateType,
    type ProviderTemplateDetails,
} from '@lightdash/common';
import { Box, Group, Stack } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import SetupContent from './SetupContent';
import TemplateContentPreview from './SetupContent/TemplateContentPreview';

const CampaignBuilderContent: React.FC<{}> = () => {
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { setShowFooterButtons, setTemplateDetails, resetContentMappings } =
        useCampaignContext((context) => context.actions);

    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { templateDetails, communicationDetails } = campaignPayload;
    const { data: providerMetadata, isInitialLoading } = useGetProviderMetada(
        communicationDetails?.providerId ?? '',
        campaignPayload?.channel ?? '',
    );

    const {
        data: templateMetadata,
        isInitialLoading: isTemplateMetadataLoading,
    } = useGetTemplateById(
        templateDetails?.id ?? '',
        campaignPayload?.channel !== CommunicationChannel.WHATSAPP,
    );

    const {
        data: syncedTemplateMetadata,
        isInitialLoading: isSyncedTemplateMetadataLoading,
    } = useGetSyncedTemplateByTemplateName(
        communicationDetails?.id ?? '',
        templateDetails?.id ?? '',
        campaignPayload?.channel === CommunicationChannel.WHATSAPP,
    );

    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid ?? '');

    const handleTemplateSelect = useCallback(
        (value: ProviderTemplateDetails) => {
            if (!value) return;
            setTemplateDetails({
                id:
                    campaignPayload?.channel === CommunicationChannel.WHATSAPP
                        ? value?.name
                        : value?.id,
                type:
                    campaignPayload?.channel === CommunicationChannel.WHATSAPP
                        ? TemplateType.EXTERNAL
                        : TemplateType.INTERNAL,
            });
            resetContentMappings();
            if (campaignUuid) {
                updateCampaign({
                    templateDetails: {
                        id:
                            campaignPayload?.channel ===
                            CommunicationChannel.WHATSAPP
                                ? value?.name
                                : value?.id,
                        type:
                            campaignPayload?.channel ===
                            CommunicationChannel.WHATSAPP
                                ? TemplateType.EXTERNAL
                                : TemplateType.INTERNAL,
                    },
                    contentMappings: {},
                });
            }
        },
        [
            setTemplateDetails,
            campaignPayload?.channel,
            resetContentMappings,
            campaignUuid,
            updateCampaign,
        ],
    );

    useEffect(() => {
        if (templateDetails?.id) {
            setShowFooterButtons({
                next: true,
                back: true,
            });
            return;
        }
        setShowFooterButtons({
            next: false,
            back: true,
        });
    }, [templateDetails, setShowFooterButtons]);

    const getTemplateMetadata = useMemo(() => {
        if (campaignPayload?.channel === CommunicationChannel.WHATSAPP) {
            return {
                ...syncedTemplateMetadata,
                contents: syncedTemplateMetadata?.languages[0]?.content ?? [],
                channel: CommunicationChannel.WHATSAPP,
            };
        }
        return templateMetadata;
    }, [campaignPayload?.channel, templateMetadata, syncedTemplateMetadata]);

    if (
        isInitialLoading ||
        isTemplateMetadataLoading ||
        isSyncedTemplateMetadataLoading
    )
        return <SuboptimalState loading />;

    return (
        <Group className="items-start gap-3.5 pb-[82px]" noWrap>
            <Stack className="w-full grow">
                <Box className="">
                    {!templateDetails?.id &&
                    providerMetadata &&
                    campaignPayload?.channel ? (
                        <Box className="w-[32rem]">
                            <TemplateBuilderManager
                                integration={providerMetadata}
                                isSingleSelect={true}
                                handleTemplateSelect={handleTemplateSelect}
                                integrationId={communicationDetails?.id ?? ''}
                                channel={campaignPayload?.channel ?? ''}
                                onClose={undefined}
                                editMode={false}
                            />
                        </Box>
                    ) : (
                        <>
                            <SetupContent
                                templateMetadata={getTemplateMetadata as any}
                                onTemplateSetupComplete={undefined}
                                fields={undefined}
                                additionalPropertySelectList={undefined}
                            />
                        </>
                    )}
                </Box>
            </Stack>
            {templateDetails?.id && getTemplateMetadata && (
                <Box className="flex justify-end w-full">
                    <TemplateContentPreview
                        templateMetadata={getTemplateMetadata as any}
                        grapesContainerId="html-preview-campaigns"
                        showDeviceAndThemeSwitcher={true}
                        onTemplateSelect={undefined}
                        isSetupComplete={undefined}
                        openContentSetupManager={() => {}}
                    />
                </Box>
            )}
        </Group>
    );
};

export default CampaignBuilderContent;
