import { Flex, Grid } from '@mantine/core';
import {
    TextAlignCenter,
    TextAlignJustify,
    TextAlignLeft,
    TextAlignRight,
    TextItalic,
    TextStrikethrough,
    TextUnderline,
    X,
} from '@phosphor-icons/react';
import { CssValues } from '../../types';

const IconRadioGroup = ({
    options,
    active,
    onChange,
}: {
    options: { label: string; value: string }[];
    active: string;
    onChange: (val: string) => void;
}) => {
    const color = 'rgb(var(--color-gray-600))';
    const getIcon = (val: string) => {
        const formattedVal = val.toLowerCase();
        switch (formattedVal) {
            case CssValues.ITALIC:
                return <TextItalic weight="regular" color={color} />;
            case CssValues.UNDERLINE:
                return <TextUnderline weight="regular" color={color} />;
            case CssValues.LINE_THROUGH:
                return <TextStrikethrough weight="regular" color={color} />;
            case CssValues.NORMAL:
            case CssValues.NONE:
                return <X weight="regular" color={color} />;
            case CssValues.JUSTIFY:
                return <TextAlignJustify color={color} />;
            case CssValues.RIGHT:
                return <TextAlignRight color={color} />;
            case CssValues.LEFT:
                return <TextAlignLeft color={color} />;
            case CssValues.CENTER:
            default:
                return <TextAlignCenter color={color} />;
        }
    };
    return (
        <Grid className="icon-radio-group-root" justify="center" align="center">
            {options.map((option) => (
                <Grid.Col
                    onClick={() => onChange(option.value)}
                    className={`group-item ${
                        active === option.value ? 'selected' : ''
                    }`}
                    key={option.value}
                    span={12 / options.length}
                >
                    <Flex justify={'center'} align={'center'}>
                        {getIcon(option.label)}
                    </Flex>
                </Grid.Col>
            ))}
        </Grid>
    );
};

export default IconRadioGroup;
