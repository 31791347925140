import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import LinkButton from '@components/common/LinkButton';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import JourneyTable from '@components/Journeys/Table/JourneyTable';
import Page from '@components/Page/Page';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import SetupPage from '@components/SetupPage';
import { useJourneys } from '@hooks/useJourney';
import { useLocale } from '@hooks/useLocale';
import { CommonReservedTags } from '@lightdash/common';
import { Text } from '@mantine/core';
import { PlusCircle } from '@phosphor-icons/react';
import { useProjectContext } from '@providers/ProjectProvider';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ButtonVariant } from '../mantineTheme';

const JourneysManagerContainer: React.FC<{}> = () => {
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { projectData: activeProject } = useProjectContext();
    const [currentPage, setCurrentPage] = useState(1);
    const [searchString, setSearchString] = useState('');
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    const { data: journeys, isInitialLoading } = useJourneys({
        perPage: 10,
        currentPage,
        query: `name=${searchString}&isDeleted=false&${
            isHiddenActive
                ? `includesTags=${CommonReservedTags.HIDDEN}`
                : `excludesTags=${CommonReservedTags.HIDDEN}`
        }`,
    });
    const { data: hiddenJourneys } = useJourneys({
        perPage: 10,
        currentPage: 1,
        query: `includesTags=${CommonReservedTags.HIDDEN}`,
    });
    useEffect(() => {
        if ((hiddenJourneys?.data?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [hiddenJourneys]);
    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.journeys',
                    ),
                })}
            />
        );
    }
    if (isInitialLoading) {
        return <SuboptimalState />;
    }

    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('journeys.page_title')}
            rightSection={
                <LinkButton
                    href={`/projects/${projectUuid}/journeys/create`}
                    variant={ButtonVariant.PRIMARY}
                    leftIcon={<PlusCircle color="white" />}
                >
                    <Text className="text-sm font-semibold">
                        {t('journeys.create_journey')}
                    </Text>
                </LinkButton>
            }
        >
            {journeys &&
            journeys.data &&
            journeys.data.length === 0 &&
            searchString === '' ? (
                <LinkButton
                    className="m-3.5"
                    href={`/projects/${projectUuid}/journeys/create`}
                    variant={ButtonVariant.PRIMARY}
                    leftIcon={<PlusCircle color="white" />}
                >
                    <Text className="text-sm font-semibold">
                        {t('journeys_manager.create_your_first_journey')}
                    </Text>
                </LinkButton>
            ) : (
                <JourneyTable
                    isDataFetching={isInitialLoading}
                    journeys={journeys && journeys.data}
                    onPageChange={(newPage) => {
                        setCurrentPage(newPage);
                    }}
                    onSearchChange={(newSearchString) => {
                        setSearchString(newSearchString);
                    }}
                    pagination={
                        journeys?.paginate ?? {
                            total: 0,
                            lastPage: 0,
                            currentPage: 0,
                            perPage: 0,
                            from: 0,
                            to: 0,
                        }
                    }
                    isHiddenActive={isHiddenActive}
                    onHiddenChange={(isHiddenFlag: boolean) => {
                        setIsHiddenActive(isHiddenFlag);
                        setCurrentPage(1);
                    }}
                    hideHiddenButton={(hiddenJourneys?.data?.length ?? 0) === 0}
                />
            )}
        </ManagerBuilderContainer>
    );
};

const JourneysManager: React.FC<{}> = () => {
    const { t } = useLocale();
    return (
        <Page
            title={t('journeys.page_title')}
            withFullHeight
            withNavbar
            withFixedContent
            withPaddedContent
        >
            <JourneysManagerContainer />
        </Page>
    );
};

export default JourneysManager;
