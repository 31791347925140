import { useFieldsWithEvents } from '@components/Audience/Filters/FiltersCard/useFieldsWithEvents';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import { useGetJourneyNodeParams } from '@hooks/useJourney';
import {
    JourneyParamsNames,
    JourneyTableType,
    SupportedDbtAdapter,
    type CompiledRelation,
    type JourneyDataSchema,
    type JourneyTable,
} from '@lightdash/common';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

export const useJourneyNodeParams = (nodeId: string) => {
    const [relationData, setRelationData] = useState<
        CompiledRelation | undefined
    >();

    const [journeyEventTables, setJourneyEventTables] = useState<
        Record<string, JourneyTable> | undefined
    >();

    const { journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { mutateAsync, isLoading, isError } = useGetJourneyNodeParams(nodeId);

    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: relationData,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    const { activeRelationUuid } = useRelationContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const { data: eventsData, eventsTableNames } = useFieldsWithEvents({
        relationData: relationData,
        activeRelationUuid,
        projectUuid,
    });

    const [journeyDataSchema, setJourneyDataSchema] =
        useState<JourneyDataSchema>();
    const journeyPayloadRef = useRef(journeyPayload);

    // Update the ref whenever journeyPayload changes
    useEffect(() => {
        journeyPayloadRef.current = journeyPayload;
    }, [journeyPayload]);

    const getJourneyNodeParams = useCallback(async () => {
        const { config } = journeyPayloadRef.current;
        if (!config) return;

        const result = await mutateAsync(journeyPayloadRef.current);
        setRelationData({
            tables: result.relationTables,
            joinedTables: [],
            name: '',
            label: '',
            tags: [],
            baseTable: '',
            targetDatabase: SupportedDbtAdapter.SNOWFLAKE, //INFO: default to snowflake. doesn't really matter since we don't use it for anything.
        });

        const eventTables: Record<string, JourneyTable> = {};
        for (const [key, table] of Object.entries(result.tables)) {
            if (table.type === JourneyTableType.EVENT) {
                eventTables[key] = table;
            }
        }
        setJourneyEventTables(eventTables);
        setJourneyDataSchema(result);
    }, [mutateAsync]);

    const cachedFields = useMemo(() => {
        if (!relationData) return {};
        return (
            relationData.tables[JourneyParamsNames.CACHED_PROPERTY]
                ?.dimensions ?? {}
        );
    }, [relationData]);

    const journeyEventFields = useMemo(() => {
        if (!journeyEventTables) return {};

        const mappedFields = Object.entries(
            journeyEventTables,
        ).reduce<FieldsWithSuggestions>((acc, [tableId, table]) => {
            const dimensions = table.dimensions;
            const fields = Object.keys(dimensions).reduce((fieldAcc, key) => {
                const dimension = dimensions[key];
                const fieldId = `${tableId}_${key}`;
                return {
                    ...fieldAcc,
                    [fieldId]: {
                        ...dimension,
                        suggestions: [],
                        tableType: table.type,
                        table: tableId,
                    },
                };
            }, {});
            return {
                ...acc,
                ...fields,
            };
        }, {});
        return mappedFields;
    }, [journeyEventTables]);

    const allFields = useMemo(
        () =>
            Object.values({ ...fieldsWithSuggestions, ...journeyEventFields }),
        [fieldsWithSuggestions, journeyEventFields],
    );

    useEffect(() => {
        void getJourneyNodeParams();
    }, [getJourneyNodeParams]);

    return {
        journeyDataSchema,
        isLoading,
        isError,
        getJourneyNodeParams,
        warehouseFields: fieldsWithSuggestions,
        allFields: allFields,
        cachedFields: cachedFields,
        journeyEventFields: journeyEventFields,
        eventsData: eventsData,
        eventsTableNames: eventsTableNames,
    };
};
