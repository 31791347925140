import TableIcon from '@components/common/IconPack/TableIcon';
import TraitIcon from '@components/common/IconPack/TraitIcon';
import { FieldType, RelationTableType } from '@lightdash/common';
import { Nut } from '@phosphor-icons/react';
import { t as translate } from 'i18next';
import { GlobalStyles } from '../../../../mantineTheme';
import { type PropertySelectGroupType } from '../FieldListItem/utils';

export interface FilterPropertyTypeMapper {
    propertyType: PropertySelectGroupType;
    label: string;
    shortLabel?: string;
    description: string;
    icon: React.ReactNode;
    onActiveClasses: {
        background: string;
        label: string;
        description: string;
    };
    searchPlaceholder: string;
}

export const filterPropertyTypeMapper: FilterPropertyTypeMapper[] = [
    {
        propertyType: RelationTableType.PRIMARY,
        label: translate('filter_property_type.primary_label'),
        shortLabel: translate('filter_property_type.primary_sublabel'),
        description: translate('filter_property_type.primary_description'),
        icon: (
            <TableIcon
                color={
                    GlobalStyles?.tableStyles?.[RelationTableType?.PRIMARY]
                        ?.textColor
                }
                type={RelationTableType.PRIMARY}
            />
        ),
        onActiveClasses: {
            background: 'bg-blu-100',
            label: 'text-blu-800',
            description: 'text-blu-800/60',
        },
        searchPlaceholder: translate(
            'field_filter_select.search_placeholder_primary_table',
        ),
    },
    {
        propertyType: RelationTableType.RELATED,
        label: translate('filter_property_type.related_label'),
        shortLabel: translate('filter_property_type.related_sublabel'),
        description: translate('filter_property_type.related_description'),
        icon: (
            <TableIcon
                color={
                    GlobalStyles?.tableStyles?.[RelationTableType?.RELATED]
                        ?.textColor
                }
                type={RelationTableType.PRIMARY}
            />
        ),
        onActiveClasses: {
            background: 'bg-green/10',
            label: 'text-green',
            description: 'text-green/60',
        },
        searchPlaceholder: translate(
            'field_filter_select.search_placeholder_related_table',
        ),
    },
    {
        propertyType: RelationTableType.EVENT,
        label: translate('filter_property_type.events_label'),
        description: translate('filter_property_type.events_description'),
        icon: (
            <TableIcon
                color={
                    GlobalStyles?.tableStyles?.[RelationTableType?.EVENT]
                        ?.textColor
                }
                type={RelationTableType.EVENT}
            />
        ),
        onActiveClasses: {
            background: 'bg-mustard-800/10',
            label: 'text-mustard-800',
            description: 'text-mustard-800/60',
        },
        searchPlaceholder: translate(
            'field_filter_select.search_placeholder_events_table',
        ),
    },
    {
        propertyType: FieldType.METRIC,
        label: translate('filter_property_type.custom_metrics_label'),
        shortLabel: translate('filter_property_type.custom_metrics_sublabel'),
        description: translate(
            'filter_property_type.custom_metrics_description',
        ),
        icon: <TraitIcon showFunction={true} />,
        onActiveClasses: {
            background: 'bg-purple/10',
            label: 'text-purple',
            description: 'text-purple/60',
        },
        searchPlaceholder: translate(
            'field_filter_select.search_placeholder_metric_property',
        ),
    },
    {
        propertyType: RelationTableType.AUDIENCE,
        label: translate('filter_property_type.audience_label'),
        shortLabel: translate('filter_property_type.audience_sublabel'),
        description: translate('filter_property_type.audience_description'),
        icon: <Nut color={'rgb(var(--color-purple)'} />,
        onActiveClasses: {
            background: 'bg-purple/10',
            label: 'text-purple',
            description: 'text-purple/60',
        },
        searchPlaceholder: translate(
            'field_filter_select.search_placeholder_audience',
        ),
    },
];
