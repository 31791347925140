export enum AlertEntities {
    CAMPAIGNS = 'campaigns',
    AUDIENCES = 'audiences',
    JOURNEYS = 'journeys',
}
export enum AlertingChannels {
    EMAIL = 'email',
}

export type UserAlertPreference = {
    id: string;
    userUuid: string;
    projectUuid: string;
    channel: AlertingChannels;
    entity: AlertEntities;
    createdAt: Date;
    updatedAt: Date;
};

export type UserAlertPreferenceResponse = UserAlertPreference & {
    firstName: string;
    lastName: string;
};

export type UserAlertPreferenceCreateRequest = Omit<
    UserAlertPreference,
    'id' | 'userUuid' | 'createdAt' | 'updatedAt'
>;
