import { type CacheMetadata, type Item } from '..';
import {
    type AdditionalMetric,
    type MetricQueryResponse,
    type NestedMetricQueryGroup,
} from './metricQuery';
import { type LightdashUser } from './user';

export enum AudienceInternalTags {
    INTERNAL = 'srt_internal',
}

export enum QueryGenerationStrategy {
    AUDIENCE_BUILDER = 'AUDIENCE_BUILDER',
    AI = 'AI',
    MANUAL = 'MANUAL',
}

export enum ReservedAudienceKeys {
    SRT_AUDIENCE = 'srt_audience:',
}

export enum AudienceStatus {
    ACTIVE = 'ACTIVE',
    DRAFT = 'DRAFT',
    INACTIVE = 'INACTIVE',
}

export enum AudienceRunStatus {
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    RUNNING = 'RUNNING',
    NEVER = 'NEVER',
    SCHEDULED = 'SCHEDULED',
}

export enum AudienceRunTypes {
    SCHEDULED = 'SCHEDULED',
    CRON = 'CRON',
    MANUAL = 'MANUAL',
}

export type AudiencePreviewConfig = {
    previewFields: string[];
};

export type DependentAudiences = string[];

export type Audience = {
    id: string;
    name: string;
    description: string | undefined;
    status: AudienceStatus;
    projectId: string;
    relationId: string;
    nestedMetricQuery: NestedMetricQueryGroup | undefined;
    sqlQuery: string | undefined;
    aiPrompt: string | undefined;
    generationStrategy: QueryGenerationStrategy;
    totalCount: number | undefined;
    createdAt: Date;
    createdBy: Pick<LightdashUser, 'userUuid' | 'firstName' | 'lastName'>;
    updatedAt: Date;
    updatedBy: Pick<
        LightdashUser,
        'userUuid' | 'firstName' | 'lastName'
    > | null;
    lastRunAt: Date | undefined;
    lastRunStatus: AudienceRunStatus;
    userAlias: string | undefined;
    runType: AudienceRunTypes | undefined;
    runAt: Date | undefined;
    cron: string | undefined;
    audienceLastMaterializationName: string | undefined;
    campaignLastMaterializationName: string | undefined;
    jobId: string | undefined;
    cronStartAt: Date | undefined;
    cronEndAt: Date | undefined;
    previewConfig: AudiencePreviewConfig | undefined;
    schedulerUuid: string | undefined;
    dependentAudiences: DependentAudiences | undefined;
    isArchived: boolean | undefined;
    lastArchivedAt: Date | undefined;
    tags: string[] | undefined;
    insights: AudienceInsights | undefined;
};

export type AudienceList = {
    data: Audience[] | null;
    paginate: {
        total?: number;
        lastPage?: number;
        currentPage: number;
        perPage: number;
        from: number;
        to: number;
    };
};

export type InsertBaseAudience = Pick<
    Audience,
    | 'name'
    | 'description'
    | 'generationStrategy'
    | 'sqlQuery'
    | 'userAlias'
    | 'previewConfig'
> &
    Partial<Pick<Audience, 'status' | 'tags' | 'insights'>>;

export type InsertBuilderAudience = InsertBaseAudience & {
    nestedMetricQuery: NestedMetricQueryGroup;
    aiPrompt?: string;
};

export type InsertAIAudience = InsertBaseAudience & {
    nestedMetricQuery?: NestedMetricQueryGroup;
    aiPrompt: string;
};

export type InsertManualAudience = InsertBaseAudience & {
    nestedMetricQuery?: NestedMetricQueryGroup;
    aiPrompt?: string;
};

export type InsertAudience =
    | InsertBuilderAudience
    | InsertAIAudience
    | InsertManualAudience;

export type UpdateAudience = Partial<
    Omit<Audience, 'updatedBy'> & { updatedBy: string }
>;
export type UpdateAudienceRun = Pick<
    UpdateAudience,
    | 'lastRunStatus'
    | 'audienceLastMaterializationName'
    | 'jobId'
    | 'schedulerUuid'
>;
export type APIUpdateAudience = Partial<Omit<UpdateAudience, 'status'>>; // status can't be updated via this type
export type AudienceFilters = {
    status?: string[];
    lastRunStatus: string[];
};

export type FindAudienceResponseDto = {
    status: string;
    results: AudienceList;
};

export type GetAudienceResponseDto = {
    status: string;
    results: Audience;
};

export type GetAudiencesResponseDto = {
    status: string;
    results: Audience[];
};

export type MaterializeAudCampDto = {
    status: string;
    results: string;
};

export type ScheduleOpts = {
    runType: AudienceRunTypes;
    runAt?: Date;
    cron?: string;
    cronStartAt?: Date;
    cronEndAt?: Date;
};

export type AudiencePreviewPayload = {
    metricQuery?: NestedMetricQueryGroup;
    sqlQuery?: string;
    limit?: number;
    offset?: number;
    fields?: string[];
};

export type AudienceCountPayload = {
    metricQuery?: NestedMetricQueryGroup;
    sqlQuery?: string;
};
export enum CommonReservedTags {
    HIDDEN = 'hidden',
}

export type AudienceInsights = {
    breakdownFilters: string[] | undefined;
    overlapFilters: string[] | undefined;
    reachabilityFilters:
        | {
              [key: string]: string[];
          }
        | undefined;
};

export type AudienceInsightsBreakdownPayload = {
    relationName: string;
    filters: AudiencePreviewPayload;
    breakdownDimensions: string[];
    rowLimit?: number;
};

export type AudienceOverlapPayload = {
    relationName: string;
    filters: AudiencePreviewPayload;
    materialisedAudNames: string[];
};

export type AudienceReachabilityPayload = {
    relationName: string;
    filters: AudiencePreviewPayload;
    channels: {
        [key: string]: string[];
    };
};

export type ApiInsightsRunQueryDto = {
    status: string;
    results: {
        [key: string]: {
            metricQuery: MetricQueryResponse; // tsoa doesn't support complex types like MetricQuery
            cacheMetadata: CacheMetadata;
            rows: any[];
            fields?: Record<string, Item | AdditionalMetric>;
        };
    };
};

export type ApiInsightsCountQueryDto = {
    status: string;
    results: {
        [key: string]: number;
    };
};

export enum AudienceBuilderHashParams {
    SQL = 'sql',
    VISUAL = 'visual',
}
