import TextInput from '@components/common/Inputs/TextInput';
import UnsavedChangesModal from '@components/common/modal/UnsavedChangesModal';
import { useIsEqual } from '@hooks/useIsEqual';
import { useLocale } from '@hooks/useLocale';
import { Box, Text } from '@mantine/core';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import SettingsTitle from '../SettingsTitle';

interface FormData {
    name: string;
    description: string | undefined;
}

const OverView: React.FC<{}> = () => {
    const { t } = useLocale();
    const { updateJourneyPayload } = useJourneyBuilderContext(
        (context) => context.actions,
    );
    const { isEditable } = useJourneyBuilderContext((context) => context.state);

    const { mutateAsyncJourney } = useJourneyBuilderContext(
        (context) => context.actions,
    );

    const { journeyPayload, initialJourneyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );

    const [defaultValues, setDefaultValues] = useState<FormData>({
        name: journeyPayload.name!,
        description: journeyPayload.description,
    });

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm<FormData>({
        defaultValues,
    });

    const watchFields = useWatch({ control });

    const hasFormChanged = useIsEqual(defaultValues, watchFields);

    useEffect(() => {
        updateJourneyPayload(watchFields);
    }, [watchFields, updateJourneyPayload]);

    const onSubmit = useCallback(
        async (data: FormData) => {
            setDefaultValues(data);
            reset(data);
            await mutateAsyncJourney(true);
        },
        [mutateAsyncJourney, reset],
    );

    return (
        <>
            <form
                name="update_overview"
                id="update_overview"
                onSubmit={handleSubmit(onSubmit)}
            >
                <SettingsTitle
                    icon={undefined}
                    title={t('organization_settings.menu_item_overview')}
                    subtitle={t('journey_settings_overview.subtitle')}
                />
                <Box className="w-1/3 p-3">
                    <Box className="mb-3">
                        <Text className="text-sm font-medium text-gray-800 mb-1.5">
                            {t('journey_settings_overview.name')}
                        </Text>
                        <TextInput
                            {...register('name')}
                            required
                            error={
                                errors.name &&
                                t('subscription_group.invalid_name')
                            }
                            placeholder={t(
                                'journey_builder.header_title_placeholder',
                            )}
                            autoFocus={false}
                            disabled={!isEditable}
                        />
                    </Box>
                    <Box>
                        <Box className="flex">
                            <Text className="text-sm font-medium text-gray-800 mb-1.5">
                                {t('journey_settings_overview.description')}
                            </Text>
                            <Text className="ml-1 text-sm font-medium text-gray-600">
                                {t(
                                    'custom_metric.dimension_modal_description_label_optional',
                                )}
                            </Text>
                        </Box>
                        <TextInput
                            {...register('description')}
                            error={
                                errors.description &&
                                t(
                                    'journey_settings_overview.invalid_description',
                                )
                            }
                            placeholder={t(
                                'journey_settings_overview.decription_textinput',
                            )}
                            disabled={!isEditable}
                        />
                    </Box>
                </Box>
            </form>
            <UnsavedChangesModal
                opened={hasFormChanged}
                secondaryActionButtonClick={() => {
                    setDefaultValues({
                        name: initialJourneyPayload.name!,
                        description: initialJourneyPayload.description
                            ? initialJourneyPayload.description
                            : '',
                    });
                    reset({
                        name: initialJourneyPayload.name,
                        description: initialJourneyPayload.description
                            ? initialJourneyPayload.description
                            : '',
                    });
                    updateJourneyPayload({
                        name: initialJourneyPayload.name,
                        description: initialJourneyPayload.description,
                    });
                }}
                disableButtons={false}
                form="update_overview"
                type="submit"
            />
        </>
    );
};

export default OverView;
