import { JourneyFiltersProvider } from '@components/Journeys/Builder/JourneyFilters/FiltersForm/JourneyFiltersProvider';
import React from 'react';
import SplitBlock from './SplitBlock';

export interface SplitActionProps {
    nodeId: string;
}
const Split: React.FC<SplitActionProps> = ({ nodeId }) => {
    return (
        <JourneyFiltersProvider nodeId={nodeId}>
            <SplitBlock nodeId={nodeId} />
        </JourneyFiltersProvider>
    );
};

export default React.memo(Split);
