export const previewConfig = {
    container: '#html-preview',
    width: '100%',
    // Disable all commands
    commands: { defaults: {} },
    // Disable all blocks
    blockManager: { blocks: [] },
    // Disable asset manager
    assetManager: { embedAsBase64: true },
    // Disable storage manager
    storageManager: false,
    deviceManager: {
        devices: [
            {
                id: 'desktop',
                name: 'Desktop',
                width: '', // default size
            },
            {
                id: 'mobile',
                name: 'Mobile portrait',
                width: '360px',
            },
        ],
    },
    panels: {
        defaults: [],
    },
};

export const getPreviewConfig = (grapesContainerId: string) => {
    return {
        ...previewConfig,
        container: `#${grapesContainerId}`,
    };
};

export const nonEditableComponentConfig = {
    editable: false,
    draggable: false,
    selectable: false,
    hoverable: false,
};

export const themeCSS = `
    .darkmode-layer {
        position: fixed;
        background: #fff;
        mix-blend-mode: difference;
    }

    .darkmode-layer--simple {
        background: #dedede;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    img, .darkmode-ignore {
        isolation: isolate;
        display: inline-block;
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .darkmode-toggle { display: none !important }
    }

    @supports (-ms-ime-align:auto), (-ms-accelerator:true) {
        .darkmode-toggle { display: none !important }
    }
`;

export const FREQUENCY_CAPPING_RULE_INPUT_MAX = 999;
