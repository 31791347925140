import {
    FilterGroupOperator,
    type FilterGroupOperatorType,
} from '@lightdash/common';
import { Box, Button, Text } from '@mantine/core';
import React from 'react';
import { ButtonVariant } from '../../../../mantineTheme';

interface FilterGroupOperatorConditionProps {
    toggleFilterCondition: (condition: FilterGroupOperatorType) => void;
    initialFilterCondition: FilterGroupOperatorType;
    isEditMode: boolean;
}

const FilterGroupOperatorCondition: React.FC<
    FilterGroupOperatorConditionProps
> = ({ toggleFilterCondition, initialFilterCondition, isEditMode }) => {
    return (
        <Box className="flex flex-col items-center ml-8 w-fit">
            <span className="h-4 w-0.5 bg-shade-6"></span>
            <Button
                variant={ButtonVariant.SUBTLE}
                className="p-2 text-sm font-normal text-gray-600 border-dashed hover:font-medium border-base border-gray-250"
                onClick={() => {
                    toggleFilterCondition(
                        initialFilterCondition === FilterGroupOperator.and
                            ? FilterGroupOperator.or
                            : FilterGroupOperator.and,
                    );
                }}
                disabled={!isEditMode}
            >
                <Text className="text-xs font-semibold text-gray-800 uppercase">
                    {initialFilterCondition}
                </Text>
            </Button>
            <span className="h-4 w-0.5 bg-shade-6"></span>
        </Box>
    );
};

export default FilterGroupOperatorCondition;
