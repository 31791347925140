import { type MetricFilterRuleWithFieldId } from '@components/Explorer/CustomMetricModal/FilterForm';
import { getCustomMetricName } from '@components/Explorer/CustomMetricModal/utils';
import {
    DimensionType,
    FilterOperator,
    friendlyName,
    getFieldRef,
    getFilterGroupItemsPropertyName,
    getFilterRuleWithDefaultValue,
    getItemId as getFieldId,
    getItemsFromFilterGroup,
    isAdditionalMetric,
    isAndFilterGroup,
    isAndNestedMetricQuery,
    isDimension,
    isFilterableDimension,
    isFilterGroup,
    isMetric,
    isOrFilterGroup,
    JoinType,
    RelationTableType,
    type AdditionalMetric,
    type AndFilterGroup,
    type CompiledRelation,
    type CreateSavedChartVersion,
    type FilterableDimension,
    type FilterableField,
    type FilterGroup,
    type FilterGroupItem,
    type FilterRule,
    type Filters,
    type MetricFilterRule,
    type MetricQuery,
    type MetricType,
    type NestedMetricQueryGroup,
    type OrFilterGroup,
} from '@lightdash/common';
import { metricQueryDefaultState } from '@providers/AudienceProvider';
import { generateShortUUID } from '@utils/helpers';
import { getTableRelationType } from '@utils/relation';
import { v4 as uuidv4 } from 'uuid';
import type {
    FieldsWithSuggestions,
    FieldWithSuggestions,
} from './Filters/FiltersProvider';

export const getUnsavedChartVersion = (
    nestedMetricQuery: NestedMetricQueryGroup | undefined,
) => {
    if (!nestedMetricQuery) return [];
    let metricQueries: CreateSavedChartVersion[] = [];

    if (nestedMetricQuery) {
        const savedMetricQueries = isAndNestedMetricQuery(nestedMetricQuery)
            ? nestedMetricQuery.and
            : nestedMetricQuery.or;
        savedMetricQueries.forEach((metricQuery) => {
            metricQueries.push({
                ...metricQueryDefaultState,
                metricQuery: metricQuery as MetricQuery,
            });
        });
    }
    return metricQueries;
};

/**
 * this function is used to check if the filter is an event
 * @param filter it is the filter that is passed to the function
 * @param fieldsMap it is the fields map that is passed to the function
 * @returns it returns true if the filter is an event
 */
export const isEventField = (
    filter: FilterGroupItem,
    fieldsMap: FieldsWithSuggestions,
) => {
    if (isFilterGroup(filter)) return false;
    const fieldId = filter.target.fieldId;
    return fieldsMap[fieldId]?.tableType === RelationTableType.EVENT;
};

/**
 * this function is used to check if the filter is an event name
 * @param filter it is the filter that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event name
 */
export const isEventNameProperty = (
    filter: FilterGroupItem,
    eventsMap: FieldWithSuggestions[],
) => {
    if (filter && isFilterGroup(filter)) return false;
    const filterValue = filter?.values?.[0] ?? '';
    return eventsMap.some(
        (event) =>
            (event as FilterableDimension).fieldReference === filterValue,
    );
};

/**
 * this function used to place all the events at top if the first filter is an event and if the first filter is dimension it places all the dimensions at top
 * @param filterGroup it is the filter group that is passed to the function
 * @param eventFieldIds it is the event field ids that is passed to the function
 * @returns it returns the categorized filters we have the filter group in filter group so in eeach nested groups we tries to arrange the dimensions or events at their desired places.
 */
export const categorizeDimensionsAndEvents = (
    filterGroup: FilterGroup | undefined,
    fieldsMap: FieldsWithSuggestions,
    eventsMap: FieldWithSuggestions[],
): FilterGroup | undefined => {
    const categorizeFilters = (
        filters: FilterGroupItem[],
    ): FilterGroupItem[] => {
        const nonGroupFilters = filters.filter(
            (filter) => !isFilterGroup(filter),
        );
        const eventsName = nonGroupFilters
            .filter((filter) => isEventField(filter, fieldsMap))
            .filter((event) => isEventNameProperty(event, eventsMap));
        const eventDimensions = nonGroupFilters
            .filter((filter) => isEventField(filter, fieldsMap))
            .filter((event) => !isEventNameProperty(event, eventsMap));
        const dimensions = nonGroupFilters.filter(
            (filter) => !isEventField(filter, fieldsMap),
        );
        const groups = filters.filter(isFilterGroup);

        return [
            ...eventsName,
            ...eventDimensions,
            ...dimensions,
            ...groups
                .map((group) =>
                    categorizeDimensionsAndEvents(group, fieldsMap, eventsMap),
                )
                .filter((item): item is FilterGroup => item !== undefined),
        ];
    };

    if (!filterGroup) return undefined;

    if ('or' in filterGroup) {
        const orFilters = (filterGroup as OrFilterGroup).or;
        return {
            ...filterGroup,
            or: categorizeFilters(orFilters as FilterGroupItem[]),
        };
    } else if ('and' in filterGroup) {
        const andFilters = (filterGroup as AndFilterGroup).and;
        return {
            ...filterGroup,
            and: categorizeFilters(andFilters as FilterGroupItem[]),
        };
    }

    return filterGroup;
};

/**
 * this function is used to check if the filter is an event dimension
 * @param filter it is the filter that is passed to the function
 * @param fieldsMap it is the fields map that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event dimension
 */
export const isEventDimension = (
    filter: FilterGroupItem,
    fieldsMap: FieldsWithSuggestions,
    eventsMap: FieldWithSuggestions[],
) => {
    if (isFilterGroup(filter)) return false;
    const filterValue = filter?.values?.[0] ?? '';
    return (
        fieldsMap[filter.target.fieldId]?.tableType ===
            RelationTableType.EVENT &&
        !eventsMap.find(
            (event) =>
                isDimension(event) && event.fieldReference === filterValue,
        )
    );
};

/**
 * this function is used to check if any filter is an event dimension
 * @param filters it is the filters that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event dimension
 */
export const hasEventNameFilter = (
    filters: FilterGroupItem[],
    eventsMap: FieldWithSuggestions[],
) => {
    return filters.some((filter) => {
        const filterValue = (filter as FilterRule)?.values?.[0] ?? '';
        return eventsMap.find(
            (event) =>
                isDimension(event) &&
                isFilterableDimension(event) &&
                event.fieldReference === filterValue,
        );
    });
};

/**
 * this function is used to count the event name filters in the filterGroup
 * @param filters it is the filters that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns the count of the event name filters in the group
 */
export const getEventFieldWithEventNameCount = (
    filters: FilterGroupItem[],
    eventsMap: FieldWithSuggestions[],
    fieldsMap: FieldsWithSuggestions,
) => {
    return filters.filter(
        (filter) =>
            isEventField(filter, fieldsMap) &&
            isEventNameProperty(filter, eventsMap),
    ).length;
};

/**
 * this function is check number of filters present in the filter group with the given value for events
 * @param filters it is the filters that is passed to the function
 * @param value it is the value that is passed to the function
 * @returns it returns the count of the event name filters in the group
 */
export const getEventNameFilterCount = (
    filters: FilterGroupItem[],
    field: FilterGroupItem,
) => {
    if (isFilterGroup(field)) return 0;
    return filters.filter((filter) => {
        if (isFilterGroup(filter)) return 0;
        return filter.target.fieldId === field.target.fieldId;
    }).length;
};

/**
 * this function is used to a filter with fly additional metric as target.
 *@param field it is the field that is passed to the function
 *@param value it is the value that is passed to the function
 *@param targetId it is the target id that is passed to the function
 *@returns it returns the filter rule with fly additional metric as target.
 */
const createFilterRuleFromFieldForFlyAdditionalMetric = (
    field: FilterableField,
    value: any,
    targetId: string,
): FilterRule =>
    getFilterRuleWithDefaultValue(
        field,
        {
            id: uuidv4(),
            target: {
                fieldId: targetId,
            },
            operator:
                value === null ? FilterOperator.NULL : FilterOperator.EQUALS,
        },
        // eslint-disable-next-line no-nested-ternary
        value ? (Array.isArray(value) ? value : [value]) : null, // When `null`, don't set default value if no value is provided
    );

interface AddFilterRuleArgsForFlyAdditionalMetric {
    filters: Filters;
    field: FilterableField;
    value: any;
    targetId: string;
}

/**
 * this function used to create filter rule with fly additional metric as target.
 * @param filters,
 * @param field,
 * @param value,
 * @param targetId,
 * @returns it returns the filter rule with fly additional metric as target.
 */
export const createFilterRuleWithFlyAdditionalMetric = ({
    filters,
    field,
    value,
    targetId,
}: AddFilterRuleArgsForFlyAdditionalMetric) => {
    const groupKey = 'metrics';

    const group = filters[groupKey];
    return {
        ...filters,
        [groupKey]: {
            id: uuidv4(),
            ...group,
            [getFilterGroupItemsPropertyName(group)]: [
                ...getItemsFromFilterGroup(group),
                createFilterRuleFromFieldForFlyAdditionalMetric(
                    field,
                    value,
                    targetId,
                ),
            ],
        },
    };
};

/**
 * it takes filterrule and fields and gives the additionametric in filters
 * @param fields
 * @param filterRules
 * @returns it returns the additional metric in filters
 */
export const getAdditionalMetricInFilters = (
    fieldsMap: FieldsWithSuggestions,
    filterRules: FilterRule[],
    eventsMap: FieldWithSuggestions[],
) => {
    return filterRules.filter((filter) => {
        const field = fieldsMap[filter.target.fieldId];
        if (isMetric(field)) {
            const subFilters = field.filters?.[0];

            return isEventNameProperty(subFilters as FilterRule, eventsMap);
        }
        return false;
    });
};

/**
 * this function is used to get the event properties and metric properties in the filters
 * @param fieldsMap
 * @param filterRules
 * @param eventsMap
 * @returns it returns the event properties and metric properties in the filters
 */
export const getMetricAndEventPropertiesInFilters = (
    fieldsMap: FieldsWithSuggestions,
    filterRules: FilterGroupItem[],
    eventsMap: FieldWithSuggestions[],
    additionalMetrics: AdditionalMetric[] | undefined,
) => {
    return filterRules.reduce(
        (acc, filter) => {
            if (isFilterGroup(filter)) return acc;
            const field = fieldsMap[filter.target.fieldId];
            if (isMetric(field)) {
                const subFilters = field.filters?.[0];

                if (
                    isEventNameProperty(
                        subFilters as FilterGroupItem,
                        eventsMap,
                    ) &&
                    additionalMetrics?.some(
                        (metric) => metric.name === field.name,
                    )
                ) {
                    acc.eventPropertiesMetrics.push(filter);
                } else {
                    acc.metricProperties.push(filter);
                }
            }
            return acc;
        },
        {
            eventPropertiesMetrics: [] as FilterRule[],
            metricProperties: [] as FilterRule[],
        },
    );
};

/**
 * this function is used to check if the filter is an event metric
 * @param filter it is the filter that is passed to the function
 * @param eventsMap it is the events map that is passed to the function
 * @returns it returns true if the filter is an event metric
 */
export const isEventMetricCreatedByAdditionalMetric = (
    field: FilterableField | undefined,
    eventsMap: FieldWithSuggestions[],
    additionalMetrics: AdditionalMetric[] | undefined,
) => {
    if (!field) return false;
    if (
        isMetric(field) &&
        additionalMetrics?.some((metric) => metric.name === field.name)
    ) {
        const subFilters = field.filters?.[0];
        return isEventNameProperty(subFilters as FilterRule, eventsMap);
    }
    return false;
};

/**
 * to delete the all event dimensions from the filters
 * @param dimensions
 * @param eventsMap
 * @returns
 */
export const deleteAllEventDimensions = (
    dimensions: FilterGroup | undefined,
    eventsMap: FieldWithSuggestions[],
    fieldsMap: FieldsWithSuggestions,
) => {
    if (!dimensions) return undefined;
    if (isFilterGroup(dimensions) && isAndFilterGroup(dimensions)) {
        return {
            ...dimensions,
            and: dimensions.and.filter((dimension) => {
                return !isEventDimension(dimension, fieldsMap, eventsMap);
            }),
        };
    }
    if (isFilterGroup(dimensions) && isOrFilterGroup(dimensions)) {
        return {
            ...dimensions,
            or: dimensions.or.filter((dimension) => {
                return !isEventDimension(dimension, fieldsMap, eventsMap);
            }),
        };
    }
    return dimensions;
};

/**
 * this function is used to create filter rule from event name field
 * @param field
 * @returns
 */
export const createFilterRuleFromEventNameField = (
    field: FilterableDimension,
    value: string,
) => {
    return {
        id: uuidv4(),
        target: {
            fieldId: getFieldId(field),
            fieldRef: getFieldRef(field),
        },
        operator: FilterOperator.EQUALS,
        values: [value],
    };
};

/**
 * this function is used to get all the metrics in the filters
 * @param metricsFilterGroup
 * @param eventPropertiesFilterGroup
 * @returns it returns the all metrics in the filters
 */
export const getAllMetrics = (
    metricsFilterGroup: FilterGroup | undefined,
    eventPropertiesFilterGroup: FilterGroup | undefined,
) => {
    if (!metricsFilterGroup || !eventPropertiesFilterGroup) return undefined;
    if (
        isAndFilterGroup(metricsFilterGroup) &&
        isAndFilterGroup(eventPropertiesFilterGroup)
    ) {
        return {
            ...metricsFilterGroup,
            and: [...metricsFilterGroup.and, ...eventPropertiesFilterGroup.and],
        };
    } else if (
        isOrFilterGroup(metricsFilterGroup) &&
        isOrFilterGroup(eventPropertiesFilterGroup)
    ) {
        return {
            ...metricsFilterGroup,
            or: [...metricsFilterGroup.or, ...eventPropertiesFilterGroup.or],
        };
    }
    return undefined;
};

/**
 * this function is used to check if the field can be converted to an additional metric to achieve records in related tables, and events.
 * @param {FilterableField} field
 * @param {CompiledRelation} activeRelation
 * @returns {boolean} it returns true if the field can be converted to an additional metric
 */
export const shouldConvertFieldToAdditionalMetric = (
    field: FilterableField,
    activeRelation: CompiledRelation,
): boolean => {
    if (!field || !field.table) return false;
    if (
        activeRelation.tables[field.table]?.type === RelationTableType.EVENT &&
        field.type === DimensionType.EVENT
    )
        return true;
    const joinType = getTableRelationType(field.table, activeRelation);
    if (joinType === JoinType.one_many || joinType === JoinType.many_one)
        return true;
    return false;
};

const getCustomMetricDescription = (
    metricType: MetricType,
    label: string,
    tableLabel: string,
    filters: MetricFilterRule[],
) =>
    `${friendlyName(metricType)} of ${label} on the table ${tableLabel} ${
        filters.length > 0
            ? `with filters ${filters
                  .map((filter) => filter.target.fieldRef)
                  .join(', ')}`
            : ''
    }`;

/**
 * this function is used to prepare the additional metric data
 * @param item
 * @param type
 * @param metricFilters
 * @param activeRelation
 * @returns it returns the additional metric data
 */
export const prepareAdditionalMetricData = ({
    item,
    type,
    metricFilters,
    activeRelation,
}: {
    item: FilterableField;
    type: MetricType;
    metricFilters: MetricFilterRuleWithFieldId[];
    activeRelation: CompiledRelation;
}): AdditionalMetric | undefined => {
    if (!item.table || !item.name || !activeRelation.tables[item.table]) return;
    const customMetricFilters: MetricFilterRule[] = metricFilters.map(
        ({ target: { fieldId, ...restTarget }, ...customMetricFilter }) => ({
            ...customMetricFilter,
            target: restTarget,
        }),
    );
    const table = item.table && activeRelation.tables[item.table];
    if (!table || !item.name || !table.name || !table.primaryKey) return;
    let name =
        getCustomMetricName(
            item.table,
            table.primaryKey,
            isAdditionalMetric(item) &&
                isAdditionalMetric(item) &&
                'baseDimensionName' in item &&
                item.baseDimensionName
                ? item.baseDimensionName
                : table.primaryKey,
        ) +
        '_' +
        generateShortUUID();

    // Some warehouses don't support long names, so we need to truncate these custom metrics if the name is too long and make it unique
    if (name.length >= 64) {
        name = name.slice(0, 52) + '_' + generateShortUUID();
    }
    return {
        uuid: uuidv4(),
        baseDimensionName: table.primaryKey,
        table: item.table,
        sql: item.sql,
        type,
        filters: customMetricFilters.length > 0 ? customMetricFilters : [],
        label: table.primaryKey,
        name: name,

        description: getCustomMetricDescription(
            type,
            table.primaryKey,
            table.label,
            customMetricFilters,
        ),
    };
};

export const isDerivedAdditionalMetric = (
    field: FilterableField,
    additionalMetrics: AdditionalMetric[],
) => {
    return additionalMetrics.some(
        (metric) => metric.name === field.name && metric.table === field.table,
    );
};
