import { sortmentApi } from '@api/index';
import {
    type ApiError,
    type ApiSuccessEmpty,
    type ColumnDescription,
    type ColumnDescriptionRequest,
    type CompiledRelation,
    type DimensionConfigurationResult,
    type TableDescription,
    type WarehouseTableInfo,
} from '@lightdash/common';
import {
    useMutation,
    useQueries,
    useQuery,
    type UseQueryOptions,
} from '@tanstack/react-query';
import { t as translate } from 'i18next';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import useQueryError from './useQueryError';

const getRelationSchema = async (projectId: string, relationId: string) =>
    sortmentApi<CompiledRelation>({
        url: `/projects/${projectId}/relations/${relationId}`,
        method: 'GET',
        body: undefined,
    });

const useRelation = (
    relationId: string,
    useQueryOptions?: UseQueryOptions<CompiledRelation, ApiError>,
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const setErrorResponse = useQueryError();

    const relations = useQuery<CompiledRelation, ApiError>({
        queryKey: [QueryKeys.RELATION_SCHEMA, projectUuid, relationId],
        queryFn: () => getRelationSchema(projectUuid, relationId),
        enabled: !!relationId,
        onError: (result) => setErrorResponse(result),
        retry: false,
        ...useQueryOptions,
    });
    return relations;
};

interface RelationCacheConfig {
    projectUuid: string;
    relationUuid: string;
    tableName: string;
    columnName: string;
}

export const getRelationCacheConfig = async ({
    projectUuid,
    relationUuid,
    tableName,
    columnName,
}: RelationCacheConfig) =>
    sortmentApi<DimensionConfigurationResult>({
        url: `/projects/${projectUuid}/relations/${relationUuid}/tables/${tableName}/dim/${columnName}/cache-config`,
        method: 'GET',
        body: undefined,
    });

export const useRelationCacheConfig = ({
    relationUuid,
    tableName,
    columnName,
}: RelationCacheConfig) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const relations = useQuery<DimensionConfigurationResult, ApiError>({
        queryKey: [
            QueryKeys.CACHE_CONFIG,
            projectUuid,
            relationUuid,
            tableName,
            columnName,
        ],
        queryFn: () =>
            getRelationCacheConfig({
                projectUuid,
                relationUuid,
                tableName,
                columnName,
            }),
        enabled: !!relationUuid && !!projectUuid && !!tableName && !!columnName,
        retry: false,
    });

    return relations;
};

export const useMultipleRelationCacheConfigs = (
    configs: RelationCacheConfig[],
) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();

    const queries: UseQueryOptions<DimensionConfigurationResult, ApiError>[] =
        configs.map((config) => ({
            queryKey: [
                QueryKeys.MULTIPLE_CACHE_CONFIG,
                projectUuid,
                config.relationUuid,
                config.tableName,
                config.columnName,
            ],
            queryFn: () =>
                getRelationCacheConfig({
                    projectUuid,
                    relationUuid: config.relationUuid,
                    tableName: config.tableName,
                    columnName: config.columnName,
                }),
            enabled:
                !!config.relationUuid &&
                !!projectUuid &&
                !!config.tableName &&
                !!config.columnName,
            retry: false,
        }));

    return useQueries({ queries });
};

const getUpdatedDimensions = ({
    tableName,
    projectUuid,
    relationId,
}: {
    tableName: string;
    projectUuid: string;
    relationId: string;
}) => {
    return sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/relations/${relationId}/tables/${tableName}/sync`,
        method: 'PATCH',
        body: undefined,
    });
};

export const useRefreshDimensions = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { showToastSuccess } = useNotify();
    return useMutation<
        ApiSuccessEmpty,
        ApiError,
        { tableName: string; relationId: string }
    >({
        mutationKey: [QueryKeys.REFRESH_DIMENSIONS, projectUuid],
        mutationFn: ({
            tableName,
            relationId,
        }: {
            tableName: string;
            relationId: string;
        }) => getUpdatedDimensions({ tableName, projectUuid, relationId }),
        onSuccess: () => {
            showToastSuccess({
                title: translate('refresh_job_started'),
                autoClose: 3000,
            });
        },
    });
};

const generateTableDescription = (projectUuid: string, body: any) => {
    return sortmentApi<TableDescription>({
        url: `/projects/${projectUuid}/ai/table-description`,
        method: 'POST',
        body: JSON.stringify(body),
    });
};

export const useGenerateTableDescription = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useMutation<TableDescription, ApiError, WarehouseTableInfo>({
        mutationKey: [QueryKeys.GENERATE_TABLE_DESCRIPTION, projectUuid],
        mutationFn: (body) => generateTableDescription(projectUuid, body),
    });
};

const generateColumnDescription = (projectUuid: string, body: any) => {
    return sortmentApi<ColumnDescription>({
        url: `/projects/${projectUuid}/ai/column-description`,
        method: 'POST',
        body: JSON.stringify(body),
    });
};

export const useGenerateColumnDescription = () => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    return useMutation<ColumnDescription, ApiError, ColumnDescriptionRequest>({
        mutationKey: [QueryKeys.GENERATE_COLUMN_DESCRIPTION, projectUuid],
        mutationFn: (body) => generateColumnDescription(projectUuid, body),
    });
};

export default useRelation;
