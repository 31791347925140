import { type ContentStepComponentProps } from '@components/Campaigns/Builder/types';
import {
    getNextContentStep,
    getPreviousContentStep,
} from '@components/Campaigns/Builder/utils';
import Checkbox from '@components/common/Checkbox';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignFieldType,
    CommunicationChannel,
    ContentMappingSection,
    type ExternalTemplate,
    type ParsedVariable,
    type Template,
} from '@lightdash/common';
import { Box, Divider, Group, Menu, Stack, Text } from '@mantine/core';
import {
    useCampaignContext,
    type TrackedLinks,
} from '@providers/CampaignProvider';
import { isEqual } from 'lodash';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    type SyntheticEvent,
} from 'react';
import { useParams } from 'react-router-dom';

const LinkTracking: React.FC<ContentStepComponentProps> = ({
    templateMetadata,
    setActiveContentStep,
    activeContentStep,
}) => {
    const initialData = useRef<any>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { t } = useLocale();
    const { state, actions } = useCampaignContext((context) => context);
    const { campaignPayload, editor } = state;
    // const { communicationDetails, templateDetails } = campaignPayload;
    const { setCurrentStepCallback, setPreviousStepCallback, setTrackedLinks } =
        actions;

    const { mutate: updateCampaign } = useUpdateCampaign(campaignUuid ?? '');

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    const linkChildren = useMemo(() => {
        let result: {
            [key: string]: {
                tagName: string;
                imageUrl: string;
                content: string;
            };
        } = {};
        if (editor) {
            const rootComponent = editor?.DomComponents.getWrapper();
            if (rootComponent) {
                const linkComponents = rootComponent?.findType('link');
                linkComponents.forEach((comp) => {
                    const childrenComponents = comp.components();
                    let childrenComponentType = '';
                    let imageUrl: string = '';
                    childrenComponents.forEach((component, index) => {
                        if (component.attributes.type && index === 0) {
                            childrenComponentType = component.attributes.type;
                            if (childrenComponentType === 'image') {
                                imageUrl = component.attributes.src;
                            }
                        }
                    });
                    const child = comp?.getChildAt(0);
                    const id = comp?.attributes?.attributes?.['data-srt-id'];
                    if (id) {
                        result = {
                            ...result,
                            [id]: {
                                tagName:
                                    (childrenComponentType === 'textnode'
                                        ? comp?.attributes?.type
                                        : childrenComponentType) || '',
                                content:
                                    childrenComponentType !== 'image'
                                        ? child?.content || comp?.getInnerHTML()
                                        : '',
                                imageUrl,
                            },
                        };
                    }
                });
            }
        }
        return result;
    }, [editor]);

    const links = useMemo(
        () =>
            (campaignPayload?.channel === CommunicationChannel.WHATSAPP
                ? (templateMetadata as ExternalTemplate)?.variables
                : (templateMetadata as Template)?.contents[0]
                      .parsedVariables) as unknown as ParsedVariable[],
        [campaignPayload?.channel, templateMetadata],
    );

    const getTrackedLinksPayload = useCallback(
        (enableTracking: boolean) => {
            if (links) {
                const payload = links.reduce((accum, curr) => {
                    if (accum) {
                        return {
                            ...accum,
                            [curr.key]: {
                                value: curr.value,
                                defaultValue: '',
                                type: CampaignFieldType.STATIC,
                                enableTracking,
                            },
                        };
                    }
                    return {
                        [curr.key]: {
                            value: curr.value,
                            defaultValue: '',
                            type: CampaignFieldType.STATIC,
                            enableTracking,
                        },
                    };
                }, {});
                return payload;
            }
            return {};
        },
        [links],
    );

    const trackedLinks: TrackedLinks = useMemo(() => {
        if (campaignPayload?.contentMappings) {
            return Object.keys(
                campaignPayload?.contentMappings?.[
                    ContentMappingSection.LINK_TRACKING
                ] ?? {},
            )
                .filter(
                    (mapping) =>
                        campaignPayload?.contentMappings?.[
                            ContentMappingSection.LINK_TRACKING
                        ]?.[mapping]?.enableTracking !== undefined,
                )
                .reduce((accum, curr) => {
                    if (accum) {
                        return {
                            ...accum,
                            [curr]: campaignPayload?.contentMappings?.[
                                ContentMappingSection.LINK_TRACKING
                            ]?.[curr],
                        };
                    }
                    return {
                        [curr]: campaignPayload?.contentMappings?.[
                            ContentMappingSection.LINK_TRACKING
                        ]?.[curr],
                    };
                }, {});
        }
        return [];
    }, [campaignPayload?.contentMappings]);

    const handleTrackAll = (e: SyntheticEvent) => {
        if ('checked' in e.target && e.target.checked && links) {
            const payload = getTrackedLinksPayload(true);
            setTrackedLinks({ ...trackedLinks, ...payload });
            return;
        }
        const payload = getTrackedLinksPayload(false);
        setTrackedLinks({ ...trackedLinks, ...payload });
    };

    const handleTrackLink = (val: boolean, link: ParsedVariable) => {
        if (val) {
            setTrackedLinks({
                ...trackedLinks,
                [link.key]: {
                    value: link.value,
                    type: CampaignFieldType.STATIC,
                    defaultValue: '',
                    enableTracking: true,
                },
            });
            return;
        }
        setTrackedLinks({
            ...trackedLinks,
            [link.key]: {
                ...trackedLinks[link.key],
                enableTracking: false,
            },
        });
    };

    const nextStep = useMemo(
        () =>
            getNextContentStep(
                activeContentStep,
                campaignPayload?.channel,
                (campaignPayload?.channel === CommunicationChannel.WHATSAPP
                    ? (templateMetadata as ExternalTemplate)?.variables
                    : (templateMetadata as Template)?.contents[0]
                          .parsedVariables) as unknown as ParsedVariable[],
            ),
        [activeContentStep, campaignPayload?.channel, templateMetadata],
    );

    const prevStep = useMemo(
        () =>
            getPreviousContentStep(
                activeContentStep,
                campaignPayload?.channel,
                (campaignPayload?.channel === CommunicationChannel.WHATSAPP
                    ? (templateMetadata as ExternalTemplate)?.variables
                    : (templateMetadata as Template)?.contents[0]
                          .parsedVariables) as unknown as ParsedVariable[],
            ),
        [activeContentStep, campaignPayload?.channel, templateMetadata],
    );

    const nextStepCallback = useCallback(() => {
        if (nextStep) {
            setActiveContentStep(nextStep);
            if (
                campaignUuid &&
                !isEqual(
                    campaignPayload.contentMappings,
                    initialData.current.contentMappings,
                )
            ) {
                updateCampaign({
                    contentMappings: campaignPayload.contentMappings,
                });
            }
        }
    }, [
        nextStep,
        setActiveContentStep,
        campaignUuid,
        updateCampaign,
        campaignPayload.contentMappings,
    ]);

    const prevStepCallback = useCallback(() => {
        if (prevStep) {
            setActiveContentStep(prevStep);
        }
    }, [prevStep, setActiveContentStep]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: nextStepCallback,
            skipExecutionAfterCallback: Boolean(nextStep),
        });
    }, [nextStepCallback, nextStep, setCurrentStepCallback]);

    useEffect(() => {
        setPreviousStepCallback({
            callback: prevStepCallback,
            skipExecutionAfterCallback: Boolean(prevStep),
        });
    }, [prevStepCallback, prevStep, setPreviousStepCallback]);

    useEffect(() => {
        if (links) {
            const payload = getTrackedLinksPayload(true);
            setTrackedLinks({ ...trackedLinks, ...payload });
        }
        // INFO - Added this because we want to call this hook only at the first render
        // and not any sub sequent update of trackedLinks
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTrackedLinksPayload, links, setTrackedLinks]);

    return (
        <Box>
            <Text>{t('campaigns_builder.tracking.link_tracking')}</Text>
            <Text className="mt-1 text-xs font-medium text-gray-600">
                {t('campaigns_builder.tracking.link_tracking_description')}
            </Text>
            <Box className="border-base p-[6px] mt-3 max-h-[550px] overflow-scroll">
                <Group className="py-[6px] px-3">
                    <Checkbox
                        checked={
                            Object.keys(trackedLinks).length ===
                                links?.length &&
                            Object.values(trackedLinks).every(
                                (link) => link.enableTracking,
                            )
                        }
                        onClick={handleTrackAll}
                    />
                    <Text>All links</Text>
                </Group>
                <Divider size="md" className="mt-2 mb-2 border-t-shade-2" />
                {links?.map((link) => (
                    <Stack className="px-3 py-2" spacing={4} key={link.key}>
                        <Group>
                            <Checkbox
                                checked={
                                    trackedLinks?.[link.key]?.enableTracking
                                }
                                onChange={(e) =>
                                    handleTrackLink(e.target.checked, link)
                                }
                            />
                            <Text>{link.value}</Text>
                        </Group>
                        {linkChildren && (
                            <Text className="flex gap-1 text-gray-600 underline">
                                <Menu
                                    trigger="hover"
                                    position="top"
                                    disabled={
                                        !linkChildren?.[link.key]?.imageUrl
                                    }
                                >
                                    <Menu.Target>
                                        <span className="capitalize">
                                            {linkChildren?.[link.key]?.tagName}{' '}
                                        </span>
                                    </Menu.Target>
                                    <Menu.Dropdown className="p-2 rounded-lg bg-gray-850">
                                        <Box>
                                            <img
                                                src={
                                                    linkChildren?.[link.key]
                                                        ?.imageUrl
                                                }
                                                alt="something"
                                                width={500}
                                            />
                                        </Box>
                                    </Menu.Dropdown>
                                </Menu>

                                {linkChildren?.[link.key]?.content ? (
                                    <Text
                                        className="truncate w-[450px]"
                                        dangerouslySetInnerHTML={{
                                            __html: linkChildren?.[link.key]
                                                .content,
                                        }}
                                    />
                                ) : null}
                            </Text>
                        )}
                    </Stack>
                ))}
            </Box>
        </Box>
    );
};

export default LinkTracking;
