import { sortmentApi } from '@api/index';
import { SortmentLogo } from '@assets/icons/Core';
import Page from '@components/Page/Page';
import { useLocale } from '@hooks/useLocale';
import {
    OpenIdIdentityIssuerType,
    type ApiError,
    type CreateUserArgs,
    type LightdashUser,
} from '@lightdash/common';
import { Anchor, Stack } from '@mantine/core';
import { useMutation } from '@tanstack/react-query';
import { useEffect, type FC } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { ThirdPartySignInButton } from '../components/common/ThirdPartySignInButton';
import PageSpinner from '../components/PageSpinner';
import CreateUserForm from '../components/RegisterForms/CreateUserForm';
import useNotify from '../hooks/toaster/useNotify';
import { useFlashMessages } from '../hooks/useFlashMessages';
import { useApp } from '../providers/AppProvider';
import { useTracking } from '../providers/TrackingProvider';

const registerQuery = async (data: CreateUserArgs) =>
    sortmentApi<LightdashUser>({
        url: `/user`,
        method: 'POST',
        body: JSON.stringify(data),
    });

const Register: FC = () => {
    const location = useLocation<{ from?: Location } | undefined>();
    const { health } = useApp();
    const { showToastError, showToastApiError } = useNotify();
    const flashMessages = useFlashMessages();

    useEffect(() => {
        if (flashMessages.data?.error) {
            showToastError({
                title: 'Failed to authenticate',
                subtitle: flashMessages.data.error.join('\n'),
            });
        }
    }, [flashMessages.data, showToastError]);
    const allowPasswordAuthentication =
        !health.data?.auth.disablePasswordAuthentication;
    const { identify } = useTracking();
    const redirectUrl = location.state?.from
        ? `${location.state.from.pathname}${location.state.from.search}`
        : '/';
    const { isLoading, mutate, isSuccess } = useMutation<
        LightdashUser,
        ApiError,
        CreateUserArgs
    >(registerQuery, {
        mutationKey: ['login'],
        onSuccess: (data) => {
            identify({ id: data.userUuid });
            window.location.href = redirectUrl;
        },
        onError: ({ error }) => {
            showToastApiError({
                title: `Failed to create user`,
                apiError: error,
            });
        },
    });
    const { t } = useLocale();

    if (health.isInitialLoading) {
        return <PageSpinner />;
    }

    if (health.status === 'success' && health.data?.isAuthenticated) {
        return <Redirect to={{ pathname: '/', state: { from: location } }} />;
    }

    const ssoAvailable =
        health.data?.auth.google.enabled ||
        health.data?.auth.okta.enabled ||
        health.data?.auth.oneLogin.enabled ||
        health.data?.auth.azuread.enabled ||
        health.data?.auth.oidc.enabled;
    const ssoLogins = ssoAvailable && (
        <Stack>
            {Object.values(OpenIdIdentityIssuerType).map((providerName) => (
                <ThirdPartySignInButton
                    key={providerName}
                    providerName={providerName}
                    intent="signup"
                    redirect={redirectUrl}
                    className="py-3 rounded-lg"
                />
            ))}
        </Stack>
    );
    const passwordLogin = allowPasswordAuthentication && (
        <CreateUserForm
            isLoading={isLoading || isSuccess}
            onSubmit={(data: CreateUserArgs) => {
                mutate(data);
            }}
        />
    );
    const logins = (
        <>
            {ssoLogins}
            {ssoLogins && passwordLogin && <>or</>}
            {passwordLogin}
        </>
    );
    return (
        <Page
            title="Register"
            withCenteredContent
            withFullHeight
            backgroundColor="gray"
        >
            <section className="w-[300px] px-3 pt-6 pb-3 bg-white flex flex-col items-center border-base  gap-4 shadow-card rounded-3xl">
                <div className="flex flex-col items-center w-full gap-2">
                    <SortmentLogo />
                    <p className="text-sm font-medium text-gray-600">
                        {t('register.header')}
                    </p>
                </div>

                {logins}

                <div className="flex flex-row items-center gap-1 text-sm font-medium text-gray-600">
                    <p> {t('register.already_registered')}</p>
                    <Anchor href="/login" className="text-blu">
                        {t('common.login')}
                    </Anchor>
                </div>
            </section>
        </Page>
    );
};

export default Register;
