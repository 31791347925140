import { type CommunicationChannel } from '@lightdash/common';
import { Tabs } from '@mantine/core';
import React, { useState } from 'react';

import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import { useProjectContext } from '@providers/ProjectProvider';

export const activeTabClass =
    '!bg-shade-6 !text-gray-800 !text-sm !font-semibold  px-2 py-1';
export const tabClass =
    'text-sm font-semibold text-gray-600 px-2 py-1 rounded-lg hover:bg-shade-2';

const ChannelTabs: React.FC<{
    activeTab: string;
}> = ({ activeTab }) => {
    const { communicationChannels } = useProjectContext();
    const [hoveredTab, setHoveredTab] = useState<string>('');

    if (!communicationChannels) return null;
    return (
        <Tabs.List className="pb-3">
            {communicationChannels.map((tab) => {
                return (
                    <Tabs.Tab
                        className={`${tabClass} ${
                            activeTab === tab.value && activeTabClass
                        }`}
                        key={tab.value}
                        value={tab.value}
                        onMouseEnter={() => setHoveredTab(tab.value)}
                        onMouseLeave={() => setHoveredTab('')}
                    >
                        <ChannelIcon
                            channel={tab.value as CommunicationChannel}
                            color={
                                activeTab === tab.value ||
                                hoveredTab === tab.value
                                    ? 'rgb(var(--color-gray-800))'
                                    : 'rgb(var(--color-gray-700))'
                            }
                        />
                    </Tabs.Tab>
                );
            })}
        </Tabs.List>
    );
};

export default ChannelTabs;
