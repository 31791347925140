import { type EditorConfig } from 'grapesjs';
import plugin from 'grapesjs-preset-newsletter';

import useFileUpload from '@hooks/useFileUpload';
import { useLocale } from '@hooks/useLocale';
import { type ApiResponse } from '@lightdash/common';
import { CssProperties, GrapesFieldTypes } from '../types';

export const useGrapesEditorConfig = (): {
    newAsset: string | undefined;
    editorConfig: EditorConfig;
} => {
    const { t } = useLocale();
    const { mutate, data: uploadData } = useFileUpload<
        { url: string } & ApiResponse
    >();

    return {
        newAsset: uploadData?.url, // INFO - This state which we use to add any new images added by user
        editorConfig: {
            container: '#html-editor',
            plugins: [plugin],
            storageManager: false,
            pluginsOpts: {
                [`${plugin}`]: {
                    updateStyleManager: false,
                },
            },
            deviceManager: {
                devices: [
                    {
                        id: 'desktop',
                        name: 'Desktop',
                        width: '960px', // default size,
                    },
                    {
                        id: 'tablet',
                        name: 'Tablet',
                        width: '760px',
                    },
                    {
                        id: 'mobile',
                        name: 'Mobile portrait',
                        width: '320px',
                    },
                ],
            },
            panels: {
                defaults: [],
            },
            assetManager: {
                uploadFile: (e) => {
                    let file: FileList | null = null;
                    if (e.dataTransfer) {
                        file = e.dataTransfer.files;
                    } else if (e.target instanceof HTMLInputElement) {
                        file = e.target.files;
                    }
                    if (file) {
                        mutate(file);
                    }
                },
                showUrlInput: false,
            },
            styleManager: {
                custom: true,
                sectors: [
                    {
                        name: t('template_builder.style_manager.dimensions'),
                        buildProps: [
                            CssProperties.WIDTH,
                            CssProperties.HEIGHT,
                            CssProperties.MAX_WIDTH,
                            CssProperties.MAX_HEIGHT,
                            CssProperties.MARGIN,
                            CssProperties.PADDING,
                        ],
                    },
                    {
                        id: 'typography',
                        name: t('template_builder.style_manager.typography'),
                        buildProps: [
                            CssProperties.COLOR,
                            CssProperties.FONT_FAMILY,
                            CssProperties.FONT_WEIGHT,
                            CssProperties.FONT_SIZE,
                            CssProperties.LINE_HEIGHT,
                            CssProperties.LETTER_SPACING,
                            CssProperties.TEXT_ALIGN,
                            CssProperties.TEXT_DECORATION,
                            CssProperties.FONT_STYLE,
                            CssProperties.VERTICAL_ALIGN,
                        ],
                        properties: [
                            {
                                id: 'text-decoration',
                                property: CssProperties.TEXT_DECORATION,
                                type: GrapesFieldTypes.RADIO,
                                defaults: 'none',
                                list: [
                                    {
                                        id: 'none',
                                        value: 'none',
                                        name: 'None',
                                    },
                                    {
                                        id: 'underline',
                                        value: 'underline',
                                        name: 'underline',
                                    },
                                    {
                                        id: 'line-through',
                                        value: 'line-through',
                                        name: 'Line-through',
                                    },
                                ],
                            },
                            {
                                id: 'vertical-align',
                                property: CssProperties.VERTICAL_ALIGN,
                                type: GrapesFieldTypes.SELECT,
                                defaults: 'baseline',
                                list: [
                                    { id: 'baseline', value: 'baseline' },
                                    { id: 'top', value: 'top' },
                                    { id: 'middle', value: 'middle' },
                                    { id: 'bottom', value: 'bottom' },
                                ],
                            },
                            {
                                id: 'font-style',
                                property: CssProperties.FONT_STYLE,
                                type: GrapesFieldTypes.RADIO,
                                defaults: 'normal',
                                list: [
                                    {
                                        id: 'normal',
                                        label: 'Normal',
                                        value: 'normal',
                                        name: 'Normal',
                                    },
                                    {
                                        id: 'italic',
                                        label: 'Italic',
                                        value: 'italic',
                                        name: 'Italic',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        name: t('template_builder.style_manager.decorations'),
                        buildProps: [
                            CssProperties.BACKGROUND_COLOR,
                            CssProperties.BORDER,
                            CssProperties.BORDER_RADIUS,
                        ],
                    },
                ],
            },
        },
    };
};
