import { type CustomDimension, type TableCalculation } from './field';
import { type JourneyCreatePayload } from './journeys';
import {
    type AdditionalMetric,
    type MetricQuery,
    type NestedMetricQuery,
    type NestedMetricQueryGroup,
} from './metricQuery';
import { type JoinType, type RelationTableType } from './relation/enums';
import type {
    CachedColumnValues,
    CompiledRelation,
} from './relation/relations';
import { type ChartConfig } from './savedCharts';
import { type WarehouseTableInfo } from './warehouse';
// import { WarehouseTableColumn, WarehouseTableInfoBase } from './warehouse';

// The use case context in which AI is receiving and generating messages
export enum AIMessageContext {
    SQL_AUDIENCE = 'sql_audience',
    VISUAL_AUDIENCE = 'visual_audience',
    HOME_PAGE = 'home_page',
    EVENT_SETUP = 'events',
    OTHERS = 'others',
    SCHEMA_SEARCH = 'schema_search',
    CHART = 'chart',
    CUSTOM_METRIC = 'custom_metric',
    RELATIONS = 'relations',
}

export type AgentMessage = {
    threadId: string;
    author: Author;
    context: AIMessageContext;
    content: TextContent | ImageContent | AgentJsonContent;
};

export type RelationDetails = {
    relationId: string;
    relation: CompiledRelation;
};

export enum AiMessageTypes {
    TEXT = 'text',
    IMAGE = 'image',
    JSON = 'json',
}

export type AgentJsonContent = {
    type: AiMessageTypes.JSON;
    value: SQLAudience | VisualAudience | Chart; // Add Analyst output type here
};

type TextContent = {
    type: AiMessageTypes.TEXT;
    value: string;
};

type ImageContent = {
    type: AiMessageTypes.IMAGE;
    value: string;
    alt?: string;
};

export type VisualAudience = {
    context: AIMessageContext.VISUAL_AUDIENCE;
    nestedMetricQuery: NestedMetricQuery;
};

export type SQLAudience = {
    context: AIMessageContext.SQL_AUDIENCE;
    sql: string;
};

export type Chart = {
    context: AIMessageContext.CHART;
    metricQuery: MetricQuery;
    chartConfig: ChartConfig;
};

export type CustomMetric = {
    context: AIMessageContext.CUSTOM_METRIC;
    customMetric: AdditionalMetric;
};

export type NaturalLanguageSQLResult = {
    question: string;
    response: string | null;
    summary?: string;
};

export enum Author {
    USER = 'user',
    SYSTEM = 'system',
}

export type AgentContext = {
    messageContext: AIMessageContext;
    schemaLinks?: SchemaLinks;
    projectUuid?: string;
    organisationUuid?: string;
    userUuid?: string;
    metricQuery?: MetricQuery;
    customAttribute?: AdditionalMetric | CustomDimension | TableCalculation;
};

export interface Column {
    name: string;
    type: string;
    description?: string;
    label?: string;
}

export type TableDescription = {
    tableName: string;
    tableDescription: string;
    tableLabel: string;
};

export type ColumnDescription = Record<string, ColumnDetails>;

export type EventColumnMapping = {
    key: string;
    columnName: string;
    columnDataType: string;
    label: string;
    nullable: boolean;
};

export type ColumnDetails = {
    description: string;
    label: string;
    masked?: boolean;
    cached?: boolean;
};

export type RelationsServiceRequest = {
    type: string;
    relationID: string;
    tableID: string;
};

export type TableDetails = {
    type: RelationTableType;
    relationID: string;
    tableID: string;
};

export type ColumnDescriptionRequest = {
    warehouseTableInfo: WarehouseTableInfo;
    table: TableDetails;
};

export type EventReservedKeysRequest = {
    user_id: string;
    event_id: string;
    timestamp: string;
};

export type EventExistingColumnsRequest = {
    existingColumns: Record<string, string>;
};

export type JourneyNodeDescriptionRequest = {
    journeyDataSchema: JourneyCreatePayload;
    nodeId: string;
};

export type JourneyNodeDescription = {
    nodeId: string;
    nodeDescription: string;
};

export type SchemaLinkElement = {
    id: string;
    label: string;
};

export type SchemaLinkJoin = {
    join: string;
    joinType: JoinType;
};

export type SchemaLinks = {
    columns: Array<SchemaLinkElement>;
    values: Array<string>;
    foreignKeys: Array<SchemaLinkJoin>;
    audiences: Array<SchemaLinkElement>;
    customMetrics: Array<SchemaLinkElement>;
};

export type AudienceDescription = {
    audienceId: string;
    audienceDescription: string;
};

export type BaseAudienceDescriptionRequest = {
    audienceId: string;
};

export type VisualAudienceDescriptionRequest =
    BaseAudienceDescriptionRequest & {
        context: AIMessageContext.VISUAL_AUDIENCE;
        nestedMetricQuery: NestedMetricQueryGroup;
    };

export type SqlAudienceDescriptionRequest = BaseAudienceDescriptionRequest & {
    context: AIMessageContext.SQL_AUDIENCE;
    sqlQuery: string;
};

export type AudienceDescriptionRequest =
    | VisualAudienceDescriptionRequest
    | SqlAudienceDescriptionRequest;

export type RelationDetailswithCachedValues = {
    relationId: string;
    relation: CompiledRelation;
    allTableCachedValues: Record<string, Record<string, CachedColumnValues[]>>;
};

export type SuggestedPrompt = {
    prompt: string;
    example: string;
};
