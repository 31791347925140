import { subject } from '@casl/ability';
import { getCampaignAnalyticsDashboardUrl } from '@components/Campaigns/campaignUtils';
import { useAbilityContext } from '@components/common/Authorization';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import Timestamp from '@components/common/Timestamp';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useDeleteCampaign,
    useDuplicateCampaign,
    useTriggerCampaign,
    useUpdateCampaignTags,
} from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceType,
    CampaignScheduleType,
    CampaignStatus,
    CampaignType,
    CommonReservedTags,
    CommunicationChannel,
    type ReducedCampaign,
} from '@lightdash/common';
import { Box, Button, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    CaretRight,
    CopySimple,
    PaperPlaneTilt,
    Pencil,
    Trash,
    TrendUp,
} from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { useQueryClient } from '@tanstack/react-query';
import { type ColumnDef } from '@tanstack/react-table';
import { t as translate } from 'i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';
import { CampaignBuilderQueryParams } from '../Builder/types';
import { allowCampaignEdits } from '../utils';
import AudienceAndAnalyticsCell from './AudienceAndAnalyticsCell';
import CampaignCurrentStatus from './CampaignCurrentStatus';

interface CampaignMenuItemProps {
    rowData: ReducedCampaign;
}
const CAMPAIGN_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const CAMPAIGN_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
// const CAMPAIGN_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !w-56`;

const CampaignMenuItem: React.FC<CampaignMenuItemProps> = ({ rowData }) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateDeleteCampaign } = useDeleteCampaign();
    const { mutateAsync: mutateTriggerCampaign } = useTriggerCampaign();
    const { mutate: duplicateCampaign } = useDuplicateCampaign({
        id: rowData.id ?? '',
        name: rowData.name ?? '',
        channel: rowData.channel ?? CommunicationChannel.EMAIL,
        description: rowData.description ?? '',
    });
    const {
        mutateAsync: updateCampaignTags,
        isLoading: updateCampaignTagsLoading,
    } = useUpdateCampaignTags({ campaignId: rowData.id ?? '' });
    const queryClient = useQueryClient();
    const { projectData } = useProjectContext();
    const history = useHistory();
    const { t } = useLocale();
    const { user } = useApp();
    const [opened, { open, close }] = useDisclosure(false);
    const ability = useAbilityContext();
    const { showToastSuccess, showToastError } = useNotify();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const canEditCampaigns = ability.can(
        'manage',
        subject('Campaign', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleDuplicate = useCallback(() => {
        duplicateCampaign();
    }, [duplicateCampaign]);

    const handleDelete = useCallback(
        async (id: string) => {
            await mutateDeleteCampaign({ campaignId: id });
            await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
            close();
        },
        [mutateDeleteCampaign, queryClient, close],
    );

    const handleTrigger = useCallback(
        async (id: string) => {
            await mutateTriggerCampaign({ campaignId: id });
            await queryClient.invalidateQueries([QueryKeys.GET_CAMPAIGNS]);
        },
        [mutateTriggerCampaign, queryClient],
    );

    const handleViewOrEdit = useCallback(
        (id: string, type: string) => {
            history.push(
                `/projects/${projectUuid}/campaigns/${id}/${type}?${CampaignBuilderQueryParams.CHANNEL}=${rowData.channel}`,
            );
        },
        [projectUuid, history, rowData.channel],
    );

    const isCampaignEditable = useMemo(
        () =>
            allowCampaignEdits({
                executionTime: rowData?.schedule?.executionTime,
                status: rowData?.status,
            }),
        [rowData],
    );
    const hideItem = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={
                        rowData?.status !== CampaignStatus.IN_PROGRESS
                            ? 'rgb(var(--color-halt-800))'
                            : 'rgb(var(--color-gray-600))'
                    }
                />
            ),
            children: (
                <Text
                    className={`${
                        rowData?.status !== CampaignStatus.IN_PROGRESS
                            ? 'text-halt-800'
                            : ''
                    }`}
                >
                    {t('common.hide')}
                </Text>
            ),
            customClass: ` ${
                rowData?.status === CampaignStatus.IN_PROGRESS
                    ? 'opacity-50'
                    : 'hover:bg-halt-800/6'
            }`,
            disabled: rowData?.status === CampaignStatus.IN_PROGRESS,
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal, rowData?.status],
    );
    const restoreItem = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async () => {
                await updateCampaignTags(
                    {
                        tags: [
                            ...(rowData?.tags ?? []).filter(
                                (tag) => tag !== CommonReservedTags.HIDDEN,
                            ),
                        ],
                    },
                    {
                        onSuccess: async () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.campaign'),
                                }),
                            });
                            await queryClient.invalidateQueries([
                                QueryKeys.GET_CAMPAIGNS,
                            ]);
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.campaign'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            updateCampaignTags,
            rowData?.tags,
            showToastError,
            showToastSuccess,
            queryClient,
        ],
    );

    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<ReducedCampaign>[] = [];
        if (rowData?.status === CampaignStatus.READY) {
            items = [
                {
                    leftSection: (
                        <PaperPlaneTilt
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate('campaign_manager.menu_item_send'),
                    onClick: (values) => handleTrigger(values?.id ?? ''),
                    isDivider: true,
                },
                {
                    leftSection: (
                        <Pencil
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate('campaign_manager.menu_item_edit'),
                    onClick: (values) =>
                        handleViewOrEdit(values?.id ?? '', 'edit'),
                },
            ];
        } else if (
            rowData?.status === CampaignStatus.COMPLETED ||
            (rowData?.status === CampaignStatus.SCHEDULED &&
                rowData?.type === CampaignType.RECURRING &&
                rowData?.schedule?.nextExecution)
        ) {
            items = [
                {
                    leftSection: (
                        <TrendUp
                            size={14}
                            weight="duotone"
                            color={'rgb(var(--color-gray-600))'}
                            strokeWidth={2.5}
                        />
                    ),
                    children: translate(
                        'campaign_manager.menu_item_view_report',
                    ),
                    onClick: (_values) => {
                        const dashboardUrl = getCampaignAnalyticsDashboardUrl(
                            projectData,
                            rowData.id,
                        );
                        history.push(dashboardUrl);
                    },
                    isDivider: true,
                },
            ];
        } else if (rowData?.status === CampaignStatus.SCHEDULED) {
            if (isCampaignEditable) {
                items = [
                    {
                        leftSection: (
                            <Pencil
                                size={14}
                                color={'rgb(var(--color-gray-600))'}
                                strokeWidth={2.5}
                                weight="duotone"
                            />
                        ),
                        children: translate('campaign_manager.menu_item_edit'),
                        onClick: (values) =>
                            handleViewOrEdit(values?.id ?? '', 'edit'),
                    },
                ];
            }
        }
        if (rowData.audienceType === AudienceType.WAREHOUSE) {
            items.push({
                leftSection: (
                    <CopySimple
                        size={14}
                        weight="duotone"
                        color={'rgb(var(--color-gray-600))'}
                        strokeWidth={2.5}
                    />
                ),
                children: translate('campaign_manager.menu_item_duplicate'),
                onClick: () => handleDuplicate(),
            });
        }
        items = [
            ...items,
            {
                leftSection: (
                    <Trash
                        size={14}
                        weight="duotone"
                        color={'rgb(var(--color-gray-600))'}
                        strokeWidth={2.5}
                    />
                ),
                children: translate('campaign_manager.menu_item_delete'),
                onClick: () => open(),
            },
        ];
        if (rowData?.tags?.includes(CommonReservedTags.HIDDEN)) {
            items.push(restoreItem);
        } else {
            items.push(hideItem);
        }

        return items;
    }, [
        handleDuplicate,
        handleViewOrEdit,
        handleTrigger,
        history,
        open,
        projectData,
        rowData,
        isCampaignEditable,
        hideItem,
        restoreItem,
    ]);

    // Update the callback to use the stored initialCampaignData
    const handleHideConfirm = useCallback(async () => {
        await updateCampaignTags(
            {
                tags: [...(rowData?.tags ?? []), CommonReservedTags.HIDDEN],
            },
            {
                onSuccess: async () => {
                    showToastSuccess({
                        title: t('common.hidden_success', {
                            entityName: t('common.campaign'),
                        }),
                    });
                    await queryClient.invalidateQueries([
                        QueryKeys.GET_CAMPAIGNS,
                    ]);
                },
                onError: (error) => {
                    showToastError({
                        title: t('common.hidden_error', {
                            entityName: t('common.campaign'),
                        }),
                        subtitle: error.error.message,
                    });
                },
            },
        );
        closeHideConfirmModal();
    }, [
        closeHideConfirmModal,
        rowData?.tags,
        updateCampaignTags,
        showToastError,
        showToastSuccess,
        t,
        queryClient,
    ]);

    return (
        <>
            {canEditCampaigns && (
                <CustomMenu<ReducedCampaign>
                    menuItems={menuItems}
                    data={rowData}
                />
            )}
            <Modal
                opened={opened}
                onClose={close}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Flex direction={'column'} align={'flex-start'}>
                        <Text>{t('campaign.delete_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData?.name}
                        </Text>
                    </Flex>
                }
                centered
                footerRightSection={
                    <Flex justify={'flex-end'}>
                        <Button
                            variant={ButtonVariant.OUTLINED}
                            className="py-2 mr-2"
                            onClick={() => close()}
                        >
                            {t('campaign.delete.modal.dont_delete')}
                        </Button>
                        <Button
                            variant={ButtonVariant.FILLED_DESTRUCTIVE}
                            style={{
                                backgroundColor:
                                    'rgb(var(--color-halt-800)) !important',
                            }}
                            onClick={async (e) => {
                                e.stopPropagation();
                                if (rowData?.id)
                                    await handleDelete(rowData?.id);
                            }}
                        >
                            {t('campaign.delete.modal.confirm_button')}{' '}
                            <Trash
                                className="ml-1"
                                size={14}
                                weight="duotone"
                                color={'rgb(var(--color-white))'}
                            />
                        </Button>
                    </Flex>
                }
            >
                <Text className="text-sm">
                    {t('campaign.delete.modal.description')}
                </Text>
            </Modal>
            <Modal
                opened={openedHideConfirmModal}
                onClose={closeHideConfirmModal}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                title={
                    <Box>
                        <Text>{t('campaign_manager.hide_modal_title')}</Text>
                        <Text className="text-sm font-normal text-gray-600">
                            {rowData.name}
                        </Text>
                    </Box>
                }
                footerRightSection={
                    <ButtonGroup
                        primaryButtonLabel={t(
                            'audience_manager.confirm_hide_prim_button',
                        )}
                        secondaryButtonLabel={t('common.no')}
                        isUpdating={updateCampaignTagsLoading}
                        handlePrimaryButtonClick={handleHideConfirm}
                        handleSecondaryButtonClick={closeHideConfirmModal}
                        primaryButtonVariant={ButtonVariant.FILLED_DESTRUCTIVE}
                        primaryButtonRightIcon={<CaretRight color="white" />}
                    />
                }
                closeButtonProps={{
                    className: 'absolute top-3 right-3',
                }}
            >
                <Flex direction={'column'} gap={12}>
                    <Text className="text-sm font-medium text-gray-800">
                        {t('campaign_manager.hide_modal_header')}
                    </Text>
                </Flex>
            </Modal>
        </>
    );
};

export const useCampaignColumns = () => {
    const [columns, setColumns] = useState<ColumnDef<ReducedCampaign>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: translate('campaign_manager.th_campaign_name'),
                    cell: ({ row, getValue }) => (
                        <Box className={CAMPAIGN_TABLE_CELL_CLASSNAME}>
                            <Flex align={'center'} gap={6}>
                                {row.original.tags?.includes(
                                    CommonReservedTags.HIDDEN,
                                ) && <Archive weight="duotone" />}
                                <Text className={CAMPAIGN_TABLE_CELL_NAME}>
                                    {getValue<string>()}
                                </Text>
                            </Flex>
                            <ChannelIcon
                                channel={
                                    row?.original
                                        ?.channel as CommunicationChannel
                                }
                                color={'rgb(var(--color-gray-600)'}
                                className="text-xs"
                            />
                        </Box>
                    ),
                },
                {
                    accessorKey: 'scheduledAt',
                    header: translate('campaign_manager.th_status'),
                    cell: ({ row }) => {
                        const { original } = row;
                        const status = original?.status;
                        return (
                            <CampaignCurrentStatus
                                status={status}
                                onFailureReasonClick={undefined}
                            />
                        );
                    },
                },
                {
                    accessorKey: '',
                    header: translate('campaign_manager.th_scheduled_date'),
                    cell: ({ row }) => (
                        <Box className={CAMPAIGN_TABLE_CELL_CLASSNAME}>
                            <Text className={CAMPAIGN_TABLE_CELL_NAME}>
                                {row.original.status ===
                                    CampaignStatus.DRAFT && <Text>-</Text>}
                                {row.original.schedule &&
                                    row.original.schedule.type ===
                                        CampaignScheduleType.MANUAL &&
                                    (row.original.schedule.executionTime ? (
                                        <Box className="flex flex-col">
                                            <Timestamp
                                                timestamp={row.original.schedule.executionTime.toString()}
                                                customClass="!text-gray-800"
                                            />
                                            <Text className="text-xs font-normal text-gray-600">
                                                {translate(
                                                    'campaign_manager.tr_sent_once',
                                                )}
                                            </Text>
                                        </Box>
                                    ) : (
                                        <Text>-</Text>
                                    ))}

                                {row.original.schedule &&
                                    row.original.schedule.type ===
                                        CampaignScheduleType.DELAYED &&
                                    row.original.schedule.executionTime && (
                                        <Box className="flex flex-col">
                                            <Timestamp
                                                timestamp={row.original.schedule.executionTime.toString()}
                                                customClass="!text-gray-800"
                                            />
                                            {row.original.type ===
                                                CampaignType.RECURRING && (
                                                <Box className="flex">
                                                    <Text className="text-xs font-normal text-gray-600">
                                                        {translate(
                                                            'campaign_manager.tr_recurring_schedule',
                                                        )}
                                                    </Text>
                                                    <Text className="text-xs font-normal text-gray-600">
                                                        {'·'}
                                                        {row.original.schedule
                                                            .children &&
                                                        row.original.schedule
                                                            .children > 1
                                                            ? `${translate(
                                                                  'campaign_manager.tr_sent',
                                                              )} ${
                                                                  row.original
                                                                      .schedule
                                                                      .children -
                                                                  1
                                                              } ${translate(
                                                                  'campaign_manager.tr_times',
                                                              )}`
                                                            : translate(
                                                                  'campaign_manager.tr_yet_to_start',
                                                              )}
                                                    </Text>
                                                </Box>
                                            )}
                                            {row.original.type ===
                                                CampaignType.ONE_TIME && (
                                                <Text className="text-xs font-normal text-gray-600">
                                                    {translate(
                                                        'campaign_manager.tr_one_time',
                                                    )}
                                                </Text>
                                            )}
                                        </Box>
                                    )}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'audienceCount',
                    header: translate('campaign_manager.th_audience_count'),
                    cell: ({ getValue, row }) => (
                        <AudienceAndAnalyticsCell
                            row={row}
                            count={getValue<number>()}
                        />
                    ),
                },
                {
                    accessorKey: 'createdAt',
                    header: translate('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    row?.original?.createdBy?.firstName || ''
                                } ${row?.original?.createdBy?.lastName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <CampaignMenuItem rowData={row?.original} />
                    ),
                },
            ]);
        }
    }, [projectUuid]);

    return columns;
};
