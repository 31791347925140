import {
    type FieldWithSelectStatus,
    type PropertySelectGroupType,
} from '@components/Audience/Filters/FieldListItem/utils';
import { type FieldsWithSuggestions } from '@components/Audience/Filters/FiltersProvider';
import CampaignBuilderFooter from '@components/Campaigns/Builder/CampaignBuilderFooter';
import SetupContent from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent';
import TemplateContentPreview from '@components/Campaigns/Builder/Steps/CampaignBuilderContent/SetupContent/TemplateContentPreview';
import { type TemplateSetupCompleteArgs } from '@components/Campaigns/Builder/types';
import Modal from '@components/common/modal/Modal';
import { type PropertySelectListType } from '@components/common/Select/PropertySelect/type';
import {
    useGetSyncedTemplateByTemplateName,
    useGetTemplateById,
} from '@hooks/useTemplate';
import {
    CommunicationChannel,
    type ExternalCampaignTriggerRequest,
    type ProviderTemplateDetails,
    type Template,
} from '@lightdash/common';
import { Box, Flex, Group, Loader, Stack, Text } from '@mantine/core';
import React, { useCallback, useMemo } from 'react';

interface JourneyTemplateSetupModalProps {
    opened: boolean;
    closeTemplateSetup: () => void;
    setJourneyContentPayload: (
        messageConfig: ExternalCampaignTriggerRequest,
    ) => void;
    fields: FieldsWithSuggestions;
    additionalPropertySelectList: PropertySelectListType<
        FieldWithSelectStatus,
        PropertySelectGroupType
    >[];
    messageConfig: ExternalCampaignTriggerRequest;
    onTemplateSelect: (value: ProviderTemplateDetails) => void;
}

const JourneyTemplateSetupModal: React.FC<JourneyTemplateSetupModalProps> = ({
    opened,
    closeTemplateSetup,
    messageConfig,
    setJourneyContentPayload,
    fields,
    additionalPropertySelectList,
    onTemplateSelect,
}) => {
    const { data: templateMetadata } = useGetTemplateById(
        messageConfig?.templateDetails?.id ?? '',
        Boolean(messageConfig?.templateDetails?.id),
    );

    const {
        data: syncedTemplateMetadata,
        isLoading: isSyncedTemplateMetadataLoading,
    } = useGetSyncedTemplateByTemplateName(
        messageConfig.communicationDetails?.id ?? '',
        messageConfig.templateDetails?.id ?? '',
        messageConfig.channel === CommunicationChannel.WHATSAPP,
    );

    const onTemplateSetupComplete = useCallback(
        (args: TemplateSetupCompleteArgs) => {
            const { templateDetails, communicationDetails } = args;
            setJourneyContentPayload({
                ...messageConfig,
                templateDetails:
                    templateDetails ?? messageConfig.templateDetails,
                communicationDetails:
                    communicationDetails ?? messageConfig.communicationDetails,
                channel: args.channel ?? messageConfig.channel,
                contentMappings: args.contentMappings ?? {},
                contentDetails:
                    args.contentDetails ?? messageConfig.contentDetails,
                sendTo: args.sendTo ?? messageConfig.sendTo,
            });

            closeTemplateSetup();
        },
        [closeTemplateSetup, setJourneyContentPayload, messageConfig],
    );

    const getTemplateMetadata = useMemo(() => {
        if (messageConfig.channel === CommunicationChannel.WHATSAPP) {
            return {
                ...syncedTemplateMetadata,
                contents: syncedTemplateMetadata?.languages[0]?.content ?? [],
                channel: CommunicationChannel.WHATSAPP,
            };
        }
        if (messageConfig.templateDetails?.id) {
            return templateMetadata;
        }
        return null;
    }, [
        messageConfig.channel,
        messageConfig.templateDetails?.id,
        syncedTemplateMetadata,
        templateMetadata,
    ]);

    return (
        <Modal
            opened={opened}
            onClose={closeTemplateSetup}
            title={'Setup your content'}
            styles={() => ({
                content: {
                    maxHeight: '100% !important',
                    overflow: 'hidden',
                },
                body: {
                    padding: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
            })}
            size="calc(100vw - 3rem)"
            footerRightSection={<CampaignBuilderFooter />}
            closeOnClickOutside={false}
        >
            {getTemplateMetadata && (
                <>
                    <Stack style={{ flex: 1, overflow: 'hidden' }}>
                        <Group
                            className="w-full h-full p-3 items-start gap-3.5"
                            noWrap
                            style={{ flex: 1, overflowY: 'auto' }}
                        >
                            <Box className="w-1/2">
                                {isSyncedTemplateMetadataLoading &&
                                messageConfig.channel ===
                                    CommunicationChannel.WHATSAPP ? (
                                    <Flex className="p-3.5" align="center">
                                        <Loader size={11} />
                                        <Text className="text-sm font-normal text-blu-800 px-1.5">
                                            Loading...
                                        </Text>
                                    </Flex>
                                ) : (
                                    <SetupContent
                                        templateMetadata={
                                            getTemplateMetadata as Template
                                        }
                                        onTemplateSetupComplete={
                                            onTemplateSetupComplete
                                        }
                                        fields={fields}
                                        additionalPropertySelectList={
                                            additionalPropertySelectList
                                        }
                                    />
                                )}
                            </Box>
                            {messageConfig.templateDetails?.id &&
                                getTemplateMetadata && (
                                    <Box className="w-full">
                                        <TemplateContentPreview
                                            templateMetadata={
                                                getTemplateMetadata as Template
                                            }
                                            grapesContainerId="html-preview-journeys"
                                            showDeviceAndThemeSwitcher={true}
                                            onTemplateSelect={onTemplateSelect}
                                            isSetupComplete={undefined}
                                            openContentSetupManager={() => {}}
                                        />
                                    </Box>
                                )}
                        </Group>
                    </Stack>
                </>
            )}
        </Modal>
    );
};

export default React.memo(JourneyTemplateSetupModal);
