import ErrorState from '@components/common/ErrorState';
import PageSpinner from '@components/PageSpinner';
import { useActiveProjectUuid } from '@hooks/useActiveProject';
import { useProject } from '@hooks/useProject';
import { useProjects } from '@hooks/useProjects';
import { type FC } from 'react';
import { Redirect } from 'react-router-dom';

const Projects: FC = () => {
    const { isInitialLoading, data, error } = useProjects();
    const { isLoading: isActiveProjectLoading, activeProjectUuid } =
        useActiveProjectUuid();
    const { isInitialLoading: isProjectDataLoading } =
        useProject(activeProjectUuid);

    if (!isInitialLoading && data && data.length === 0) {
        return <Redirect to="/no-access" />;
    }

    if (
        isInitialLoading ||
        isActiveProjectLoading ||
        !activeProjectUuid ||
        isProjectDataLoading
    ) {
        return <PageSpinner />;
    }

    if (error && error.error) {
        return <ErrorState error={error.error} />;
    }

    // if (!projectData?.blobConnection) {
    //     return <Redirect to={`/projects/${activeProjectUuid}/blob`} />;
    // }

    // if (projectData?.needsRelation) {
    //     return (
    //         <Redirect to={`/projects/${activeProjectUuid}/relations/create`} />
    //     );
    // }

    return <Redirect to={`/projects/${activeProjectUuid}/relations`} />;
};

export default Projects;
