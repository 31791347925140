import { type JourneyNode } from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useJourneyBuilderContext } from '@providers/JourneyBuilderProvider';
import React, { useCallback } from 'react';
import {
    BaseEdge,
    EdgeLabelRenderer,
    getSmoothStepPath,
    type EdgeProps,
} from 'reactflow';
import { isSplitBlock } from '../../ControlPanel/Actions/Split/utils';
import { ControlPanel } from '../../types';
import { updatePlaceholdersToGhostNodes } from '../../utils';

const BranchingEdge: React.FC<EdgeProps> = ({
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
    label,
    target,
    source,
}) => {
    const [edgePath] = getSmoothStepPath({
        sourceX,
        sourceY,
        sourcePosition,
        targetX,
        targetY,
        targetPosition,
    });
    const { nodes, edges, journeyPayload } = useJourneyBuilderContext(
        (context) => context.state,
    );
    const { setNodes, removePlaceholderNodes, openControlPanel } =
        useJourneyBuilderContext((context) => context.actions);
    const handleEdgeLabelClick = useCallback(() => {
        const sourceNode = journeyPayload?.config?.nodes.find(
            (node: JourneyNode) => node.id === source,
        );
        const targetNode = journeyPayload?.config?.nodes.some(
            (node: JourneyNode) => node.id === target,
        );
        if (isSplitBlock(sourceNode) && !targetNode) {
            removePlaceholderNodes();
            setNodes(
                updatePlaceholdersToGhostNodes({
                    nodes,
                    edges,
                    journeyPayload,
                    targetNodeId: target,
                }),
            );
            openControlPanel({
                type: ControlPanel.BLOCKS_LIST,
                reactFlowNodeId: target,
            });
        }
    }, [
        source,
        target,
        nodes,
        edges,
        setNodes,
        removePlaceholderNodes,
        openControlPanel,
        journeyPayload,
    ]);

    return (
        <>
            <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
            <EdgeLabelRenderer>
                <Box
                    style={{
                        transform: `translate(-50%, -50%) translate(${targetX}px, ${
                            targetY - 23
                        }px)`,
                    }}
                    className="absolute pointer-events-auto"
                >
                    {label && (
                        <Box
                            className="ps-1 py-1 pe-2 bg-gray-200 rounded-xl hover:bg-gray-300 !cursor-pointer"
                            onClick={() => {
                                handleEdgeLabelClick();
                            }}
                        >
                            <Flex justify="center" align="center" gap={4}>
                                <Box className="bg-white w-[1rem] rounded-lg flex justify-center">
                                    {typeof label === 'string'
                                        ? label.split(' ')[0]
                                        : null}
                                </Box>
                                <Text>
                                    {typeof label === 'string'
                                        ? label.split(' ').slice(1).join(' ')
                                        : label}
                                </Text>
                            </Flex>
                        </Box>
                    )}
                </Box>
            </EdgeLabelRenderer>
        </>
    );
};

export default BranchingEdge;
