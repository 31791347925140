import { useLocale } from '@hooks/useLocale';
import { AudienceRunTypes, type Audience } from '@lightdash/common';
import { Button, Group, Text } from '@mantine/core';
import { StopCircle } from '@phosphor-icons/react';
import React from 'react';
import { ButtonVariant } from '../../../mantineTheme';

interface SchedulerModalFooterProps {
    audienceData: Audience;
    onClose: () => void;
    handleDeleteSchedule: () => void;
    isPrimaryButtonDisabled: boolean;
}

const SchedulerModalFooter: React.FC<SchedulerModalFooterProps> = ({
    onClose,
    handleDeleteSchedule,
    audienceData,
    isPrimaryButtonDisabled,
}) => {
    const { t } = useLocale();
    const isScheduled =
        audienceData.runType === AudienceRunTypes.SCHEDULED ||
        audienceData.runType === AudienceRunTypes.CRON;
    return (
        <Group
            className={`flex ${
                isScheduled ? 'justify-between' : 'justify-end'
            } pt-3.5`}
        >
            {isScheduled && (
                <Button
                    variant={ButtonVariant.DEFAULT}
                    onClick={() => handleDeleteSchedule()}
                >
                    <StopCircle />
                    <Text className="pl-1">
                        {t('scheduler_modal_content.turn_off_schedule')}
                    </Text>
                </Button>
            )}
            <Group>
                <Button onClick={onClose} variant={ButtonVariant.DEFAULT}>
                    {t('common.cancel')}
                </Button>
                <Button
                    variant={ButtonVariant.PRIMARY}
                    type="submit"
                    disabled={isPrimaryButtonDisabled}
                >
                    {t('password_modal.primary_btn_text')}
                </Button>
            </Group>
        </Group>
    );
};

export default SchedulerModalFooter;
