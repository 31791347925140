import { subject } from '@casl/ability';
// import { CampaignBuilderQueryParams } from '@components/Campaigns/Builder/types';
import CampaignTable, {
    CampaignTab,
} from '@components/Campaigns/Table/CampaignTable';
import { useAbilityContext } from '@components/common/Authorization';
import ManagerBuilderContainer from '@components/common/BuilderContainer/ManagerBuilderContainer';
import ChannelIcon from '@components/common/IconPack/ChannelIcon';
import TextInput from '@components/common/Inputs/TextInput';
import Modal from '@components/common/modal/Modal';
import ModalFooter from '@components/common/modal/ModalFooter';
import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import Page from '@components/Page/Page';
import { isProjectSetUpCompleted } from '@components/ProjectConnection/utils';
import SetupPage from '@components/SetupPage';
import { useCampaigns, useCreateCampaign } from '@hooks/useCampaigns';
import { useLocale } from '@hooks/useLocale';
import {
    CampaignStatus,
    CommonReservedTags,
    CommunicationChannelName,
    type CommunicationChannel,
} from '@lightdash/common';
import { Box, Button, Flex, Menu, Stack, Text, Textarea } from '@mantine/core';
import { useDebouncedState } from '@mantine/hooks';
import { CaretRight, PlusCircle, SpinnerGap } from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useProjectContext } from '@providers/ProjectProvider';
import { SEARCH_INPUT_DEBOUNCE_TIME } from '@utils/constants';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ButtonVariant } from '../mantineTheme';

const CampaignBuilderContainer: React.FC<{}> = () => {
    const history = useHistory();
    const { t } = useLocale();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const [createModalOpen, setCreateModalOpen] = useState<boolean>(false);
    const { projectData: activeProject } = useProjectContext();
    const [selectedChannel, setSelectedChannel] =
        useState<CommunicationChannel | null>(null);
    const [campaignName, setCampaignName] = useState<string>('');
    const [campaignDescription, setCampaignDescription] = useState<string>('');
    const [searchString, setSearchString] = useDebouncedState<string>(
        '',
        SEARCH_INPUT_DEBOUNCE_TIME,
    );
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedTab, setSelectedTab] = useState<CampaignTab>(
        CampaignTab.ALL,
    );
    const [isHiddenActive, setIsHiddenActive] = useState<boolean>(false);
    const [pollingActive, setPollingActive] = useState<boolean>(true);
    const {
        data: campaigns,
        isInitialLoading,
        isFetching,
    } = useCampaigns({
        perPage: 10,
        currentPage,
        query: `name=${searchString}${
            selectedTab !== CampaignTab.ALL ? `&filter=${selectedTab}` : ''
        }&${
            isHiddenActive
                ? `includesTags=${CommonReservedTags.HIDDEN}`
                : `excludesTags=${CommonReservedTags.HIDDEN}`
        }`,
        polling: pollingActive,
    });

    const { data: campaignsHidden } = useCampaigns({
        perPage: 10,
        currentPage: 1,
        query: `includesTags=${CommonReservedTags.HIDDEN}`,
        polling: false,
    });
    const { mutate: createCampaign } = useCreateCampaign();
    const { communicationChannels } = useProjectContext();
    useEffect(() => {
        if ((campaignsHidden?.data?.length ?? 0) === 0) {
            setIsHiddenActive(false);
        }
    }, [campaignsHidden]);
    const { user } = useApp();
    const ability = useAbilityContext();
    const canCreateCampaigns = ability.can(
        'manage',
        subject('Campaign', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleSearchInput = (value: string) => {
        setSearchString(value);
        setCurrentPage(1);
    };

    const handleTabChange = (tab: CampaignTab) => {
        setSelectedTab(tab);
        setCurrentPage(1);
        if (tab === CampaignTab.ALL) {
            history.push(`/projects/${projectUuid}/campaigns`);
        } else {
            history.push(`/projects/${projectUuid}/campaigns#${tab}`);
        }
    };

    useEffect(() => {
        if (history.location.hash) {
            setSelectedTab(history.location.hash.substring(1) as CampaignTab);
        }
    }, [history.location.hash]);

    useEffect(() => {
        if (campaigns?.data) {
            const allowedPollingStatus = [CampaignStatus.IN_PROGRESS];
            const res = campaigns.data.some(
                (campaign) =>
                    campaign?.status &&
                    allowedPollingStatus.includes(campaign?.status),
            );
            setPollingActive(res);
        }
    }, [campaigns]);

    const handleMenuItemClick = useCallback((channel: CommunicationChannel) => {
        setCreateModalOpen(true);
        setSelectedChannel(channel);
        // history.push(
        //     `/projects/${projectUuid}/campaigns/create?${CampaignBuilderQueryParams.CHANNEL}=${channel}`,
        // );
    }, []);

    const handleCreateCampaign = useCallback(() => {
        setCreateModalOpen(false);
        if (selectedChannel) {
            createCampaign({
                name: campaignName,
                description: campaignDescription,
                channel: selectedChannel,
            });
        }
        // TODO: Implement
        // history.push(
        //     `/projects/${projectUuid}/campaigns/create?${CampaignBuilderQueryParams.CHANNEL}=${channel}`,
        // );
    }, [campaignDescription, campaignName, createCampaign, selectedChannel]);

    const labelBox = useCallback((tabLabel: string, tabCount: number) => {
        return (
            <Box className="flex gap-1">
                <Text>{tabLabel}</Text>
                <Text className="text-sm text-gray-600">{tabCount}</Text>
            </Box>
        );
    }, []);

    const statsMap = useMemo(() => {
        return (
            campaigns &&
            campaigns.stats &&
            campaigns.stats.reduce((acc, stat) => {
                acc[stat.status] = stat.count;
                return acc;
            }, {})
        );
    }, [campaigns]);

    const totalCampaigns = useMemo(() => {
        return (
            statsMap &&
            Object.values(statsMap).reduce((sum, count) => sum + count, 0)
        );
    }, [statsMap]);

    const tabs = useMemo(() => {
        if (!statsMap) return [];

        return [
            {
                key: CampaignTab.ALL,
                label: labelBox(
                    t('campaign_manager.tabs.all'),
                    totalCampaigns || 0,
                ),
            },
            {
                key: CampaignTab.SCHEDULED,
                label: labelBox(
                    t('campaign_manager.tabs.scheduled'),
                    statsMap[CampaignStatus.SCHEDULED] || 0,
                ),
            },
            {
                key: CampaignTab.READY,
                label: labelBox(
                    t('campaign_manager.tabs.ready'),
                    statsMap[CampaignStatus.READY] || 0,
                ),
            },
            {
                key: CampaignTab.FINISHED,
                label: labelBox(
                    t('campaign_manager.tabs.finished'),
                    statsMap[CampaignStatus.COMPLETED] || 0,
                ),
            },
            {
                key: CampaignTab.DRAFTS,
                label: labelBox(
                    t('campaign_manager.tabs.drafts'),
                    statsMap[CampaignStatus.DRAFT] || 0,
                ),
            },
            {
                key: CampaignTab.RUNNING_NOW,
                label: (
                    <Flex className="!text-blu-800 gap-1" align="center">
                        <SpinnerGap
                            size={14}
                            color="rgb(var(--color-blu-800))"
                        />
                        {labelBox(
                            t('campaign_manager.tabs.running_now'),
                            statsMap[CampaignStatus.IN_PROGRESS] || 0,
                        )}
                    </Flex>
                ),
            },
            {
                key: CampaignTab.FAILURE,
                label: labelBox(
                    t('campaign_manager.tabs.failure'),
                    statsMap[CampaignStatus.FAILURE] || 0,
                ),
            },
        ];
    }, [statsMap, t, totalCampaigns, labelBox]);

    const renderCreateCampaignButton = useMemo(() => {
        return (
            <Menu>
                <Menu.Target>
                    <Button
                        styles={{
                            root: {
                                height: '28px',
                            },
                        }}
                        leftIcon={<PlusCircle color="white" />}
                    >
                        {campaigns?.data?.length
                            ? t('campaign.create.title')
                            : t('campaign_manager.create_your_first_campaign')}
                    </Button>
                </Menu.Target>
                <Menu.Dropdown>
                    {communicationChannels?.map((channel) => (
                        <Menu.Item
                            key={channel.value}
                            onClick={() =>
                                handleMenuItemClick(
                                    channel.value as CommunicationChannel,
                                )
                            }
                        >
                            <Flex gap={6}>
                                <ChannelIcon
                                    channel={
                                        channel.value as CommunicationChannel
                                    }
                                    showChannelName={false}
                                />
                                <Text className="text-gray-800 font-medium">
                                    {channel.name}
                                </Text>
                            </Flex>
                        </Menu.Item>
                    ))}
                </Menu.Dropdown>
            </Menu>
        );
    }, [campaigns, communicationChannels, handleMenuItemClick, t]);
    if (activeProject && !isProjectSetUpCompleted(activeProject)) {
        return (
            <SetupPage
                description={t('set_up_page.description', {
                    type_description: t(
                        'set_up_page.type_description.campaigns',
                    ),
                })}
            />
        );
    }

    if (isInitialLoading) {
        return <SuboptimalState loading />;
    }
    return (
        <ManagerBuilderContainer
            withContentPadding={false}
            title={t('campaign.title')}
            rightSection={
                canCreateCampaigns &&
                campaigns?.data?.length &&
                renderCreateCampaignButton
            }
        >
            {!Boolean(totalCampaigns) && searchString === '' ? (
                canCreateCampaigns && renderCreateCampaignButton
            ) : (
                <CampaignTable
                    isDataFetching={isFetching}
                    campaigns={campaigns}
                    tabs={tabs}
                    onPageChange={async (newPage) => {
                        setCurrentPage(newPage);
                    }}
                    onSearchChange={handleSearchInput}
                    searchValue={searchString}
                    setSelectedTab={handleTabChange}
                    selectedTab={selectedTab}
                    isHiddenActive={isHiddenActive}
                    onHiddenChange={(isHiddenFlag: boolean) => {
                        setIsHiddenActive(isHiddenFlag);
                        setCurrentPage(1);
                    }}
                    hideHiddenButton={
                        (campaignsHidden?.data?.length ?? 0) === 0
                    }
                />
            )}
            <Modal
                opened={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                title={
                    <Flex gap={'xs'}>
                        {selectedChannel && (
                            <ChannelIcon
                                showChannelName={false}
                                channel={selectedChannel}
                            />
                        )}
                        <Text>
                            {`New ${
                                selectedChannel &&
                                CommunicationChannelName[selectedChannel]
                            } Campaign`}
                        </Text>
                    </Flex>
                }
                footerRightSection={
                    <ModalFooter
                        primaryText="Start"
                        secondaryText="Cancel"
                        primaryButtonClick={handleCreateCampaign}
                        secondaryButtonClick={() => setCreateModalOpen(false)}
                        showPrimaryButton
                        showSecondaryButton
                        primaryLeftIcon={undefined}
                        primaryRightIcon={<CaretRight color="white" />}
                        secondaryLeftIcon={undefined}
                        secondaryRightIcon={undefined}
                        primaryButtonVariant={undefined}
                        secondaryButtonVariant={ButtonVariant.OUTLINED}
                        isLoading={undefined}
                        primaryButtonDisabled={undefined}
                        primaryButtonCustomClass={undefined}
                    />
                }
            >
                <Stack spacing={'xs'}>
                    <TextInput
                        label="Name"
                        placeholder="Enter a name"
                        value={campaignName}
                        onChange={(e) => setCampaignName(e.target.value)}
                    />
                    <Textarea
                        label="Description (Optional)"
                        placeholder="Leave a note"
                        value={campaignDescription}
                        onChange={(e) => setCampaignDescription(e.target.value)}
                    />
                </Stack>
            </Modal>
        </ManagerBuilderContainer>
    );
};

const CampaignManager = () => {
    const { t } = useLocale();
    return (
        <>
            <Page
                title={t('campaign.title')}
                withFullHeight
                withPaddedContent
                withNavbar
                withFixedContent
            >
                <CampaignBuilderContainer />
            </Page>
        </>
    );
};
export default CampaignManager;
