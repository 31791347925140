import { subject } from '@casl/ability';
import { useFieldsWithSuggestions } from '@components/Audience/Filters/FiltersCard/useFieldsWithSuggestions';
import { useAbilityContext } from '@components/common/Authorization';
import TableIcon from '@components/common/IconPack/TableIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Timestamp from '@components/common/Timestamp';

import Modal from '@components/common/modal/Modal';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useGetCustomMetricById,
    useUpdateCustomMetricTags,
} from '@hooks/useCustomMetric';
import { useLocale } from '@hooks/useLocale';
import {
    CommonReservedTags,
    RelationTableType,
    type CustomAttribute,
} from '@lightdash/common';
import { Box, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    CaretRight,
} from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useQueryClient } from '@tanstack/react-query';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronLeft, Copy, Hash } from 'react-feather';
import { useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant, GlobalStyles } from '../../../mantineTheme';
import CreateCustomMetric from '../CreateCustomMetric';
interface CustomMetricMenuItemProps {
    rowData: Row<CustomAttribute>;
}
const CUSTOM_METRIC_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const CUSTOM_METRIC_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const CustomMetricMenuItem: React.FC<CustomMetricMenuItemProps> = ({
    rowData,
}) => {
    const { t } = useLocale();
    const ability = useAbilityContext();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { user } = useApp();
    const queryClient = useQueryClient();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const canCreateCustomMetrics = ability.can(
        'create',
        subject('CustomMetric', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );
    const { showToastSuccess, showToastError } = useNotify();
    const { mutate: updateCustomMetricTags } = useUpdateCustomMetricTags(
        rowData.original.uuid,
    );
    const [customMetricId, setCustomMetricId] = useState<string | undefined>(
        undefined,
    );
    const {
        data: customMetricData,
        refetch,
        isRefetching,
        isFetching,
    } = useGetCustomMetricById(customMetricId ?? '');

    useEffect(() => {
        if (customMetricId && !isRefetching && !isFetching) {
            void refetch();
        }
    }, [customMetricId, refetch, isRefetching, isFetching]);
    const handleModalClose = () => {
        setCustomMetricId(undefined);
    };

    const handleDuplicate = useCallback(
        (id: string) => {
            if (id) {
                setCustomMetricId(id);
            }
        },
        [setCustomMetricId],
    );
    const restoreItem = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async () => {
                await updateCustomMetricTags(
                    {
                        tags: [
                            ...(rowData.original.tags ?? []).filter(
                                (tag) => tag !== CommonReservedTags.HIDDEN,
                            ),
                        ],
                    },
                    {
                        onSuccess: async () => {
                            await queryClient.invalidateQueries([
                                QueryKeys.GET_CUSTOM_METRICS,
                            ]);
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.custom_metric'),
                                }),
                            });
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.custom_metric'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            showToastSuccess,
            showToastError,
            updateCustomMetricTags,
            rowData.original.tags,
            queryClient,
        ],
    );
    const hideItems = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: <Text className="text-halt-800">{t('common.hide')}</Text>,
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const menuItems = useMemo(() => {
        const items: CustomMenuItemProps<CustomAttribute>[] = [
            {
                leftSection: (
                    <Copy
                        size={14}
                        color={'rgb(var(--color-gray-500))'}
                        strokeWidth={2.5}
                    />
                ),
                children: t('custom_metric_manager.menu_item_duplicate'),
                onClick: (values) => handleDuplicate(values.uuid),
            },
            rowData.original.tags?.includes(CommonReservedTags.HIDDEN)
                ? restoreItem
                : hideItems,
        ];
        return items;
    }, [t, handleDuplicate, restoreItem, hideItems, rowData.original.tags]);
    if (!canCreateCustomMetrics) {
        return null;
    }

    return (
        canCreateCustomMetrics && (
            <Box
                onClick={(event) => {
                    event.stopPropagation();
                }}
            >
                <CustomMenu menuItems={menuItems} data={rowData.original} />

                <CreateCustomMetric
                    customMetricData={customMetricData}
                    customMetricId={customMetricId}
                    isDuplicate={true}
                    onModalClose={handleModalClose}
                    openBaseTable={undefined}
                    tableName={undefined}
                    isViewMode={undefined}
                    disableTableChange={undefined}
                />
                <Modal
                    opened={openedHideConfirmModal}
                    onClose={closeHideConfirmModal}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    title={
                        <Box>
                            <Text>
                                {t(
                                    'custom_metric_table_modal.confirm_hide_modal_title',
                                )}
                            </Text>
                            <Text className="text-sm font-normal text-gray-600">
                                {rowData.original.name}
                            </Text>
                        </Box>
                    }
                    footerRightSection={
                        <ButtonGroup
                            primaryButtonLabel={t(
                                'audience_manager.confirm_hide_prim_button',
                            )}
                            secondaryButtonLabel={t('common.no')}
                            isUpdating={false}
                            handlePrimaryButtonClick={async () => {
                                await updateCustomMetricTags(
                                    {
                                        tags: [
                                            ...(rowData.original.tags ?? []),
                                            CommonReservedTags.HIDDEN,
                                        ],
                                    },
                                    {
                                        onSuccess: async () => {
                                            showToastSuccess({
                                                title: t(
                                                    'common.hidden_success',
                                                    {
                                                        entityName: t(
                                                            'common.custom_metric',
                                                        ),
                                                    },
                                                ),
                                            });
                                            await queryClient.invalidateQueries(
                                                [QueryKeys.GET_CUSTOM_METRICS],
                                            );
                                        },
                                        onError: (error) => {
                                            showToastError({
                                                title: t(
                                                    'common.hidden_error',
                                                    {
                                                        entityName: t(
                                                            'common.custom_metric',
                                                        ),
                                                    },
                                                ),
                                                subtitle: error.error.message,
                                            });
                                        },
                                    },
                                );
                                closeHideConfirmModal();
                            }}
                            handleSecondaryButtonClick={closeHideConfirmModal}
                            primaryButtonVariant={
                                ButtonVariant.FILLED_DESTRUCTIVE
                            }
                            primaryButtonRightIcon={
                                <CaretRight color="white" />
                            }
                        />
                    }
                    closeButtonProps={{
                        className: 'absolute top-3 right-3',
                    }}
                >
                    <Flex direction={'column'} gap={12}>
                        <Text className="font-semibold text-gray-800 text-sm">
                            {t(
                                'custom_metric_table_modal.confirm_hide_modal_header',
                            )}
                        </Text>
                    </Flex>
                </Modal>
            </Box>
        )
    );
};
export const useCustomMetricCoulumns = () => {
    const [columns, setColumns] = useState<ColumnDef<CustomAttribute>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();
    const { activeRelation } = useRelationContext();
    const fieldsWithSuggestions = useFieldsWithSuggestions({
        relationData: activeRelation,
        queryResults: undefined,
        additionalMetrics: undefined,
        tableCalculations: undefined,
        customDimensions: undefined,
    });

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('custom_metric_manager.th_custom_metric_name'),
                    cell: ({ row }) => {
                        return (
                            <Box className={CUSTOM_METRIC_TABLE_CELL_CLASSNAME}>
                                <Box className="flex flex-col gap-1 justify-center items-start ">
                                    <Flex
                                        justify="center"
                                        align="center"
                                        className="gap-1"
                                    >
                                        {row.original.tags?.includes(
                                            CommonReservedTags.HIDDEN,
                                        ) && <Archive weight="duotone" />}
                                        <Hash
                                            color={'rgb(var(--color-gray-600)'}
                                            size={13}
                                            strokeWidth={2}
                                            className="inline me-1"
                                        />
                                        <Text
                                            span
                                            className={
                                                CUSTOM_METRIC_TABLE_CELL_NAME
                                            }
                                        >
                                            {row.original.name}
                                        </Text>
                                        <ChevronLeft
                                            color={'rgb(var(--color-gray-600)'}
                                            size={13}
                                            strokeWidth={2}
                                        />
                                        <TableIcon
                                            type={
                                                activeRelation?.tables[
                                                    row.original.srcTable
                                                ]?.type ??
                                                RelationTableType.RELATED
                                            }
                                            strokeWidth={2.5}
                                        />
                                        {activeRelation?.tables[
                                            row.original.srcTable
                                        ]?.type && (
                                            <Text
                                                className={
                                                    `
                                ${
                                    GlobalStyles.tableStyles[
                                        activeRelation?.tables[
                                            row.original.srcTable
                                        ].type
                                    ].textColorTailwind
                                }` + ' whitespace-nowrap'
                                                }
                                            >
                                                {
                                                    activeRelation?.tables[
                                                        row.original.srcTable
                                                    ].label
                                                }
                                            </Text>
                                        )}
                                    </Flex>
                                    {row.original.description && (
                                        <Text
                                            span
                                            className="text-xs font-normal text-gray-600 "
                                        >
                                            {row.original.description}
                                        </Text>
                                    )}
                                </Box>
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box
                            className="text-sm text-gray-800"
                            onClick={(event) => {
                                event.stopPropagation();
                            }}
                        >
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`by ${
                                    row.original.createdBy?.firstName || ''
                                } ${row.original.createdBy?.lastName || ''}`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => <CustomMetricMenuItem rowData={row} />,
                },
            ]);
        }
    }, [t, projectUuid, activeRelation, fieldsWithSuggestions]);
    return columns;
};
