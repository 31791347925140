import { FilterOperator, ReservedEventColumns } from '@lightdash/common';
import { t as translate } from 'i18next';
import { v4 as uuidv4 } from 'uuid';
export enum ProfilesActivityAndEngagementTabs {
    EVENT_HISTORY = 'event_history',
    ENGAGEMENT = 'engagement',
}

/**
 * @param timestamp - ISO 8601 timestamp
 * @returns formatted time string
 */
export const getFormattedTime = (timestamp: string) => {
    return new Date(timestamp).toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
    });
};

/**
 * @param dateString - Date string in 'DD/MM/YYYY' format
 * @returns formatted date string
 */
export const formatDate = (dateString: string) => {
    const [day, month, year] = dateString.split('/').map(Number);
    const date = new Date(year, month - 1, day);
    if (isNaN(date.getTime())) {
        return 'Invalid Date';
    }

    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);

    const isToday =
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear();

    const isYesterday =
        date.getDate() === yesterday.getDate() &&
        date.getMonth() === yesterday.getMonth() &&
        date.getFullYear() === yesterday.getFullYear();

    const isTomorrow =
        date.getDate() === tomorrow.getDate() &&
        date.getMonth() === tomorrow.getMonth() &&
        date.getFullYear() === tomorrow.getFullYear();

    const options: Intl.DateTimeFormatOptions = {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    };

    if (isToday) {
        return `${translate('common.today')}, ${date.toLocaleDateString(
            'en-GB',
            options,
        )}`;
    } else if (isYesterday) {
        return `${translate('common.yesterday')}, ${date.toLocaleDateString(
            'en-GB',
            options,
        )}`;
    } else if (isTomorrow) {
        return `${translate('common.tomorrow')}, ${date.toLocaleDateString(
            'en-GB',
            options,
        )}`;
    } else {
        return date.toLocaleDateString('en-GB', options);
    }
};
/**
 * @param projectUuid
 * @returns event table name
 */
export const getEventTableName = (projectUuid: string) => {
    return `raw_events_${projectUuid.split('-')[0]}`;
};

/**
 * @param projectUuid
 * @returns filters for user history
 */
export const getUserHistoryFilter = (
    projectUuid: string,
    userId: string,
    searchQuery: string = '',
    startTimestamp?: string,
    endTimestamp?: string,
) => {
    const filters = [
        {
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(projectUuid)}_${
                    ReservedEventColumns.USER_ID
                }`,
            },
            operator: FilterOperator.EQUALS,
            values: [userId],
        },
    ];

    if (searchQuery) {
        filters.push({
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(projectUuid)}_${
                    ReservedEventColumns.EVENT_NAME
                }`,
            },
            operator: FilterOperator.INCLUDE,
            values: [searchQuery],
        });
    }

    if (startTimestamp) {
        filters.push({
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(projectUuid)}_${
                    ReservedEventColumns.SERVER_TIMESTAMP
                }`,
            },
            operator: FilterOperator.GREATER_THAN_OR_EQUAL,
            values: [startTimestamp],
        });
    }

    if (endTimestamp) {
        filters.push({
            id: uuidv4(),
            target: {
                fieldId: `${getEventTableName(projectUuid)}_${
                    ReservedEventColumns.SERVER_TIMESTAMP
                }`,
            },
            operator: FilterOperator.LESS_THAN_OR_EQUAL,
            values: [endTimestamp],
        });
    }

    return {
        dimensions: {
            id: uuidv4(),
            and: filters,
        },
    };
};
