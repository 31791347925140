export enum Timezones {
    ETC_GMT_PLUS_12 = 'Etc/GMT+12',
    ETC_GMT_PLUS_11 = 'Etc/GMT+11',
    PACIFIC_MIDWAY = 'Pacific/Midway',
    PACIFIC_NIUE = 'Pacific/Niue',
    PACIFIC_PAGO_PAGO = 'Pacific/Pago_Pago',
    PACIFIC_SAMOA = 'Pacific/Samoa',
    US_SAMOA = 'US/Samoa',
    ETC_GMT_PLUS_10 = 'Etc/GMT+10',
    HST = 'HST',
    PACIFIC_HONOLULU = 'Pacific/Honolulu',
    PACIFIC_JOHNSTON = 'Pacific/Johnston',
    PACIFIC_RAROTONGA = 'Pacific/Rarotonga',
    PACIFIC_TAHITI = 'Pacific/Tahiti',
    US_HAWAII = 'US/Hawaii',
    PACIFIC_MARQUESAS = 'Pacific/Marquesas',
    AMERICA_ADAK = 'America/Adak',
    AMERICA_ATKA = 'America/Atka',
    ETC_GMT_PLUS_9 = 'Etc/GMT+9',
    PACIFIC_GAMBIER = 'Pacific/Gambier',
    US_ALEUTIAN = 'US/Aleutian',
    AMERICA_ANCHORAGE = 'America/Anchorage',
    AMERICA_JUNEAU = 'America/Juneau',
    AMERICA_METLAKATLA = 'America/Metlakatla',
    AMERICA_NOME = 'America/Nome',
    AMERICA_SITKA = 'America/Sitka',
    AMERICA_YAKUTAT = 'America/Yakutat',
    ETC_GMT_PLUS_8 = 'Etc/GMT+8',
    PACIFIC_PITCAIRN = 'Pacific/Pitcairn',
    US_ALASKA = 'US/Alaska',
    AMERICA_CRESTON = 'America/Creston',
    AMERICA_DAWSON = 'America/Dawson',
    AMERICA_DAWSON_CREEK = 'America/Dawson_Creek',
    AMERICA_ENSENADA = 'America/Ensenada',
    AMERICA_FORT_NELSON = 'America/Fort_Nelson',
    AMERICA_HERMOSILLO = 'America/Hermosillo',
    AMERICA_LOS_ANGELES = 'America/Los_Angeles',
    AMERICA_MAZATLAN = 'America/Mazatlan',
    AMERICA_PHOENIX = 'America/Phoenix',
    AMERICA_SANTA_ISABEL = 'America/Santa_Isabel',
    AMERICA_TIJUANA = 'America/Tijuana',
    AMERICA_VANCOUVER = 'America/Vancouver',
    AMERICA_WHITEHORSE = 'America/Whitehorse',
    CANADA_PACIFIC = 'Canada/Pacific',
    CANADA_YUKON = 'Canada/Yukon',
    ETC_GMT_PLUS_7 = 'Etc/GMT+7',
    MST = 'MST',
    MEXICO_BAJANORTE = 'Mexico/BajaNorte',
    MEXICO_BAJASUR = 'Mexico/BajaSur',
    PST8PDT = 'PST8PDT',
    US_ARIZONA = 'US/Arizona',
    US_PACIFIC = 'US/Pacific',
    AMERICA_BAHIA_BANDERAS = 'America/Bahia_Banderas',
    AMERICA_BELIZE = 'America/Belize',
    AMERICA_BOISE = 'America/Boise',
    AMERICA_CAMBRIDGE_BAY = 'America/Cambridge_Bay',
    AMERICA_CHIHUAHUA = 'America/Chihuahua',
    AMERICA_COSTA_RICA = 'America/Costa_Rica',
    AMERICA_DENVER = 'America/Denver',
    AMERICA_EDMONTON = 'America/Edmonton',
    AMERICA_EL_SALVADOR = 'America/El_Salvador',
    AMERICA_GUATEMALA = 'America/Guatemala',
    AMERICA_INUVIK = 'America/Inuvik',
    AMERICA_MANAGUA = 'America/Managua',
    AMERICA_MERIDA = 'America/Merida',
    AMERICA_MEXICO_CITY = 'America/Mexico_City',
    AMERICA_MONTERREY = 'America/Monterrey',
    AMERICA_REGINA = 'America/Regina',
    AMERICA_SHIPROCK = 'America/Shiprock',
    AMERICA_SWIFT_CURRENT = 'America/Swift_Current',
    AMERICA_TEGUCIGALPA = 'America/Tegucigalpa',
    AMERICA_YELLOWKNIFE = 'America/Yellowknife',
    CANADA_MOUNTAIN = 'Canada/Mountain',
    CANADA_SASKATCHEWAN = 'Canada/Saskatchewan',
    ETC_GMT_PLUS_6 = 'Etc/GMT+6',
    MST7MDT = 'MST7MDT',
    MEXICO_GENERAL = 'Mexico/General',
    NAVAJO = 'Navajo',
    PACIFIC_GALAPAGOS = 'Pacific/Galapagos',
    US_MOUNTAIN = 'US/Mountain',
    AMERICA_ATIKOKAN = 'America/Atikokan',
    AMERICA_BOGOTA = 'America/Bogota',
    AMERICA_CANCUN = 'America/Cancun',
    AMERICA_CAYMAN = 'America/Cayman',
    AMERICA_CHICAGO = 'America/Chicago',
    AMERICA_CORAL_HARBOUR = 'America/Coral_Harbour',
    AMERICA_EIRUNEPE = 'America/Eirunepe',
    AMERICA_GUAYAQUIL = 'America/Guayaquil',
    AMERICA_INDIANA_KNOX = 'America/Indiana/Knox',
    AMERICA_INDIANA_TELL_CITY = 'America/Indiana/Tell_City',
    AMERICA_JAMAICA = 'America/Jamaica',
    AMERICA_KNOX_IN = 'America/Knox_IN',
    AMERICA_LIMA = 'America/Lima',
    AMERICA_MATAMOROS = 'America/Matamoros',
    AMERICA_MENOMINEE = 'America/Menominee',
    AMERICA_NORTH_DAKOTA_BEULAH = 'America/North_Dakota/Beulah',
    AMERICA_NORTH_DAKOTA_CENTER = 'America/North_Dakota/Center',
    AMERICA_NORTH_DAKOTA_NEW_SALEM = 'America/North_Dakota/New_Salem',
    AMERICA_OJINAGA = 'America/Ojinaga',
    AMERICA_PANAMA = 'America/Panama',
    AMERICA_PORTO_ACRE = 'America/Porto_Acre',
    AMERICA_RAINY_RIVER = 'America/Rainy_River',
    AMERICA_RANKIN_INLET = 'America/Rankin_Inlet',
    AMERICA_RESOLUTE = 'America/Resolute',
    AMERICA_RIO_BRANCO = 'America/Rio_Branco',
    AMERICA_WINNIPEG = 'America/Winnipeg',
    BRAZIL_ACRE = 'Brazil/Acre',
    CST6CDT = 'CST6CDT',
    CANADA_CENTRAL = 'Canada/Central',
    CHILE_EASTERISLAND = 'Chile/EasterIsland',
    EST = 'EST',
    ETC_GMT_PLUS_5 = 'Etc/GMT+5',
    JAMAICA = 'Jamaica',
    PACIFIC_EASTER = 'Pacific/Easter',
    US_CENTRAL = 'US/Central',
    US_INDIANA_STARKE = 'US/Indiana-Starke',
    AMERICA_ANGUILLA = 'America/Anguilla',
    AMERICA_ANTIGUA = 'America/Antigua',
    AMERICA_ARUBA = 'America/Aruba',
    AMERICA_BARBADOS = 'America/Barbados',
    AMERICA_BLANC_SABLON = 'America/Blanc-Sablon',
    AMERICA_BOA_VISTA = 'America/Boa_Vista',
    AMERICA_CAMPO_GRANDE = 'America/Campo_Grande',
    AMERICA_CARACAS = 'America/Caracas',
    AMERICA_CUIABA = 'America/Cuiaba',
    AMERICA_CURACAO = 'America/Curacao',
    AMERICA_DETROIT = 'America/Detroit',
    AMERICA_DOMINICA = 'America/Dominica',
    AMERICA_FORT_WAYNE = 'America/Fort_Wayne',
    AMERICA_GRAND_TURK = 'America/Grand_Turk',
    AMERICA_GRENADA = 'America/Grenada',
    AMERICA_GUADELOUPE = 'America/Guadeloupe',
    AMERICA_GUYANA = 'America/Guyana',
    AMERICA_HAVANA = 'America/Havana',
    AMERICA_INDIANA_INDIANAPOLIS = 'America/Indiana/Indianapolis',
    AMERICA_INDIANA_MARENGO = 'America/Indiana/Marengo',
    AMERICA_INDIANA_PETERSBURG = 'America/Indiana/Petersburg',
    AMERICA_INDIANA_VEVAY = 'America/Indiana/Vevay',
    AMERICA_INDIANA_VINCENNES = 'America/Indiana/Vincennes',
    AMERICA_INDIANA_WINAMAC = 'America/Indiana/Winamac',
    AMERICA_INDIANAPOLIS = 'America/Indianapolis',
    AMERICA_IQALUIT = 'America/Iqaluit',
    AMERICA_KENTUCKY_LOUISVILLE = 'America/Kentucky/Louisville',
    AMERICA_KENTUCKY_MONTICELLO = 'America/Kentucky/Monticello',
    AMERICA_KRALENDIJK = 'America/Kralendijk',
    AMERICA_LA_PAZ = 'America/La_Paz',
    AMERICA_LOUISVILLE = 'America/Louisville',
    AMERICA_LOWER_PRINCES = 'America/Lower_Princes',
    AMERICA_MANAUS = 'America/Manaus',
    AMERICA_MARIGOT = 'America/Marigot',
    AMERICA_MARTINIQUE = 'America/Martinique',
    AMERICA_MONTREAL = 'America/Montreal',
    AMERICA_MONTSERRAT = 'America/Montserrat',
    AMERICA_NASSAU = 'America/Nassau',
    AMERICA_NEW_YORK = 'America/New_York',
    AMERICA_NIPIGON = 'America/Nipigon',
    AMERICA_PANGNIRTUNG = 'America/Pangnirtung',
    AMERICA_PORT_AU_PRINCE = 'America/Port-au-Prince',
    AMERICA_PORT_OF_SPAIN = 'America/Port_of_Spain',
    AMERICA_PORTO_VELHO = 'America/Porto_Velho',
    AMERICA_PUERTO_RICO = 'America/Puerto_Rico',
    AMERICA_SANTO_DOMINGO = 'America/Santo_Domingo',
    AMERICA_ST_BARTHELEMY = 'America/St_Barthelemy',
    AMERICA_ST_KITTS = 'America/St_Kitts',
    AMERICA_ST_LUCIA = 'America/St_Lucia',
    AMERICA_ST_THOMAS = 'America/St_Thomas',
    AMERICA_ST_VINCENT = 'America/St_Vincent',
    AMERICA_THUNDER_BAY = 'America/Thunder_Bay',
    AMERICA_TORONTO = 'America/Toronto',
    AMERICA_TORTOLA = 'America/Tortola',
    AMERICA_VIRGIN = 'America/Virgin',
    BRAZIL_WEST = 'Brazil/West',
    CANADA_EASTERN = 'Canada/Eastern',
    CUBA = 'Cuba',
    EST5EDT = 'EST5EDT',
    ETC_GMT_PLUS_4 = 'Etc/GMT+4',
    US_EAST_INDIANA = 'US/East-Indiana',
    US_EASTERN = 'US/Eastern',
    US_MICHIGAN = 'US/Michigan',
    AMERICA_ARAGUAINA = 'America/Araguaina',
    AMERICA_ARGENTINA_BUENOS_AIRES = 'America/Argentina/Buenos_Aires',
    AMERICA_ARGENTINA_CATAMARCA = 'America/Argentina/Catamarca',
    AMERICA_ARGENTINA_COMODRIVADAVIA = 'America/Argentina/ComodRivadavia',
    AMERICA_ARGENTINA_CORDOBA = 'America/Argentina/Cordoba',
    AMERICA_ARGENTINA_JUJUY = 'America/Argentina/Jujuy',
    AMERICA_ARGENTINA_LA_RIOJA = 'America/Argentina/La_Rioja',
    AMERICA_ARGENTINA_MENDOZA = 'America/Argentina/Mendoza',
    AMERICA_ARGENTINA_RIO_GALLEGOS = 'America/Argentina/Rio_Gallegos',
    AMERICA_ARGENTINA_SALTA = 'America/Argentina/Salta',
    AMERICA_ARGENTINA_SAN_JUAN = 'America/Argentina/San_Juan',
    AMERICA_ARGENTINA_SAN_LUIS = 'America/Argentina/San_Luis',
    AMERICA_ARGENTINA_TUCUMAN = 'America/Argentina/Tucuman',
    AMERICA_ARGENTINA_USHUAIA = 'America/Argentina/Ushuaia',
    AMERICA_ASUNCION = 'America/Asuncion',
    AMERICA_BAHIA = 'America/Bahia',
    AMERICA_BELEM = 'America/Belem',
    AMERICA_BUENOS_AIRES = 'America/Buenos_Aires',
    AMERICA_CATAMARCA = 'America/Catamarca',
    AMERICA_CAYENNE = 'America/Cayenne',
    AMERICA_CORDOBA = 'America/Cordoba',
    AMERICA_FORTALEZA = 'America/Fortaleza',
    AMERICA_GLACE_BAY = 'America/Glace_Bay',
    AMERICA_GOOSE_BAY = 'America/Goose_Bay',
    AMERICA_HALIFAX = 'America/Halifax',
    AMERICA_JUJUY = 'America/Jujuy',
    AMERICA_MACEIO = 'America/Maceio',
    AMERICA_MENDOZA = 'America/Mendoza',
    AMERICA_MONCTON = 'America/Moncton',
    AMERICA_MONTEVIDEO = 'America/Montevideo',
    AMERICA_PARAMARIBO = 'America/Paramaribo',
    AMERICA_PUNTA_ARENAS = 'America/Punta_Arenas',
    AMERICA_RECIFE = 'America/Recife',
    AMERICA_ROSARIO = 'America/Rosario',
    AMERICA_SANTAREM = 'America/Santarem',
    AMERICA_SANTIAGO = 'America/Santiago',
    AMERICA_SAO_PAULO = 'America/Sao_Paulo',
    AMERICA_THULE = 'America/Thule',
    ANTARCTICA_PALMER = 'Antarctica/Palmer',
    ANTARCTICA_ROTHERA = 'Antarctica/Rothera',
    ATLANTIC_BERMUDA = 'Atlantic/Bermuda',
    ATLANTIC_STANLEY = 'Atlantic/Stanley',
    BRAZIL_EAST = 'Brazil/East',
    CANADA_ATLANTIC = 'Canada/Atlantic',
    CHILE_CONTINENTAL = 'Chile/Continental',
    ETC_GMT_PLUS_3 = 'Etc/GMT+3',
    AMERICA_ST_JOHNS = 'America/St_Johns',
    CANADA_NEWFOUNDLAND = 'Canada/Newfoundland',
    AMERICA_GODTHAB = 'America/Godthab',
    AMERICA_MIQUELON = 'America/Miquelon',
    AMERICA_NORONHA = 'America/Noronha',
    AMERICA_NUUK = 'America/Nuuk',
    ATLANTIC_SOUTH_GEORGIA = 'Atlantic/South_Georgia',
    BRAZIL_DENORONHA = 'Brazil/DeNoronha',
    ETC_GMT_PLUS_2 = 'Etc/GMT+2',
    AMERICA_SCORESBYSUND = 'America/Scoresbysund',
    ATLANTIC_AZORES = 'Atlantic/Azores',
    ATLANTIC_CAPE_VERDE = 'Atlantic/Cape_Verde',
    ETC_GMT_PLUS_1 = 'Etc/GMT+1',
    AFRICA_ABIDJAN = 'Africa/Abidjan',
    AFRICA_ACCRA = 'Africa/Accra',
    AFRICA_BAMAKO = 'Africa/Bamako',
    AFRICA_BANJUL = 'Africa/Banjul',
    AFRICA_BISSAU = 'Africa/Bissau',
    AFRICA_CASABLANCA = 'Africa/Casablanca',
    AFRICA_CONAKRY = 'Africa/Conakry',
    AFRICA_DAKAR = 'Africa/Dakar',
    AFRICA_EL_AAIUN = 'Africa/El_Aaiun',
    AFRICA_FREETOWN = 'Africa/Freetown',
    AFRICA_LOME = 'Africa/Lome',
    AFRICA_MONROVIA = 'Africa/Monrovia',
    AFRICA_NOUAKCHOTT = 'Africa/Nouakchott',
    AFRICA_OUAGADOUGOU = 'Africa/Ouagadougou',
    AFRICA_SAO_TOME = 'Africa/Sao_Tome',
    AFRICA_TIMBUKTU = 'Africa/Timbuktu',
    AMERICA_DANMARKSHAVN = 'America/Danmarkshavn',
    ANTARCTICA_TROLL = 'Antarctica/Troll',
    ATLANTIC_CANARY = 'Atlantic/Canary',
    ATLANTIC_FAEROE = 'Atlantic/Faeroe',
    ATLANTIC_FAROE = 'Atlantic/Faroe',
    ATLANTIC_MADEIRA = 'Atlantic/Madeira',
    ATLANTIC_REYKJAVIK = 'Atlantic/Reykjavik',
    ATLANTIC_ST_HELENA = 'Atlantic/St_Helena',
    EIRE = 'Eire',
    ETC_GMT = 'Etc/GMT',
    ETC_GMT_PLUS_0 = 'Etc/GMT+0',
    ETC_GMT_MINUS_0 = 'Etc/GMT-0',
    ETC_GMT0 = 'Etc/GMT0',
    ETC_GREENWICH = 'Etc/Greenwich',
    ETC_UCT = 'Etc/UCT',
    ETC_UTC = 'Etc/UTC',
    ETC_UNIVERSAL = 'Etc/Universal',
    ETC_ZULU = 'Etc/Zulu',
    EUROPE_BELFAST = 'Europe/Belfast',
    EUROPE_DUBLIN = 'Europe/Dublin',
    EUROPE_GUERNSEY = 'Europe/Guernsey',
    EUROPE_ISLE_OF_MAN = 'Europe/Isle_of_Man',
    EUROPE_JERSEY = 'Europe/Jersey',
    EUROPE_LISBON = 'Europe/Lisbon',
    EUROPE_LONDON = 'Europe/London',
    GB = 'GB',
    GB_EIRE = 'GB-Eire',
    GMT = 'GMT',
    GMT_PLUS_0 = 'GMT+0',
    GMT_MINUS_0 = 'GMT-0',
    GMT0 = 'GMT0',
    GREENWICH = 'Greenwich',
    ICELAND = 'Iceland',
    PORTUGAL = 'Portugal',
    UCT = 'UCT',
    UTC = 'UTC',
    UNIVERSAL = 'Universal',
    WET = 'WET',
    ZULU = 'Zulu',
    AFRICA_ALGIERS = 'Africa/Algiers',
    AFRICA_BANGUI = 'Africa/Bangui',
    AFRICA_BRAZZAVILLE = 'Africa/Brazzaville',
    AFRICA_CEUTA = 'Africa/Ceuta',
    AFRICA_DOUALA = 'Africa/Douala',
    AFRICA_KINSHASA = 'Africa/Kinshasa',
    AFRICA_LAGOS = 'Africa/Lagos',
    AFRICA_LIBREVILLE = 'Africa/Libreville',
    AFRICA_LUANDA = 'Africa/Luanda',
    AFRICA_MALABO = 'Africa/Malabo',
    AFRICA_NDJAMENA = 'Africa/Ndjamena',
    AFRICA_NIAMEY = 'Africa/Niamey',
    AFRICA_PORTO_NOVO = 'Africa/Porto-Novo',
    AFRICA_TUNIS = 'Africa/Tunis',
    ARCTIC_LONGYEARBYEN = 'Arctic/Longyearbyen',
    ATLANTIC_JAN_MAYEN = 'Atlantic/Jan_Mayen',
    CET = 'CET',
    ETC_GM_MINUS_1 = 'Etc/GMT-1',
    EUROPE_AMSTERDAM = 'Europe/Amsterdam',
    EUROPE_ANDORRA = 'Europe/Andorra',
    EUROPE_BELGRADE = 'Europe/Belgrade',
    EUROPE_BERLIN = 'Europe/Berlin',
    EUROPE_BRATISLAVA = 'Europe/Bratislava',
    EUROPE_BRUSSELS = 'Europe/Brussels',
    EUROPE_BUDAPEST = 'Europe/Budapest',
    EUROPE_BUSINGEN = 'Europe/Busingen',
    EUROPE_COPENHAGEN = 'Europe/Copenhagen',
    EUROPE_GIBRALTAR = 'Europe/Gibraltar',
    EUROPE_LJUBLJANA = 'Europe/Ljubljana',
    EUROPE_LUXEMBOURG = 'Europe/Luxembourg',
    EUROPE_MADRID = 'Europe/Madrid',
    EUROPE_MALTA = 'Europe/Malta',
    EUROPE_MONACO = 'Europe/Monaco',
    EUROPE_OSLO = 'Europe/Oslo',
    EUROPE_PARIS = 'Europe/Paris',
    EUROPE_PODGORICA = 'Europe/Podgorica',
    EUROPE_PRAGUE = 'Europe/Prague',
    EUROPE_ROME = 'Europe/Rome',
    EUROPE_SAN_MARINO = 'Europe/San_Marino',
    EUROPE_SARAJEVO = 'Europe/Sarajevo',
    EUROPE_SKOPJE = 'Europe/Skopje',
    EUROPE_STOCKHOLM = 'Europe/Stockholm',
    EUROPE_TIRANE = 'Europe/Tirane',
    EUROPE_VADUZ = 'Europe/Vaduz',
    EUROPE_VATICAN = 'Europe/Vatican',
    EUROPE_VIENNA = 'Europe/Vienna',
    EUROPE_WARSAW = 'Europe/Warsaw',
    EUROPE_ZAGREB = 'Europe/Zagreb',
    EUROPE_ZURICH = 'Europe/Zurich',
    MET = 'MET',
    POLAND = 'Poland',
    AFRICA_BLANTYRE = 'Africa/Blantyre',
    AFRICA_BUJUMBURA = 'Africa/Bujumbura',
    AFRICA_CAIRO = 'Africa/Cairo',
    AFRICA_GABORONE = 'Africa/Gaborone',
    AFRICA_HARARE = 'Africa/Harare',
    AFRICA_JOHANNESBURG = 'Africa/Johannesburg',
    AFRICA_JUBA = 'Africa/Juba',
    AFRICA_KHARTOUM = 'Africa/Khartoum',
    AFRICA_KIGALI = 'Africa/Kigali',
    AFRICA_LUBUMBASHI = 'Africa/Lubumbashi',
    AFRICA_LUSAKA = 'Africa/Lusaka',
    AFRICA_MAPUTO = 'Africa/Maputo',
    AFRICA_MASERU = 'Africa/Maseru',
    AFRICA_MBABANE = 'Africa/Mbabane',
    AFRICA_TRIPOLI = 'Africa/Tripoli',
    AFRICA_WINDHOEK = 'Africa/Windhoek',
    ASIA_BEIRUT = 'Asia/Beirut',
    ASIA_FAMAGUSTA = 'Asia/Famagusta',
    ASIA_GAZA = 'Asia/Gaza',
    ASIA_HEBRON = 'Asia/Hebron',
    ASIA_JERUSALEM = 'Asia/Jerusalem',
    ASIA_NICOSIA = 'Asia/Nicosia',
    ASIA_TEL_AVIV = 'Asia/Tel_Aviv',
    EET = 'EET',
    EGYPT = 'Egypt',
    ETC_GMT_MINUS_2 = 'Etc/GMT-2',
    EUROPE_ATHENS = 'Europe/Athens',
    EUROPE_BUCHAREST = 'Europe/Bucharest',
    EUROPE_CHISINAU = 'Europe/Chisinau',
    EUROPE_HELSINKI = 'Europe/Helsinki',
    EUROPE_KALININGRAD = 'Europe/Kaliningrad',
    EUROPE_KIEV = 'Europe/Kiev',
    EUROPE_KYIV = 'Europe/Kyiv',
    EUROPE_MARIEHAMN = 'Europe/Mariehamn',
    EUROPE_NICOSIA = 'Europe/Nicosia',
    EUROPE_RIGA = 'Europe/Riga',
    EUROPE_SOFIA = 'Europe/Sofia',
    EUROPE_TALLINN = 'Europe/Tallinn',
    EUROPE_TIRASPOL = 'Europe/Tiraspol',
    EUROPE_UZHGOROD = 'Europe/Uzhgorod',
    EUROPE_VILNIUS = 'Europe/Vilnius',
    EUROPE_ZAPOROZHYE = 'Europe/Zaporozhye',
    ISRAEL = 'Israel',
    LIBYA = 'Libya',
    AFRICA_ADDIS_ABABA = 'Africa/Addis_Ababa',
    AFRICA_ASMARA = 'Africa/Asmara',
    AFRICA_ASMERA = 'Africa/Asmera',
    AFRICA_DAR_ES_SALAAM = 'Africa/Dar_es_Salaam',
    AFRICA_DJIBOUTI = 'Africa/Djibouti',
    AFRICA_KAMPALA = 'Africa/Kampala',
    AFRICA_MOGADISHU = 'Africa/Mogadishu',
    AFRICA_NAIROBI = 'Africa/Nairobi',
    ANTARCTICA_SYOWA = 'Antarctica/Syowa',
    ASIA_ADEN = 'Asia/Aden',
    ASIA_AMMAN = 'Asia/Amman',
    ASIA_BAGHDAD = 'Asia/Baghdad',
    ASIA_BAHRAIN = 'Asia/Bahrain',
    ASIA_DAMASCUS = 'Asia/Damascus',
    ASIA_ISTANBUL = 'Asia/Istanbul',
    ASIA_KUWAIT = 'Asia/Kuwait',
    ASIA_QATAR = 'Asia/Qatar',
    ASIA_RIYADH = 'Asia/Riyadh',
    ETC_GMT_MINUS_3 = 'Etc/GMT-3',
    EUROPE_ISTANBUL = 'Europe/Istanbul',
    EUROPE_KIROV = 'Europe/Kirov',
    EUROPE_MINSK = 'Europe/Minsk',
    EUROPE_MOSCOW = 'Europe/Moscow',
    EUROPE_SIMFEROPOL = 'Europe/Simferopol',
    EUROPE_VOLGOGRAD = 'Europe/Volgograd',
    INDIAN_ANTANANARIVO = 'Indian/Antananarivo',
    INDIAN_COMORO = 'Indian/Comoro',
    INDIAN_MAYOTTE = 'Indian/Mayotte',
    TURKEY = 'Turkey',
    W_SU = 'W-SU',
    ASIA_TEHRAN = 'Asia/Tehran',
    IRAN = 'Iran',
    ASIA_BAKU = 'Asia/Baku',
    ASIA_DUBAI = 'Asia/Dubai',
    ASIA_MUSCAT = 'Asia/Muscat',
    ASIA_TBILISI = 'Asia/Tbilisi',
    ASIA_YEREVAN = 'Asia/Yerevan',
    ETC_GMT_MINUS_4 = 'Etc/GMT-4',
    EUROPE_ASTRAKHAN = 'Europe/Astrakhan',
    EUROPE_SAMARA = 'Europe/Samara',
    EUROPE_SARATOV = 'Europe/Saratov',
    EUROPE_ULYANOVSK = 'Europe/Ulyanovsk',
    INDIAN_MAHE = 'Indian/Mahe',
    INDIAN_MAURITIUS = 'Indian/Mauritius',
    INDIAN_REUNION = 'Indian/Reunion',
    ASIA_KABUL = 'Asia/Kabul',
    ANTARCTICA_MAWSON = 'Antarctica/Mawson',
    ANTARCTICA_VOSTOK = 'Antarctica/Vostok',
    ASIA_ALMATY = 'Asia/Almaty',
    ASIA_AQTAU = 'Asia/Aqtau',
    ASIA_AQTOBE = 'Asia/Aqtobe',
    ASIA_ASHGABAT = 'Asia/Ashgabat',
    ASIA_ASHKHABAD = 'Asia/Ashkhabad',
    ASIA_ATYRAU = 'Asia/Atyrau',
    ASIA_DUSHANBE = 'Asia/Dushanbe',
    ASIA_KARACHI = 'Asia/Karachi',
    ASIA_ORAL = 'Asia/Oral',
    ASIA_QOSTANAY = 'Asia/Qostanay',
    ASIA_QYZYLORDA = 'Asia/Qyzylorda',
    ASIA_SAMARKAND = 'Asia/Samarkand',
    ASIA_TASHKENT = 'Asia/Tashkent',
    ASIA_YEKATERINBURG = 'Asia/Yekaterinburg',
    ETC_GMT_MINUS_5 = 'Etc/GMT-5',
    INDIAN_KERGUELEN = 'Indian/Kerguelen',
    INDIAN_MALDIVES = 'Indian/Maldives',
    ASIA_CALCUTTA = 'Asia/Calcutta',
    ASIA_COLOMBO = 'Asia/Colombo',
    ASIA_KOLKATA = 'Asia/Kolkata',
    ASIA_KATHMANDU = 'Asia/Kathmandu',
    ASIA_KATMANDU = 'Asia/Katmandu',
    ASIA_BISHKEK = 'Asia/Bishkek',
    ASIA_DACCA = 'Asia/Dacca',
    ASIA_DHAKA = 'Asia/Dhaka',
    ASIA_KASHGAR = 'Asia/Kashgar',
    ASIA_OMSK = 'Asia/Omsk',
    ASIA_THIMBU = 'Asia/Thimbu',
    ASIA_THIMPHU = 'Asia/Thimphu',
    ASIA_URUMQI = 'Asia/Urumqi',
    ETC_GMT_MINUS_6 = 'Etc/GMT-6',
    INDIAN_CHAGOS = 'Indian/Chagos',
    ASIA_RANGOON = 'Asia/Rangoon',
    ASIA_YANGON = 'Asia/Yangon',
    INDIAN_COCOS = 'Indian/Cocos',
    ANTARCTICA_DAVIS = 'Antarctica/Davis',
    ASIA_BANGKOK = 'Asia/Bangkok',
    ASIA_BARNAUL = 'Asia/Barnaul',
    ASIA_HO_CHI_MINH = 'Asia/Ho_Chi_Minh',
    ASIA_HOVD = 'Asia/Hovd',
    ASIA_JAKARTA = 'Asia/Jakarta',
    ASIA_KRASNOYARSK = 'Asia/Krasnoyarsk',
    ASIA_NOVOKUZNETSK = 'Asia/Novokuznetsk',
    ASIA_NOVOSIBIRSK = 'Asia/Novosibirsk',
    ASIA_PHNOM_PENH = 'Asia/Phnom_Penh',
    ASIA_PONTIANAK = 'Asia/Pontianak',
    ASIA_SAIGON = 'Asia/Saigon',
    ASIA_TOMSK = 'Asia/Tomsk',
    ASIA_VIENTIANE = 'Asia/Vientiane',
    ETC_GMT_MINUS_7 = 'Etc/GMT-7',
    INDIAN_CHRISTMAS = 'Indian/Christmas',
    ANTARCTICA_CASEY = 'Antarctica/Casey',
    ASIA_BRUNEI = 'Asia/Brunei',
    ASIA_CHOIBALSAN = 'Asia/Choibalsan',
    ASIA_CHONGQING = 'Asia/Chongqing',
    ASIA_CHUNGKING = 'Asia/Chungking',
    ASIA_HARBIN = 'Asia/Harbin',
    ASIA_HONG_KONG = 'Asia/Hong_Kong',
    ASIA_IRKUTSK = 'Asia/Irkutsk',
    ASIA_KUALA_LUMPUR = 'Asia/Kuala_Lumpur',
    ASIA_KUCHING = 'Asia/Kuching',
    ASIA_MACAO = 'Asia/Macao',
    ASIA_MACAU = 'Asia/Macau',
    ASIA_MAKASSAR = 'Asia/Makassar',
    ASIA_MANILA = 'Asia/Manila',
    ASIA_SHANGHAI = 'Asia/Shanghai',
    ASIA_SINGAPORE = 'Asia/Singapore',
    ASIA_TAIPEI = 'Asia/Taipei',
    ASIA_UJUNG_PANDANG = 'Asia/Ujung_Pandang',
    ASIA_ULAANBAATAR = 'Asia/Ulaanbaatar',
    ASIA_ULAN_BATOR = 'Asia/Ulan_Bator',
    AUSTRALIA_PERTH = 'Australia/Perth',
    AUSTRALIA_WEST = 'Australia/West',
    ETC_GMT_MINUS_8 = 'Etc/GMT-8',
    HONGKONG = 'Hongkong',
    PRC = 'PRC',
    ROC = 'ROC',
    SINGAPORE = 'Singapore',
    AUSTRALIA_EUCLA = 'Australia/Eucla',
    ASIA_CHITA = 'Asia/Chita',
    ASIA_DILI = 'Asia/Dili',
    ASIA_JAYAPURA = 'Asia/Jayapura',
    ASIA_KHANDYGA = 'Asia/Khandyga',
    ASIA_PYONGYANG = 'Asia/Pyongyang',
    ASIA_SEOUL = 'Asia/Seoul',
    ASIA_TOKYO = 'Asia/Tokyo',
    ASIA_YAKUTSK = 'Asia/Yakutsk',
    ETC_GMT_MINUS_9 = 'Etc/GMT-9',
    JAPAN = 'Japan',
    PACIFIC_PALAU = 'Pacific/Palau',
    ROK = 'ROK',
    AUSTRALIA_DARWIN = 'Australia/Darwin',
    AUSTRALIA_NORTH = 'Australia/North',
    ANTARCTICA_DUMONTDURVILLE = 'Antarctica/DumontDUrville',
    ASIA_UST_NERA = 'Asia/Ust-Nera',
    ASIA_VLADIVOSTOK = 'Asia/Vladivostok',
    AUSTRALIA_BRISBANE = 'Australia/Brisbane',
    AUSTRALIA_LINDEMAN = 'Australia/Lindeman',
    AUSTRALIA_QUEENSLAND = 'Australia/Queensland',
    ETC_GMT_MINUS_10 = 'Etc/GMT-10',
    PACIFIC_CHUUK = 'Pacific/Chuuk',
    PACIFIC_GUAM = 'Pacific/Guam',
    PACIFIC_PORT_MORESBY = 'Pacific/Port_Moresby',
    PACIFIC_SAIPAN = 'Pacific/Saipan',
    PACIFIC_TRUK = 'Pacific/Truk',
    PACIFIC_YAP = 'Pacific/Yap',
    AUSTRALIA_ADELAIDE = 'Australia/Adelaide',
    AUSTRALIA_BROKEN_HILL = 'Australia/Broken_Hill',
    AUSTRALIA_SOUTH = 'Australia/South',
    AUSTRALIA_YANCOWINNA = 'Australia/Yancowinna',
    ANTARCTICA_MACQUARIE = 'Antarctica/Macquarie',
    ASIA_MAGADAN = 'Asia/Magadan',
    ASIA_SAKHALIN = 'Asia/Sakhalin',
    ASIA_SREDNEKOLYMSK = 'Asia/Srednekolymsk',
    AUSTRALIA_ACT = 'Australia/ACT',
    AUSTRALIA_CANBERRA = 'Australia/Canberra',
    AUSTRALIA_CURRIE = 'Australia/Currie',
    AUSTRALIA_HOBART = 'Australia/Hobart',
    AUSTRALIA_LHI = 'Australia/LHI',
    AUSTRALIA_LORD_HOWE = 'Australia/Lord_Howe',
    AUSTRALIA_MELBOURNE = 'Australia/Melbourne',
    AUSTRALIA_NSW = 'Australia/NSW',
    AUSTRALIA_SYDNEY = 'Australia/Sydney',
    AUSTRALIA_TASMANIA = 'Australia/Tasmania',
    AUSTRALIA_VICTORIA = 'Australia/Victoria',
    ETC_GMT_MINUS_11 = 'Etc/GMT-11',
    PACIFIC_BOUGAINVILLE = 'Pacific/Bougainville',
    PACIFIC_EFATE = 'Pacific/Efate',
    PACIFIC_GUADALCANAL = 'Pacific/Guadalcanal',
    PACIFIC_KOSRAE = 'Pacific/Kosrae',
    PACIFIC_NOUMEA = 'Pacific/Noumea',
    PACIFIC_POHNPEI = 'Pacific/Pohnpei',
    PACIFIC_PONAPE = 'Pacific/Ponape',
    ASIA_ANADYR = 'Asia/Anadyr',
    ASIA_KAMCHATKA = 'Asia/Kamchatka',
    ETC_GMT_MINUS_12 = 'Etc/GMT-12',
    KWAJALEIN = 'Kwajalein',
    PACIFIC_FIJI = 'Pacific/Fiji',
    PACIFIC_FUNAFUTI = 'Pacific/Funafuti',
    PACIFIC_KWAJALEIN = 'Pacific/Kwajalein',
    PACIFIC_MAJURO = 'Pacific/Majuro',
    PACIFIC_NAURU = 'Pacific/Nauru',
    PACIFIC_NORFOLK = 'Pacific/Norfolk',
    PACIFIC_TARAWA = 'Pacific/Tarawa',
    PACIFIC_WAKE = 'Pacific/Wake',
    PACIFIC_WALLIS = 'Pacific/Wallis',
    ANTARCTICA_MCMURDO = 'Antarctica/McMurdo',
    ANTARCTICA_SOUTH_POLE = 'Antarctica/South_Pole',
    ETC_GMT_MINUS_13 = 'Etc/GMT-13',
    NZ = 'NZ',
    PACIFIC_APIA = 'Pacific/Apia',
    PACIFIC_AUCKLAND = 'Pacific/Auckland',
    PACIFIC_ENDERBURY = 'Pacific/Enderbury',
    PACIFIC_FAKAOFO = 'Pacific/Fakaofo',
    PACIFIC_KANTON = 'Pacific/Kanton',
    PACIFIC_TONGATAPU = 'Pacific/Tongatapu',
    NZ_CHAT = 'NZ-CHAT',
    PACIFIC_CHATHAM = 'Pacific/Chatham',
    ETC_GMT_MINUS_14 = 'Etc/GMT-14',
    PACIFIC_KIRITIMATI = 'Pacific/Kiritimati',
}

export enum StandardTimeZoneAbbreviation {
    IDLW = 'International Date Line West',
    ACDT = 'Australian Central Daylight Time',
    ACST = 'Australian Central Standard Time',
    ACT = 'Acre Time',
    ACT_AUS = 'Australian Central Time',
    ACWST = 'Australian Central Western Standard Time',
    ADT = 'Arabia Daylight Time',
    ADT_NA = 'Atlantic Daylight Time',
    AEDT = 'Australian Eastern Daylight Time',
    AEST = 'Australian Eastern Standard Time',
    AET = 'Australian Eastern Time',
    AFT = 'Afghanistan Time',
    AKDT = 'Alaska Daylight Time',
    AKST = 'Alaska Standard Time',
    ALMT = 'Alma-Ata Time',
    AMST_AM = 'Amazon Summer Time',
    AMST_ARM = 'Armenia Summer Time',
    AMT_AM = 'Amazon Time',
    AMT_ARM = 'Armenia Time',
    ANAST = 'Anadyr Summer Time',
    ANAT = 'Anadyr Time',
    AQTT = 'Aqtobe Time',
    ART = 'Argentina Time',
    AST_ARB = 'Arabia Standard Time',
    AST_NA = 'Atlantic Standard Time',
    AT = 'Atlantic Time',
    AWDT = 'Australian Western Daylight Time',
    AWST = 'Australian Western Standard Time',
    AZOST = 'Azores Summer Time',
    AZOT = 'Azores Time',
    AZST = 'Azerbaijan Summer Time',
    AZT = 'Azerbaijan Time',
    AOE = 'Anywhere on Earth',
    B = 'Bravo Time Zone',
    BNT = 'Brunei Darussalam Time',
    BOT = 'Bolivia Time',
    BRST = 'Brasília Summer Time',
    BRT = 'Brasília Time',
    BST_BAN = 'Bangladesh Standard Time',
    BST_BOUG = 'Bougainville Standard Time',
    BST_BRIT = 'British Summer Time',
    BTT = 'Bhutan Time',
    C = 'Charlie Time Zone',
    CAST = 'Casey Time',
    CAT = 'Central Africa Time',
    CCT = 'Cocos Islands Time',
    CDT = 'Central Daylight Time',
    CEST = 'Central European Summer Time',
    CET = 'Central European Time',
    CHADT = 'Chatham Island Daylight Time',
    CHAST = 'Chatham Island Standard Time',
    CHOST = 'Choibalsan Summer Time',
    CHOT = 'Choibalsan Time',
    CHUT = 'Chuuk Time',
    CIDST = 'Cayman Islands Daylight Saving Time',
    CIST = 'Cayman Islands Standard Time',
    CKT = 'Cook Island Time',
    CLST = 'Chile Summer Time',
    CLT = 'Chile Standard Time',
    COT = 'Colombia Time',
    CST_ARB = 'Arabia Standard Time',
    CST_NA = 'Central Standard Time',
    CST_CHINA = 'China Standard Time',
    CST_CUBA = 'Cuba Standard Time',
    CT = 'Central Time',
    CVT = 'Cape Verde Time',
    CXT = 'Christmas Island Time',
    ChST = 'Chamorro Standard Time',
    D = 'Delta Time Zone',
    DAVT = 'Davis Time',
    DDUT = "Dumont-d'Urville Time",
    E = 'Echo Time Zone',
    EASST = 'Easter Island Summer Time',
    EAST = 'Easter Island Standard Time',
    EAT = 'Eastern Africa Time',
    ECT = 'Ecuador Time',
    EDT = 'Eastern Daylight Time',
    EEST = 'Eastern European Summer Time',
    EET = 'Eastern European Time',
    EGST = 'Eastern Greenland Summer Time',
    EGT = 'East Greenland Time',
    EST_NA = 'Eastern Standard Time',
    ET = 'Eastern Time',
    F = 'Foxtrot Time Zone',
    FET = 'Further-Eastern European Time',
    FJST = 'Fiji Summer Time',
    FJT = 'Fiji Time',
    FKST = 'Falkland Islands Summer Time',
    FKT = 'Falkland Island Time',
    FNT = 'Fernando de Noronha Time',
    G = 'Golf Time Zone',
    GALT = 'Galapagos Time',
    GAMT = 'Gambier Time',
    GET = 'Georgia Standard Time',
    GFT = 'French Guiana Time',
    GILT = 'Gilbert Island Time',
    GMT = 'Greenwich Mean Time',
    GST_ARB = 'Arabia Standard Time',
    GST_SG = 'Singapore Standard Time',
    GST_SOUTH = 'South Georgia Time',
    GYT = 'Guyana Time',
    H = 'Hotel Time Zone',
    HDT = 'Hawaii-Aleutian Daylight Time',
    HKT = 'Hong Kong Time',
    HOVST = 'Hovd Summer Time',
    HOVT = 'Hovd Time',
    HST = 'Hawaii Standard Time',
    I = 'India Time Zone',
    ICT = 'Indochina Time',
    IDT = 'Israel Daylight Time',
    IOT = 'Indian Chagos Time',
    IRDT = 'Iran Daylight Time',
    IRKST = 'Irkutsk Summer Time',
    IRKT = 'Irkutsk Time',
    IRST = 'Iran Standard Time',
    IST_IN = 'India Standard Time',
    IST_IR = 'Israel Standard Time',
    JST = 'Japan Standard Time',
    K = 'Kilo Time Zone',
    KGT = 'Kyrgyzstan Time',
    KOST = 'Kosrae Time',
    KRAST = 'Krasnoyarsk Summer Time',
    KRAT = 'Krasnoyarsk Time',
    KST = 'Korea Standard Time',
    KUYT = 'Kuybyshev Time',
    L = 'Lima Time Zone',
    LHDT = 'Lord Howe Daylight Time',
    LHST = 'Lord Howe Standard Time',
    LINT = 'Line Islands Time',
    M = 'Mike Time Zone',
    MAGST = 'Magadan Summer Time',
    MAGT = 'Magadan Time',
    MART = 'Marquesas Time',
    MAWT = 'Mawson Time',
    MDT = 'Mountain Daylight Time',
    MHT = 'Marshall Islands Time',
    MMT = 'Myanmar Time',
    MSD = 'Moscow Daylight Time',
    MSK = 'Moscow Standard Time',
    MST_NA = 'Mountain Standard Time',
    MUT = 'Mauritius Time',
    MVT = 'Maldives Time',
    MYT = 'Malaysia Time',
    N = 'November Time Zone',
    NCT = 'New Caledonia Time',
    NDT = 'Newfoundland Daylight Time',
    NFDT = 'Norfolk Daylight Time',
    NFT = 'Norfolk Time',
    NOVST = 'Novosibirsk Summer Time',
    NOVT = 'Novosibirsk Time',
    NPT = 'Nepal Time',
    NRT = 'Nauru Time',
    NST_NA = 'Newfoundland Standard Time',
    NUT = 'Niue Time',
    NZDT = 'New Zealand Daylight Time',
    NZST = 'New Zealand Standard Time',
    O = 'Oscar Time Zone',
    OMSST = 'Omsk Summer Time',
    OMST = 'Omsk Standard Time',
    ORAT = 'Oral Time',
    P = 'Papa Time Zone',
    PDT = 'Pacific Daylight Time',
    PET = 'Peru Time',
    PETST = 'Kamchatka Summer Time',
    PETT = 'Kamchatka Time',
    PGT = 'Papua New Guinea Time',
    PHOT = 'Phoenix Island Time',
    PHT = 'Philippine Time',
    PKT = 'Pakistan Standard Time',
    PMDT = 'Pierre & Miquelon Daylight Time',
    PMST = 'Pierre & Miquelon Standard Time',
    PONT = 'Pohnpei Standard Time',
    PST = 'Pacific Standard Time',
    PST_PIT = 'Pitcairn Standard Time',
    PWT = 'Palau Time',
    PYST = 'Paraguay Summer Time',
    PYT = 'Paraguay Time',
    PYT_PY = 'Pyongyang Time',
    Q = 'Quebec Time Zone',
    QYZT = 'Qyzylorda Time',
    R = 'Romeo Time Zone',
    RET = 'Reunion Time',
    ROTT = 'Rothera Time',
    S = 'Sierra Time Zone',
    SAKT = 'Sakhalin Time',
    SAMT = 'Samara Time',
    SAST = 'South Africa Standard Time',
    SBT = 'Solomon Islands Time',
    SCT = 'Seychelles Time',
    SGT = 'Singapore Time',
    SRET = 'Srednekolymsk Time',
    SRT = 'Suriname Time',
    SST = 'Samoa Standard Time',
    SYOT = 'Syowa Time',
    T = 'Tango Time Zone',
    TAHT = 'Tahiti Time',
    TFT = 'French Southern and Antarctic Time',
    TJT = 'Tajikistan Time',
    TKT = 'Tokelau Time',
    TLT = 'East Timor Time',
    TMT = 'Turkmenistan Time',
    TOST = 'Tonga Summer Time',
    TOT = 'Tonga Time',
    TRT = 'Turkey Time',
    TVT = 'Tuvalu Time',
    U = 'Uniform Time Zone',
    ULAST = 'Ulaanbaatar Summer Time',
    ULAT = 'Ulaanbaatar Time',
    UTC = 'Coordinated Universal Time',
    UYST = 'Uruguay Summer Time',
    UYT = 'Uruguay Time',
    UZT = 'Uzbekistan Time',
    V = 'Victor Time Zone',
    VET = 'Venezuelan Standard Time',
    VLAST = 'Vladivostok Summer Time',
    VLAT = 'Vladivostok Time',
    VOST = 'Vostok Time',
    VUT = 'Vanuatu Time',
    W = 'Whiskey Time Zone',
    WAKT = 'Wake Time',
    WARST = 'Western Argentine Summer Time',
    WAST = 'West Africa Summer Time',
    WAT = 'West Africa Time',
    WEST = 'Western European Summer Time',
    WET = 'Western European Time',
    WFT = 'Wallis and Futuna Time',
    WGST = 'Western Greenland Summer Time',
    WGT = 'West Greenland Time',
    WIB = 'Western Indonesian Time',
    WIT = 'Eastern Indonesian Time',
    WITA = 'Central Indonesian Time',
    WST_PI = 'West Samoa Time',
    WST_WS = 'Western Sahara Summer Time',
    WT = 'Western Sahara Standard Time',
    X = 'X-ray Time Zone',
    Y = 'Yankee Time Zone',
    YAKST = 'Yakutsk Summer Time',
    YAKT = 'Yakutsk Time',
    YAPT = 'Yap Time',
    YEKST = 'Yekaterinburg Summer Time',
    YEKT = 'Yekaterinburg Time',
    Z = 'Zulu Time Zone',
}

export enum TimeZone {
    'UTC' = 'UTC', // A special value which we use to make skip tz behavior
    // 'Local' = 'Local', // TODO: decide what to do with local option UX
    'Pacific/Pago_Pago' = 'Pacific/Pago_Pago',
    'Pacific/Honolulu' = 'Pacific/Honolulu',
    'America/Anchorage' = 'America/Anchorage',
    'America/Los_Angeles' = 'America/Los_Angeles',
    'America/Denver' = 'America/Denver',
    'America/Chicago' = 'America/Chicago',
    'America/New_York' = 'America/New_York',
    'America/Santo_Domingo' = 'America/Santo_Domingo',
    'America/Buenos_Aires' = 'America/Buenos_Aires',
    'America/Noronha' = 'America/Noronha',
    'Atlantic/Cape_Verde' = 'Atlantic/Cape_Verde',
    'Europe/Paris' = 'Europe/Paris',
    'Europe/Athens' = 'Europe/Athens',
    'Europe/Moscow' = 'Europe/Moscow',
    'Europe/London' = 'Europe/London',
    'Asia/Dubai' = 'Asia/Dubai',
    'Asia/Karachi' = 'Asia/Karachi',
    'Asia/Dhaka' = 'Asia/Dhaka',
    'Asia/Bangkok' = 'Asia/Bangkok',
    'Asia/Shanghai' = 'Asia/Shanghai',
    'Asia/Tokyo' = 'Asia/Tokyo',
    'Australia/Sydney' = 'Australia/Sydney',
    'Pacific/Noumea' = 'Pacific/Noumea',
    'Pacific/Auckland' = 'Pacific/Auckland',
    'Pacific/Apia' = 'Pacific/Apia',
    'Pacific/Kiritimati' = 'Pacific/Kiritimati',
}
