import { useLocale } from '@hooks/useLocale';
import { ActionIcon, Box, Group, Menu, Text } from '@mantine/core';
import { CopySimple, DotsThree, X } from '@phosphor-icons/react';
import React from 'react';
import { ButtonVariant } from '../../../mantineTheme';

interface MenuForDimensionFilterRuleProps {
    duplicateFilter: () => void;
    onDelete: () => void;
}

export const MenuForDimensionFilterRule: React.FC<
    MenuForDimensionFilterRuleProps
> = ({ duplicateFilter, onDelete }) => {
    const { t } = useLocale();

    return (
        <Group className="flex items-center justify-center gap-1">
            <Menu
                position="bottom-end"
                shadow="md"
                closeOnItemClick
                withArrow
                arrowPosition="center"
            >
                <Menu.Target>
                    <Box>
                        <ActionIcon
                            variant={ButtonVariant.UNSTYLED}
                            className="rounded-lg hover:bg-shade-4"
                        >
                            <DotsThree
                                color={'rgb(var(--color-gray-700))'}
                                weight="bold"
                            />
                        </ActionIcon>
                    </Box>
                </Menu.Target>

                <Menu.Dropdown>
                    <Menu.Item onClick={duplicateFilter}>
                        <Group className="gap-1.5 font-medium text-gray-800 text-sm">
                            <CopySimple
                                weight="duotone"
                                color={'rgb(var(--color-gray-800))'}
                            />
                            <Text>{t('audience_filter.duplicate_filter')}</Text>
                        </Group>
                    </Menu.Item>
                </Menu.Dropdown>
            </Menu>
            <ActionIcon
                onClick={onDelete}
                variant={ButtonVariant.UNSTYLED}
                className="rounded-lg hover:bg-shade-4"
            >
                <X
                    size={14}
                    color={'rgb(var(--color-gray-700))'}
                    weight="bold"
                />
            </ActionIcon>
        </Group>
    );
};
