import { subject } from '@casl/ability';
import { useAbilityContext } from '@components/common/Authorization';
import MantineIcon from '@components/common/MantineIcon';
import CustomMenu, {
    type CustomMenuItemProps,
} from '@components/common/MenuItem/MenuItem';
import Modal from '@components/common/modal/Modal';
import Timestamp from '@components/common/Timestamp';
import UserCount from '@components/common/UserCount';
import ButtonGroup from '@components/ProjectSettings/DeliveryControls/ButtonGroup';
import useNotify from '@hooks/toaster/useNotify';
import {
    useAudienceScheduleMutation,
    useAudienceUpdation,
} from '@hooks/useAudience';
import { useLocale } from '@hooks/useLocale';
import {
    AudienceBuilderHashParams,
    AudienceRunStatus,
    AudienceRunTypes,
    AudienceStatus,
    CommonReservedTags,
    QueryGenerationStrategy,
    type Audience,
} from '@lightdash/common';
import { Box, Flex, Group, Loader as LoaderIcon, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    Archive,
    ArrowCounterClockwise,
    ArrowElbowDownLeft,
    CaretRight,
    Clock,
    CopySimple,
    DotsThree,
    Play,
    Spinner,
} from '@phosphor-icons/react';
import { useApp } from '@providers/AppProvider';
import { useRelationContext } from '@providers/RelationProvider';
import { useQueryClient } from '@tanstack/react-query';
import { type ColumnDef, type Row } from '@tanstack/react-table';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { QueryKeys } from 'types/UseQuery';
import { ButtonVariant } from '../../../mantineTheme';

interface AudienceMenuItemProps {
    rowData: Row<Audience>;
    setModalOpen: () => void;
    setAudienceData: (data: Audience) => void;
}

const AUDIENCE_TABLE_CELL_CLASSNAME = `flex flex-col gap-0.5 justify-center items-start !w-56`;
const AUDIENCE_TABLE_CELL_NAME = `text-sm font-medium text-gray-800 cursor-pointer truncate !w-56`;
const AUDIENCE_TABLE_CELL_DESCRIPTION = `text-xs font-normal text-gray-600 truncate !w-56`;

const AudienceMenuItem: React.FC<AudienceMenuItemProps> = ({
    rowData,
    setModalOpen,
    setAudienceData,
}) => {
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { mutateAsync: mutateAudienceSchedule } =
        useAudienceScheduleMutation();
    const queryClient = useQueryClient();
    const { user } = useApp();
    const { t } = useLocale();
    const ability = useAbilityContext();
    const { mutateAsync: mutateAsyncUpdate, isLoading: isUpdating } =
        useAudienceUpdation(true); // INFO: hide toast for now as we want to customise mesage here.
    const { activeRelationUuid } = useRelationContext();
    const history = useHistory();
    const { showToastSuccess, showToastError } = useNotify();
    const [
        openedHideConfirmModal,
        { open: openHideConfirmModal, close: closeHideConfirmModal },
    ] = useDisclosure();
    const canEditAudience = ability.can(
        'manage',
        subject('VisualAudience', {
            organizationUuid: user.data?.organizationUuid,
            projectUuid,
        }),
    );

    const handleViewEdit = useCallback(
        (id: string, type: string) => {
            history.push(`/projects/${projectUuid}/audiences/${id}/${type}`);
        },
        [projectUuid, history],
    );

    const handleDuplicate = useCallback(
        (value: Audience) => {
            history.push(
                `/projects/${projectUuid}/audiences/create?templateId=${
                    value.id
                }#${
                    value.generationStrategy ===
                    QueryGenerationStrategy.AUDIENCE_BUILDER
                        ? AudienceBuilderHashParams.VISUAL
                        : AudienceBuilderHashParams.SQL
                }`,
            );
        },
        [projectUuid, history],
    );

    const handleRunnow = useCallback(
        async (id: string) => {
            await mutateAudienceSchedule({
                audienceId: id,
                data: { runType: AudienceRunTypes.MANUAL },
            });

            await queryClient.invalidateQueries([QueryKeys.GET_AUDIENCE]);
        },
        [queryClient, mutateAudienceSchedule],
    );
    const viewAudienceItem = useCallback(
        () => ({
            leftSection: (
                <ArrowElbowDownLeft
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_view'),
            onClick: (values: Audience) => handleViewEdit(values.id, 'view'),
        }),
        [handleViewEdit, t],
    );
    const duplicateAudienceItem = useCallback(
        (isDividerRequired: boolean) => ({
            leftSection: (
                <CopySimple
                    size={14}
                    weight="duotone"
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_duplicate'),
            onClick: (values: Audience) => handleDuplicate(values),
            isDivider: isDividerRequired,
        }),
        [handleDuplicate, t],
    );
    const runnowItem = useCallback(
        (isRunning: boolean) => ({
            leftSection: isRunning ? (
                <Spinner weight="duotone" size={13} />
            ) : (
                <Play
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: isRunning ? (
                <Text className="text-blu-800">
                    {t('audience_manager.menu_item_running')}
                </Text>
            ) : (
                <Text>{t('audience_manager.menu_item_run')}</Text>
            ),
            onClick: (values: Audience) => {
                if (isRunning) return;
                return handleRunnow(values.id);
            },
        }),
        [handleRunnow, t],
    );

    const changeRunScheduleItem = useCallback(
        (isDividerRequired: boolean) => {
            const menuItem = {
                leftSection: (
                    <Clock
                        size={14}
                        color={'rgb(var(--color-gray-600))'}
                        weight="duotone"
                        strokeWidth={2.5}
                    />
                ),
                children:
                    rowData.original.runType === AudienceRunTypes.SCHEDULED ||
                    rowData.original.runType === AudienceRunTypes.CRON
                        ? t('audience_manager.menu_item_change_schedule')
                        : t('audience_manager.menu_item_add_schedule'),
                onClick: (values: Audience) => {
                    setAudienceData(values);
                    setModalOpen();
                },
                isDivider: isDividerRequired,
            };

            return menuItem;
        },
        [setAudienceData, setModalOpen, t, rowData.original.runType],
    );

    const editItems = useMemo(
        () => ({
            leftSection: (
                <ArrowElbowDownLeft
                    size={14}
                    color={'rgb(var(--color-gray-600))'}
                    strokeWidth={2.5}
                />
            ),
            children: t('audience_manager.menu_item_edit'),
            onClick: (audience: Audience) =>
                handleViewEdit(audience.id, 'edit'),
        }),
        [t, handleViewEdit],
    );
    const hideItems = useMemo(
        () => ({
            leftSection: (
                <Archive
                    weight="duotone"
                    color={'rgb(var(--color-halt-800))'}
                />
            ),
            children: <Text className="text-halt-800">{t('common.hide')}</Text>,
            customClass: 'hover:bg-halt-800/6',
            onClick: () => openHideConfirmModal(),
        }),
        [t, openHideConfirmModal],
    );
    const restoreItems = useMemo(
        () => ({
            leftSection: <ArrowCounterClockwise weight="duotone" />,
            children: t('common.restore'),
            onClick: async (audience: Audience) => {
                await mutateAsyncUpdate(
                    {
                        audienceId: audience.id,
                        relationUuid: activeRelationUuid,
                        payload: {
                            tags: [
                                ...(audience.tags?.filter(
                                    (tag) => tag !== CommonReservedTags.HIDDEN,
                                ) || []),
                            ],
                        },
                    },
                    {
                        onSuccess: () => {
                            showToastSuccess({
                                title: t('common.hidden_restore_success', {
                                    entityName: t('common.audience'),
                                }),
                            });
                        },
                        onError: (error) => {
                            showToastError({
                                title: t('common.hidden_restore_error', {
                                    entityName: t('common.audience'),
                                }),
                                subtitle: error.error.message,
                            });
                        },
                    },
                );
            },
        }),
        [
            t,
            mutateAsyncUpdate,
            activeRelationUuid,
            showToastError,
            showToastSuccess,
        ], // Ensure all dependencies are listed and separated by commas
    );
    const menuItems = useMemo(() => {
        let items: CustomMenuItemProps<Audience>[] = [];
        if (rowData.original.status === AudienceStatus.DRAFT) {
            items = [editItems, duplicateAudienceItem(true)];
        } else {
            items = [
                viewAudienceItem(),
                duplicateAudienceItem(true),
                runnowItem(
                    rowData.original.lastRunStatus ===
                        AudienceRunStatus.RUNNING,
                ),
                changeRunScheduleItem(true),
            ];
        }
        if (rowData.original.tags?.includes(CommonReservedTags.HIDDEN)) {
            items.push(restoreItems);
        } else {
            items.push(hideItems);
        }
        return items;
    }, [
        rowData.original.lastRunStatus,
        changeRunScheduleItem,
        duplicateAudienceItem,
        editItems,
        rowData.original.status,
        runnowItem,
        viewAudienceItem,
        restoreItems,
        hideItems,
        rowData.original.tags,
    ]);

    return (
        <>
            {canEditAudience && (
                <>
                    <CustomMenu<Audience>
                        menuItems={menuItems}
                        data={rowData.original}
                        icon={
                            <MantineIcon
                                icon={() => (
                                    <DotsThree
                                        size={14}
                                        weight="bold"
                                        color="rgb(var(--color-gray-600))"
                                    />
                                )}
                            />
                        }
                    />
                    <Modal
                        opened={openedHideConfirmModal}
                        onClose={closeHideConfirmModal}
                        onClick={(e) => {
                            e.stopPropagation();
                        }}
                        title={
                            <Box>
                                <Text>
                                    {t(
                                        'audinece_manager.confirm_hide_modal_title',
                                    )}
                                </Text>
                                <Text className="text-sm font-normal text-gray-600">
                                    {rowData.original.name}
                                </Text>
                            </Box>
                        }
                        footerRightSection={
                            <ButtonGroup
                                primaryButtonLabel={t(
                                    'audience_manager.confirm_hide_prim_button',
                                )}
                                secondaryButtonLabel={t('common.no')}
                                isUpdating={isUpdating}
                                handlePrimaryButtonClick={async () => {
                                    await mutateAsyncUpdate(
                                        {
                                            audienceId: rowData.original.id,
                                            relationUuid: activeRelationUuid,
                                            payload: {
                                                tags: [
                                                    ...(rowData.original.tags ||
                                                        []),
                                                    CommonReservedTags.HIDDEN,
                                                ],
                                            },
                                        },
                                        {
                                            onSuccess: () => {
                                                showToastSuccess({
                                                    title: t(
                                                        'common.hidden_success',
                                                        {
                                                            entityName:
                                                                t(
                                                                    'common.audience',
                                                                ),
                                                        },
                                                    ),
                                                });
                                            },
                                            onError: (error) => {
                                                showToastError({
                                                    title: t(
                                                        'common.hidden_error',
                                                        {
                                                            entityName:
                                                                t(
                                                                    'common.audience',
                                                                ),
                                                        },
                                                    ),
                                                    subtitle:
                                                        error.error.message,
                                                });
                                            },
                                        },
                                    );
                                    closeHideConfirmModal();
                                }}
                                handleSecondaryButtonClick={
                                    closeHideConfirmModal
                                }
                                primaryButtonVariant={
                                    ButtonVariant.FILLED_DESTRUCTIVE
                                }
                                primaryButtonRightIcon={
                                    <CaretRight color="white" />
                                }
                            />
                        }
                        closeButtonProps={{
                            className: 'absolute top-3 right-3',
                        }}
                    >
                        <Flex direction={'column'} gap={12}>
                            <Text className="font-semibold text-gray-800 text-sm">
                                {t(
                                    'audinece_manager.confirm_hide_modal_header',
                                )}
                            </Text>
                            <Text className="text-gray-800 text-sm">
                                {t(
                                    'audinece_manager.confirm_hide_modal_description',
                                )}
                            </Text>
                        </Flex>
                    </Modal>
                </>
            )}
        </>
    );
};

export function useAudienceColumns(
    setModalOpen: () => void,
    setAudienceData: (data: Audience) => void,
) {
    const [columns, setColumns] = useState<ColumnDef<Audience>[]>();
    const { projectUuid } = useParams<{ projectUuid: string }>();
    const { t } = useLocale();

    useEffect(() => {
        if (projectUuid) {
            setColumns([
                {
                    accessorKey: 'name',
                    header: t('audience_manager.th_audience_name'),
                    cell: ({ row, getValue }) => (
                        <Box className={AUDIENCE_TABLE_CELL_CLASSNAME}>
                            <Text>
                                <Flex align={'center'} gap={6}>
                                    {row.original.tags?.includes(
                                        CommonReservedTags.HIDDEN,
                                    ) && <Archive weight="duotone" />}
                                    <Box className={AUDIENCE_TABLE_CELL_NAME}>
                                        {row.original.status ===
                                            AudienceStatus.DRAFT && (
                                            <span className="text-pink-800">
                                                {t(
                                                    'audience_manager.tr_audience_status_draft',
                                                )}{' '}
                                            </span>
                                        )}

                                        {getValue<string>()}
                                    </Box>
                                </Flex>
                            </Text>
                            {row.original.description && (
                                <Text
                                    className={AUDIENCE_TABLE_CELL_DESCRIPTION}
                                >
                                    {row.original.description}
                                </Text>
                            )}
                        </Box>
                    ),
                },
                {
                    accessorKey: 'totalCount',
                    header: t('audience_manager.th_audience_count'),
                    cell: ({ getValue }) => (
                        <Box className="">
                            <UserCount
                                count={getValue<string>()}
                                withRightSection={false}
                            />
                        </Box>
                    ),
                },
                {
                    accessorKey: 'lastRunAt',
                    header: t('audience_manager.th_last_run'),
                    cell: ({ row, getValue }) => {
                        const { original } = row;
                        const { lastRunStatus } = original;

                        let content;
                        switch (lastRunStatus) {
                            case AudienceRunStatus.RUNNING:
                                content = (
                                    <Group className="gap-1.5">
                                        <LoaderIcon
                                            color="rgb(var(--color-blu-800))"
                                            size={14}
                                        />
                                        <Text className="text-sm font-normal text-blu-800">
                                            {t(
                                                'campaign_manager.tr_status_progress',
                                            )}
                                        </Text>
                                    </Group>
                                );
                                break;
                            case AudienceRunStatus.NEVER:
                                content = (
                                    <Text className="text-sm font-normal text-gray-600">
                                        {t('timestamp.undefined')}
                                    </Text>
                                );
                                break;
                            case AudienceRunStatus.FAILED:
                                content = (
                                    <Box className="flex flex-row items-center justify-start gap-1">
                                        <Text className="text-sm font-normal text-halt-800">
                                            {t(
                                                'audience_manager.tr_last_run_failed',
                                            )}
                                        </Text>
                                        <Timestamp
                                            timestamp={getValue<string>()}
                                        />
                                    </Box>
                                );
                                break;
                            case AudienceRunStatus.SUCCESS:
                                content = (
                                    <Timestamp timestamp={getValue<string>()} />
                                );
                                break;
                            case AudienceRunStatus.SCHEDULED:
                                content = (
                                    <Timestamp timestamp={getValue<string>()} />
                                );
                                break;
                            default:
                                content = (
                                    <Text className="text-sm font-normal text-gray-600">
                                        {t('timestamp.undefined')}
                                    </Text>
                                );
                        }
                        if (!original.lastRunAt) {
                            content = (
                                <Text className="text-sm font-normal text-gray-600">
                                    {t('timestamp.undefined')}
                                </Text>
                            );
                        }
                        return (
                            <Box className="text-sm text-gray-800">
                                {content}
                            </Box>
                        );
                    },
                },
                {
                    accessorKey: 'createdAt',
                    header: t('common.created_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />
                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`${
                                    row.original.createdBy?.firstName ||
                                    row.original.createdBy?.lastName
                                        ? 'by'
                                        : ''
                                } ${row.original.createdBy?.firstName || ''} ${
                                    row.original.createdBy?.lastName || ''
                                }`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'updatedAt',
                    header: t('common.modified_th'),
                    cell: ({ getValue, row }) => (
                        <Box className="text-sm text-gray-800">
                            <Timestamp timestamp={getValue<string>()} />

                            <Text className="text-sm font-normal text-gray-600 truncate max-w-44">
                                {`${
                                    row.original.updatedBy?.firstName ||
                                    row.original.updatedBy?.lastName
                                        ? 'by'
                                        : ''
                                } ${row.original.updatedBy?.firstName || ''} ${
                                    row.original.updatedBy?.lastName || ''
                                }`}
                            </Text>
                        </Box>
                    ),
                },
                {
                    accessorKey: 'menu',
                    header: '',
                    cell: ({ row }) => (
                        <AudienceMenuItem
                            setModalOpen={setModalOpen}
                            rowData={row}
                            setAudienceData={setAudienceData}
                        />
                    ),
                },
            ]);
        }
    }, [projectUuid, t, setAudienceData, setModalOpen]);

    return columns;
}
