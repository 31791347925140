import SuboptimalState from '@components/common/SuboptimalState/SuboptimalState';
import ImportTemplateModal from '@components/Templates/Builder/modals/ImportTemplateModal';
import { useLocale } from '@hooks/useLocale';
import { useGetTemplateById } from '@hooks/useTemplate';
import {
    CommunicationChannel,
    type Integration,
    type ProviderTemplateDetails,
} from '@lightdash/common';
import { Box, Flex, Stack, Text } from '@mantine/core';
import {
    ArrowSquareIn,
    Book,
    CaretRight,
    PaintBrush,
} from '@phosphor-icons/react';
import {
    EmailBuilderType,
    TemplateBuilderProvider,
    useTemplateBuilderContext,
} from '@providers/TemplateBuilderProvider';
import React, { useMemo } from 'react';

import CreateTemplateModal from './modals/CreateTemplateModal';
import TemplateLibraryModal from './modals/TemplateLibraryModal';
import { useTemplateBuilderInitialData } from './useTemplateBuilderInitialData';

interface TemplateSelectionTileProps {
    title: string;
    description: string;
    onClick: () => void;
    icon: React.ReactNode;
}
const TemplateSelectionTile: React.FC<TemplateSelectionTileProps> = React.memo(
    ({ title, description, onClick, icon }) => {
        return (
            <Box
                className={
                    'shadow-card border-base rounded-lg p-3.5 cursor-pointer hover:bg-shade-2'
                }
                onClick={onClick}
            >
                <Flex justify={'space-between'} align={'center'}>
                    <Flex justify={'center'} align={'center'}>
                        {icon}
                        <Text className="mx-1.5 text-sm font-medium	">
                            {title}
                        </Text>
                    </Flex>
                    <CaretRight weight="regular" />
                </Flex>
                <Text className="text-sm font-normal text-gray-500">
                    {description}
                </Text>
            </Box>
        );
    },
);

type TemplateOptionsProps = {
    integration: Integration;
    showCreateEditTemplate: Boolean;
    defaultTemplateName: string | undefined;
    isTemplateLibrary: boolean;
    channel: string;
};

const TemplateOptions: React.FC<TemplateOptionsProps> = ({
    integration,
    showCreateEditTemplate,
    defaultTemplateName,
    isTemplateLibrary,
    channel,
}) => {
    const { t } = useLocale();

    const {
        setOpenLibraryModal,
        setOpenImportModal,
        setOpenCreateTemplateModal,
        setEmailBuilderType,
    } = useTemplateBuilderContext((context) => context.actions);

    const options = useMemo(() => {
        const result = [];
        if (integration.channelName !== CommunicationChannel.WHATSAPP) {
            result.push({
                title: t('campaigns.import_template.import_from_integration', {
                    integration: 'integration',
                }),
                description: t(
                    'campaigns.import_template.clone_from_provider',
                    {
                        channel: integration.channelName,
                    },
                ),
                onClick: () => setOpenImportModal(true),
                icon: <ArrowSquareIn />,
            });
        }
        if (
            showCreateEditTemplate &&
            ((integration.channelName &&
                integration.channelName !== CommunicationChannel.WHATSAPP) ||
                (channel && channel !== CommunicationChannel.WHATSAPP))
        ) {
            result.unshift({
                title: defaultTemplateName
                    ? t('campaigns.import_template.modify_template', {
                          templateName: defaultTemplateName,
                      })
                    : t('campaigns.import_template.design_new_template'),
                description: defaultTemplateName
                    ? 'Edit this template`s content and layout'
                    : integration.channelName === CommunicationChannel.EMAIL
                    ? t('campaigns.import_template.visual_builder')
                    : 'Write a new message',
                onClick: () => {
                    setOpenCreateTemplateModal(true);
                    setEmailBuilderType(EmailBuilderType.GRAPES);
                },
                icon: <PaintBrush />,
            });
            if (
                !integration.channelName &&
                channel === CommunicationChannel.EMAIL
            ) {
                result.unshift({
                    title: 'BeeFree Email Builder',
                    description:
                        'Design a new email template using BeeFree email builder',
                    onClick: () => {
                        setOpenCreateTemplateModal(true);
                        setEmailBuilderType(EmailBuilderType.BEEFREE);
                    },
                    icon: <PaintBrush />,
                });
            }
        }
        if (!isTemplateLibrary) {
            result.push({
                title: t('campaigns.import_template.select_from_library'),
                description: t(
                    'campaigns.import_template.choose_from_saved_templates',
                ),
                onClick: () => setOpenLibraryModal(true),
                icon: <Book />,
            });
        }
        return result;
    }, [
        isTemplateLibrary,
        t,
        integration.channelName,
        showCreateEditTemplate,
        defaultTemplateName,
        setOpenLibraryModal,
        setOpenImportModal,
        setOpenCreateTemplateModal,
        channel,
        setEmailBuilderType,
    ]);

    return (
        <Stack>
            {options.map((option, index) => (
                <TemplateSelectionTile
                    key={index}
                    title={option.title}
                    description={option.description}
                    onClick={option.onClick}
                    icon={option.icon}
                />
            ))}
        </Stack>
    );
};

interface TemplateImporterType {
    integration: Integration;
    isSingleSelect: boolean;
    handleTemplateSelect: (obj: ProviderTemplateDetails) => void;
    integrationId: string;
    channel: CommunicationChannel;
    showCreateEditTemplate?: Boolean;
    templateId?: string;
    isTemplateLibrary?: boolean;
    onClose: (() => void) | undefined;
    editMode: boolean;
}
const TemplateBuilderManager = ({
    integration,
    handleTemplateSelect,
    integrationId,
    channel,
    showCreateEditTemplate = true,
    templateId,
    isTemplateLibrary = false,
    onClose,
    editMode,
}: TemplateImporterType) => {
    const { data: templateMetadata, isInitialLoading } = useGetTemplateById(
        templateId ?? '',
        channel !== CommunicationChannel.WHATSAPP,
    );

    const initialState = useTemplateBuilderInitialData({
        templatePayload: templateMetadata,
        channel,
        templateId,
    });

    if (isInitialLoading) {
        return <SuboptimalState loading />;
    }

    return (
        <TemplateBuilderProvider
            initialState={initialState}
            channel={channel}
            templateId={templateMetadata?.id ?? ''}
            handleTemplateSelectCallback={handleTemplateSelect}
        >
            <>
                <TemplateOptions
                    integration={integration}
                    showCreateEditTemplate={showCreateEditTemplate}
                    defaultTemplateName={templateMetadata?.name}
                    isTemplateLibrary={isTemplateLibrary}
                    channel={channel}
                />

                {/* Modal - Import from Library */}
                <TemplateLibraryModal
                    channel={channel}
                    integrationId={integrationId}
                />

                {/* Modal - Import from Provider */}
                <ImportTemplateModal />

                {/* Modal - Design New Template */}
                <CreateTemplateModal
                    integration={integration}
                    integrationId={integrationId}
                    onClose={onClose}
                    editMode={editMode}
                />
            </>
        </TemplateBuilderProvider>
    );
};

export default TemplateBuilderManager;
