import { sortmentApi } from '@api/index';
import useQueryError from '@hooks/useQueryError';
import {
    type ApiError,
    type ApiJobStartedResults,
    type ApiSuccessEmpty,
    type MostPopularAndRecentlyUpdated,
    type Project,
    type ProjectSyncPayload,
    type ReachabilityConfig,
    type SemanticLayerConnectionUpdate,
    type UpdateProject,
    type UpdateProjectSettings,
    type UpdateSchedulerSettings,
    type UserAlertPreferenceResponse,
} from '@lightdash/common';
import { useActiveJob } from '@providers/ActiveJobProvider';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'types/UseQuery';
import useNotify from './toaster/useNotify';
import { useLocale } from './useLocale';

// const createProject = async (data: CreateProject) =>
//     sortmentApi<ApiJobStartedResults>({
//         url: `/org/projects/precompiled`,
//         method: 'POST',
//         body: JSON.stringify(data),
//     });

const updateProject = async (uuid: string, data: UpdateProject) =>
    sortmentApi<ApiJobStartedResults>({
        url: `/projects/${uuid}`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

const updateProjectSettings = async (id: string, data: UpdateProjectSettings) =>
    sortmentApi<ApiJobStartedResults>({
        url: `/projects/${id}/settings`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

const getProject = async (id: string) =>
    sortmentApi<Project>({
        url: `/projects/${id}`,
        method: 'GET',
        body: undefined,
    });

const updateProjectSemanticLayerConnection = async (
    uuid: string,
    data: SemanticLayerConnectionUpdate,
) =>
    sortmentApi<undefined>({
        url: `/projects/${uuid}/semantic-layer-connection`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

const updateProjectSchedulerSettings = async (
    uuid: string,
    data: UpdateSchedulerSettings,
) =>
    sortmentApi<undefined>({
        url: `/projects/${uuid}/schedulerSettings`,
        method: 'PATCH',
        body: JSON.stringify(data),
    });

const deleteProjectSemanticLayerConnection = async (uuid: string) =>
    sortmentApi<undefined>({
        url: `/projects/${uuid}/semantic-layer-connection`,
        method: 'DELETE',
        body: undefined,
    });

export const useProject = (id: string | undefined) => {
    const setErrorResponse = useQueryError();
    return useQuery<Project, ApiError>({
        queryKey: [QueryKeys.PROJECT, id],
        queryFn: () => getProject(id || ''),
        enabled: id !== undefined,
        retry: false,
        onError: (result) => setErrorResponse(result),
    });
};

export const useUpdateSettingsMutation = (id: string) => {
    const queryClient = useQueryClient();
    const { showToastError, showToastSuccess } = useNotify();
    const { t } = useLocale();
    return useMutation<ApiJobStartedResults, ApiError, UpdateProjectSettings>(
        (data) => updateProjectSettings(id, data),
        {
            mutationKey: [QueryKeys.PROJECT_UPDATE, id],
            onSuccess: async () => {
                await queryClient.invalidateQueries(['projects']);
                await queryClient.invalidateQueries(['project', id]);
                showToastSuccess({
                    title: t('workspace_settings_form.update_success_msg'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('workspace_settings_form.update_error_msg'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};

export const useUpdateMutation = (uuid: string) => {
    const queryClient = useQueryClient();
    const { setActiveJobId } = useActiveJob();
    const { showToastApiError } = useNotify();
    return useMutation<ApiJobStartedResults, ApiError, UpdateProject>(
        (data) => updateProject(uuid, data),
        {
            mutationKey: ['project_update', uuid],
            onSuccess: async (data) => {
                setActiveJobId(data.jobUuid);

                await queryClient.invalidateQueries(['projects']);
                await queryClient.invalidateQueries(['project', uuid]);
                await queryClient.invalidateQueries(['tables']);
                await queryClient.invalidateQueries(['queryResults']);
                await queryClient.invalidateQueries(['status']);
            },
            onError: ({ error }) => {
                showToastApiError({
                    title: `Failed to update project`,
                    apiError: error,
                });
            },
        },
    );
};

// export const useCreateMutation = () => {
//     const { setActiveJobId } = useActiveJob();
//     const { showToastError } =useNotify();
//     return useMutation<ApiJobStartedResults, ApiError, CreateProject>(
//         (data) => createProject(data),
//         {
//             mutationKey: [QueryKeys.PROJECT_CREATE],
//             retry: 3,
//             onSuccess: (data) => {
//                 setActiveJobId(data.jobUuid);
//             },
//             onError: (error) => {
//                 showToastError({
//                     title: `Failed to create project`,
//                     subtitle: error.error.message,
//                 });
//             },
//         },
//     );
// };

const getMostPopularAndRecentlyUpdated = async (projectUuid: string) =>
    sortmentApi<MostPopularAndRecentlyUpdated>({
        url: `/projects/${projectUuid}/most-popular-and-recently-updated`,
        method: 'GET',
        body: undefined,
    });

export const useMostPopularAndRecentlyUpdated = (projectUuid: string) =>
    useQuery<MostPopularAndRecentlyUpdated, ApiError>({
        queryKey: ['most-popular-and-recently-updated', projectUuid],
        queryFn: () => getMostPopularAndRecentlyUpdated(projectUuid || ''),
    });

export const useProjectSemanticLayerUpdateMutation = (uuid: string) => {
    const queryClient = useQueryClient();
    return useMutation<undefined, ApiError, SemanticLayerConnectionUpdate>(
        (data) => updateProjectSemanticLayerConnection(uuid, data),
        {
            mutationKey: ['project_semantic_layer_update', uuid],
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', uuid]);
            },
        },
    );
};

export const useProjectSemanticLayerDeleteMutation = (uuid: string) => {
    const queryClient = useQueryClient();
    return useMutation<undefined, ApiError>(
        () => deleteProjectSemanticLayerConnection(uuid),
        {
            mutationKey: ['project_semantic_layer_delete', uuid],
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', uuid]);
            },
        },
    );
};

export const useProjectUpdateSchedulerSettings = (uuid: string) => {
    const queryClient = useQueryClient();
    return useMutation<undefined, ApiError, UpdateSchedulerSettings>(
        (data) => updateProjectSchedulerSettings(uuid, data),
        {
            mutationKey: ['project_scheduler_settings_update', uuid],
            onSuccess: async () => {
                await queryClient.invalidateQueries(['project', uuid]);
                await queryClient.invalidateQueries(['schedulerLogs']);
            },
        },
    );
};

const updateProjectSyncConfig = async (
    projectUuid: string,
    data: ProjectSyncPayload,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/sync-schedule`,
        method: 'PUT',
        body: JSON.stringify(data),
    });

export const useUpdateSyncConfigMutation = (projectUuid: string) => {
    return useMutation<ApiSuccessEmpty, ApiError, ProjectSyncPayload>(
        (data) => updateProjectSyncConfig(projectUuid, data),
        {
            mutationKey: [QueryKeys.PROJECT_UPDATE_SYNC_CONFIG, projectUuid],
        },
    );
};

const getProjectUserAlertPreferences = async (projectUuid: string) =>
    sortmentApi<(UserAlertPreferenceResponse | null)[]>({
        url: `/projects/${projectUuid}/user-alert-preferences`,
        method: 'GET',
        body: undefined,
    });

export const useProjectUserAlertPreferences = (projectUuid: string) =>
    useQuery<(UserAlertPreferenceResponse | null)[], ApiError>({
        queryKey: [QueryKeys.PROJECT_USER_ALERT_PREFERENCES, projectUuid],
        queryFn: () => getProjectUserAlertPreferences(projectUuid || ''),
    });

const updateProjectReachabilityConfig = async (
    projectUuid: string,
    data: ReachabilityConfig,
) =>
    sortmentApi<ApiSuccessEmpty>({
        url: `/projects/${projectUuid}/reachability-config`,
        method: 'PUT',
        body: JSON.stringify(data),
    });
export const useUpdateReachabilityConfigMutation = (projectUuid: string) => {
    const { showToastError, showToastSuccess } = useNotify();
    const { t } = useLocale();
    const queryClient = useQueryClient();

    return useMutation<ApiSuccessEmpty, ApiError, ReachabilityConfig>(
        (data) => updateProjectReachabilityConfig(projectUuid, data),
        {
            mutationKey: [
                QueryKeys.PROJECT_UPDATE_REACHABILITY_CONFIG,
                projectUuid,
            ],
            onSuccess: async () => {
                await queryClient.invalidateQueries([
                    QueryKeys.PROJECT,
                    projectUuid,
                ]);
                showToastSuccess({
                    title: t('reachability_variables.update_success_msg'),
                });
            },
            onError: (error) => {
                showToastError({
                    title: t('reachability_variables.update_error_msg'),
                    subtitle: error.error.message,
                });
            },
        },
    );
};
