import {
    Box,
    ColorPicker,
    Grid,
    Menu,
    Select,
    Stack,
    TextInput,
} from '@mantine/core';
import {
    type Property,
    type PropertyProps,
    type PropertySelect,
    type Sector,
} from 'grapesjs';
import { useState } from 'react';
import { GrapesFieldTypes } from '../../types';
import GrapesIcons from './GrapesIcons';
import IconRadioGroup from './IconRadioGroup';

const GrapesInputField = ({
    data,
    showIcon,
}: {
    data: Property<PropertyProps> | PropertySelect;
    showIcon?: boolean;
}) => {
    const [inputValue, setInputValue] = useState(
        data.hasValue() ? data.getValue() : '',
    );
    const [active, setActive] = useState(false);
    const type = data.getType();
    if (
        type === GrapesFieldTypes.COMPOSITE ||
        type === GrapesFieldTypes.STACK
    ) {
        return (
            <Grid gutter={'sm'}>
                {(data as unknown as Sector)
                    ?.getProperties()
                    ?.map((property: Property<PropertyProps>) => (
                        <Grid.Col
                            key={property.getId()}
                            span={property.getType() === 'color' ? 12 : 6}
                        >
                            <Stack>
                                <GrapesInputField
                                    data={property}
                                    showIcon={true}
                                />
                            </Stack>
                        </Grid.Col>
                    ))}
            </Grid>
        );
    }
    const defaultValue = data.getDefaultValue();
    let options: any[] = [];

    if ('getOptions' in data) {
        options = data?.getOptions()?.map((option) => ({
            label: data?.getOptionLabel(option),
            value: data?.getOptionId(option),
        }));
    }

    const handleTextInput = (e: any) => {
        setInputValue(e.target.value);
        data.upValue(e.target.value);
    };

    const handleSelectInput = (val: string) => {
        setInputValue(val);
        data.upValue(val);
    };

    const handleRadioChange = (val: string) => {
        setInputValue(val);
        data.upValue(val);
    };

    const handleColorInput = (e: any) => {
        setInputValue(e);
        data.upValue(e);
    };

    const getTextInputStyles = () => {
        return {
            root: {
                '& input': {
                    background: 'rgb(var(--color-gray-100)) !important',
                    border: 'none !important',
                    borderRadius: '8px !important',
                    color: 'rgb(var(--color-gray-800)) !important',
                    fontWeight: '500 !important' as any,
                    boxShadow: 'none !important',
                },
                '& input:focus': {
                    background: 'white !important',
                    border: '1px solid rgb(var(--color-blu-800)) !important',
                    color: 'rgb(var(--color-gray-800)) !important',
                    fontWeight: '500 !important' as any,
                },
                '& .mantine-TextInput-icon': {
                    background: `${
                        active ? '#FFF' : 'rgb(var(--color-gray-100))'
                    } !important`,
                    border: active
                        ? '1px solid rgb(var(--color-blu-800)) !important'
                        : 'none',
                    borderRight: 'none !important',
                    borderRadius: '8px !important',
                    borderTopRightRadius: '0 !important',
                    borderBottomRightRadius: '0 !important',
                },
                '& svg': {
                    background: `${
                        active ? '#FFF' : 'rgb(var(--color-gray-100))'
                    } !important`,
                },
                '& .mantine-TextInput-rightSection': {
                    background: `${
                        active ? '#FFF' : 'rgb(var(--color-gray-100))'
                    } !important`,
                    border: active
                        ? '1px solid rgb(var(--color-blu-800)) !important'
                        : 'none',
                    borderRadius: '8px !important',
                    borderLeft: 'none !important',
                    borderTopLeftRadius: '0 !important',
                    borderBottomLeftRadius: '0 !important',
                    paddingRight: '8px !important',
                    '& .color-picker-box': {
                        background: `${inputValue ?? '#000'} !important`,
                    },
                },
            },
            icon: {
                background: 'rgb(var(--color-gray-100)) !important',
            },
        };
    };

    const getSelectInputStyles = () => {
        return {
            root: {
                '& input': {
                    background: 'rgb(var(--color-gray-100)) !important',
                    border: 'none !important',
                    borderRadius: '8px !important',
                    color: 'rgb(var(--color-gray-800)) !important',
                    fontWeight: '500 !important' as any,
                    boxShadow: 'none !important',
                },
                '& input:focus': {
                    background: 'white !important',
                    border: '1px solid rgb(var(--color-blu-800)) !important',
                    color: 'rgb(var(--color-gray-800)) !important',
                    fontWeight: '500 !important' as any,
                },
                '& .mantine-Select-rightSection': {
                    background: `${
                        active ? '#FFF' : 'rgb(var(--color-gray-100))'
                    } !important`,
                    border: active
                        ? '1px solid rgb(var(--color-blu-800)) !important'
                        : 'none',
                    borderRadius: '8px !important',
                    borderLeft: 'none !important',
                    borderTopLeftRadius: '0 !important',
                    borderBottomLeftRadius: '0 !important',
                },
                '& svg': {
                    background: `${
                        active ? '#FFF' : 'rgb(var(--color-gray-100))'
                    } !important`,
                },
                '& .mantine-Select-icon': {
                    background: `${
                        active ? '#FFF' : 'rgb(var(--color-gray-100))'
                    } !important`,
                    border: active
                        ? '1px solid rgb(var(--color-blu-800)) !important'
                        : 'none',
                    borderRight: 'none !important',
                    borderRadius: '8px !important',
                    borderTopRightRadius: '0 !important',
                    borderBottomRightRadius: '0 !important',
                },
            },
        };
    };

    switch (type) {
        case GrapesFieldTypes.COLOR:
            return (
                <TextInput
                    onBlur={() => setActive(false)}
                    onClick={() => setActive(true)}
                    styles={() => getTextInputStyles()}
                    rightSection={
                        <Menu withinPortal>
                            <Menu.Target>
                                <Box
                                    className="color-picker-box"
                                    style={{
                                        width: '20px',
                                        height: '20px',
                                        borderRadius: '4px',
                                    }}
                                />
                            </Menu.Target>
                            <Menu.Dropdown>
                                <ColorPicker
                                    value={inputValue}
                                    onChange={handleColorInput}
                                />
                            </Menu.Dropdown>
                        </Menu>
                    }
                    icon={
                        showIcon && (
                            <GrapesIcons
                                active={active}
                                type={data.getName()}
                            />
                        )
                    }
                    value={inputValue}
                    onChange={handleTextInput}
                    placeholder={defaultValue}
                />
            );
        case GrapesFieldTypes.RADIO:
            return (
                <IconRadioGroup
                    options={options}
                    active={inputValue}
                    onChange={handleRadioChange}
                />
            );
        case GrapesFieldTypes.SELECT:
            return (
                <Select
                    icon={
                        showIcon && (
                            <GrapesIcons
                                active={active}
                                type={data.getName()}
                            />
                        )
                    }
                    value={inputValue}
                    onChange={handleSelectInput}
                    onBlur={() => setActive(false)}
                    onClick={() => setActive(true)}
                    data={options}
                    styles={() => getSelectInputStyles()}
                />
            );
        case GrapesFieldTypes.NUMBER:
        default:
            return (
                <TextInput
                    onBlur={() => setActive(false)}
                    onClick={() => setActive(true)}
                    styles={() => getTextInputStyles()}
                    icon={
                        showIcon && (
                            <GrapesIcons
                                active={active}
                                type={data.getName()}
                            />
                        )
                    }
                    value={inputValue}
                    onChange={handleTextInput}
                    placeholder={defaultValue}
                />
            );
    }
};

export default GrapesInputField;
