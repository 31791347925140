import {
    AlignBottomSimple,
    AlignLeftSimple,
    AlignRightSimple,
    AlignTopSimple,
    ArrowsHorizontal,
    ArrowsOutLineHorizontal,
    ArrowsOutLineVertical,
    ArrowsVertical,
    Eyedropper,
    Gradient,
    TextAa,
} from '@phosphor-icons/react';
import { CssProperties } from '../../types';
import BorderBottomLeftRadius from './BorderBottomLeftRadius';
import BorderBottomRightRadius from './BorderBottomRightRadius';
import BorderTopLeftRadius from './BorderTopLeftRadius';
import BorderTopRightRadius from './BorderTopRightRadius';

const GrapesIcons = ({ type, active }: { type: string; active: boolean }) => {
    const color = active
        ? 'rgb(var(--color-blu-800))'
        : 'rgb(var(--color-gray-600))';
    switch (type) {
        case CssProperties.FONT_FAMILY:
            return <TextAa weight="duotone" color={color} />;
        case CssProperties.MAX_HEIGHT:
            return <ArrowsOutLineVertical weight="regular" color={color} />;
        case CssProperties.LINE_HEIGHT:
        case CssProperties.HEIGHT:
            return <ArrowsVertical weight="regular" color={color} />;
        case CssProperties.MAX_WIDTH:
            return <ArrowsOutLineHorizontal weight="regular" color={color} />;
        case CssProperties.LETTER_SPACING:
        case CssProperties.WIDTH:
            return <ArrowsHorizontal weight="regular" color={color} />;
        case CssProperties.BORDER_COLOR:
        case CssProperties.BACKGROUND_COLOR:
            return <Eyedropper weight="duotone" color={color} />;
        case CssProperties.BORDER_WIDTH:
            return <Gradient weight="duotone" color={color} />;
        case CssProperties.BORDER_TOP_LEFT_RADIUS:
            return <BorderTopLeftRadius />;
        case CssProperties.BORDER_TOP_RIGHT_RADIUS:
            return <BorderTopRightRadius />;
        case CssProperties.BORDER_BOTTOM_RIGHT_RADIUS:
            return <BorderBottomRightRadius />;
        case CssProperties.BORDER_BOTTOM_LEFT_RADIUS:
            return <BorderBottomLeftRadius />;
        case CssProperties.PADDING_LEFT:
        case CssProperties.MARGIN_LEFT:
            return <AlignLeftSimple weight="duotone" color={color} />;
        case CssProperties.PADDING_RIGHT:
        case CssProperties.MARGIN_RIGHT:
            return <AlignRightSimple weight="duotone" color={color} />;
        case CssProperties.PADDING_BOTTOM:
        case CssProperties.MARGIN_BOTTOM:
            return <AlignBottomSimple weight="duotone" color={color} />;
        case CssProperties.PADDING_TOP:
        case CssProperties.MARGIN_TOP:
        default:
            return <AlignTopSimple weight="duotone" color={color} />;
    }
};

export default GrapesIcons;
