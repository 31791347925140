import {
    createFilterRuleFromEventNameField,
    prepareAdditionalMetricData,
    shouldConvertFieldToAdditionalMetric,
} from '@components/Audience/utils';
import { type MetricFilterRuleWithFieldId } from '@components/Explorer/CustomMetricModal/FilterForm';
import { addFieldIdToMetricFilterRule } from '@components/Explorer/CustomMetricModal/utils';
import {
    addFilterRule,
    DimensionType,
    FieldType,
    FilterOperator,
    isField,
    isFilterableField,
    MetricType,
    RelationTableType,
    ReservedAudienceKeys,
    type AdditionalMetric,
    type CompiledRelation,
    type Filters,
    type MetricFilterRule,
} from '@lightdash/common';
import { getBaseTableUserFieldId } from '.';
import { type FieldWithSuggestions } from '../FiltersProvider';

interface CreateFilterRuleProps {
    field: FieldWithSuggestions;
    additionalMetrics: AdditionalMetric[] | undefined;
    setAdditionalMetrics:
        | ((
              value: AdditionalMetric[],
              shouldFetchResults: boolean,
              index: number,
          ) => void)
        | undefined;
    activeRelation: CompiledRelation | undefined;
    filters: Filters;
    setFilters: (
        value: Filters,
        shouldFetchResults: boolean,
        index: number,
    ) => void;
    index: number;
    additionalMetricsFilters: MetricFilterRule[] | undefined;
}

interface FilterRuleResult {
    additionalMetricData?: AdditionalMetric;
    filterRule?: Filters;
}

/**
 * Helper function to prepare data for additional metrics and filter rules.
 * @param props - Properties required to create filter rules.
 * @returns An object containing additional metric data and filter rule.
 */
export const prepareFilterRuleData = ({
    field,
    activeRelation,
    filters,
    additionalMetricsFilters,
}: Omit<
    CreateFilterRuleProps,
    'setAdditionalMetrics' | 'setFilters'
>): FilterRuleResult => {
    let value: string | number = 1;
    if (
        activeRelation &&
        shouldConvertFieldToAdditionalMetric(field, activeRelation)
    ) {
        let newMetricFilters: MetricFilterRuleWithFieldId[] =
            additionalMetricsFilters?.map(addFieldIdToMetricFilterRule) ?? [];

        if (
            field.tableType === RelationTableType.EVENT &&
            field.type === DimensionType.EVENT
        ) {
            newMetricFilters = [
                ...newMetricFilters,
                addFieldIdToMetricFilterRule(
                    createFilterRuleFromEventNameField(
                        field,
                        field.fieldReference ?? '',
                    ),
                ),
            ];
        }

        const data = prepareAdditionalMetricData({
            item: field,
            type: MetricType.COUNT_DISTINCT,
            metricFilters: newMetricFilters,
            activeRelation,
        });
        if (!data) return {};

        const newFilterRule = addFilterRule({
            filters,
            field: {
                ...data,
                isAutoGenerated: false,
                fieldType: FieldType.METRIC,
                label: data.label ?? '',
                tableLabel: data.tableLabel ?? '',
                hidden: false,
            },
            value: 1,
            operator: FilterOperator.GREATER_THAN_OR_EQUAL,
        });

        return {
            additionalMetricData: data,
            filterRule: newFilterRule,
        };
    }

    if (field.tableType === RelationTableType.AUDIENCE) {
        value = `${ReservedAudienceKeys.SRT_AUDIENCE}${field.table}`;
        field.name = activeRelation
            ? getBaseTableUserFieldId(activeRelation)
            : field.name;
    }
    if (isField(field) && isFilterableField(field)) {
        const newFilterRule = addFilterRule({
            filters,
            field,
            ...(Boolean(value) && { value }),
        });
        return { filterRule: newFilterRule };
    }

    return {};
};

/**
 * Main function to create filter rules and update state.
 * @param props - Properties required to create filter rules.
 */
export const createFilterRule = (props: CreateFilterRuleProps) => {
    const { setAdditionalMetrics, setFilters, index } = props;
    const { additionalMetricData, filterRule } = prepareFilterRuleData(props);

    if (additionalMetricData && setAdditionalMetrics) {
        setAdditionalMetrics(
            [...(props.additionalMetrics ?? []), additionalMetricData],
            false,
            index,
        );
    }

    if (filterRule) {
        setFilters(filterRule, false, index);
    }
};
