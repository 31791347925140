import StepTitle from '@components/Campaigns/Builder/Steps/StepTitle';
import { CampaignBuilderStep } from '@components/Campaigns/Builder/types';
import { CampaignBuilderSteps } from '@components/Campaigns/Builder/utils';
import { useUpdateCampaign } from '@hooks/useCampaigns';
import { useAudiences } from '@hooks/useGetAudience';
import {
    AudienceInternalTags,
    AudienceRunStatus,
    AudienceStatus,
    AudienceType,
    CommonReservedTags,
} from '@lightdash/common';
import { Box } from '@mantine/core';
import { useCampaignContext } from '@providers/CampaignProvider';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SelectCampaignAudience from './SelectCampaignAudience';
import SelectedCampaignAudience from './SelectedCampaignAudience';

const CampaignBuilderAudience: React.FC<{}> = ({}) => {
    const initialData = useRef<any>(null);
    const { campaignUuid } = useParams<{ campaignUuid: string }>();
    const { campaignPayload } = useCampaignContext((context) => context.state);
    const { setCurrentStepCallback } = useCampaignContext(
        (context) => context.actions,
    );
    const { mutate: updateCampaign } = useUpdateCampaign(
        campaignUuid ?? '',
        false,
    );

    const {
        data,
        refetch: refetchAudiences,
        isLoading,
        isFetching,
        isInitialLoading,
    } = useAudiences({
        query: `status=${AudienceStatus.ACTIVE}&lastRunStatus=${AudienceRunStatus.SUCCESS}&excludesTags=${CommonReservedTags.HIDDEN},${AudienceInternalTags.INTERNAL}`,
        perPage: 9999,
        currentPage: 1,
        polling: false,
    });

    useEffect(() => {
        if (initialData.current === null) {
            initialData.current = campaignPayload;
        }
    }, [campaignPayload]);

    const handleNextStep = useCallback(() => {
        if (
            (campaignPayload.audienceId &&
                initialData.current.audienceId ===
                    campaignPayload.audienceId) ||
            (campaignPayload.csvUploadDetails?.uploadId &&
                initialData.current.csvUploadDetails?.uploadId ===
                    campaignPayload.csvUploadDetails?.uploadId)
        ) {
            return;
        }
        if (campaignPayload.audienceType === AudienceType.WAREHOUSE) {
            updateCampaign({
                audienceType: campaignPayload.audienceType,
                audienceId: campaignPayload.audienceId,
            });
        } else if (campaignPayload.audienceType === AudienceType.CSV) {
            updateCampaign({
                audienceType: campaignPayload.audienceType,
                csvUploadDetails: campaignPayload.csvUploadDetails,
            });
        }
    }, [campaignPayload, updateCampaign]);

    useEffect(() => {
        setCurrentStepCallback({
            callback: handleNextStep,
            skipExecutionAfterCallback: false,
        });
    }, [handleNextStep, setCurrentStepCallback]);

    const title = CampaignBuilderSteps.find(
        (step) => step.key === CampaignBuilderStep.AUDIENCE,
    )?.pageHeader;

    const handleRefresh = useCallback(() => {
        void refetchAudiences();
    }, [refetchAudiences]);

    const renderAudienceContent = useMemo(() => {
        if (
            campaignPayload.audienceType === AudienceType.WAREHOUSE &&
            campaignPayload.audienceId
        ) {
            return (
                <SelectedCampaignAudience
                    audiences={data?.data}
                    isLoading={isLoading || isInitialLoading}
                />
            );
        }
        if (
            campaignPayload.audienceType === AudienceType.CSV &&
            campaignPayload.csvUploadDetails?.fileName
        ) {
            return (
                <SelectedCampaignAudience
                    audiences={data?.data}
                    isLoading={isLoading || isInitialLoading}
                />
            );
        }
        return (
            <SelectCampaignAudience
                audiences={data?.data}
                isLoading={isLoading}
                isFetching={isFetching}
                onRefresh={handleRefresh}
            />
        );
    }, [
        campaignPayload,
        data,
        handleRefresh,
        isFetching,
        isInitialLoading,
        isLoading,
    ]);

    return (
        <Box>
            <StepTitle title={title || ''} />
            <Box className="mt-6 max-w-[800px] w-[600px]">
                {renderAudienceContent}
            </Box>
        </Box>
    );
};

export default CampaignBuilderAudience;
