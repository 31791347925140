import InputErrorText from '@components/common/InputErrorText';
import {
    Box,
    Overlay,
    Stack,
    Text,
    Textarea as MantineTextarea,
    type TextareaProps as MantineTextareaProps,
} from '@mantine/core';
import { Sparkle } from '@phosphor-icons/react';
import React from 'react';

interface TextAreaProps extends MantineTextareaProps {
    error?: React.ReactNode;
    description?: React.ReactNode;
    errorClass?: string;
    aiGeneratedData?: string;
    isAiGeneratingData?: boolean;
}

const getTextAreaIcon = (
    icon: MantineTextareaProps['icon'],
    isAiGeneratingData: boolean | undefined,
    aiGeneratedData: string | undefined,
    value: MantineTextareaProps['value'],
) => {
    if ((aiGeneratedData && value === aiGeneratedData) || isAiGeneratingData) {
        return <Sparkle color="rgb(var(--color-purple))" />;
    }
    if (icon) {
        return icon;
    }
    return null;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            error,
            description,
            errorClass,
            aiGeneratedData,
            isAiGeneratingData,
            onChange,
            value: propValue,
            ...rest
        },
        ref,
    ) => {
        // TODO - Sai and Manoj to discuss and fix this later
        // const inputRef = useRef<HTMLTextAreaElement>(null);
        // const [localValue, setLocalValue] = useState(propValue ?? '');
        // const handleChange = useCallback(
        //     (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        //         const { value, selectionStart } = event.target;
        //         setLocalValue(value);

        //         if (onChange) {
        //             onChange(event);
        //         }

        //         // Preserve cursor position
        //         if (inputRef.current && selectionStart !== null) {
        //             requestAnimationFrame(() => {
        //                 inputRef.current!.setSelectionRange(
        //                     selectionStart,
        //                     selectionStart,
        //                 );
        //             });
        //         }
        //     },
        //     [onChange],
        // );

        return (
            <Stack className="gap-1.5">
                <Box className="relative">
                    <MantineTextarea
                        icon={getTextAreaIcon(
                            rest.icon,
                            isAiGeneratingData,
                            aiGeneratedData,
                            propValue,
                        )}
                        styles={() =>
                            aiGeneratedData && aiGeneratedData === propValue
                                ? {
                                      input: {
                                          border: '1px solid rgb(var(--color-purple))',
                                          ':focus-within': {
                                              border: '1px solid rgb(var(--color-purple))',
                                          },
                                      },
                                  }
                                : {}
                        }
                        value={propValue}
                        onChange={onChange}
                        // ref={(node) => {
                        //     if (typeof ref === 'function') {
                        //         ref(node);
                        //     } else if (ref && 'current' in ref) {
                        //         (
                        //             ref as React.MutableRefObject<HTMLTextAreaElement | null>
                        //         ).current = node;
                        //     }
                        //     (
                        //         inputRef as React.MutableRefObject<HTMLTextAreaElement | null>
                        //     ).current = node;
                        // }}
                        ref={ref}
                        {...rest}
                    />
                    {isAiGeneratingData && (
                        <Overlay className="h-5 top-8 ml-8 mr-3 bg-white">
                            <Box className="w-full h-full bg-purple/20 animate-pulse" />
                        </Overlay>
                    )}
                </Box>
                {error && (
                    <InputErrorText className={errorClass} value={error} />
                )}
                {description && (
                    <Text className="text-sm font-medium text-gray-500">
                        {description}
                    </Text>
                )}
            </Stack>
        );
    },
);

export default TextArea;
