import { type FilterRule, type JourneyFiltersConfig } from '@lightdash/common';
import React from 'react';

import FiltersForm from './FiltersForm';
import { JourneyFiltersProvider } from './FiltersForm/JourneyFiltersProvider';

/**
 * Props for the JourneyFilters component.
 */
interface JourneyFiltersProps {
    /** Function called when the filter rules are changed. */
    onChange: (value: FilterRule[]) => void;

    /** The list of filters that are currently applied to the selected node in the journey. */
    filters: JourneyFiltersConfig;

    /** Function called when the filters are changed. */
    setFilters: (value: JourneyFiltersConfig) => void;

    /** The project UUID. */
    projectUuid: string;

    /** The ID of the selected node. */
    nodeId: string;
}

const JourneyFilters: React.FC<JourneyFiltersProps> = ({
    filters,
    setFilters,
    nodeId,
}) => {
    return (
        <JourneyFiltersProvider nodeId={nodeId}>
            <FiltersForm filters={filters} setFilters={setFilters} />
        </JourneyFiltersProvider>
    );
};

export default React.memo(JourneyFilters);
